const EditMessage = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0501 2.71671L12.7834 1.98337C13.6501 1.11671 15.1584 1.11671 16.0251 1.98337L16.6167 2.57504C17.0501
         3.00837 17.2917 3.58337 17.2917 4.19171C17.2917 4.80004 17.0501 5.38337 16.6167 5.80837L15.8834 6.54171L12.0501 
         2.70837V2.71671ZM11.1667 3.60004L3.57508 11.1917C3.33341 11.4334 3.18341 11.75 3.15841 12.0917L2.93341 
         14.5334C2.90841 14.8417 3.01675 15.1417 3.23341 15.3667C3.43341 15.5667 3.69175 15.675 3.96675 15.675H4.05841L6.50008
          15.45C6.84175 15.4167 7.15842 15.2667 7.40008 15.025L14.9917 7.43337L11.1584 3.60004H11.1667ZM18.9584 18.3334C18.9584
           17.9917 18.6751 17.7084 18.3334 17.7084H1.66675C1.32508 17.7084 1.04175 17.9917 1.04175 18.3334C1.04175 18.675 1.32508
            18.9584 1.66675 18.9584H18.3334C18.6751 18.9584 18.9584 18.675 18.9584 18.3334Z"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default EditMessage;
