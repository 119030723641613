import { DialogBody } from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import JobInformation from "components/contact/sidebar/common/JobInformation";
import PersonalInformation from "components/contact/sidebar/common/PersonalInfomation";
import ProfileInfo from "components/contact/sidebar/common/ProfileInfo";
import { QUERY_KEY } from "constants/pathApi";
import { getInfoUserLogin } from "http/contact.http";
import { UserContact } from "types/user.type";

const DialogMyProfile = () => {
  const { data: userDetail } = useQuery({
    queryKey: [QUERY_KEY.USER_LOGIN_INFO],
    queryFn: () => getInfoUserLogin<UserContact>(),
    ...{
      refetchOnWindowFocus: true,
    },
    staleTime: 0,
  });

  return (
    <DialogBody
      placeholder={"dialog"}
      className="px-8 pt-0 text-neutral-110 !max-h-[818px] flex-1 hover:overflow-y-scroll scroll-sidebar overflow-hidden"
    >
      <div className="">
        <ProfileInfo isUploadAvatar={true} userDetailContact={userDetail} />
        <div className="h-[7px] px-0 bg-primary-200"></div>
        <div className="pt-3">
          <PersonalInformation userDetailContact={userDetail} />
        </div>
        <div className="h-[7px] px-0 bg-primary-200"></div>
        <div className="pt-3">
          <JobInformation userDetailContact={userDetail} />
        </div>
      </div>
    </DialogBody>
  );
};

export default DialogMyProfile;
