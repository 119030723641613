import { MutableRefObject, SyntheticEvent } from "react";
import { API_URL, BASE_SRC_FILE, DEFAULT_AVATAR, DEFAULT_AVATAR_CONVERSATION } from "../constants/pathApi";
import { InfiniteData } from "@tanstack/react-query";
import { includes, isArray, isEmpty, omitBy, partition } from "lodash";
import {
  TypeValidateFile,
  PaginationResponse,
  TYPE_REFETCH,
  TYPE_PAGE_PARAM,
  ResponseTypeCommon,
  ParamSearchMessage,
} from "types/utils.type";
import { CONTENT_TYPE_FILE, SIZE_FILE_UPLOAD, TYPE_DATE_FILTER_DAY } from "types/message.type";
import { ACCEPT_INPUT_SEND, FILE_EXTENSIONS, ROLE_MEMBER, TYPE_FILE } from "constants/constans";
import { TYPE_STATUS_VALIDATE } from "constants/statusCode";
import { t } from "i18next";
import moment from "moment";
import { CHAT } from "constants/pathUrl";
import * as axiosClient from "api/axiosClient";
export function getFullPathFileAvatar(sortPath: string | undefined, isConversation = false) {
  if (!sortPath) return isConversation ? DEFAULT_AVATAR_CONVERSATION : DEFAULT_AVATAR;
  return getFullPathFile(sortPath);
}
export async function getPathWithToken(path:string) {
  try {
    const response:Blob = await axiosClient.getAxios(path, { responseType: "blob" });
    return window.URL.createObjectURL(response);
  } catch (error) {
    return "";
  }
}
export function getFullPathFile(sortPath: string | undefined) {
  if (!sortPath) return "";
  return BASE_SRC_FILE + sortPath;
}

export function defaultAvatar(e: SyntheticEvent<HTMLImageElement, Event>, isConversation = false) {
  e.currentTarget.src = isConversation ? DEFAULT_AVATAR_CONVERSATION : DEFAULT_AVATAR;
}

export function isGIF(url: string) {
  return url.toLowerCase().endsWith(".gif");
}
interface TypeInfiniteQuery<T> {
  data: T[];
}

export function mapDataInfiniteQuery<T, K extends TypeInfiniteQuery<T>>(data: InfiniteData<K, unknown> | undefined): T[] | [] {
  if (data && !isEmpty(data)) {
    const rawData = data.pages?.flatMap((page) => page?.data ?? []) ?? [];
    return rawData;
  } else {
    return [];
  }
}

// get next page InfiniteQuery
export function getNextPageIndex(pagination: PaginationResponse | undefined): number | undefined {
  if (pagination) return pagination?.current_page < pagination?.total_page ? +pagination?.current_page + 1 : undefined;
  return undefined;
}
// get previous page InfiniteQuery
export function getPreviousPageIndex(pagination: PaginationResponse | undefined): number | undefined {
  if (pagination) return pagination?.current_page <= 1 ? undefined : +pagination?.current_page - 1;
  return undefined;
}

// get next page InfiniteQuery list message
export function getNextPageIndexMessage(pagination: PaginationResponse | undefined): TYPE_PAGE_PARAM | undefined {
  //When current_page == null => has next page
  if (pagination)
    return {
      page: +pagination?.current_page + 1,
      typeRefetch: TYPE_REFETCH.NEXT_PAGE,
    };
  return undefined;
}
// get previous page InfiniteQuery
export function getPreviousPageIndexMessage(pagination: PaginationResponse | undefined) : TYPE_PAGE_PARAM | undefined {
  //When current_page == null => has previous page
  if (pagination)
    return !pagination?.current_page
      ? {
        page: 1,
        typeRefetch: TYPE_REFETCH.PREVIOUS_PAGE,
      }
      : undefined;
  return undefined;
}

// get next page InfiniteQuery with data last record
export function getNextPageWithLastIdRecord<K, Q extends ResponseTypeCommon<K>>(
  pagination: PaginationResponse | undefined,
  data: Q[],
): K | undefined {
  if (data?.length === 0) return undefined;
  const lastRecord = data?.[data.length - 1];
  return lastRecord?.data[lastRecord?.data?.length - 1];
}

// get custom param 
export const getCustomParam = ({
  from,
  keyword,
  last_record_id,
  senders,
  to,
  typeToFilter,
  conversation_id,
}: ParamSearchMessage): any => {
  const obj = {
    ...last_record_id ? { last_record_id } : {},
    keyword: keyword,
    from: from ? moment(from).format("DD/MM/YYYY") : from,
    to: to ? moment(to).format("DD/MM/YYYY") : to,
    typeRefetch: typeToFilter ? typeToFilter : TYPE_DATE_FILTER_DAY.CUSTOM,
    senders: senders ? JSON.stringify([senders]) : [],
    ...(conversation_id ? { conversation_id } : {}),
  };
  return omitBy(obj, (v) => (!v && v !== 0));
};

/**
 * cover Seconds To Min
 *
 * @param   {number}  duration  vd: 7.00s
 *
 * @return  {[string]}            0.07m
 */
export function coverSecondsToMin(duration: number): string {
  return [Math.floor((duration % 3600) / 60), ("00" + Math.floor(duration % 60)).slice(-2)].join(":");
}
export function coverSecondsToMinRecord(duration: number): string {
  return [
    Math.floor((duration % 3600000) / 60000), // minutes
    Math.floor((duration % 60000) / 1000), // seconds
  ]
    .map((v) => (v < 10 ? "0" + v : v))
    .join(":");
}
// get unique id
export function getUniqueId() {
  return Math.random().toString(36).substr(2, 8);
}

// cover min to milliseconds
export const minutesToMilliseconds = (minutes: number): number => minutes * 60 * 1000;

// get dynamic api url
export const replaceDynamicApi = (text: string, dynamicValue: number | string | number[]): string => {
  const replacementArray = isArray(dynamicValue) ? dynamicValue : [dynamicValue];

  return text.replace(/{{(.*?)}}/g, (_, p1) => {
    const replacement = replacementArray.shift();
    return replacement !== undefined ? replacement.toString() : p1;
  });
};

// get extension file and return type constans
export function getTypeFile(file: File): number | null {
  const fileName = file.name;
  const fileExtension = fileName.split(".").pop()?.toLowerCase();

  let type = null;

  if (includes(FILE_EXTENSIONS.IMAGE, fileExtension)) {
    type = CONTENT_TYPE_FILE.IMG;
  } else if (includes(FILE_EXTENSIONS.VIDEO, fileExtension)) {
    type = CONTENT_TYPE_FILE.VIDEO;
  } else if (includes(FILE_EXTENSIONS.AUDIO, fileExtension)) {
    type = CONTENT_TYPE_FILE.AUDIO;
  } else if (includes(FILE_EXTENSIONS.DOCUMENT, fileExtension)) {
    type = CONTENT_TYPE_FILE.OTHER;
  } else {
    type = CONTENT_TYPE_FILE.OTHER;
  }

  return type;
}

/**
 * check file size upload
 *
 * @param   {File}    file
 * @param   {string}  accept  ex: ".xls, .xlsx, .pdf, .doc, .docx"
 *
 * @return  {File}    if file invalid validate
 * @return  {null}    if file pass validate
 */
export function validateUploadedFiles(file: File, accept?: string): TypeValidateFile | null {
  const type = getTypeFile(file);
  const size = file.size / (1024 * 1024);

  // if the file is not valid
  if (!type)
    return {
      typeError: TYPE_STATUS_VALIDATE.ERROR_EXTENSION,
      file,
    };

  // Check if the file belongs to accept type
  if (accept) {
    const getAccept = includes(getAcceptType(accept), type);
    if (!getAccept)
      return {
        typeError: TYPE_STATUS_VALIDATE.ERROR_EXTENSION,
        file,
      };
  }

  // Check size corresponds to type
  if ((type === CONTENT_TYPE_FILE.IMG || type === CONTENT_TYPE_FILE.AUDIO) && size > SIZE_FILE_UPLOAD.IMG_AUDIO)
    return {
      typeError: TYPE_STATUS_VALIDATE.ERROR_SIZE,
      file,
    };
  if (type === CONTENT_TYPE_FILE.VIDEO && size > SIZE_FILE_UPLOAD.VIDEO)
    return {
      typeError: TYPE_STATUS_VALIDATE.ERROR_SIZE,
      file,
    };
  if (type === CONTENT_TYPE_FILE.OTHER && size > SIZE_FILE_UPLOAD.OTHER)
    return {
      typeError: TYPE_STATUS_VALIDATE.ERROR_SIZE,
      file,
    };

  // if allow return null
  return null;
}

/**
 * Match file accept input attribute with type
 *
 * @param   {string}  accept  ex ".xls, .xlsx, .pdf, .doc, .docx"
 *
 * @return  {number[]}      list type file accept
 */
export function getAcceptType(accept: string): number[] {
  if (accept === ACCEPT_INPUT_SEND.IMG_VIDEO_AUDIO) return [CONTENT_TYPE_FILE.IMG, CONTENT_TYPE_FILE.VIDEO, CONTENT_TYPE_FILE.AUDIO];
  if (accept === ACCEPT_INPUT_SEND.FILE_OTHER) 
    return [CONTENT_TYPE_FILE.OTHER, CONTENT_TYPE_FILE.IMG, CONTENT_TYPE_FILE.VIDEO, CONTENT_TYPE_FILE.AUDIO];
  return [];
}

/**
 * GET ROLL LABEL USER BY ID
 */
export const GetRoleLabels = (role: number) => {
  switch (role) {
    case ROLE_MEMBER.ADMIN:
      return t("roles.admin");
    case ROLE_MEMBER.MEMBER:
      return t("roles.member");
    default:
      return "";
  }
};

export const convertTypeDatetime = (date: string | undefined, format_date: string) => {
  const dateObject = moment(date, "HH:mm DD/MM/YYYY");
  // const formattedDate = dateObject.format("HH:mm, DD MMM YYYY");
  const formattedDate = dateObject.format(format_date);

  return formattedDate;
};

/**
 * Check accessToken expires
 * return true if token expires
 */
export const checkAccessTokenExpires = (expirationTime: number) => {
  const currentTime = Date.now();
  return currentTime >= expirationTime;
};

/**
 * Redirect check login
 */
export const redirectCheckLogin = () => {
  const params = new URLSearchParams({
    client_id: process.env.REACT_APP_CLIENT_ID || "",
    redirect_uri: process.env.REACT_APP_LOGIN_CALLBACK || "",
    response_type: "code",
    scope: "",
  }).toString();

  const checkLoginUrl = process.env.REACT_APP_DOMAIN_BEEID + API_URL.LOGIN_BEEID + `?${params}`;
  window.location.href = checkLoginUrl;
};

/**
 * Redirect logout
 */
export const redirectLogout = (deviceToken?:string | null) => {
  const domain = window.location.origin;
  window.location.href = process.env.REACT_APP_DOMAIN_BEEID + API_URL.LOGOUT + 
                          "?redirect_uri=" + domain + CHAT + 
                          (deviceToken ? `&device_token=${deviceToken}` : "");
};

export const downloadFile = async (url: string | undefined, fileName: string | undefined) => {
  try {
    if (url && fileName) {
      const link = document.createElement("a");
      link.href = url + "&download=1&fileName=" + fileName;
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Function to extract the file extension from the given file name.
 * @param fileName The name of the file.
 * @returns The file extension (including the dot) if found, otherwise an empty string.
 */
export const shortenFileName = (fileName: string | undefined) => {
  if (fileName) {
    const lastDotIndex = fileName.lastIndexOf(".");

    if (lastDotIndex !== -1) {
      return fileName.slice(lastDotIndex);
    } else {
      return "";
    }
  }
};

/**
 * Function to cover byte to mb, kb, gb, ti.
 * @returns string
 */
export const coverByteToKBAndMB = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Function to remove the file extension from the given file name.
 * @param fileName The name of the file.
 * @returns The file name without the file extension.
 */
export const removeFileExtension = (fileName: string | undefined) => {
  if (fileName) {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return fileName.slice(0, lastDotIndex);
    } else {
      return fileName;
    }
  }
};

/**
 * splice file name and insert ...
 * @param fileName The name of the file.
 * @param maxLength max file name length
 * @returns filename substring
 */
export function truncateFileName(fileName: string, maxLength: number) {
  if (fileName.length > maxLength) {
    const extensionIndex = fileName.lastIndexOf(".");
    const namePart = fileName.substring(0, extensionIndex);
    const extensionPart = fileName.substring(extensionIndex);
    const truncatedNamePart = namePart.substring(0, maxLength - extensionPart.length - 4);
    return truncatedNamePart + "..." + extensionPart;
  } else {
    return fileName;
  }
}

/**
 * extract file video and img or file other
 * @param listFile The list file has file media and other.
 */
export function separateFilesMediaAndOther(listFile: any) {
  const [fileMedia, fileOther] = partition(listFile, (file) => {
    return +file.type === TYPE_FILE.IMAGE || +file.type === TYPE_FILE.VIDEO;
  });
  return { fileMedia, fileOther };
}


export function scrollToElementDelay(listRef: MutableRefObject<HTMLDivElement | null>, element:string, delay = 200) {
  setTimeout(()=>{
    if(listRef.current){
      listRef.current
        .querySelector(element)
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, delay);
}
