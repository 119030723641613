import { SetStateAction, useState, Dispatch, useMemo, useEffect } from "react";
import ActionFile from "components/files/ActionFile";
import PreviewFileModal from "components/files/PreviewFileModal";
import { TYPE_SEARCH_MAIN } from "constants/constans";
import { t } from "i18next";
import { getPathFileOther } from "logic/message.logic";
import { TYPE_MESSAGE_FILE } from "types/message.type";
import { mapDataInfiniteQuery, removeFileExtension, shortenFileName } from "utils/common";
import { highlightTextSearch } from "utils/strUtils";
import { ResponseTypeCommon } from "types/utils.type";
import { InfiniteData } from "@tanstack/react-query";
import { Spinner } from "@material-tailwind/react";

interface SearchResultFileProps {
  activeTab: TYPE_SEARCH_MAIN;
  keywordSearch: string;
  isActiveSearch: string;
  isFetchingNextPageFile?: boolean;
  files: InfiniteData<ResponseTypeCommon<TYPE_MESSAGE_FILE>, unknown> | undefined;
  handleSetActiveTab: (value: TYPE_SEARCH_MAIN) => void;
  redirectToMessage: (conversationId: number | undefined, messageId: number, reply_id: number | null) => void;
}

interface ResultFileItemProps {
  file: TYPE_MESSAGE_FILE;
  keywordSearch: string;
  setDataPreview: Dispatch<SetStateAction<TYPE_MESSAGE_FILE | null | undefined>>;
  redirectToMessage: (conversationId: number | undefined, messageId: number, reply_id: number | null) => void;
}
const SearchResultFile = ({
  activeTab,
  keywordSearch,
  files,
  handleSetActiveTab,
  redirectToMessage,
  isActiveSearch,
  isFetchingNextPageFile = false,
}: SearchResultFileProps) => {
  const [dataPreview, setDataPreview] = useState<TYPE_MESSAGE_FILE | null | undefined>();
  const [initSearchValue, setInitSearchValue] = useState<string>(keywordSearch);

  const data = useMemo(() => {
    if (!files) return [];
    const mapData = mapDataInfiniteQuery<TYPE_MESSAGE_FILE, ResponseTypeCommon<TYPE_MESSAGE_FILE>>(files);
    if (activeTab === TYPE_SEARCH_MAIN.ALL && mapData.length > 0) return mapData.slice(0, 5);
    return mapData;
  }, [files]);

  useEffect(() => {
    setInitSearchValue(keywordSearch);
  }, [isActiveSearch]);

  if (data.length === 0) {
    if (activeTab === TYPE_SEARCH_MAIN.FILE) return <p className="text-center border-t pt-[30px]">{t("alert.no_data")}</p>;
    return <></>;
  }
  const total = files?.pages?.[0]?.total || 0;
  return (
    <div className="border-t border-primary-200 px-8 h-full">
      <p className="font-normal text-sm mt-3 mb-2">
        {t("search_main.file")} ({total >= 100 ? "99+" : total})
      </p>
      {data.map((file) => (
        <ResultFileItem
          redirectToMessage={redirectToMessage}
          file={file}
          keywordSearch={initSearchValue}
          key={file.id}
          setDataPreview={setDataPreview}
        />
      ))}
      {!!dataPreview && <PreviewFileModal data={dataPreview} openModal={!!dataPreview} closeModal={() => setDataPreview(undefined)} />}
      {isFetchingNextPageFile && <Spinner className="w-10 h-10 mx-auto mt-1" />}
      {activeTab === TYPE_SEARCH_MAIN.ALL && (
        <div
          onClick={() => handleSetActiveTab(TYPE_SEARCH_MAIN.FILE)}
          className="cursor-pointer w-full h-8 bg-neutral-20
                text-primary-900 flex justify-center items-center rounded-md my-2 font-normal text-sm"
        >
          {t("search_main.see_more")}
        </div>
      )}
    </div>
  );
};

const ResultFileItem = ({ file, keywordSearch, setDataPreview, redirectToMessage }: ResultFileItemProps) => {
  const [openAction, setOpenAction] = useState<boolean>(false);

  const handleScrollMessage = () => {
    redirectToMessage(file?.conversation_id, file?.message_id, +file.reply_id);
  };

  return (
    <div className="flex items-center h-14" onMouseLeave={() => setOpenAction(false)}>
      <div className="mr-3">
        <img className="object-cover w-8 h-8" src={getPathFileOther(file?.name)} alt="file img" />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex text-sm text-neutral-110">
          <span className="overflow-hidden text-sm max-w-80 lg:max-w-96 file_name text-neutral-110 text-ellipsis whitespace-nowrap">
            {highlightTextSearch(keywordSearch, removeFileExtension(file.name) || "", false)}
          </span>
          <span className="h-full text-sm text-neutral-110">{shortenFileName(file.name)}</span>
        </div>
        <div className="flex justify-between text-neutral-60 text-xs items-center">
          <p>
            {file.size} | {file.user_name}
          </p>
          <p>10:44 PM</p>
        </div>
      </div>
      <ActionFile
        setDataPreview={setDataPreview}
        openAction={openAction}
        setOpenAction={setOpenAction}
        isPending={true}
        hiddenDeleteAndCopy={true}
        data={file}
        handleScrollMessage={handleScrollMessage}
        deleteFile={() => {}}
        isPreview={true}
        placement="bottom-end"
      />
    </div>
  );
};

export default SearchResultFile;
