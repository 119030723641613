/* eslint-disable max-len */
interface BySenderIconProps{
    color?: string
}
export default function BySenderIcon({ color }: BySenderIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={`${color}`} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9761_62238)">
        <path
          d="M8 6.46875C9.49913 6.46875 10.7188 5.24913 10.7188 3.75C10.7188 2.25087 9.49913 1.03125 8 1.03125C6.50087 1.03125 5.28125 2.25087 5.28125 3.75C5.28125 5.24913 6.50087 6.46875 8 6.46875ZM8 1.96875C8.98219 1.96875 9.78125 2.76781 9.78125 3.75C9.78125 4.73219 8.98219 5.53125 8 5.53125C7.01781 5.53125 6.21875 4.73219 6.21875 3.75C6.21875 2.76781 7.01781 1.96875 8 1.96875Z"
          fill={`#1F1B16 ${color}`}
        />
        <path
          d="M13.5 6.46875C14.4477 6.46875 15.2188 5.69772 15.2188 4.75C15.2188 3.80228 14.4477 3.03125 13.5 3.03125C12.5523 3.03125 11.7812 3.80228 11.7812 4.75C11.7812 5.69772 12.5523 6.46875 13.5 6.46875ZM13.5 3.96875C13.9308 3.96875 14.2812 4.31922 14.2812 4.75C14.2812 5.18078 13.9308 5.53125 13.5 5.53125C13.0692 5.53125 12.7188 5.18078 12.7188 4.75C12.7188 4.31922 13.0692 3.96875 13.5 3.96875Z"
          fill={`#1F1B16 ${color}`}
        />
        <path
          d="M13.8781 7.53125H13.1531C12.2988 7.53125 11.5613 8.036 11.2252 8.761C10.5333 8.00569 9.53947 7.53125 8.43684 7.53125H7.56319C6.46056 7.53125 5.46672 8.00569 4.77481 8.761C4.43872 8.036 3.70122 7.53125 2.84687 7.53125H2.12188C0.951875 7.53125 0 8.47737 0 9.64031V13.0531C0 13.558 0.412313 13.9688 0.919125 13.9688H3.78559C3.83366 14.5281 4.30403 14.9688 4.87569 14.9688H11.1243C11.696 14.9688 12.1663 14.5281 12.2144 13.9688H15.0482C15.573 13.9688 16 13.5434 16 13.0206V9.64031C16 8.47737 15.0481 7.53125 13.8781 7.53125ZM0.9375 9.64031C0.9375 8.99431 1.46881 8.46875 2.12188 8.46875H2.84687C3.49994 8.46875 4.03125 8.99431 4.03125 9.64031V9.96128C3.71603 10.782 3.78125 11.2739 3.78125 13.0312H0.9375V9.64031ZM11.2812 13.8743C11.2812 13.9609 11.2109 14.0312 11.1243 14.0312H4.87566C4.78912 14.0312 4.71875 13.9608 4.71875 13.8743V11.3132C4.71875 9.74475 5.99475 8.46875 7.56316 8.46875H8.43681C10.0052 8.46875 11.2812 9.74475 11.2812 11.3132V13.8743ZM15.0625 13.0206C15.0625 13.0368 15.2373 13.0312 12.2188 13.0312C12.2188 11.2609 12.2835 10.7807 11.9688 9.96128V9.64031C11.9688 8.99431 12.5001 8.46875 13.1531 8.46875H13.8781C14.5312 8.46875 15.0625 8.99431 15.0625 9.64031V13.0206Z"
          fill={`#1F1B16 ${color}`}
        />
        <path
          d="M2.5 6.46875C3.44772 6.46875 4.21875 5.69772 4.21875 4.75C4.21875 3.80228 3.44772 3.03125 2.5 3.03125C1.55228 3.03125 0.78125 3.80228 0.78125 4.75C0.78125 5.69772 1.55228 6.46875 2.5 6.46875ZM2.5 3.96875C2.93078 3.96875 3.28125 4.31922 3.28125 4.75C3.28125 5.18078 2.93078 5.53125 2.5 5.53125C2.06922 5.53125 1.71875 5.18078 1.71875 4.75C1.71875 4.31922 2.06922 3.96875 2.5 3.96875Z"
          fill={`#1F1B16 ${color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_9761_62238">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
