import PlusIcon from "components/icon/PlusIcon";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import AvatarCatchError from "./AvatarCatchError";
import { Spinner } from "@material-tailwind/react";

interface UploadImgProp {
  avatarGroup?: string;
  handleUpdateAvatar?: () => void;
  isPending?: boolean;
  className?: string;
}

const UploadImg = ({ avatarGroup = "", handleUpdateAvatar = () => {}, isPending, className = "h-[86px] w-[86px]" }: UploadImgProp) => {
  const { register, watch } = useFormContext();
  const avatar = watch("group_avatar");
  const [image, setImage] = useState("");
  useEffect(() => {
    if (avatar && avatar?.[0]) {
      setImage(URL.createObjectURL(avatar[0]));
      handleUpdateAvatar();
      return () => URL.revokeObjectURL(avatar[0]);
    }
  }, [avatar]);

  return (
    <div className="relative flex flex-col">
      <div className="relative">
        <div className={`${className} rounded-full bg-gray-200`}>
          {isPending ? (
            <Spinner className="w-full h-full" />
          ) : (
            <>
              {avatarGroup && !image && <AvatarCatchError className="w-full h-full" src={avatarGroup} />}
              {!image && !avatarGroup && <img alt="avatar_group" className="object-cover h-full rounded-full" src="/avatar_default.png" />}
              {image && <img alt="avatar_group" className="object-cover h-full min-w-full rounded-full" src={image} />}
            </>
          )}
        </div>
        <label
          className="absolute bottom-0 bg-white rounded-full shadow-xl cursor-pointer right-2 
                            h-[26px] w-[26px] flex items-center justify-center"
        >
          <PlusIcon />
          <input
            {...register("group_avatar")}
            type="file"
            id="group_avatar"
            name="group_avatar"
            className="hidden w-full h-10 px-2 text-sm border rounded-md outline-none border-neutral-60"
            accept="image/jpeg, image/png, image/gif, image/jfif, image/jpg"
          />
        </label>
      </div>
    </div>
  );
};

export default UploadImg;
