/* eslint-disable max-len */
const ClockIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.57395 20.186L5.55844 21.7486C5.36188 22.0533 5.44706 22.4595 5.75172 22.656C5.8631 22.7281 5.98433 22.7608 6.10879 22.7608C6.32499 22.7608 6.53468 22.656 6.65913 22.4628L7.65172 20.9362C9.07342 21.7813 10.731 22.2695 12.5 22.2695C14.2723 22.2695 15.9298 21.7814 17.3483 20.9329L18.3376 22.4595C18.462 22.6528 18.675 22.7576 18.8879 22.7576C19.0092 22.7576 19.1336 22.7248 19.245 22.6528C19.5497 22.4562 19.6348 22.05 19.4383 21.7454L18.426 20.1828C20.6012 18.4433 22 15.7669 22 12.7695C22 7.53141 17.7381 3.26953 12.5 3.26953C7.26188 3.26953 3 7.53136 3 12.7695C3 15.7669 4.39878 18.4433 6.57395 20.186ZM12.5 4.5798C17.0174 4.5798 20.6897 8.25206 20.6897 12.7695C20.6897 17.2869 17.0174 20.9592 12.5 20.9592C7.98258 20.9592 4.31032 17.2869 4.31032 12.7695C4.31032 8.25206 7.98258 4.5798 12.5 4.5798Z"
        fill="#1F1B16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12.7695C2.75 7.39329 7.12382 3.01953 12.5 3.01953C17.8762 3.01953 22.25 7.39334 22.25 12.7695C22.25 15.7652 20.8887 18.448 18.7574 20.2346L19.6481 21.6095C19.9186 22.0287 19.8026 22.5906 19.3805 22.8629C19.2262 22.9627 19.0542 23.0076 18.8879 23.0076C18.5953 23.0076 18.3008 22.864 18.1275 22.5951C18.1275 22.595 18.1276 22.5951 18.1275 22.5951L17.268 21.2688C15.859 22.0648 14.2326 22.5195 12.5 22.5195C10.77 22.5195 9.14364 22.0645 7.732 21.2713L6.86932 22.5981C6.86921 22.5983 6.86944 22.5979 6.86932 22.5981C6.69639 22.8662 6.40522 23.0108 6.10879 23.0108C5.93861 23.0108 5.76978 22.9655 5.61594 22.8659C5.19406 22.5936 5.07792 22.0323 5.34837 21.6131L6.24241 20.2374C4.1113 18.4479 2.75 15.7652 2.75 12.7695ZM12.5 3.51953C7.39995 3.51953 3.25 7.66943 3.25 12.7695C3.25 15.6873 4.61137 18.2932 6.73027 19.9909L6.90692 20.1325L5.76852 21.8842C5.76843 21.8843 5.7686 21.884 5.76852 21.8842C5.64623 22.0742 5.70014 22.3252 5.88725 22.446C5.95617 22.4905 6.03005 22.5108 6.10879 22.5108C6.24455 22.5108 6.37292 22.4455 6.44894 22.3274L6.44954 22.3265L7.57304 20.5986L7.77947 20.7213C9.16396 21.5443 10.7776 22.0195 12.5 22.0195C14.2257 22.0195 15.8391 21.5443 17.2199 20.7184L17.4269 20.5946L18.5474 22.3236C18.623 22.4411 18.7548 22.5076 18.8879 22.5076C18.9641 22.5076 19.0408 22.4871 19.1092 22.4429C19.2963 22.3222 19.3507 22.0713 19.2285 21.8813M19.2285 21.8813L18.0932 20.1289L18.2699 19.9876C20.3886 18.2932 21.75 15.6874 21.75 12.7695C21.75 7.66949 17.6 3.51953 12.5 3.51953M12.5 4.8298C8.12065 4.8298 4.56032 8.39013 4.56032 12.7695C4.56032 17.1488 8.12065 20.7092 12.5 20.7092C16.8793 20.7092 20.4397 17.1488 20.4397 12.7695C20.4397 8.39013 16.8793 4.8298 12.5 4.8298ZM4.06032 12.7695C4.06032 8.11399 7.84451 4.3298 12.5 4.3298C17.1555 4.3298 20.9397 8.11399 20.9397 12.7695C20.9397 17.425 17.1555 21.2092 12.5 21.2092C7.84451 21.2092 4.06032 17.425 4.06032 12.7695Z"
        fill="#1F1B16"
      />
      <path
        d="M14.6883 15.5578C14.8128 15.6659 14.9668 15.7183 15.1207 15.7183C15.3041 15.7183 15.4843 15.643 15.6154 15.4956C15.8545 15.2237 15.825 14.8109 15.5531 14.5718L13.1552 12.4719V7.20106C13.1552 6.84071 12.8604 6.5459 12.5 6.5459C12.1397 6.5459 11.8448 6.84071 11.8448 7.20106V12.77C11.8448 12.96 11.9267 13.1402 12.0676 13.2647L14.6883 15.5578Z"
        fill="#1F1B16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5948 7.20106C11.5948 6.70264 12.0016 6.2959 12.5 6.2959C12.9984 6.2959 13.4052 6.70264 13.4052 7.20106V12.3586L15.7178 14.3837C16.0918 14.7126 16.1348 15.2835 15.8031 15.6607L15.8022 15.6616C15.6223 15.8641 15.3733 15.9683 15.1207 15.9683C14.9099 15.9683 14.6968 15.8963 14.5244 15.7466L11.903 13.4528L11.9021 13.452C11.7076 13.2802 11.5948 13.0319 11.5948 12.77V7.20106ZM12.5 6.7959C12.2777 6.7959 12.0948 6.97878 12.0948 7.20106V12.77C12.0948 12.888 12.1457 12.9999 12.2328 13.077C12.2326 13.0769 12.2329 13.0771 12.2328 13.077L14.8522 15.3691C14.8522 15.369 14.8523 15.3691 14.8522 15.3691C14.9287 15.4354 15.0238 15.4683 15.1207 15.4683C15.2348 15.4683 15.346 15.422 15.4281 15.33C15.5741 15.1634 15.5577 14.9089 15.3882 14.7596C15.3881 14.7596 15.3882 14.7597 15.3882 14.7596L12.9052 12.5853V7.20106C12.9052 6.97878 12.7223 6.7959 12.5 6.7959Z"
        fill="#1F1B16"
      />
      <path
        d="M4.31029 5.40875C4.48064 5.40875 4.651 5.34322 4.77874 5.2122C5.55841 4.41617 6.4429 3.75116 7.40272 3.23358C7.72045 3.06323 7.84168 2.66688 7.67133 2.34581C7.50097 2.02808 7.10463 1.90685 6.78356 2.0772C5.70256 2.65703 4.71326 3.40066 3.83861 4.29499C3.58636 4.55381 3.58965 4.96979 3.84846 5.22204C3.97943 5.34655 4.1465 5.40875 4.31029 5.40875Z"
        fill="#1F1B16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66644 1.8564C7.10882 1.62168 7.65617 1.78839 7.89172 2.22772L7.89223 2.22867C8.1269 2.67097 7.9603 3.21818 7.52117 3.45381C6.58376 3.95933 5.71954 4.60904 4.9574 5.38717C4.78023 5.56865 4.54438 5.65879 4.31035 5.65879C4.08359 5.65879 3.85453 5.57274 3.67626 5.40327L3.67401 5.40113L3.67403 5.40111C3.3153 5.05149 3.31203 4.47719 3.65964 4.12054L3.65994 4.12023L3.65994 4.12023C4.55215 3.20793 5.56183 2.4489 6.66544 1.85693L6.66644 1.8564L6.66644 1.8564ZM6.90122 2.29785C5.84313 2.86547 4.87443 3.59353 4.01754 4.46969C3.86118 4.63031 3.86415 4.88704 4.02191 5.04197C4.10539 5.12079 4.20998 5.15879 4.31035 5.15879C4.41685 5.15879 4.52153 5.11799 4.59979 5.03771L4.6002 5.0373C5.39733 4.22344 6.30199 3.54318 7.28412 3.01357L7.28465 3.01329C7.48063 2.90821 7.55641 2.66317 7.4508 2.4635C7.34557 2.26786 7.10074 2.19229 6.90122 2.29785Z"
        fill="#1F1B16"
      />
      <path
        d="M17.5972 3.23353C18.5603 3.74782 19.4416 4.41611 20.2212 5.21214C20.349 5.34316 20.5193 5.4087 20.6897 5.4087C20.8535 5.4087 21.0205 5.34644 21.1483 5.22199C21.4071 4.96974 21.4103 4.5537 21.1581 4.29494C20.2868 3.40388 19.2942 2.65698 18.2131 2.0772C17.8921 1.90685 17.4957 2.02808 17.3254 2.34581C17.1583 2.66683 17.2762 3.06317 17.5972 3.23353Z"
        fill="#1F1B16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.105 2.22772C17.3406 1.7884 17.888 1.62167 18.3303 1.85641L18.3313 1.85692L18.3313 1.85693C19.435 2.44886 20.448 3.21119 21.3369 4.12019L21.3372 4.12048C21.6847 4.47707 21.6815 5.05142 21.3228 5.40106L21.3227 5.40109C21.1464 5.57289 20.9159 5.65874 20.6897 5.65874C20.4556 5.65874 20.2196 5.56853 20.0424 5.38691C19.2798 4.60829 18.4189 3.95576 17.4795 3.45409C17.0348 3.21773 16.8747 2.67022 17.1036 2.23043L17.105 2.22771L17.105 2.22772ZM17.5464 2.46264C17.4423 2.66443 17.5179 2.90846 17.7144 3.01273L17.715 3.01304C18.7017 3.53991 19.6032 4.2239 20.3998 5.03725L20.4002 5.03766C20.4785 5.11794 20.5832 5.15874 20.6897 5.15874C20.791 5.15874 20.8946 5.12009 20.9738 5.04299M17.5464 2.46264C17.6519 2.26763 17.8963 2.19245 18.0955 2.29785C19.1536 2.8654 20.1256 3.59674 20.9793 4.46966C21.1359 4.6306 21.1326 4.88816 20.9738 5.04296"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default ClockIcon;
