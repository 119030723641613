import InputSearch from "components/input/InputSearch";
import ListMemberInConverSation from "components/listMemberInConverSation/listMemberInconverSation";
import PopupListSender from "components/user/PopupListSender";
import { LIST_POPUP_SELECT } from "constants/constans";
import { useDebounce } from "hook/useDebounce";
import { Dispatch, SetStateAction, useState } from "react";
import { SENDER } from "types/contact.type";
import { ChangeEvent } from "types/event.type";
import { MEMBER } from "types/message.type";
interface PopupListUserProps {
  typePopup?: LIST_POPUP_SELECT;
  className?: string;
  setMemberSelected: Dispatch<SetStateAction<MEMBER | undefined>> | Dispatch<SetStateAction<SENDER | undefined>>;
  setOpenListMember?: Dispatch<SetStateAction<boolean>>;
}

const PopupListMember = ({
  typePopup = LIST_POPUP_SELECT.IN_CONVERSATION,
  className,
  setMemberSelected,
  setOpenListMember,
}: PopupListUserProps) => {
  const [keywordSearchMember, setkeywordSearchMember] = useState<string>("");
  const debouncedSearchMember = useDebounce<string>(keywordSearchMember, 800);
  const handleSetMemberSelected = (value: any) => {
    setMemberSelected(value);
    setOpenListMember && setOpenListMember((prev) => !prev);
  };
  return (
    <div className={`h-[400px] absolute top-full lef-0 w-full bg-white rounded-b-xl z-[999] ${className}`}>
      <div
        className="h-full w-full flex flex-col  px-4 py-2 min-h-72 rounded-xl shadow-md"
        style={{
          boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <div className="pb-3 border-b-[1px]">
          <InputSearch
            className="w-full h-8 px-2 mt-3 rounded-full bg-neutral-20 "
            onChange={(e: ChangeEvent) => setkeywordSearchMember(e.target.value)}
            value={keywordSearchMember}
          />
        </div>
        {typePopup === LIST_POPUP_SELECT.IN_CONVERSATION && (
          <ListMemberInConverSation
            onclick={handleSetMemberSelected}
            debouncedSearchMember={debouncedSearchMember}
            classNameAvatar="w-8 h-8"
            classNameParent="flex flex-col gap-1 mt-4 flex flex-col overflow-hidden"
            classNameChildren="gap-x-4 cursor-pointer "
          />
        )}
        {typePopup === LIST_POPUP_SELECT.IN_CONTACT && (
          <PopupListSender
            onClick={handleSetMemberSelected}
            debouncedSearch={debouncedSearchMember}
            classNameAvatar="w-8 h-8"
            classNameParent="flex flex-col gap-1 mt-4 flex flex-col overflow-hidden"
            classNameChildren="gap-x-4 cursor-pointer "
          />
        )}
      </div>
    </div>
  );
};
export default PopupListMember;
