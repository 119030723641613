import storage from "redux-persist/lib/storage";
import sideBarReducer from "../slices/sidebar/index";
import messageReducer from "../slices/message/index";
import conversationReducer from "../slices/conversation/index";
import authReducer from "../slices/auth/index";
import contactReducer from "../slices/contact/index";
import socketReducer from "../slices/socket/index";
import { REDUX_ACTION_TYPE } from "constants/constans";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import paramsSocketReducer from "../slices/paramsSocket/index";

const persistAuthConfig = {
  key: "auth",
  storage,
  whitelist: ["prevPage", "device_token", "oauthToken"],
  blacklist: ["userInfo"],
};

const appReducer = combineReducers({
  auth: persistReducer<ReturnType<typeof authReducer>>(persistAuthConfig, authReducer),
  conversation: conversationReducer,
  sidebar: sideBarReducer,
  message: messageReducer,
  contact: contactReducer,
  socket: socketReducer,
  paramsSocket: paramsSocketReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === REDUX_ACTION_TYPE.TOKEN_VALID) {
    
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:auth");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
