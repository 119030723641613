import { InputHTMLAttributes, ReactNode } from "react";
import { useController, useFormContext } from "react-hook-form";
interface InputValidateProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  type?: string;
  name: string;
  showError?: boolean;
  parentClass?: string;
}
const InputValidate = ({
  placeholder,
  type = "text",
  name,
  hidden = false,
  showError = true,
  className = "",
  parentClass = "",
}: InputValidateProps): JSX.Element => {
  const { formState, control } = useFormContext();
  const { errors } = formState;
  const { field } = useController({
    name,
    control,
    defaultValue: "",
  });
  return (
    <div className={`flex flex-col w-full ${parentClass}`}>
      <input
        hidden={hidden}
        type={type}
        className={`w-full h-10 px-2 text-sm border rounded-md outline-none border-neutral-60 text-black ${className}`}
        placeholder={placeholder}
        {...field}
      />
      {!!errors[name] && showError && <p className="text-sm text-red-700">{errors[name]?.message as ReactNode}</p>}
    </div>
  );
};

export default InputValidate;
