import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  paramsSocketContact: any;
} = {
  paramsSocketContact: null,
};

const paramSockettSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setParamsSocketContact: (state, action) => {
      state.paramsSocketContact = action.payload;
    },
  },
});

export const { setParamsSocketContact } = paramSockettSlice.actions;
export default paramSockettSlice.reducer;
