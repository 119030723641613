/* eslint-disable max-len */
interface PinV2IconProp {
  onClick?: () => void;
  className?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const PinV2Icon = ({ onClick = () => {}, className = "" }: PinV2IconProp) => {
  return (
    <svg width="24" onClick={() => onClick()} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3345 6.27193L17.7281 2.66549C16.4257 1.36317 14.1216 2.06442 13.8211 3.96781L13.6207 5.37032L10.3148 8.67622C7.91055 8.07515 5.40608 8.7764 3.70304 10.4794C3.30232 10.8802 3.30232 11.4812 3.70304 11.8819L7.2093 15.3882L2.30054 20.297C1.89982 20.6977 1.89982 21.2987 2.30054 21.6995C2.70125 22.1002 3.30232 22.1002 3.70304 21.6995L8.6118 16.7907L12.1181 20.297C12.5188 20.6977 13.1198 20.6977 13.5206 20.297C15.2236 18.5939 15.9249 15.9893 15.3238 13.6852L18.7299 10.2791L20.0322 10.0787C21.9356 9.87837 22.737 7.57425 21.3345 6.27193ZM19.8318 8.17532L18.1288 8.37568C17.9284 8.37568 17.7281 8.47586 17.5277 8.67622L13.5206 12.6834C13.22 12.9839 13.1198 13.3846 13.3202 13.6852C13.8211 15.1878 13.6207 16.7907 12.8193 18.093C11.6172 16.9911 11.1163 16.4902 5.80679 11.2809C7.10912 10.4794 8.71198 10.1789 10.2147 10.78C10.6154 10.8802 11.0161 10.78 11.2165 10.5796L15.2236 6.57246C15.424 6.37211 15.5241 6.17175 15.5241 5.97139L15.7245 4.26835C15.7245 4.06799 16.025 3.96781 16.2254 4.06799L19.8318 7.67443C20.1324 7.87479 20.0322 8.07515 19.8318 8.17532Z"
        fill={`${className}`}
      />
    </svg>
  );
};

export default PinV2Icon;
