import { DialogBody, DialogFooter, DialogHeader, Spinner } from "@material-tailwind/react";
import { CLoseIcon } from "components/icon";
import AvatarCatchError from "components/image/AvatarCatchError";
import { BASE_SRC_FILE, QUERY_KEY } from "constants/pathApi";
import moment from "moment";
import ActionFile from "./ActionFile";
import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { removeFileExtension, shortenFileName } from "utils/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { deleteFileMessage, TYPE_DELETE_FILE_RESPONSE } from "http/message.http";
import { useCustomDispatch } from "hook/redux";
import { setScrollMessage } from "../../redux/slices/message";
import { STATUS_CODE } from "constants/statusCode";
import CustomModal from "components/Modal/ModalCommon";
interface PreviewFileModalProps {
  openModal: boolean;
  closeModal: () => void;
  data: any;
}
export default function PreviewFileModal({ closeModal, openModal, data }: PreviewFileModalProps) {
  const [openAction, setOpenAction] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const dispatch = useCustomDispatch();
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const handleScrollMessage = () => {
    dispatch(
      setScrollMessage({
        messageId: data?.message_id,
        reply: data?.reply_id,
      }),
    );
  };

  const { mutate, isPending } = useMutation<TYPE_DELETE_FILE_RESPONSE, Error, number>({
    mutationFn: async (fileId: number) => {
      if (fileId) {
        const response = await deleteFileMessage<TYPE_DELETE_FILE_RESPONSE>(fileId);
        if (response) {
          toast[response.code === STATUS_CODE.Ok ? "success" : "error"](response.message);
          if (response.code === STATUS_CODE.Ok) {
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY.LIST_FILES_MESSAGE] });
          }
        }
        return response;
      }
    },
    onSuccess: () => {
      closeModal();
    },
  });

  const handleDeleteFileMessage = async (fileId: number) => {
    await mutate(fileId);
  };

  const [iframeTimeoutId, setIframeTimeoutId] = useState<any>();
  const iframeRef: any = useRef(null);

  const getIframeLink = useCallback(() => {
    return `https://docs.google.com/gview?url=${BASE_SRC_FILE + data.path}&embedded=true`;
  }, [data.path]);

  const updateIframeSrc = useCallback(() => {
    if (iframeRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      iframeRef!.current!.src = getIframeLink();
    }
  }, [getIframeLink]);

  useEffect(() => {
    const intervalId = setInterval(updateIframeSrc, 1000 * 5);
    setIframeTimeoutId(intervalId);
  }, [updateIframeSrc]);

  function iframeLoaded() {
    setLoadingData(false);
    clearInterval(iframeTimeoutId);
  }
  return (
    <div>
      <CustomModal closeModal={closeModal} className="!max-w-[832px] h-[90vh] !w-full flex flex-col rounded-xl">
        <DialogHeader placeholder={""} className="flex items-center justify-between px-8 bg-neutral-100 rounded-t-xl">
          <p className="flex flex-1">
            <span className="max-w-sm overflow-hidden text-sm text-white file_name text-ellipsis whitespace-nowrap">
              {removeFileExtension(data.name)}
            </span>
            <span className="h-full text-sm text-white">{shortenFileName(data.name)}</span>
          </p>
          <div className="flex justify-end flex-1">
            <CLoseIcon className="text-white " onClick={closeModal} />
          </div>
        </DialogHeader>

        <DialogBody placeholder={""} className="flex-1 py-0 bg-black px-14 h-full overflow-hidden">
          <div className="w-full h-full flex justify-center items-center relative">
            {loadingData && (
              <div className="w-full h-full absolute bg-black flex items-center justify-center">
                <Spinner className="m-auto w-10 h-10  " />
              </div>
            )}
            <iframe
              ref={iframeRef}
              onLoad={iframeLoaded}
              onError={updateIframeSrc}
              title={t("action.preview") ? String(t("action.preview")) : undefined}
              className="w-full h-full"
              src={getIframeLink()}
            ></iframe>
          </div>
        </DialogBody>

        <DialogFooter className="flex justify-between px-8 bg-neutral-100 rounded-b-xl flex-nowrap" placeholder={""}>
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <AvatarCatchError className="w-4 h-4" src={data.user_avatar} />
              <span className="text-sm text-white">{data.user_name}</span>
            </div>
            <div className="flex gap-x-3">
              <span className="text-sm text-white">
                {t("files.size")}: {data.size}
              </span>
              <span className="text-white">|</span>
              <span className="text-sm text-white">
                {t("files.date")}: {moment(data.created_at).format("DD-MM-YYYY HH:mm")}
              </span>
            </div>
          </div>
          <ActionFile
            openAction={openAction}
            setOpenAction={setOpenAction}
            isPreview={false}
            isPending={isPending}
            data={data}
            handleScrollMessage={() => {
              closeModal();
              handleScrollMessage();
            }}
            deleteFile={() => handleDeleteFileMessage(data.id)}
            placement="top-end"
            colorIcon="#FFF"
          />
        </DialogFooter>
      </CustomModal>
    </div>
  );
}
