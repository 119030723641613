import { Spinner } from "@material-tailwind/react";
import { InfiniteData } from "@tanstack/react-query";
import MessageItemSearch from "components/chat/message/MessageItemSearch";
import { TYPE_SCREEN_CHAT, TYPE_SEARCH_MAIN } from "constants/constans";
import { t } from "i18next";
import { memo, useEffect, useMemo, useState } from "react";
import { MESSAGE } from "types/message.type";
import { ResponseTypeCommon } from "types/utils.type";
import { mapDataInfiniteQuery } from "utils/common";

interface SearchResultMessageProps {
  activeTab: TYPE_SEARCH_MAIN;
  keywordSearch: string;
  isActiveSearch: string;
  isFetchingNextPageMessage?: boolean;
  messages: InfiniteData<ResponseTypeCommon<MESSAGE>, unknown> | undefined;
  handleSetActiveTab?: (value: TYPE_SEARCH_MAIN) => void;
  redirectToMessage: (conversationId: number | undefined, messageId: number, reply_id: string | null) => void;
}
const SearchResultMessage = ({
  activeTab,
  keywordSearch,
  messages,
  isFetchingNextPageMessage = false,
  handleSetActiveTab = () => {},
  redirectToMessage,
  isActiveSearch,
}: SearchResultMessageProps) => {
  const [initSearchValue, setInitSearchValue] = useState<string>(keywordSearch);

  const data: MESSAGE[] = useMemo(() => {
    if (!messages) return [];
    const mapData:MESSAGE[] | [] = mapDataInfiniteQuery<MESSAGE, ResponseTypeCommon<MESSAGE>>(messages);
    if(activeTab === TYPE_SEARCH_MAIN.ALL && mapData.length > 0) return mapData.slice(0, 5);
    return mapData;
  }, [messages]);

  useEffect(() => {
    setInitSearchValue(keywordSearch);
  }, [isActiveSearch]);

  if(data.length === 0){
    if(activeTab === TYPE_SEARCH_MAIN.MESSAGE) return <p className="text-center border-t pt-[30px]">{t("alert.no_data")}</p>;
    return <></>;
  }
  const total = messages?.pages?.[0]?.total || 0;
  return (
    <div className="border-t border-primary-200 px-8 h-full">
      <p className="font-normal text-sm mt-3 mb-2">{t("search_main.message")} ({total >= 100 ? "99+" : total})</p>
      {data &&
        data.map((message) => {
          return (
            <div
              key={`search_${message.id}`}
              className="flex items-start h-auto gap-x-2 py-2 cursor-pointer border-primary-200 border-b "
              onClick={() => redirectToMessage(message.conversation_id, +message.id, message?.reply_id)}
            >
              <MessageItemSearch
                keywordSearch={initSearchValue}
                message={message}
                typeScreen={TYPE_SCREEN_CHAT.MAIN}
              />
            </div>
          );
        })}
      {isFetchingNextPageMessage && <Spinner className="w-10 h-10 mx-auto mt-1" />}
      {activeTab === TYPE_SEARCH_MAIN.ALL && (
        <div
          onClick={() => handleSetActiveTab(TYPE_SEARCH_MAIN.MESSAGE)}
          className="cursor-pointer w-full h-8 bg-neutral-20 
                      text-primary-900 flex justify-center items-center rounded-md my-2 font-normal text-sm"
        >
          {t("search_main.see_more")}
        </div>
      )}
    </div>
  );
};

export default memo(SearchResultMessage);
