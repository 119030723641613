import { FILE_IMG_SIZE, SUPPORTED_IMG_FORMATS } from "constants/constans";
import i18n from "i18n";
import { t } from "i18next";
import * as yup from "yup";

export const schema = yup.object({
  email: yup.string().when("contactMethod", ([contactMethod], schema) => {
    return contactMethod === "phone"
      ? schema.notRequired()
      : yup
        .string()
        .required(t("validate.required", { name: "Email" }) as string)
        .email(t("validate.invalid", { name: "Email" }) as string);
  }),
  password: yup.string().required(i18n.t("validate.required", { name: "Password" }) as string),

  // create group chat
  name: yup
    .string()
    .max(500, t("validate.max_length", { name: t("input.group_name"), length: "500" }) as string)
    .required(t("validate.required", { name: t("input.group_name") }) as string),

  group_avatar: yup
    .mixed<FileList>()
    .notRequired()
    .test("fileType", t("validate.type", { name: i18n.t("attributes.avatar") }) as string, (value) =>
      value?.[0]?.type ? SUPPORTED_IMG_FORMATS.includes(value?.[0]?.type.toLowerCase()) : true,
    )
    .test("fileSize", t("validate.too_large", { name: i18n.t("attributes.avatar") }) as string, (value) =>
      value?.[0]?.size ? value?.[0]?.size <= FILE_IMG_SIZE : true,
    ),

  description: yup
    .string()
    .notRequired()
    .max(10000, t("validate.max_length", { name: i18n.t("input.enter_description"), length: "10000" }) as string),

  list_user_id: yup
    .array()
    .min(1, () => `${i18n.t("validate.required", { name: i18n.t("attributes.list_user") })}`)
    .required(t("validate.required", { name: i18n.t("attributes.list_user") }) as string),

  contactMethod: yup.string(),

  phone_number: yup.string().when("contactMethod", ([contactMethod], schema) => {
    return contactMethod === "email"
      ? schema.notRequired()
      : yup
        .string()
        .required(t("validate.required", { name: i18n.t("attributes.phone_number") }) as string)
        .matches(/^[0-9]+$/, "The phone number format is invalid.");
  }),
  new_password: yup.string().required(i18n.t("validate.required", { name: i18n.t("title_validate.new_password") as string }) as string),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password")], i18n.t("validate.password_match") as string)
    .required(i18n.t("validate.required", { name: i18n.t("title_validate.confirm_password") as string }) as string),
});
export type SchemaPick = yup.InferType<typeof schema>;
