import { Switch } from "@material-tailwind/react";
import EmailIcon from "components/icon/setting/EmailIcon";
import { t } from "i18next";

interface EmailNotificationProps {
  checkedEmailNotification: boolean;
  handleChangeEmailNotification: () => void;
}
export default function EmailNotification({ checkedEmailNotification, handleChangeEmailNotification }: EmailNotificationProps) {
  return (
    <div>
      <div className="flex justify-between h-[56px] items-center">
        <div className="flex">
          <EmailIcon />

          <span className="ml-4  text-neutral-110">{t("setting.email_notification")}</span>
        </div>
        <div>
          <Switch className="w-[40px] h-[16px] ouline-none bg-[#F4EDE6] checked:bg-[#BAABA1] focus-visible:outline-none"
            checked={checkedEmailNotification}
            crossOrigin={null}
            onChange={handleChangeEmailNotification}
            circleProps={{
              className: `w-6 h-6 ${checkedEmailNotification ? "bg-[#592C12]" : "bg-[#E5E0DA]"}`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
