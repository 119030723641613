import { cloneDeep, countBy, forEach, forOwn, isEmpty, isString, some, tail, unset } from "lodash";
import moment from "moment";
import { ReactNode, RefObject } from "react";
import reactStringReplace from "react-string-replace";
import excelImg from "assets/file/excel.png";
import pdfImg from "assets/file/pdf.png";
import wordImg from "assets/file/word.png";
import fileImg from "assets/file/file.png";
import powpImg from "assets/file/powp.png";
import zipImg from "assets/file/zip.png";
import imageImg from "assets/file/image.png";
import MessageFile from "components/chat/message/MessageFile";
import MessageTo from "components/chat/message/MessageTo";
import {
  ACTION_UPDATE,
  CONTENT_TYPE_FILE,
  ContentFileType,
  DATA_MESSAGE_PREVIEW,
  EVENT_MESSAGE_PIN,
  IS_CURRENT_USER,
  IS_MESSAGE_PIN,
  MEMBER,
  MESSAGE,
  MESSAGE_REACTION,
  MESSAGE_REACTION_SOCKET,
  QuotedParseType,
  QuotedStartType,
  REACTION_VALUE,
  REPLY_IS_READ,
  ReactionNodeTotalType,
  TYPE_MESSAGE_FILE,
  extractQuotedType,
} from "types/message.type";
import { TypeValidateFile, ResponseTypeCommon, SuccessResponse } from "types/utils.type";
import { validateUploadedFiles, getUniqueId } from "utils/common";
import { User } from "types/user.type";
import { InfiniteData } from "@tanstack/react-query";
import { CongratReaction, ThankReaction, LikeReaction, RogerReaction, ClapReaction, YayReaction } from "components/icon";
import { TYPE_SCREEN_CHAT } from "constants/constans";
import MessageReply from "components/chat/message/MessageReply";
import HuggingReaction from "components/icon/reaction/HuggingReaction";
import { t } from "i18next";
import { toast } from "react-toastify";
import { BASE_SRC_FILE } from "constants/pathApi";
/**
 * parse Quoted Messages
 * ex: 3 quote nested:
 * 1---2---3---3---2---1
 */
export function parseQuotedMessages(input: string): QuotedParseType | null {
  // Regular expression to match start quoted messages
  const regexGetData = /\[qt\]\[qtmeta aid=(\d+) time=(\d+:\d+ \d+\/\d+\/\d+) msgId=(\d+) rpId=(\d+|null) user_name=([\s\S]+?)\]/g;
  const regexGetStart =
    /\[qt\]\[qtmeta aid=\d+ time=[\d:]+ \d+\/\d+\/\d+ msgId=\d+ rpId=(\d+|null) user_name=[^\]]+\][\s\S]*?(?=\[qt\]|\[\/qt\])/g;
  const results: QuotedStartType[] = [];
  const quoteStart: string[] = [];
  let match;

  // exec quote text start [qt] and end with [qt](start new quote) or [/qt](close quote)
  while ((match = regexGetStart.exec(input)) !== null) {
    const [start] = match;
    quoteStart.push(start);
  }
  // remove all start quote in quote_text
  const removeStart = input.replace(regexGetStart, "");

  // exec aid, time, msgId, rpId, user_name from --- [qt aid, time, msgId, rpId, user_name] content ---
  quoteStart.forEach((start) => {
    if ((match = regexGetData.exec(start)) !== null) {
      const [, aid, time, msgId, rpId, user_name] = match;
      const content = start.replace(regexGetData, "");
      results.push({ aid: parseInt(aid), time, msgId, rpId, user_name, content });
    }
  });
  // remove start quoted in string

  // Split the input string by [/qt] and filter out empty strings
  const closeQuoted = removeStart
    .split("[/qt]")
    .filter((str) => str.trim() !== "")
    .reverse();

  // Check if the number of start and end quoted messages match
  if (results.length === 0) return null;

  // Combine start and end quoted messages into objects
  const parsedResults = results.map((start, index) => ({
    start,
    content: start.content + (closeQuoted?.[index] || ""),
    childrent: null,
  }));

  // create quote multiple level (QuotedParseType)
  const result = convertToNestedArray(parsedResults);
  return result;
}

function convertToNestedArray(arr: QuotedParseType[]) {
  if (arr.length === 0) return null;

  const result = cloneDeep(arr[0]);
  result.childrent = convertToNestedArray(tail(arr));
  return result;
}

// Get actions to or links or file from messages and show content
export const extractTextToMessage = (
  message: string | ReactNode[],
  typeScreen = TYPE_SCREEN_CHAT.MAIN,
  quoteData?: QuotedStartType,
): [extractQuotedType, ReactNode[]] => {
  const toRegex = /(\[To:\d+[-\s\S]*?\])/g;
  const replyRegex = /(\[rp aid=\d+ user_name=[\s\S]+?\])/g;
  const toAllRegex = "[toall]";
  const linkRegex = /(https?:\/\/\S+)/g;
  const fileRegex = /\[msgid:\d+\]\[(\{.*?\})\]/g;
  const objMessageComponent = {
    replyNode: <></>,
    fileNode: <></>,
  };
  let replacedText = message;

  // replace link in message text
  replacedText = reactStringReplace(replacedText, linkRegex, (match, i) => (
    <a className="text-blue-600 overflow-wrap-anywhere" key={getUniqueId()} href={match} target="_blank" rel="noreferrer">
      {match}
    </a>
  ));

  // replace /r /n to br
  replacedText = reactStringReplace(replacedText, /(\r\n|\r|\n)(?!\r\n|\r|\n)/g, () => {
    return <br key={getUniqueId()} />;
  });

  // replace TO user in message text
  replacedText = reactStringReplace(replacedText, toRegex, (match) => {
    const toRegex = /\[To:(\d+)-?([\s\S]*?)\]/;
    const extract = toRegex.exec(match);
    if (extract) {
      const [, aid, user_name] = extract;
      if (aid) {
        return <MessageTo key={`${match}${getUniqueId()}`} member_id={+aid} member_name={user_name || ""} />;
      }
    }
  });

  // replace reply user in message text in quote
  replacedText = reactStringReplace(replacedText, replyRegex, (match) => {
    const replyRegex = /\[rp aid=(\d+) user_name=([\s\S]+?)]/;
    const matchReply = replyRegex.exec(match);
    if (matchReply) {
      const [, aid, user_name] = matchReply;
      objMessageComponent.replyNode =
        typeScreen === TYPE_SCREEN_CHAT.MAIN ? (
          <MessageReply
            replyUserName={user_name}
            replyUserId={+aid}
            key={`${aid}${getUniqueId()}`}
            onClick={() => {}}
            quoteDataReply={quoteData?.rpId}
          />
        ) : (
          <p key={`${match}${getUniqueId()}`}></p>
        );
      return "";
    }
  });

  // replace TO all in message text
  replacedText = reactStringReplace(replacedText, toAllRegex, (match) => {
    return <MessageTo key={`${match}${getUniqueId()}`} member_id={+match} toAll={true} member_name="" />;
  });

  // replace file in quote_content
  replacedText = reactStringReplace(replacedText, fileRegex, (match) => {
    objMessageComponent.fileNode = (
      <MessageFile
        files={`[${match}]`}
        key={`${getUniqueId()}`}
        typeScreen={typeScreen}
        dataPreview={{
          dateSendMessage: quoteData?.time || "",
          messageId: quoteData?.msgId || "",
          messageReplyId: JSON.parse(quoteData?.rpId || "") || null,
          messageUserId: quoteData?.aid || "",
        }}
      />
    );
    return "";
  });
  return [objMessageComponent, replacedText];
};

// extract text to component link
export const extractTextToComponent = (text:string | ReactNode[]) => {
  const linkRegex = /(https?:\/\/\S+)/g;
  let replacedText = text;

  // replace link in message text
  replacedText = reactStringReplace(replacedText, linkRegex, (match, i) => (
    <a className="text-blue-600 overflow-wrap-anywhere" key={getUniqueId()} href={match} target="_blank" rel="noreferrer">
      {match}
    </a>
  ));

  // replace /r /n to br 
  replacedText = reactStringReplace(replacedText, /(\r\n|\r|\n)(?!\r\n|\r|\n)/g, () => {
    return <br key={getUniqueId()} />;
  });
  return replacedText;
};

// insert text at the current position of the cursor (when selecting an emoji, to.)
export function insertMessageToCursor(
  inputElement: HTMLInputElement | HTMLTextAreaElement,
  insertText: string,
  inputText: string,
): [string, number] {
  const cursor = inputElement.selectionStart ?? 0;
  const text = `${inputText.slice(0, cursor)}${insertText}${inputText.slice(cursor)}`;
  const newCursor = cursor + insertText.length;
  return [text, newCursor];
}

// cover text to message
export function coverTextTo(to: MEMBER | string): string {
  if (isString(to)) return `[${to}] \n`;
  return `[To:${to.id}-${to.name}] \n`;
}

// cover text to quote
export function coverTextQuote(message: MESSAGE, typeScreen: TYPE_SCREEN_CHAT): string {
  const isFile = message.content_file ? `[msgid:${message.id}]${message.content_file}` : "";

  const isReply = !isEmpty(message?.message_reply) && typeScreen === TYPE_SCREEN_CHAT.MAIN
    ? `[rp aid=${message.message_reply.user_id} user_name=${message.message_reply.full_name}]`
    : "";
  const isQuote = message.quote_text ? message.quote_text : "";

  return `[qt][qtmeta aid=${message.user_id} time=${message.time_send_message} msgId=${message.id} rpId=${
    message?.message_reply?.id || null
  } user_name=${message.name}]${isReply + isQuote + isFile} ${message.content_text ?? ""}[/qt]`;
}
//Merge messages with 1 person close together
export const groupedMessages = (messages: MESSAGE[]) => {
  const listMessage = cloneDeep(messages);
  if (isEmpty(listMessage)) return [];
  forEach(listMessage, (message, index) => {
    // If exist next message
    const nextMessage = listMessage?.[index + 1];
    if (nextMessage) {
      const currentTime = moment(message.time_send_message, "HH:mm DD/MM/YYYY");
      const previousTime = moment(nextMessage.time_send_message, "HH:mm DD/MM/YYYY");
      // Check if the current message and the previous message are within 3 minutes of each other
      message["needMergeMessage"] = currentTime.diff(previousTime, "minutes") <= 3 && message.user_id === nextMessage.user_id;
    }
  });
  return listMessage;
};

//update cache react query
export const handleUpdateReactionMessage = (
  oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined,
  userInfo: User | null,
  reactionSocket: MESSAGE_REACTION_SOCKET,
) => {
  if (!oldData || !userInfo) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<MESSAGE>> = cloneDeep(oldData); // deep copy
  for (const page of newData.pages) {
    const messageNeedUpdate = page.data.find((messageOld) => messageOld.id === reactionSocket.message_id);
    if (messageNeedUpdate) {
      const newMessage = getChangeMessageReaction(messageNeedUpdate, userInfo, reactionSocket);
      Object.assign(messageNeedUpdate, newMessage);
      break;
    }
  }
  return newData;
};
// update text message
export const handleUpdateContentMessage = (oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined, message: Partial<MESSAGE>) => {
  if (isEmpty(oldData)) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<MESSAGE>> = cloneDeep(oldData); // deep copy
  for (const page of newData.pages) {
    const messageNeedUpdate = page.data.find((messageOld) => messageOld.id === message.id);
    if (messageNeedUpdate) {
      Object.assign(messageNeedUpdate, message);
      break;
    }
  }
  return newData;
};
export const getChangeMessageReaction = (mess: MESSAGE, currentUser: User, reactionSocket: MESSAGE_REACTION) => {
  const message = cloneDeep(mess);
  if (isEmpty(message)) return message;
  const oldReactionIndex = message?.list_reaction.findIndex((react) => react?.user_id === reactionSocket?.user_id);
  const oldReaction = message?.list_reaction?.[oldReactionIndex];
  // If the old reaction is different from the new reaction or cannot be found, add a new reaction
  if ((oldReaction?.reaction !== Number(reactionSocket?.reaction) || oldReactionIndex < 0) && !oldReaction?.isTemp) {
    message?.list_reaction.push({
      avatar: reactionSocket?.avatar,
      is_current_user: currentUser?.id === reactionSocket?.user_id ? IS_CURRENT_USER.CURRENT_USER : IS_CURRENT_USER.NOT_CURRENT_USER,
      name: reactionSocket?.name,
      reaction: reactionSocket?.reaction,
      user_id: reactionSocket?.user_id,
      isTemp: !!reactionSocket?.isTemp,
    });
  }
  // delete old reactions if have
  if (oldReactionIndex >= 0) {
    if (oldReaction?.isTemp) {
      unset(oldReaction, "isTemp");
    } else {
      message?.list_reaction?.splice(oldReactionIndex, 1);
    }
  }
  return message;
};

//update cache delete message
export const deleteMessageCache = (oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined, message: MESSAGE) => {
  if (!oldData) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<MESSAGE>> = cloneDeep(oldData); // deep copy
  for (const page of newData.pages) {
    const index = page.data.findIndex((messageOld) => messageOld.id === message.id);
    if (index !== -1) {
      page.data.splice(index, 1);
      break;
    }
  }
  return newData;
};

//update cache message react query
export const handleAppendMessage = (oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined, message: MESSAGE) => {
  if (!oldData) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<MESSAGE>> = cloneDeep(oldData); // deep copy
  //@TODO update check has page 1 => append message when api deploy
  if (newData.pages?.[0]?.current_page === 1) {
    newData.pages?.[0].data.unshift(message);
  }
  return newData;
};

//update cache message parent
export const handleUpdateMessageParent = (
  typeAction: ACTION_UPDATE,
  oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined,
  message: MESSAGE,
  reply_is_read: REPLY_IS_READ,
) => {
  if (!oldData) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<MESSAGE>> = cloneDeep(oldData); // deep copy
  // check increment total reply
  if (message?.reply_id) {
    const messageParentId = message.reply_id;

    for (const page of newData.pages) {
      const messageNeedUpdate = page.data.find((messageOld) => messageOld.id === +messageParentId);

      if (!messageNeedUpdate) continue;

      let newMessageTotal:number | null = messageNeedUpdate.message_count || 0;

      // if new message reply => + 1
      // if destroy message reply => -1
      if(typeAction === ACTION_UPDATE.APPEND){
        newMessageTotal = newMessageTotal + 1;
      }else{
        newMessageTotal = newMessageTotal - 1 > 0 ? newMessageTotal - 1 : null;
      }

      const messageReplyNew = {
        avatar: message.avatar,
        full_name: message.name,
        user_id: +message.user_id,
        is_current_user: message.message_type_user,
      };

      const checkUserExistReply = messageNeedUpdate.list_user_replies.findIndex((user) => +user.user_id === +messageReplyNew.user_id);
      const listUserReply = messageNeedUpdate.list_user_replies;

      // if new message reply and don't have user => + 1
      if (typeAction === ACTION_UPDATE.APPEND && checkUserExistReply === -1) {
        listUserReply.push(messageReplyNew);
      }
      
      Object.assign(messageNeedUpdate, {
        list_user_replies: listUserReply,
        message_count: newMessageTotal,
        reply_is_read,
      });
      break;
    }
  }
  return newData;
};

export const matchActionWithType = (
  typeAction: ACTION_UPDATE,
  oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined,
  newMessage: MESSAGE,
) => {
  let newData;
  switch (typeAction) {
    case ACTION_UPDATE.EDIT:
      newData = handleUpdateContentMessage(oldData, newMessage);
      break;
    case ACTION_UPDATE.APPEND:
      newData = handleAppendMessage(oldData, newMessage);
      break;
    case ACTION_UPDATE.DELETE:
      newData = deleteMessageCache(oldData, newMessage);
      break;
    default:
      newData = cloneDeep(oldData);
      break;
  }
  return newData;
};
// merge list reaction
export function createReactionSummary(data: MESSAGE_REACTION[]): ReactionNodeTotalType[] {
  // Count the occurrences of each reaction
  const reactionCounts = countBy(data, "reaction");

  // Create the result array
  const reactionSummary: ReactionNodeTotalType[] = [];

  // Iterate over the counted reactions and add them to the result array
  forOwn(reactionCounts, (count, reactionTypeId) => {
    // Find the corresponding reaction by key in the provided reactionList
    const reaction = LIST_REACTION.find((r) => +r.value === Number(reactionTypeId));
    const listUserReaction = data.filter((user) => user.reaction === Number(reactionTypeId));
    const hasCurrentUser = some(
      data,
      (item) => item.reaction === Number(reactionTypeId) && item.is_current_user === IS_CURRENT_USER.CURRENT_USER,
    );
    if (reaction) {
      reactionSummary.push({
        reaction: Number(reactionTypeId),
        reaction_total: count,
        icon: reaction.icon,
        is_current_user: hasCurrentUser ? IS_CURRENT_USER.CURRENT_USER : IS_CURRENT_USER.NOT_CURRENT_USER,
        user_reaction: listUserReaction,
      });
    }
  });

  return reactionSummary;
}

export const getReactionCurrentUser = (reactions:MESSAGE_REACTION[] = [], user:User | null) =>{
  if(!reactions || reactions?.length < 0 || !user) return undefined;
  const userReaction =  reactions.find((reaction) => +reaction.user_id === user.id);
  return userReaction?.reaction || undefined;
};

//update cache pin/unpin message
export const handleUpdateCachePinMessage = (oldData: InfiniteData<ResponseTypeCommon<MESSAGE>> | undefined, message: MESSAGE) => {
  if (isEmpty(oldData)) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<MESSAGE>> = cloneDeep(oldData);
  newData.pages.forEach((page) => {
    page.data = page.data.map((item) => {
      if (item.id === message.messages_id && message.event === EVENT_MESSAGE_PIN.PIN) {
        return {
          ...item,
          status_pin: IS_MESSAGE_PIN.NOT_PIN,
        };
      } else if (item.id === message.messages_id && message.event === EVENT_MESSAGE_PIN.UNPIN) {
        return {
          ...item,
          status_pin: IS_MESSAGE_PIN.PIN,
        };
      }
      return {
        ...item,
      };
    });
  });
  return newData;
};

//update cache content message
export const handleUpdateContentMessagePin = (oldData: SuccessResponse<MESSAGE[]> | undefined, messageUpdate: MESSAGE) => {
  if (isEmpty(oldData) || !messageUpdate) return oldData;
  const newData = cloneDeep(oldData);
  newData.data.forEach((messagePin) =>{
    if(messagePin?.messages_id === +messageUpdate.id){
      Object.assign(messagePin, {
        content_text: messageUpdate.content_text,
      });
    }
  });
  return newData;
};

/**
 * check time send message
 * if it were today => return time hh:mm
 * if different today => return HH:mm DD/MM/YYYY
 * @param time  "HH:mm DD/MM/YYYY"
 * @returns time "HH:mm DD/MM/YYYY" | hh:mm
 */
export const coverTimeSendMessage = (timeString: string): string => {
  const time = moment(timeString, "HH:mm DD/MM/YYYY");
  if (time.isSame(moment(), "day")) {
    const hour = time.format("hh:mm A");
    return hour;
  } else {
    return timeString;
  }
};

// Get path img file other
export const getPathFileOther = (fileName: string): string => {
  const wordRegex = /\.docx?$/i; // .doc, .docx
  const pdfRegex = /\.pdf$/i; // pdf
  const zipRegex = /\.zip$/i; // zip
  const excelRegex = /\.(xlsx?|xlsb?|xlsm|xls)$/i; //.xls, .xlsx, .xlsm, .xlsb
  const powerPointRegex = /\.(ppsx?|ppsm?|pps|pptx|ppt)$/i; //.ppsx, .ppsm, .pps, .pptx
  const imgRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i; //.jpg, .jpeg, .png, .gif, .bmp, .svg 

  if (wordRegex.test(fileName)) {
    return wordImg;
  } if (imgRegex.test(fileName)) {
    return imageImg;
  } else if (pdfRegex.test(fileName)) {
    return pdfImg;
  } else if (excelRegex.test(fileName)) {
    return excelImg;
  } else if (powerPointRegex.test(fileName)) {
    return powpImg;
  } else if (zipRegex.test(fileName)) {
    return zipImg;
  } else {
    return fileImg;
  }
};

// Get all file Img and Video in list
export const getAllImgAndVideoInList = (listFile: ContentFileType[]) => {
  if (isEmpty(listFile)) return [[], []];

  const listImg: ContentFileType[] = [];
  const listVideo: ContentFileType[] = [];

  for (const file of listFile) {
    if (file?.type === CONTENT_TYPE_FILE.IMG) {
      listImg.push(file);
    } else if (file?.type === CONTENT_TYPE_FILE.VIDEO) {
      listVideo.push(file);
    }
  }

  return [listImg, listVideo];
};

// Get all file Img in list
export const scrollToBottom = (listRef: RefObject<HTMLDivElement>) => {
  if (listRef.current) {
    listRef.current.scrollTop = listRef.current.scrollHeight;
  }
};

// create temp message when pending send message;
export const createMessageTemp = (
  content_text: string | null,
  userInfo: User | null,
  quote_text?: string | null | undefined,
  contentFile?: string | null | undefined,
): MESSAGE => {
  return {
    avatar: userInfo?.avatar ?? "",
    content_file: contentFile ?? "",
    content_text: content_text,
    group_message: "temp",
    id: getUniqueId(),
    isTo: 0,
    list_reaction: [],
    list_user_replies: [],
    message_count: null,
    message_reply: null,
    message_type_user: 1,
    name: userInfo?.full_name ?? "User",
    quote_text: quote_text ?? null,
    reply_id: null,
    reply_is_read: null,
    status_pin: 0,
    time_send_message: moment().format("HH:mm DD/MM/YYYY"),
    user_id: userInfo?.id ?? 0,
  };
};

// check file invalid send message
export const checkValidFile = (listFile: File[], accept: string) => {
  const listFileInvalid: TypeValidateFile[] = [];
  const dataValid = listFile.filter((file) => {
    const fileValidate = validateUploadedFiles(file, accept);
    if (fileValidate) {
      listFileInvalid.push(fileValidate);
      return false;
    }
    return true;
  });

  return { dataValid, listFileInvalid };
};

// get reaction node
export const getReactionNode = (reactionId: number) => {
  const reaction = LIST_REACTION.find((r) => +r.value === Number(reactionId));
  return reaction ? reaction.icon : <></>;
};
// reaction list
export const LIST_REACTION = [
  {
    value: REACTION_VALUE.ROGER,
    icon: <RogerReaction />,
    label: "Roger",
  },
  {
    value: REACTION_VALUE.THANKS,
    icon: <ClapReaction />,
    label: "Clap",
  },
  {
    value: REACTION_VALUE.YAY,
    icon: <YayReaction />,
    label: "Yay",
  },
  {
    value: REACTION_VALUE.CONGRAT,
    icon: <CongratReaction />,
    label: "Congrat",
  },
  {
    value: REACTION_VALUE.GREAT,
    icon: <ThankReaction />,
    label: "Thank",
  },
  {
    value: REACTION_VALUE.LIKE,
    icon: <LikeReaction />,
    label: "Like",
  },
  {
    value: REACTION_VALUE.HUNGGING,
    icon: <HuggingReaction />,
    label: "Love",
  },
];

export const copyLinkFile = (path: string | null) => {
  if (path) {
    navigator.clipboard
      .writeText(BASE_SRC_FILE + path ?? "")
      .then(() => {
        toast.success(t("toast.text_copied_to_clipboard"));
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
  }
};

export const coverContentFileToObjectFile = (
  file: ContentFileType,
  dataMessage: DATA_MESSAGE_PREVIEW,
  userSendMessage: MEMBER,
  conversation_id: number | undefined,
): TYPE_MESSAGE_FILE => {
  return {
    conversation_id: conversation_id || 0,
    created_at: moment(dataMessage.dateSendMessage, "HH:mm DD/MM/YYYY").toISOString(),
    department: "",
    id: file?.id || 0, // @TODO need backend return file id
    message_id: +dataMessage.messageId,
    name: file.name,
    path: file.file_path,
    size: file.size,
    thumbnail: file.thumbnail,
    type: file.type,
    user_avatar: userSendMessage.avatar,
    user_id: userSendMessage.id,
    user_name: userSendMessage.name || "",
    reply_id: dataMessage?.messageReplyId || "",
  };
};

export const getDataPreview = (message: MESSAGE): DATA_MESSAGE_PREVIEW => {
  return {
    dateSendMessage: message.time_send_message,
    messageId: message.id,
    messageReplyId: message.reply_id,
    messageUserId: message.user_id,
  };
};
