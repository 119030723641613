import DialogAccountSettingContainer from "components/dialog/accountSetting/DialogAccountSettingContainer";
import { DialogLogout } from "components/dialog/DialogLogout/DialogLogout";
import DialogMyProfileContainer from "components/dialog/profile/DialogMyProfileContainer";
import { t } from "i18next";
import { Dispatch, LegacyRef, SetStateAction, useEffect, useState } from "react";

interface PopupMenuProps {
  className?: string;
  openPopupMenu: boolean;
  popupRef: LegacyRef<HTMLUListElement>;
  handleToggleSetting: () => void;
  setOpenPopupMenu: Dispatch<SetStateAction<boolean>>;
}
export default function PopupMenu({ handleToggleSetting, openPopupMenu, className, setOpenPopupMenu, popupRef }: PopupMenuProps) {
  const [openDialogLogout, setOpenDialogLogout] = useState(false);
  const [openDialogMyProfile, setOpenDialogMyProfile] = useState(false);
  const [openDialogAccountSetting, setOpenDialogAccountSetting] = useState(false);

  useEffect(() => {
    setOpenPopupMenu(false);
  }, [openDialogLogout, openDialogMyProfile]);

  return (
    <>
      <ul
        className={`${openPopupMenu ? "block" : "hidden"} z-[99] absolute shadow-md  right-16 top-full bg-white rounded ${className}`}
        ref={popupRef}
      >
        <li
          onClick={() => setOpenDialogMyProfile(true)}
          className=" min-w-[165px] min-h-[38px] hover:bg-neutral-20 px-2 cursor-pointer flex items-center"
        >
          {t("popup_menu.profile")}
        </li>
        <li onClick={handleToggleSetting} className=" min-w-[165px] min-h-[38px] hover:bg-neutral-20 px-2 cursor-pointer flex items-center">
          {t("popup_menu.setting")}
        </li>
        <li
          onClick={() => setOpenDialogAccountSetting(true)}
          className=" min-w-[165px] min-h-[38px] hover:bg-neutral-20 px-2 cursor-pointer flex items-center"
        >
          {t("popup_menu.account_setting")}
        </li>
        <li className=" min-w-[165px] min-h-[38px] hover:bg-neutral-20 px-2 cursor-pointer flex items-center">
          {t("popup_menu.privacy_policy")}
        </li>
        <li
          onClick={() => setOpenDialogLogout(true)}
          className=" min-w-[165px] min-h-[38px] hover:bg-neutral-20 px-2 cursor-pointer flex items-center text-red-500"
        >
          {t("popup_menu.logout")}
        </li>
      </ul>
      {openDialogLogout && <DialogLogout openDialogLogout={openDialogLogout} closeModal={() => setOpenDialogLogout((prev) => !prev)} />}
      {openDialogMyProfile && <DialogMyProfileContainer closeModal={() => setOpenDialogMyProfile((prev) => !prev)} />}
      {openDialogAccountSetting && <DialogAccountSettingContainer closeModal={() => setOpenDialogAccountSetting((prev) => !prev)} />}
    </>
  );
}
