/* eslint-disable max-len */
const LinkIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.02812 14.9672C2.20781 14.1469 1.8 13.1531 1.8 11.9953C1.8 10.8844 2.24531 9.82032 3.02812 9.03282C3.84844 8.21251 4.8375 7.8047 6 7.8047H10.2C10.6969 7.8047 11.1 7.40157 11.1 6.9047C11.1 6.40782 10.6969 6.0047 10.2 6.0047H6C4.34062 6.0047 2.925 6.59064 1.75313 7.75782C0.58125 8.92501 0 10.3406 0 12C0 13.6594 0.585938 15.075 1.75313 16.2469C2.92031 17.4188 4.34062 18 6 18H10.2C10.6969 18 11.1 17.5969 11.1 17.1C11.1 16.6031 10.6969 16.2 10.2 16.2H6.0375C4.90781 16.2047 3.825 15.7594 3.02812 14.9672Z"
        fill="#1F1B16"
      />
      <path
        d="M8.2501 11.1H15.7501C16.247 11.1 16.6501 11.5031 16.6501 12C16.6501 12.4969 16.247 12.9 15.7501 12.9H8.2501C7.75322 12.9 7.3501 12.4969 7.3501 12C7.3501 11.5031 7.75322 11.1 8.2501 11.1Z"
        fill="#1F1B16"
      />
      <path
        d="M22.2468 7.75312C21.0749 6.58125 19.664 6 17.9999 6H13.7999C13.303 6 12.8999 6.40312 12.8999 6.9C12.8999 7.39687 13.303 7.8 13.7999 7.8H17.9624C19.0874 7.79531 20.1702 8.24062 20.9718 9.03281C21.7921 9.85312 22.1999 10.8469 22.1999 12.0047C22.1999 13.1156 21.7546 14.1797 20.9718 14.9672C20.1515 15.7875 19.1624 16.1953 17.9999 16.1953H13.7999C13.303 16.1953 12.8999 16.5984 12.8999 17.0953C12.8999 17.5922 13.303 17.9953 13.7999 17.9953H17.9999C19.6593 17.9953 21.0749 17.4094 22.2468 16.2422C23.4187 15.0703 23.9999 13.6594 23.9999 11.9953C23.9999 10.3406 23.414 8.925 22.2468 7.75312Z"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default LinkIcon;
