import type { CheckboxStylesType } from "@material-tailwind/react";
type ThemeCustomType = {
  checkbox: CheckboxStylesType;
};
export const themeCustom: ThemeCustomType = {
  checkbox: {
    valid: {
      colors: ["amber"],
    },
    styles: {
      colors: {
        amber: {
          background: "checked:bg-primary-900",
          border: "checked:border-primary-900",
          before: "checked:before:primary-900",
        },
      },
    },
  },
};
