import { DialogBody } from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import ProfileInfo from "components/contact/sidebar/common/ProfileInfo";
import { QUERY_KEY } from "constants/pathApi";
import { getInfoUserLogin } from "http/contact.http";
import { t } from "i18next";
import { UserContact } from "types/user.type";

interface DialogAccountSettingProp {
  onOpenDialogChangePassword: () => void;
}

const DialogAccountSetting = ({ onOpenDialogChangePassword }: DialogAccountSettingProp) => {
  const { data: userDetail } = useQuery({
    queryKey: [QUERY_KEY.USER_LOGIN_INFO],
    queryFn: () => getInfoUserLogin<UserContact>(),
    ...{
      refetchOnWindowFocus: true,
    },
  });

  return (
    <DialogBody
      placeholder={"dialog"}
      className="px-4 pt-0 text-neutral-110 h-[818px] hover:overflow-y-scroll scroll-sidebar overflow-hidden"
    >
      <div className="">
        <ProfileInfo isMoreInfo={false} className="w-[100px] h-[100px]" userDetailContact={userDetail} />
        <div className="h-[1px] px-0 bg-primary-200"></div>
        <div className="pt-6">
          <div className="mb-4">
            <label className="block text-neutral-60 mb-2" htmlFor="username">
              {t("contact.profile.user_name")}
            </label>
            <div className="rounded w-full py-3 px-3 text-neutral-110 bg-neutral-20" id="username">
              {userDetail?.email}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-neutral-60 mb-2" htmlFor="email_address">
              {t("contact.profile.email_address")}
            </label>
            <div className="rounded w-full py-3 px-3 text-neutral-110 bg-neutral-20" id="username">
              {userDetail?.email}
            </div>
          </div>
          <div className="mb-4 relative">
            <label className="block text-neutral-60 mb-2" htmlFor="password">
              {t("login.password")}
            </label>
            <input
              value="0123456789"
              className="rounded w-full py-3 px-3 text-neutral-110 bg-neutral-20"
              id="password"
              type="password"
              disabled
              placeholder="password"
            />
            <span onClick={onOpenDialogChangePassword} className="absolute cursor-pointer right-3 bottom-[14px] text-primary-900 text-sm">
              {t("button.change")}
            </span>
          </div>
        </div>
      </div>
    </DialogBody>
  );
};

export default DialogAccountSetting;
