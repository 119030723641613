import { InfiniteData } from "@tanstack/react-query";
import { TYPE_FILE } from "constants/constans";
import { postMuteConversation, postPinConversation } from "http/conversation.http";
import { cloneDeep, forEach, groupBy, isEmpty, last, orderBy, uniqBy } from "lodash";
import moment from "moment";
import { ACTION_CONVERSATION, CONVERSATION_MUTE, Conversation, PIN_CONVERSATION, PinResponse } from "types/conversation.type";
import { ContentFileType, MESSAGE } from "types/message.type";
import { ACTION_OPEN_SIDEBAR, SidebarType } from "types/sidebar.type";
import { User } from "types/user.type";
import { ResponseTypeCommon, SuccessResponse } from "types/utils.type";

export function handlePinAction(conversation: Conversation): Conversation {
  conversation.conversation_pin = conversation.conversation_pin ? PIN_CONVERSATION.NOT_PIN : PIN_CONVERSATION.PIN;
  return conversation;
}

// update conversation when send new message
export function handleUpdateConversation(
  oldData: InfiniteData<ResponseTypeCommon<Conversation>> | undefined,
  conversation_id: number,
  newMessage: MESSAGE,
) {
  if (!oldData) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<Conversation>> = cloneDeep(oldData);
  newData.pages.forEach((page) => {
    page.data.forEach((conversation) => {
      if (conversation.conversation_id === conversation_id) {
        conversation.most_recent_message = handleGetMostRecent(newMessage);
        conversation.time_send_message_default = moment(newMessage.time_send_message, "HH:mm DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss");
        conversation.time_send_message = moment(newMessage.time_send_message, "HH:mm DD/MM/YYYY").format("HH:mm A");
        return;
      }
    });
  });
  return newData;
}

// handle get most_recent_message
export function handleGetMostRecent(message: MESSAGE): string {
  let most_recent_message = message.content_text || "";
  if (message.content_file) {
    const listFile = JSON.parse(message.content_file);
    const fileRecent: ContentFileType | undefined = last(listFile);
    if (!fileRecent) return most_recent_message;
    const formatMessage = {
      [TYPE_FILE.IMAGE]: "[Photo]",
      [TYPE_FILE.VIDEO]: "[Video]",
      [TYPE_FILE.OTHER]: "[File]",
      [TYPE_FILE.RADIO]: "[File]",
    };
    forEach(formatMessage, (value, key) => {
      if (+key === fileRecent.type) {
        if (+key === TYPE_FILE.OTHER || +key === TYPE_FILE.RADIO) {
          most_recent_message = `${value} ${fileRecent.name}`;
        } else {
          most_recent_message = value;
        }
      }
    });
  }
  return most_recent_message;
}

// handle update new message to conversation
export function handleMuteAction(conversation: Conversation): Conversation {
  conversation.is_mute = conversation.is_mute ? CONVERSATION_MUTE.NOT_MUTE : CONVERSATION_MUTE.MUTE;
  return conversation;
}
// handle update cache conversation
export function handleUpdateCacheConversation(
  oldData: InfiniteData<ResponseTypeCommon<Conversation>> | undefined,
  conversationUpdate: Conversation,
  userInfo: User | null,
  type: ACTION_CONVERSATION,
): [boolean,InfiniteData<ResponseTypeCommon<Conversation>, unknown> | undefined] {
  if (!oldData) return [false, oldData];

  const newData = cloneDeep(oldData);
  let isAssigned = false;
  for (const page of newData.pages) {
    const conversationToUpdate = page.data.find((conversation) => conversation.conversation_id === conversationUpdate.conversation_id);
    if (conversationToUpdate) {
      // if is action when new message
      if (conversationUpdate?.message_id && type === ACTION_CONVERSATION.SOCKET) {

        const newMessageUnread = conversationUpdate.user_id !== userInfo?.id
                                      ? conversationToUpdate.message_count_unread + 1 
                                      : conversationToUpdate.message_count_unread;

        const newAssignData: Conversation = {
          ...conversationUpdate,
          message_count_unread: newMessageUnread,
        };
        Object.assign(conversationToUpdate, newAssignData);
      } else {
        Object.assign(conversationToUpdate, conversationUpdate);
      }
      isAssigned = true;
      break;
    }
  }
  return [isAssigned, newData];
}
//update cache  react query
export const handleAppendConversation = (oldData: InfiniteData<ResponseTypeCommon<Conversation>> | undefined,
  conversationAppend: Conversation) => {
  if (!oldData) return oldData;
  const newData: InfiniteData<ResponseTypeCommon<Conversation>> = cloneDeep(oldData); // deep copy

  if (newData.pages?.[0]) {
    newData.pages?.[0].data.unshift(conversationAppend);
  }
  return newData;
};
// handle delete cache conversation
export function handleDeleteCacheConversation(
  oldData: InfiniteData<ResponseTypeCommon<Conversation>> | undefined,
  conversationDeleteId: number,
): InfiniteData<ResponseTypeCommon<Conversation>, unknown> | undefined {
  if (!oldData) return oldData;

  const newData = cloneDeep(oldData);

  for (const page of newData.pages) {
    const index = page.data.findIndex((conversation) => conversation.conversation_id === conversationDeleteId);
    if (index !== -1) {
      page.data.splice(index, 1);
      break;
    }
  }

  return newData;
}
// sort conversation with message time
export function sortConversationWithTime(conversations: Conversation[]): Conversation[] {
  const uniqueConversations = uniqBy(conversations, "conversation_id");

  const groupedConversations = groupBy(uniqueConversations, (conversation: Conversation) => {
    return conversation.conversation_pin === PIN_CONVERSATION.PIN ? PIN_CONVERSATION.PIN : PIN_CONVERSATION.NOT_PIN;
  });
  const sortedPinnedConversations = orderBy(
    groupedConversations[PIN_CONVERSATION.PIN],
    (o: Conversation) => {
      const date = moment(o.time_send_message_default, "YYYY-MM-DD HH:mm:ss");
      if(date.isValid()) return date;
      return o.conversation_id;
    },
    "desc",
  );

  const sortedNotPinnedConversations = orderBy(
    groupedConversations[PIN_CONVERSATION.NOT_PIN],
    (o: Conversation) => {
      const date = moment(o.time_send_message_default, "YYYY-MM-DD HH:mm:ss");
      if(date.isValid()) return date;
      return o.conversation_id;
    },
    "desc",
  );

  return sortedPinnedConversations.concat(sortedNotPinnedConversations);
}

export function replaceSidebarType(oldSideBar: SidebarType, type: ACTION_OPEN_SIDEBAR) {
  if (!oldSideBar) {
    return oldSideBar;
  }

  if (isEmpty(oldSideBar?.prevSidebar)) {
    return oldSideBar;
  }

  if (oldSideBar?.prevSidebar.type === type) {
    oldSideBar.prevSidebar = {
      ...(oldSideBar?.prevSidebar ?? {}),
    };
  }
  return oldSideBar;
}

// send req mute and pin conversation
export const handleSendReqMuteAndPin =  (selectConversation: Conversation, type: ACTION_CONVERSATION.PIN | ACTION_CONVERSATION.MUTE) => {
  let conversationChange = cloneDeep(selectConversation);
  if (type === ACTION_CONVERSATION.PIN) {
    postPinConversation<SuccessResponse<PinResponse>>(conversationChange.conversation_id);
    conversationChange = handlePinAction(conversationChange);
  } else if (type === ACTION_CONVERSATION.MUTE) {
    postMuteConversation<SuccessResponse<PinResponse>>(conversationChange.conversation_id);
    conversationChange = handleMuteAction(conversationChange);
  }
  return conversationChange;
};
