import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendRequestAuthorization } from "http/user.http";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { getUserInfo, saveDeviceToken, setOauthToken, setPrevPage } from "../../redux/slices/auth";
import { CHAT, LOGIN_FAIL } from "constants/pathUrl";
import LoadingPage from "pages/common/LoadingPage";
import { LOGIN_GRANT_TYPE_CODE } from "types/auth.type";
import { redirectLogout } from "utils/common";

const CallBackLogin = () => {
  const [searchParams] = useSearchParams();
  const { prevPage, oauthToken } = useCustomSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  useEffect(() => {
    const code = searchParams.get("code");

    // check data
    if (
      oauthToken ||
      !code ||
      !process.env.REACT_APP_CLIENT_ID ||
      !process.env.REACT_APP_CLIENT_SECRET ||
      !process.env.REACT_APP_LOGIN_CALLBACK
    ) {
      return navigate(LOGIN_FAIL);
    }

    // request get token
    sendRequestAuthorization({
      grant_type: LOGIN_GRANT_TYPE_CODE.AUTHORIZATION_CODE,
      code,
    }).then((response) => {
      if (response && response?.access_token) {
        // save token
        dispatch(
          setOauthToken({
            ...response,
            expires_in: Date.now() + (response?.expires_in * 1000),
          }),
        );

        // navigate prev page
        if (prevPage && prevPage !== "/") {
          navigate(prevPage);
          dispatch(setPrevPage(null));
        } else {
          navigate(CHAT);
        }
        //get user info
        dispatch(getUserInfo());
        dispatch(saveDeviceToken());
      } else {
        redirectLogout();
      }
    });
  }, [searchParams]);

  return <LoadingPage />;
};

export default CallBackLogin;
