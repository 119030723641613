import { Spinner } from "@material-tailwind/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import AvatarCatchError from "components/image/AvatarCatchError";
import MemberLoadingSkeleton from "components/loading/MemberLoadingSkeleton";
import { LIMIT } from "constants/constans";
import { QUERY_KEY } from "constants/pathApi";
import { getListSender } from "http/contact.http";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { SENDER } from "types/contact.type";
import { ResponseTypeCommon } from "types/utils.type";
import { getNextPageIndex, mapDataInfiniteQuery } from "utils/common";
import { handleCompareString } from "utils/strUtils";

interface PopupListSenderProps {
  debouncedSearch: string;
  classNameParent?: string;
  classNameChildren?: string;
  classNameAvatar?: string;
  onClick?: (value: SENDER) => void;
}
const PopupListSender = ({ debouncedSearch, classNameParent, classNameChildren, classNameAvatar, onClick }: PopupListSenderProps) => {
  const [listSender, setListSender] = useState<SENDER[]>([]);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    totalPage: 1,
    currentPage: 1,
  });
  const { totalPage, currentPage } = pagination;
  const { ref, inView } = useInView();
  const { data, isLoading } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_ALL_SENDER_SELECT],
    queryFn: ({ pageParam = 1 }) =>
      getListSender<ResponseTypeCommon<SENDER>>({
        pageParam,
        params: {
          per_page: LIMIT.ALL,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: getNextPageIndex,
    staleTime: 0,
    gcTime: 0,
  });
  const senders = useMemo(() => {
    return listSender.slice(0, currentPage * LIMIT.SEARCH_CONTACT);
  }, [listSender, pagination]);

  const handleSearch = () => {
    const rawData = mapDataInfiniteQuery<SENDER, ResponseTypeCommon<SENDER>>(data);
    const filteredData = rawData.filter((item) => {
      return handleCompareString(debouncedSearch, item.name);
    });
    setListSender(filteredData);
    setPagination({ totalPage: Math.ceil(filteredData.length / LIMIT.SEARCH_CONTACT), currentPage: 1 });
  };

  useEffect(() => {
    if (data) {
      handleSearch();
    }
  }, [data, debouncedSearch]);

  useEffect(() => {
    if (inView) {
      if (currentPage < totalPage) {
        setIsLoadingNextPage(true);
        setTimeout(() => {
          setPagination((prevPage) => ({
            ...prevPage,
            currentPage: prevPage.currentPage + 1,
          }));
          setIsLoadingNextPage(false);
        }, 500);
      }
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <div className={`${classNameParent} h-full overflow-hidden hover:overflow-y-scroll scroll-sidebar`}>
          <MemberLoadingSkeleton />
        </div>
      ) : (
        senders && (
          <div className={`${classNameParent} h-full overflow-hidden overflow-y-auto scroll-small`}>
            {senders.map((sender) => (
              <div
                key={sender.id}
                onClick={() => onClick && onClick(sender)}
                className={`${classNameChildren} flex items-center hover:bg-neutral-20 px-4 py-2`}
              >
                <AvatarCatchError className={`${classNameAvatar}`} src={sender?.avatar} />
                <p className="text-sm font-normal text-neutral-110">{sender?.name}</p>
              </div>
            ))}
            <div ref={ref}></div>
            <div className="flex justify-center">{isLoadingNextPage && <Spinner />}</div>
          </div>
        )
      )}
    </>
  );
};
export default PopupListSender;
