const LikeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.87375 13.5894C4.87375 14.021 4.51585 14.3684 4.0948 14.3684H1.93691C1.50533 14.3684 
        1.15796 14.021 1.15796 13.5894V7.47365C1.15796 7.04207 1.50533 6.6947 1.93691 6.6947H4.08427C4.51585 
        6.6947 4.86322 7.04207 4.86322 7.47365L4.87375 13.5894ZM14.0106 8.78944C14.4737 9.15786 14.5474 
        9.83155 14.179 10.2947C14.0316 10.4842 13.8211 10.6105 13.5895 10.6631C14.0527 11.0315 14.1264 
        11.7052 13.758 12.1579C13.6106 12.3473 13.4001 12.4736 13.158 12.5368C13.6211 12.9052 13.6948 
        13.5789 13.3159 14.0421C13.1159 14.2947 12.8106 14.4421 12.4843 14.4421L6.43164 14.3789C6.00006 
        14.3789 5.6527 14.0315 5.6527 13.6V7.47365C5.6527 6.50523 8.74743 4.72628 8.90533 3.76839C9.01059 
        3.13681 8.87375 1.55786 9.2948 1.55786C10.0211 1.55786 10.9369 1.83155 10.9369 3.43155C10.9369 
        4.84207 10.0106 6.67365 10.0106 6.67365H13.758C14.3474 6.67365 14.8211 7.14734 14.8211 
        7.73681C14.8422 8.2526 14.4948 8.68418 14.0106 8.78944Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LikeIcon;
