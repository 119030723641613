import * as axiosClient from "api/axiosClient";
import { LIMIT } from "constants/constans";
import { API_URL } from "constants/pathApi";
import { Dictionary } from "lodash";
import { MESSAGE, SendMessageType } from "types/message.type";
import { ParamSearchMessage, ResponseTypeMessage, SuccessResponse } from "types/utils.type";
import { replaceDynamicApi } from "utils/common";
interface getListMessageType {
  pageParam?: number | unknown;
  conversation_id: number | undefined;
  messageId?: number;
  param?: any;
}
interface getListMemberInConversationType {
  conversation_id: number | undefined;
  pageParam?: number | unknown;
  params?: {
    user_name: string;
  };
}
interface SendAddContactType {
  typeRequest?: number;
  value?: string | undefined;
}

interface getListMemberNotBeenConversationType {
  conversation_id: number | undefined;
  pageParam?: number | unknown;
  params?: {
    keyword: string;
  };
}

interface ADD_USER_TO_CONVERSATION {
  add: string;
}

interface UPDATE_USER_TO_CONVERSATION {
  update: string;
}

interface DELETE_USER_TO_CONVERSATION {
  delete: string;
}

type ActionUserToConversation = ADD_USER_TO_CONVERSATION | UPDATE_USER_TO_CONVERSATION | DELETE_USER_TO_CONVERSATION;

export interface UPDATE_USER_TO_CONVERSATION_RESPONSE {
  code: number;
  message: string;
  data: ADD_USER_TO_CONVERSATION | UPDATE_USER_TO_CONVERSATION | DELETE_USER_TO_CONVERSATION;
}

interface getListFilesMessageType {
  filter_from?: Date;
  filter_to?: Date;
  user_id?: number;
  typeToFilter?: number;
  conversation_id: number | undefined;
  type: string;
}

interface getListFilesType {
  pageParam?: number | unknown;
  params?: {
    filter_from?: Date | string | null;
    filter_to?: Date | string | null;
    user_id?: number;
    typeToFilter?: number;
    conversation_id: number | undefined;
    type: string;
    keyword?: string;
  };
}

export interface TYPE_DELETE_FILE_RESPONSE {
  code: number;
  message: string;
  data: number;
}

export const getListMessage = async <T>({ pageParam, conversation_id, messageId, param }: getListMessageType): Promise<T> => {
  const response = await axiosClient.getAxios<T>(replaceDynamicApi(API_URL.MESSAGE_CONVERSATION, conversation_id as number), {
    params: {
      per_page: LIMIT.MESSAGE,
      messageId: messageId,
      ...param,
    },
  });
  return response;
};

export const getListMessageFileSearch = async <T>(param: Dictionary<ParamSearchMessage>, url:string): Promise<T> => {
  const response = await axiosClient.getAxios<T>(url, {
    params: {
      ...param,
    },
  });
  return response;
};

export const readMessage = async (messageResponse?: ResponseTypeMessage<MESSAGE>, conversation_id?: number, firstId?:number) => {
  const firstMessageId = firstId ? firstId : messageResponse ? messageResponse.data?.[0]?.id : null;
  if (firstMessageId && conversation_id) {
    try {
      const response = await axiosClient.getAxios(replaceDynamicApi(API_URL.READ_MESSAGE, [conversation_id, +firstMessageId]));
      return response;
    } catch (error) {
      return null;
    }
  }
};
export const getListMemberInConversation = async <T>({
  conversation_id,
  params,
  pageParam,
}: getListMemberInConversationType): Promise<T> => {
  const response = await axiosClient.getAxios<T>(API_URL.CONVERSATION_PARTICIPANT, {
    params: {
      per_page: LIMIT.ALL,
      conversation_id,
      page: pageParam,
      ...params,
    },
  });
  return response;
};
export const getAllUser = async <T>() => {
  try {
    const response = await axiosClient.getAxios<T>(API_URL.ALL_USER);
    return response;
  } catch (error) {
    return null;
  }
};
export const sendMessageConversation = async <T>(data: SendMessageType, conversation_id: number) => {
  try {
    const response = await axiosClient.postAxios<T>(replaceDynamicApi(API_URL.SEND_MESSAGE, conversation_id), {
      ...data,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const editConversation = async <T>(data: MESSAGE, message_id: number) => {
  try {
    const response = await axiosClient.postAxios<T>(replaceDynamicApi(API_URL.EDIT_MESSAGE, message_id), {
      ...data,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const sendReactionMessage = async <T>(message_id: number, reaction: number | string) => {
  try {
    const response = await axiosClient.postAxios<T>(API_URL.REACTION_MESSAGE, {
      message_id: message_id,
      reaction,
    });
    return response;
  } catch (error) {
    return null;
  }
};
export const handleUploadMultipleFile = async <T>(
  message = "message",
  files: File[],
): Promise<{
  success: SuccessResponse<T>[];
  error: File[] | [];
}> => {
  const listFileError: File[] = [];
  const uploadPromises = files.map(async (file) => {
    try {
      const response = await axiosClient.postAxios<any>(
        API_URL.UPLOAD_FILE,
        {
          test_url: file,
          type: message,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data;
    } catch (error) {
      listFileError.push(file);
      return null;
    }
  });
  const listFileSuccess = await Promise.all<SuccessResponse<T>>(uploadPromises);
  return {
    success: listFileSuccess,
    error: listFileError,
  };
};

export const getListFileMessage = async <T>(data: getListFilesMessageType): Promise<T> => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_FILES_MESSAGE, {
    params: {
      ...data,
    },
  });
  return response;
};
export const handleUploadFile = async <T>(message = "message", file: File | Blob): Promise<SuccessResponse<T> | null> => {
  try {
    const response = await axiosClient.postAxios<any>(
      API_URL.UPLOAD_FILE,
      {
        test_url: file,
        type: message,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const sendRequestContact = async <T>({ typeRequest, value }: SendAddContactType) => {
  try {
    const response = await axiosClient.postAxios<T>(API_URL.ADD_CONTACT, {
      type_request: typeRequest,
      value: value,
    });
    return response;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getListMemberNotBeenAdd = async <T>({
  conversation_id,
  params,
  pageParam,
}: getListMemberNotBeenConversationType): Promise<T> => {
  const response = await axiosClient.getAxios<T>(replaceDynamicApi(API_URL.LIST_MEMBER_NOTBEEN_ADD, conversation_id as number), {
    params: {
      per_page: LIMIT.MEMBER_IN_CONVERSATION,
      conversation_id,
      page: pageParam,
      ...params,
    },
  });
  return response;
};

export const updateUserToConversations = async <T>(action: ActionUserToConversation, conversation_id: number) => {
  try {
    const response = await axiosClient.postAxios<T>(replaceDynamicApi(API_URL.UPDATE_USER_TO_CONVERSATION, conversation_id), {
      ...action,
    });
    return response;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const deleteMessage = async <T>(messageId: number) => {
  try {
    const response = await axiosClient.deleteAxios<T>(replaceDynamicApi(API_URL.DELETE_MESSAGE, messageId as number));
    return response;
  } catch (error) {
    return null;
  }
};

export const postPinMessage = async <T>(message_id: number | undefined) => {
  const response = await axiosClient.postAxios<T>(replaceDynamicApi(API_URL.PIN_MESSAGE, message_id as number), {
    params: {
      message_id,
    },
  });
  return response;
};

export const getPinMessage = async <T>(conversationId: number | undefined) => {
  try {
    const response = await axiosClient.getAxios<T>(replaceDynamicApi(API_URL.LIST_PIN_MESSAGE, conversationId as number));
    return response;
  } catch (error) {
    return null;
  }
};

export const getListFileInConversation = async <T>({ pageParam, params }: getListFilesType): Promise<T> => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_FILES_MESSAGE, {
    params: {
      per_page: LIMIT.FILES,
      page: pageParam,
      ...params,
    },
  });
  return response;
};

export const deleteFileMessage = async <T>(fileId: number | undefined) => {
  try {
    const response = await axiosClient.deleteAxios<T>(replaceDynamicApi(API_URL.DELETE_FILE_MESSAGES, fileId as number));
    return response;
  } catch (error: any) {
    return error?.response?.data;
  }
};
