interface ChevronDownProps {
  open?: boolean;
}
const ChevronDownIcon = ({ open }: ChevronDownProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={`${open ? "" : "rotate-180"} cursor-pointer transition-transform`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.5 15L12.5 9L6.5 15" stroke="#1F1B16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChevronDownIcon;
