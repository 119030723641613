import * as axiosClient from "api/axiosClient";
import { LIMIT } from "constants/constans";
import { API_URL } from "constants/pathApi";
import { CREATE_CONVERSATION_PARAM, TYPE_CONVERSATION_FILTER, UPDATE_CONVERSATION_INFO } from "types/conversation.type";
import { replaceDynamicApi } from "utils/common";

interface getListConversationType {
  pageParam: number | unknown;
  params?: {
    type?: TYPE_CONVERSATION_FILTER;
    conversation_name?: string;
    conversation_id?: number | null;
  };
  per_page?: number;
}

interface getDetailConversationType {
  conversation_id: number | undefined;
}

export const getListConversation = async <T>({ pageParam, params, per_page = LIMIT.CONVERSATION }: getListConversationType) => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_CONVERSATION, {
    params: {
      per_page,
      page: pageParam,
      ...params,
    },
  });
  return response;
};

export const postPinConversation = async <T>(conversationId: number) => {
  try {
    const response = await axiosClient.postAxios<T>(API_URL.CONVERSATION_PIN, {
      conversation_id: conversationId,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const postMuteConversation = async <T>(conversationId: number) => {
  try {
    const response = await axiosClient.postAxios<T>(API_URL.CONVERSATION_MUTE, {
      conversation_id: conversationId,
    });
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteConversation = async <T>(conversationId: number) => {
  const response = await axiosClient.deleteAxios<T>(replaceDynamicApi(API_URL.DELETE_CONVERSATION, conversationId));
  return response;
};

export const getDetailConversation = async <T>({ conversation_id }: getDetailConversationType) => {
  try {
    const response = await axiosClient.getAxios<T>(replaceDynamicApi(API_URL.CONVERSATION_DETAIL, conversation_id as number));
    return response;
  } catch (error) {
    return null;
  }
};

export const postConversationInfo = async <T>(data: UPDATE_CONVERSATION_INFO, conversationId: number) => {
  try {
    const response = await axiosClient.postAxios<T>(
      replaceDynamicApi(API_URL.UPDATE_CONVERSATION_INFO, conversationId as number),
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const postConversation = async <T>(data: CREATE_CONVERSATION_PARAM) => {
  try {
    const response = await axiosClient.postAxios<T>(API_URL.CREATE_CONVERSATION, {
      ...data,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    );
    return response;
  } catch (error: any) {
    return error?.response?.data;
  }
};
