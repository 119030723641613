/* eslint-disable max-len */
const LogoSvg = () => {
  return (
    <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.83045 4.13798C3.66658 4.02406 3.51149 3.90429 3.34762 3.79328C2.3644 3.13017 1.30218 2.65401 0.125828 2.44661C0.00585249 2.42324 0 2.46705 0 2.56345V14.1314C0 14.2775 0.00292624 14.4235 0.00585248 14.5725C0.00877872 14.4703 0.0204837 14.371 0.0204837 14.2687C0.0238463 14.2563 0.0258093 14.2436 0.0263362 14.2307C0.0438936 14.1285 0.0585248 14.0292 0.0790085 13.9299C0.107527 13.7173 0.152531 13.5074 0.213616 13.3018C0.216542 13.296 0.216542 13.2901 0.219468 13.2843C0.327757 12.8192 0.471563 12.3632 0.649626 11.9201C1.19391 10.5354 1.80256 9.17414 2.37903 7.80409C2.87259 6.62977 3.37005 5.45739 3.87142 4.28696C3.90068 4.21686 3.8919 4.17888 3.83045 4.13798Z"
        fill="#592C12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3821 7.31877C10.3037 6.9119 10.204 6.50868 10.0794 6.10976C9.47372 4.18176 8.37052 2.60723 6.68793 1.4592C6.38511 1.25033 6.06416 1.0725 5.74213 0.894061C5.72186 0.88283 5.70158 0.871596 5.68131 0.860352H5.66082C5.13995 2.10868 4.61811 3.35603 4.09529 4.60241L1.16904 11.6045C1.09996 11.7709 1.02885 11.9364 0.957723 12.1019C0.721539 12.6517 0.485288 13.2016 0.323361 13.7808C0.323361 13.7823 0.322629 13.7838 0.321897 13.7852C0.321166 13.7867 0.320434 13.7881 0.320434 13.7896C0.283654 13.9636 0.247748 14.1385 0.211761 14.3138C0.181895 14.4592 0.151973 14.6049 0.12145 14.7507C0.118524 14.7799 0.116329 14.8084 0.114134 14.8369C0.11194 14.8653 0.109745 14.8938 0.106819 14.923L0.0892613 15.1596C0.0632632 15.3748 0.0505588 15.5914 0.0512201 15.8082C0.0379104 16.4683 0.0944322 17.1275 0.219492 17.7747C0.972068 21.9623 4.63432 25.1399 9.03875 25.1399C13.9877 25.1399 17.9996 21.1281 17.9996 16.1793C17.9996 11.6871 14.6938 7.96694 10.3821 7.31877Z"
        fill="#592C12"
      />
      <ellipse cx="4.35353" cy="16.5888" rx="1.44435" ry="1.4443" fill="white" />
      <ellipse cx="8.98903" cy="16.5888" rx="1.44435" ry="1.4443" fill="white" />
      <ellipse cx="13.6243" cy="16.5888" rx="1.44435" ry="1.4443" fill="white" />
    </svg>
  );
};

export default LogoSvg;
