const ReplyIcon = ({ className =  "text-neutral-10" }: { className?: string }) => {
  return (
    <div className={className}>
      <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7110_32270)">
          <path
            d="M0.900016 0.899929C1.39709 0.89993 1.80003 1.30291 1.80003 
            1.79994L1.80003 3.59997C1.80003 5.28272 2.45529 6.86479 3.6452 
            8.05483C4.83513 9.24467 6.41721 9.89996 8.09999 9.89996L14.9272 
            9.89996L11.0636 6.03641C10.7121 5.68495 10.7121 5.11509 11.0636 
            4.76362C11.4151 4.41213 11.9849 4.41213 12.3364 4.76362L17.7364 
            10.1636C17.737 10.1642 17.7377 10.1649 17.7384 10.1655C17.7386 
            10.1658 17.739 10.1661 17.7392 10.1664C17.7396 10.1667 17.7399 
            10.1671 17.7403 10.1675C17.7408 10.168 17.7412 10.1684 17.7417 
            10.1689C17.7419 10.1691 17.742 10.1692 17.7422 10.1694C17.7428 
            10.17 17.7434 10.1706 17.744 10.1712L17.744 10.1712C17.8249 
            10.2541 17.8867 10.3487 17.9292 10.4495C17.9293 10.4497 
            17.9294 10.4499 17.9295 10.4502C17.9297 10.4505 17.9298 10.451 17.93 10.4513C17.9751 
            10.5586 18 10.6764 18 10.8C18 10.9245 17.9747 11.043 17.9291 11.1508C17.8865 11.2515 17.8248 11.3459 17.744 
            11.4287L17.7439 11.4288C17.7433 11.4294 17.7428 11.4299 17.7422 11.4306C17.742 11.4307 17.7419 11.4309 17.7417 
            11.431C17.7412 11.4315 17.7408 11.432 17.7403 11.4325C17.74 11.4328 17.7397 11.4331 17.7394 11.4334C17.7391 11.4337 17.7387 
            11.4341 17.7384 11.4344C17.7377 11.4351 17.7371 11.4357 17.7364 11.4364L12.3364 16.8364C11.9849 17.1878 11.4151 17.1879 
            11.0636 16.8364C10.7121 16.4849 10.7121 15.915 11.0636 15.5636L14.9272 11.7L8.09999 11.7C7.03091 11.7 5.98801 11.4925
             5.00028 11.0834C4.01255 10.6742 3.12842 10.0835 2.37241 9.32761C1.61641 8.57154 1.02572 7.68741 0.61657 6.69968C0.207458 
             5.71195 2.99409e-06 4.66905 3.08756e-06 3.59997L3.24492e-06 1.79994C3.28837e-06 1.30291 0.40298 0.899929 0.900016 0.899929Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_7110_32270">
            <rect width={18} height={18} fill="white" transform="translate(18 18) rotate(-180)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ReplyIcon;
