const ClapReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.1423 13.5092C23.1423 13.5092 20.7394 11.5336 19.4579 9.6113C18.9774 8.91715 17.4289 9.02395 16.8949 10.3588L8.56515 
        13.5626L3.06536 10.6258C2.21103 10.0919 1.08971 10.4656 0.609147 11.4802C0.128583 12.4413 0.395563 13.6694 
        1.3033 14.2034L2.15763 14.6839C1.41009 14.5771 0.609147 14.9509 0.235375 15.6984C-0.245189 16.6596 0.021791 
        17.8877 0.929523 18.4216L2.85178 19.543C1.99744 19.009 0.876127 19.3828 0.395563 20.3973C-0.0850009 21.3584 
        0.181979 22.5865 1.08971 23.1205C1.08971 23.1205 10.7544 29.1008 14.0649 30.8629C16.6813 32.3046 21.8608 
        32.9988 25.1179 29.0474C26.1858 27.6592 23.1423 13.5092 23.1423 13.5092Z"
        fill="#D99B57"
      />
      <path
        d="M27.1468 13.5091C25.4381 12.3344 24.2634 10.7859 23.409 8.4365C23.0887 7.63556 21.9139 7.47538 21.2198 
        8.81028C20.2053 10.7325 21.1664 12.281 21.7004 12.9752L21.9673 13.2955C21.9673 13.2955 22.1275 13.8829 
        22.3945 14.577C20.9528 14.2567 19.3509 15.0576 18.2296 16.5527C17.0549 18.1012 14.2783 20.771 13.1036 
        22.3195C11.6619 24.1883 12.0357 26.8581 13.6376 28.0328C13.6376 28.0328 22.0207 34.1734 27.5739 26.6445C31.9524 
        20.771 28.8554 14.6838 27.1468 13.5091Z"
        fill="#FFDD67"
      />
      <path
        d="M24.317 15.7519C25.1713 16.3926 25.3849 17.6207 24.7441 18.5818C24.1568 19.543 22.9287 19.81 22.0743 
        19.1692L8.77873 9.61132C7.9244 8.97057 7.71081 7.74246 8.35157 6.78133C8.93892 5.8202 10.167 5.55323 11.0214
         6.19398L24.317 15.7519Z"
        fill="#FFDD67"
      />
      <path
        d="M17.4286 14.6839L8.93862 8.54334C8.45805 8.22296 8.13768 7.689 8.29787 6.88806C7.71051 7.95598 
        8.08428 9.0239 8.77843 9.55786L17.2684 15.6984C18.1227 16.3392 18.2829 15.3246 17.4286 14.6839Z"
        fill="#EBA352"
      />
      <path
        d="M19.9923 17.6207C20.8466 18.2614 21.0602 19.4895 20.4194 20.4507C19.8321 21.4118 18.604 21.6788 
        17.7496 21.038L4.45403 11.4268C3.59969 10.786 3.38611 9.55789 4.02686 8.59676C4.61421 7.63564 5.84232 
        7.36865 6.69666 8.00941L19.9923 17.6207Z"
        fill="#FFDD67"
      />
      <path
        d="M14.8656 17.8342L4.61355 10.4121C4.13299 10.0917 3.81261 9.45098 3.9728 8.75684C3.38544 9.82476 
        3.70582 10.9995 4.45336 11.5334L14.7588 18.8487C15.5597 19.4894 15.7199 18.4215 14.8656 17.8342Z"
        fill="#EBA352"
      />
      <path
        d="M19.1378 21.8924C19.9921 22.5332 20.2057 23.7613 19.5649 24.7224C18.9776 25.6835 17.7495 25.9505 16.8951 
        25.3098L3.59953 15.6985C2.7452 15.0577 2.53161 13.8296 3.17237 12.8685C3.75972 11.9074 4.98783 11.6404 5.84217
         12.2811L19.1378 21.8924Z"
        fill="#FFDD67"
      />
      <path
        d="M12.8366 21.2515L3.75928 14.7372C3.27872 14.4168 2.95834 13.6693 3.17193 12.9218C2.47778 13.9363 2.74476 
        15.2712 3.4923 15.8051L12.6764 22.2661C13.5308 22.9068 13.6909 21.8923 12.8366 21.2515Z"
        fill="#EBA352"
      />
      <path
        d="M18.7636 26.6979C19.6179 27.3387 13.5308 27.9794 12.6764 27.3387L3.27873 20.5574C2.4244 19.9166 2.21081
         18.6885 2.85157 17.7274C3.43892 16.7663 4.66703 16.4993 5.52137 17.14L18.7636 26.6979Z"
        fill="#FFDD67"
      />
      <path
        d="M29.7636 21.1449C29.7636 21.1449 28.8559 25.8437 25.1182 28.6737C21.8076 31.1833 16.6816 28.9941 14.2254 
        27.3922C11.6624 25.6301 10.3809 24.7758 4.18693 20.0769C3.01222 19.1692 2.69184 18.5819 2.85203 17.6207C1.9443 
        18.5819 2.42486 20.0769 3.17241 20.6109L3.81316 21.0381C3.81316 21.0381 11.2352 26.4845 13.7448 28.1931C16.0408 
        29.795 21.9678 32.3046 25.7055 29.3145C29.9238 26.0573 29.7636 21.1449 29.7636 21.1449ZM23.4095 15.0043C22.3416 
        13.883 22.2882 12.2811 22.2882 12.2811L22.0212 11.9608C21.594 11.4268 20.8999 10.3055 21.1669 8.97058C20.1523 
        10.8394 21.1669 12.3345 21.6474 12.9753L21.9144 13.2957C21.9144 13.2957 21.861 13.7762 22.128 14.15L23.4095 15.0043Z"
        fill="#EBA352"
      />
      <path
        d="M17.9627 0L19.5646 6.51431L22.715 0.480564L17.9627 0ZM25.8654 1.92226L23.4625 
        7.31525L29.0691 5.87356L25.8654 1.92226ZM13.9046 0.80094L15.3997 6.6745L10.4873 3.52414L13.9046 0.80094Z"
        fill="#42ADE2"
      />
    </svg>
  );
};

export default ClapReaction;
