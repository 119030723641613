import { DialogBody, DialogHeader, Spinner } from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import JobInformation from "components/contact/sidebar/common/JobInformation";
import PersonalInformation from "components/contact/sidebar/common/PersonalInfomation";
import ProfileInfo from "components/contact/sidebar/common/ProfileInfo";
import { CLoseIcon } from "components/icon";
import { QUERY_KEY } from "constants/pathApi";
import { getDetailUserInContact } from "http/contact.http";
import { t } from "i18next";
import { UserContact } from "types/user.type";
import { SuccessResponse } from "types/utils.type";
import CustomModal from "components/Modal/ModalCommon";
interface DialogProfileMemberContainerProp {
  openModal: boolean;
  closeModal: () => void;
  memberId?: number;
}
export default function DialogProfileMemberContainer({ openModal, closeModal, memberId }: DialogProfileMemberContainerProp) {
  const { data: userDetail, isLoading } = useQuery({
    queryKey: [QUERY_KEY.USER_DETAIL_CONTACT, memberId],
    queryFn: () => getDetailUserInContact<SuccessResponse<UserContact>>({ userId: memberId }),
    ...{
      refetchOnWindowFocus: false,
    },
    staleTime: 0,
    enabled: openModal,
  });
  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[512px] !w-full max-h-[95vh] overflow-hidden flex flex-col">
        <DialogHeader placeholder={t("dialog.placholder")} className="flex items-center justify-between">
          <p>{t("profile.title")}</p>
          <CLoseIcon onClick={closeModal} />
        </DialogHeader>
        <DialogBody
          placeholder={""}
          className="px-8 flex-1 overflow-hidden overflow-y-auto scroll-small text-neutral-110 min-h-[80vh] max-h-[90vh]"
        >
          {isLoading ? (
            <Spinner className="w-10 h-10 mx-auto" />
          ) : (
            <>
              <ProfileInfo isUploadAvatar={false} userDetailContact={userDetail?.data} />
              <div className="h-[7px] px-0 bg-primary-200"></div>
              <PersonalInformation userDetailContact={userDetail?.data} />
              <div className="h-[7px] px-0 bg-primary-200"></div>
              <JobInformation userDetailContact={userDetail?.data} />
            </>
          )}
        </DialogBody>
      </CustomModal>
    </>
  );
}
