import { User, UserContact } from "types/user.type";
import { t } from "i18next";
interface PersonalInfomationProp {
  userDetailContact: UserContact | undefined | User | null;
}
const PersonalInformation = ({ userDetailContact }: PersonalInfomationProp) => {
  return (
    <>
      <h3 className="mb-4">{t("contact.profile.personal_info")}</h3>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.language")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.language ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.first_name")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.first_name ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.last_name")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.last_name ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.sex")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.gender === 0 ? "Nam" : "Nữ"}</p>
      </div>
    </>
  );
};

export default PersonalInformation;
