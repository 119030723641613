import { MouseEvent } from "types/event.type";

interface PinIconProp {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  isShow?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const PinIcon = ({ onClick = () => {}, isShow = true }: PinIconProp) => {
  return (
    <div
      className={`cursor-pointer ${isShow ? "hover:text-[#87644e] text-[#5f4a3d]" : "hover:text-[#5f4a3d] text-[#87644e]"}`}
      onClick={(e) => onClick(e)}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 9L16 16H13V20L12 23L11 20V16H6V13L9 10V9ZM17 2V4L15 5V10L18 13V15.461L12.27 9.73L9 6.46V5L7 4V2H17Z"
          fill="currentColor"
        />
        <path
          d="M9.35355 10.3536L9.5 10.2071V10V5.5H14.5V10V10.2071L14.6464 10.3536L17.5 13.2071V15.5H6.5V13.2071L9.35355 10.3536Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </svg>
    </div>
  );
};

export default PinIcon;
