const BarIcon = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22217 7.77778C6.22217 6.79594 7.01811 6 7.99995 6C8.98179 6 9.77772 6.79594 9.77772 7.77778C9.77772 
        8.75962 8.98179 9.55556 7.99995 9.55556C7.01811 9.55556 6.22217 8.75962 6.22217 7.77778Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4445 7.77778C12.4445 6.79594 13.2404 6 14.2222 6C15.2041 6 16 6.79594 16 7.77778C16
         8.75962 15.2041 9.55556 14.2222 9.55556C13.2404 9.55556 12.4445 8.75962 12.4445 7.77778Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.77778C0 6.79594 0.795938 6 1.77778 6C2.75962 6 3.55556 6.79594 3.55556 7.77778C3.55556 
        8.75962 2.75962 9.55556 1.77778 9.55556C0.795938 9.55556 0 8.75962 0 7.77778Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BarIcon;
