import FilterUserAndDate from "components/filterDate/FilterUserAndDate";
import { CLoseIcon } from "components/icon";
import CaretDown from "components/icon/CaretDown";
import CaretUp from "components/icon/CaretUp";
import InputSearch from "components/input/InputSearch";
import { LIST_POPUP_SELECT, NOTIFICATION_TYPE, TYPE_SEARCH_MAIN } from "constants/constans";
import { t } from "i18next";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { SENDER } from "types/contact.type";
import { getCustomParam, getNextPageWithLastIdRecord, getUniqueId } from "utils/common";
import { setDataNotification } from "../../redux/slices/conversation";
import SearchResultAll from "./SearchResultAll";
import SearchResultContactModal from "./SearchResultContactModal";
import SearchResultMessage from "./SearchResultMessage";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { API_URL, QUERY_KEY } from "constants/pathApi";
import { ErrorResponse, ResponseTypeCommon } from "types/utils.type";
import { MESSAGE, TYPE_MESSAGE_FILE } from "types/message.type";
import { AxiosError } from "axios";
import { getListMessageFileSearch } from "http/message.http";
import { Spinner } from "@material-tailwind/react";
import { useInView } from "react-intersection-observer";
import { isNumber } from "lodash";
import SearchResultFile from "./SearchResultFile";
interface SearchModalContainerProps {
  closeModal: () => void;
  initSearch: string;
  searchInConversation?: boolean;
  setOpenModalSearch: Dispatch<SetStateAction<boolean>>;
  setContactId: Dispatch<SetStateAction<number | undefined>>;
}

const LIST_TAB_SEARCH = [
  {
    type: TYPE_SEARCH_MAIN.ALL,
    text: t("search_main.all"),
  },
  {
    type: TYPE_SEARCH_MAIN.CONTACT,
    text: t("search_main.contact"),
  },
  {
    type: TYPE_SEARCH_MAIN.MESSAGE,
    text: t("search_main.message"),
  },
  {
    type: TYPE_SEARCH_MAIN.FILE,
    text: t("search_main.file"),
  },
];

const SearchModalContainer = ({
  closeModal,
  initSearch,
  searchInConversation = false,
  setOpenModalSearch,
  setContactId,
}: SearchModalContainerProps) => {
  // state
  const [activeTab, setActiveTab] = useState(searchInConversation ? TYPE_SEARCH_MAIN.MESSAGE : TYPE_SEARCH_MAIN.ALL);
  const [keywordSearch, setKeywordSearch] = useState<string>(initSearch);
  const [isActiveSearch, setIsActiveSearch] = useState<string>("active");
  const [filterFrom, setFilterFrom] = useState<string | Date | null>(null);
  const [filterTo, setFilterTo] = useState<string | Date | null>(null);
  const [typeToFilter, setTypeToFilter] = useState<number | undefined>(undefined);
  const [filterByUser, setFilterByUser] = useState<SENDER>();
  const [isOpenOption, setIsOpenOption] = useState<boolean>(false);

  // custom hook
  const { ref, inView } = useInView();
  const { ref: refFile, inView: inViewFile } = useInView();

  // ref
  const listWrapRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();
  const dispatch = useCustomDispatch();
  const { conversationSelected } = useCustomSelector((state) => state.conversation);

  const {
    data: messages,
    fetchNextPage: fetchNextPageMessage,
    isFetching: isFetchingMessage,
    isFetchingNextPage: isFetchingNextPageMessage,
    isLoading: isLoadingMessage,
    refetch: refetchTabMessage,
  } = useInfiniteQuery<ResponseTypeCommon<MESSAGE>, AxiosError<ErrorResponse>>({
    queryKey: [QUERY_KEY.LIST_MESSAGE_SEARCH],
    queryFn: async ({ pageParam }: { pageParam: any }) => {
      const param = (activeTab === TYPE_SEARCH_MAIN.MESSAGE && isOpenOption) ? getCustomParam({
        from: filterFrom,
        keyword: keywordSearch,
        last_record_id: !isNumber(pageParam) ?  pageParam?.id : undefined,
        senders: filterByUser?.id,
        to: filterTo,
        typeToFilter: typeToFilter,
        ...(searchInConversation ? { conversation_id: conversationSelected?.conversation_id } : {}),
      }) : {
        keyword: keywordSearch,
        ...(!isNumber(pageParam) ? { last_record_id: pageParam?.id } : {}),
      };
      // get list message
      const response = await getListMessageFileSearch<ResponseTypeCommon<MESSAGE>>(param, API_URL.MESSAGE_SEARCH);
      return response;
    },
    initialPageParam: 1,
    ...{
      refetchOnWindowFocus: false,
    },
    getNextPageParam: getNextPageWithLastIdRecord<MESSAGE, ResponseTypeCommon<MESSAGE>>,
    enabled: false,
    gcTime: 0,
  });

  const {
    data: files,
    fetchNextPage: fetchNextPageFile,
    isFetching: isFetchingFile,
    isFetchingNextPage: isFetchingNextPageFile,
    isLoading: isLoadingFile,
    refetch: refetchTabFile,
  } = useInfiniteQuery<ResponseTypeCommon<TYPE_MESSAGE_FILE>, AxiosError<ErrorResponse>>({
    queryKey: [QUERY_KEY.LIST_FILE_SEARCH],
    queryFn: async ({ pageParam }: { pageParam: any }) => {
      const param =
        activeTab === TYPE_SEARCH_MAIN.FILE && isOpenOption ? getCustomParam({
          from: filterFrom,
          keyword: keywordSearch,
          last_record_id: !isNumber(pageParam) ? pageParam?.id : undefined,
          senders: filterByUser?.id,
          to: filterTo,
          typeToFilter: typeToFilter,
        })
        : {
          keyword: keywordSearch,
          ...(!isNumber(pageParam) ? { last_record_id: pageParam?.id } : {}),
        };
      // get list file
      const response = await getListMessageFileSearch<ResponseTypeCommon<TYPE_MESSAGE_FILE>>(param, API_URL.FILE_SEARCH);
      return response;
    },
    initialPageParam: 1,
    ...{
      refetchOnWindowFocus: false,
    },
    getNextPageParam: getNextPageWithLastIdRecord<TYPE_MESSAGE_FILE, ResponseTypeCommon<TYPE_MESSAGE_FILE>>,
    enabled: false,
    gcTime: 0,
  });

  // refetch data tab message or file or all(message, file)
  const handleDataTabMessage = (value: TYPE_SEARCH_MAIN) => {
    if ((value === TYPE_SEARCH_MAIN.FILE || value === TYPE_SEARCH_MAIN.MESSAGE || value === TYPE_SEARCH_MAIN.ALL) && keywordSearch) {
      queryClient.removeQueries({ queryKey: [QUERY_KEY.LIST_MESSAGE_SEARCH], exact: true });
      queryClient.removeQueries({ queryKey: [QUERY_KEY.LIST_FILE_SEARCH], exact: true });
      refetchTabMessage();
      refetchTabFile();
    }
  };

  // change tab modal search
  const handleSetActiveTab = (value: TYPE_SEARCH_MAIN) => {
    setActiveTab(value);
    // call api search message when change tab
    handleDataTabMessage(value);
  };

  // when call it => active search tab active
  const handleActiveSearch = () => {
    setIsActiveSearch(getUniqueId());
    handleDataTabMessage(activeTab);
  };

  // clear all filter date and user sender
  const handleResetFilter = () => {
    setFilterByUser(undefined);
    setFilterFrom(null);
    setFilterTo(null);
    setTypeToFilter(undefined);
  };

  // handle redirect to message
  const redirectToMessage = useCallback((conversationId: number | undefined, messageId: number, reply_id: number | string | null) => {
    closeModal();
    dispatch(
      setDataNotification({
        conversation_id: conversationId,
        id: messageId,
        notification_type: NOTIFICATION_TYPE.NOTIFICATION_TYPE_MESSAGE,
        reply_id: reply_id,
      }),
    );
  }, []);

  // handle when change tab
  useEffect(() => {
    if (listWrapRef.current) {
      listWrapRef.current.scrollTo(0, 0);
      setIsActiveSearch(getUniqueId());
    }
    handleResetFilter();
    setIsOpenOption(false);
  }, [activeTab]);

  useEffect(() => {
    // call api search message when open modal
    if (activeTab === TYPE_SEARCH_MAIN.ALL && keywordSearch) {
      refetchTabMessage();
      refetchTabFile();
    }
  }, []);

  // handle load more tab message or tab file
  useEffect(() => {
    if (inView && !isFetchingMessage && activeTab === TYPE_SEARCH_MAIN.MESSAGE) {
      fetchNextPageMessage();
    }
    if (inViewFile && !isFetchingFile && activeTab === TYPE_SEARCH_MAIN.FILE) {
      fetchNextPageFile();
    }
  }, [inView, inViewFile]);

  return (
    <div className="xl:w-[592px] w-[500px] py-6">
      <div className="px-8">
        <div className=" bg-white flex items-center justify-between w-full">
          <div className="text-neutral-110 text-[22px] font-bold flex items-center justify-between w-full">
            <p className="line-clamp-1">
              {t("search_main.keyword_search")}{searchInConversation ? `: ${conversationSelected?.conversation_name}` : ""}
            </p>
            <CLoseIcon onClick={closeModal} />
          </div>
        </div>
        <div className={`mt-3 flex items-center justify-between gap-x-3 ${searchInConversation ? " mb-3" : " mb-6"}`}>
          <InputSearch
            placeholder={t("search_main.type_to_search") as string}
            onChange={(e) => setKeywordSearch(e.target.value)}
            className="h-8 flex-1"
            color="#1F1B16"
            value={keywordSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter" && keywordSearch.trim()) {
                handleActiveSearch();
              }
            }}
          />
          <div
            className={`w-[72px] text-xs text-neutral-0 h-full ${
              keywordSearch && !isFetchingMessage && !isFetchingFile ? "cursor-pointer bg-primary-900" : "pointer-events-none bg-neutral-60"
            }
                            rounded-lg py-2 flex justify-center items-center cursor-pointer`}
            onClick={() => {
              if (keywordSearch && !isFetchingMessage && !isFetchingFile) {
                handleActiveSearch();
              }
            }}
          >
            {t("chat.search")}
          </div>
        </div>
        <div className={`${!searchInConversation ? "grid grid-cols-5" : "flex justify-end"} mb-3`}>
          {!searchInConversation &&
            LIST_TAB_SEARCH.map((tab) => (
              <p
                key={tab.type}
                onClick={() => handleSetActiveTab(tab.type)}
                className={`${
                  activeTab === tab.type ? "bg-neutral-20 border-primary-900 text-primary-900" : "text-neutral-110"
                } font-normal text-base border border-primary-200 h-8 flex justify-center items-center transition-all cursor-pointer`}
              >
                {tab.text}
              </p>
            ))}
          <div
            className={`${
              activeTab === TYPE_SEARCH_MAIN.FILE || activeTab === TYPE_SEARCH_MAIN.MESSAGE
                ? "text-primary-900"
                : "text-neutral-60 pointer-events-none"
            } gap-x-1 text-sm h-8 flex ${searchInConversation ? "justify-end" : "justify-center"}
            items-center transition-all cursor-pointer font-bold`}
            onClick={() => {
              if (activeTab === TYPE_SEARCH_MAIN.FILE || activeTab === TYPE_SEARCH_MAIN.MESSAGE) {
                if (isOpenOption) {
                  handleResetFilter();
                }
                setIsOpenOption((prev) => !prev);
              }
            }}
          >
            {t("search_main.option")}
            {isOpenOption ? <CaretUp /> : <CaretDown />}
          </div>
        </div>
      </div>
      {(activeTab === TYPE_SEARCH_MAIN.FILE || activeTab === TYPE_SEARCH_MAIN.MESSAGE) && isOpenOption && (
        <div className="px-8">
          <FilterUserAndDate
            filterByUser={filterByUser}
            filterFrom={filterFrom}
            typeToFilter={typeToFilter}
            filterTo={filterTo}
            typePopup={searchInConversation ? LIST_POPUP_SELECT.IN_CONVERSATION : LIST_POPUP_SELECT.IN_CONTACT}
            setFilterByUser={setFilterByUser}
            setFilterFrom={setFilterFrom}
            setFilterTo={setFilterTo}
            setTypeToFilter={setTypeToFilter}
          />
        </div>
      )}
      <div ref={listWrapRef} className="max-h-[60vh] overflow-y-auto sidebar-search-style min-h-[60vh]">
        {activeTab === TYPE_SEARCH_MAIN.ALL && (
          <SearchResultAll
            isActiveSearch={isActiveSearch}
            isLoadingFile={isLoadingFile}
            redirectToMessage={redirectToMessage}
            handleSetActiveTab={handleSetActiveTab}
            keywordSearch={keywordSearch}
            setOpenModalSearch={setOpenModalSearch}
            setContactId={setContactId}
            isLoadingMessage={isLoadingMessage}
            files={files}
            messages={messages}
          />
        )}
        {activeTab === TYPE_SEARCH_MAIN.CONTACT && (
          <SearchResultContactModal
            isActiveSearch={isActiveSearch}
            keywordSearch={keywordSearch}
            handleSetActiveTab={handleSetActiveTab}
            activeTab={activeTab}
            setOpenModalSearch={setOpenModalSearch}
            setContactId={setContactId}
          />
        )}
        {activeTab === TYPE_SEARCH_MAIN.MESSAGE &&
          (isLoadingMessage ? (
            <Spinner className="w-10 h-10 mx-auto" />
          ) : (
            <>
              <SearchResultMessage
                isActiveSearch={isActiveSearch}
                redirectToMessage={redirectToMessage}
                activeTab={activeTab}
                keywordSearch={keywordSearch}
                messages={messages}
                isFetchingNextPageMessage={isFetchingNextPageMessage}
              />
              {messages && <div className="p-1" ref={ref}></div>}
            </>
          ))}
        {activeTab === TYPE_SEARCH_MAIN.FILE &&
          (isLoadingFile ? (
            <Spinner className="w-10 h-10 mx-auto" />
          ) : (
            <>
              <SearchResultFile
                files={files}
                isActiveSearch={isActiveSearch}
                redirectToMessage={redirectToMessage}
                handleSetActiveTab={handleSetActiveTab}
                activeTab={activeTab}
                keywordSearch={keywordSearch}
                isFetchingNextPageFile={isFetchingNextPageFile}
              />
              {files && <div className="p-1" ref={refFile}></div>}
            </>
          ))}
      </div>
    </div>
  );
};

export default SearchModalContainer;
