import { t } from "i18next";
import { JSXElementConstructor, ReactElement, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  dateSelected?: Date | null | undefined;
  dateFormat?: string;
  showIcon?: boolean | undefined;
  className?: string;
  icon?: string | ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  onChange(date: Date | null): void;
  name?: string;
  placeholder: string;
  calendarClassName?: string;
  popperClassName?: string;
}
export default function DatePicker({
  dateSelected,
  showIcon,
  dateFormat,
  className,
  icon,
  maxDate,
  minDate,
  onChange,
  name,
  placeholder,
  calendarClassName,
  popperClassName,
}: DatePickerProps) {
  const calRef = useRef<ReactDatePicker>(null);
  return (
    <div>
      <ReactDatePicker
        calendarClassName={calendarClassName}
        popperClassName={popperClassName}
        className={className}
        ref={calRef}
        placeholderText={placeholder}
        selected={dateSelected}
        name={name}
        showIcon={showIcon}
        dateFormat={dateFormat}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        onChange={(value) => value && onChange(value)}
        icon={icon}
        maxDate={maxDate && maxDate}
        minDate={minDate && minDate}
        autoComplete="off"
      >
        <div className="w-full flex justify-end px-4">
          <button
            className="p-2 border-[1px] boder-neutral-70 rounded-lg w-20 hover:bg-neutral-70"
            onClick={() => {
              calRef.current && calRef.current.setOpen(false);
            }}
          >
            {t("action.cancel")}
          </button>
        </div>
      </ReactDatePicker>
    </div>
  );
}
