const PauseIcon = () => {
  return (
    <div>
      <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.53476 10.3286C4.53476 11.2516 3.78639 12 2.86333 12C1.94027 12 1.19189 11.2516 
          1.19189 10.3286V1.67143C1.19189 0.748377 1.94027 0 2.86333 
          0C3.78639 0 4.53476 0.748377 4.53476 1.67143V10.3286Z"
          fill="white"
        />
        <path
          d="M10.8077 10.3286C10.8077 11.2516 10.0593 12 9.13628 12C8.21322 12 7.46484 11.2516 
          7.46484 10.3286V1.67143C7.4651 0.748377 8.21347 0 9.13628 
          0C10.0593 0 10.8077 0.748377 10.8077 1.67143V10.3286Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default PauseIcon;
