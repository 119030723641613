const ResetIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7533 7.53455C14.733 6.71822 13.4943 6.07143 12 6.07143C8.4496 6.07143 5.57143 8.94959 5.57143 12.5C5.57143 
        16.0504 8.4496 18.9286 12 18.9286C14.7899 18.9286 17.1669 17.1503 18.0554 14.6648C18.2147 14.219 18.6643 13.9201 
        19.1274 14.0183L19.9659 14.196C20.429 14.2942 20.7288 14.751 20.5868 15.2027C19.4389 18.8525 16.0299 21.5 12 
        21.5C7.02944 21.5 3 17.4706 3 12.5C3 7.52944 7.02944 3.5 12 3.5C14.3859 3.5 16.2442 4.58726 17.581 5.7068L19.1082 
        4.17962C19.3533 3.93448 19.722 3.86115 20.0423 3.99382C20.3626 4.12649 20.5714 4.43903 20.5714 4.78571V9.5C20.5714 
        9.97338 20.1877 10.3571 19.7143 10.3571H15C14.6534 10.3571 14.3408 10.1483 14.2081 9.82801C14.0755 9.50772 14.1488 
        9.13905 14.3939 8.89391L15.7533 7.53455Z"
        fill="#592C12"
      />
    </svg>
  );
};

export default ResetIcon;
