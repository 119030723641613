import DatePicker from "components/DatePicker/DatePicker";
import ButtonCommon from "components/button/ButtonCommon";
import CalenderIcon from "components/icon/file/CalenderIcon";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
interface CalenderProps {
  setTypeToFilter: Dispatch<SetStateAction<number | undefined>>;
  typeToFilter: number | undefined;
  filterFrom: any;
  typeToFilterArr: { name: string; value: number }[];
  setFilterFrom: Dispatch<SetStateAction<Date | string | null>>;
  filterTo: any;
  setFilterTo: Dispatch<SetStateAction<Date | string | null>>;
  setOpenCalender?: Dispatch<SetStateAction<boolean>>;
}

export default function Calender({
  filterTo,
  filterFrom,
  typeToFilter,
  typeToFilterArr,
  setFilterFrom,
  setFilterTo,
  setOpenCalender,
  setTypeToFilter,
}: CalenderProps) {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const handleSetTypeToFilter = (type: number | undefined) => {
    setOpenCalender && setOpenCalender((prev) => !prev);
    setFilterFrom("");
    setFilterTo("");
    return type === typeToFilter ? setTypeToFilter(undefined) : setTypeToFilter(type);
  };

  const setFilterByDate = () => {
    setFilterFrom(startDate ? startDate : filterFrom ? filterFrom : "");
    setFilterTo(endDate ? endDate : filterTo ? filterTo : "");
    if (startDate || endDate) {
      setTypeToFilter(undefined);
    }
    setOpenCalender && setOpenCalender(false);
  };

  return (
    <div className="absolute top-full lef-0 z-[999] bg-white rounded-b-xl">
      <div
        style={{
          boxShadow: "0px 4px 9px 0px rgba(0, 0, 0, 0.15)",
        }}
        className=" w-full px-4 py-2  rounded-xl  bg-white"
      >
        <div className="flex justify-between py-3 border-b border-[#E7E7E7]">
          {typeToFilterArr.map((type, index) => (
            <div
              onClick={() => handleSetTypeToFilter(type.value)}
              key={index}
              className={`${
                typeToFilter === type.value
                  ? "  border-primary-900 text-primary-900 bg-neutral-20"
                  : "border-[#e7e7e7] bg-[#e7e7e7] text-neutral-70"
              } 
            text-xs border-[1px] font-medium py-1 px-2 rounded-lg 
            w-[90px] h-6 text-center cursor-pointer items-center flex justify-center`}
            >
              {type.name}
            </div>
          ))}
        </div>
        <div className="flex flex-col mt-2">
          <span className="font-medium text-neutral-110 text-base">{t("date.custom")}</span>
          <div className="py-2 flex gap-4 relative">
            <DatePicker
              placeholder={t("date.date_from")}
              dateSelected={startDate ? startDate : filterFrom ? filterFrom : ""}
              name="date-from"
              dateFormat={"dd/MM/yyyy"}
              className=" w-full  outline-none text-left
         text-primary-900 border-neutral-70 rounded-xl border-[1px] flex-1 font-medium text-sm !px-2 h-8"
              onChange={(value) => setStartDate(value)}
              icon={<CalenderIcon width="20" height="20" className="w-4 h-4 align-middle right-0" />}
              maxDate={endDate ? endDate : filterTo ? filterTo : ""}
              showIcon={true}
              popperClassName="!left-[-16px] !w-[320px] shadow-xl"
            />

            <DatePicker
              placeholder={t("date.date_to")}
              dateSelected={endDate ? endDate : filterTo ? filterTo : ""}
              name="date-to"
              dateFormat={"dd/MM/yyyy"}
              className=" w-full outline-none  text-left
           text-primary-900 border-neutral-70 rounded-xl border-[1px] flex-1 font-medium text-sm !px-2 h-8"
              onChange={(value) => setEndDate(value)}
              icon={<CalenderIcon width="20" height="20" className="w-4 h-4 align-middle right-0" />}
              minDate={startDate ? startDate : filterFrom ? filterFrom : ""}
              showIcon={true}
              popperClassName="!left-[-16px] !w-[320px] shadow-xl"
            />
          </div>
        </div>
        <div className="w-full flex justify-end ">
          <ButtonCommon
            onClick={setFilterByDate}
            className="text-white bg-primary-900 py-1 px-4 rounded text-lg w-20 h-8 leading-[100%]"
            title={t("action.ok")}
          />
        </div>
      </div>
    </div>
  );
}
