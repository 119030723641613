import { escapeRegExp } from "lodash";
import { ReactNode } from "react";
import reactStringReplace from "react-string-replace";

export const removeVietnameseAccent = (str: string) => {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const highlightTextSearch = (keyword: string, textSearch: string, relativeSearch = true): ReactNode => {
  if (!textSearch) return textSearch;
  // if relativeSearch = true => remove vietnamese accent
  const listKeyword = escapeRegExp(relativeSearch ? removeVietnameseAccent(keyword).trim() : keyword.trim()).split(" ");

  const filteredListKeyword = listKeyword.filter((kw) => !!kw.trim());
  const regexCheck = new RegExp(`(${filteredListKeyword.join("|")})`, "gi");
  const listKeywordDefault = textSearch;
  let replacedText: string | ReactNode[] = textSearch;
  const listReplace: string[] = [];

  // search relativeSearch
  if (relativeSearch) {
    const listKeywordRemoveVn: string | ReactNode[] = removeVietnameseAccent(textSearch);
    reactStringReplace(listKeywordRemoveVn, regexCheck, (match, _, offset) => {
      listReplace.push(escapeRegExp(listKeywordDefault).normalize("NFC").trim().substring(offset, offset + match.length));
      return <></>;
    });
  }

  if (replacedText.length > 0) {
    const checkAll = relativeSearch ? new RegExp(`(${listReplace.join("|")})`, "gi") : regexCheck;
    replacedText = reactStringReplace(textSearch.normalize("NFC"), checkAll, (match, i) => (
      <span key={i} className="text-white bg-primary-900">
        {match}
      </span>
    ));
  }

  return replacedText;
};

export const handleCompareString  = (keyword: string, stringNeedCheck:string) => {
  const nameWithoutAccents = removeVietnameseAccent(stringNeedCheck.toLowerCase());
  const searchWithoutAccents = removeVietnameseAccent(keyword.toLowerCase().trim()).split(/\s+/);
  const regexCheck = new RegExp(searchWithoutAccents.map((keyword) => `(?=.*${escapeRegExp(keyword)})`).join(""), "gi");
  return regexCheck.test(nameWithoutAccents);
};
