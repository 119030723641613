import { ReactNode } from "react";
import { ResponseTypeCommon } from "./utils.type";

export interface MESSAGE_REPLY {
  id: number;
  avatar: string;
  user_id: number;
  full_name: string;
}
export interface UploadFile {
  file_path: string;
  height: number;
  inputType: string;
  name: string;
  thumbnail: string;
  type: number;
  width: number;
}
export interface SendMessageType {
  content_text?: string | null;
  quote_text?: string | null;
  content_file?: string | null;
  reply_id?: number | string;
  is_sent_group?: IS_SEND_GROUP;
  messageUpdate?: MESSAGE;
}
export interface USER_REPLY {
  user_id: number;
  avatar: string;
  full_name: string;
  is_current_user: IS_CURRENT_USER;
}
export interface QuotedStartType{
  aid: number;
  time: string;
  msgId: string;
  rpId: string | null;
  user_name: string
  content?: string
}
export interface extractQuotedType {
    replyNode: ReactNode;
    fileNode: ReactNode;
}
export interface QuotedParseType {
  start: QuotedStartType;
  content: string;
  childrent: QuotedParseType | null;
}
export enum CONTENT_TYPE_FILE {
  IMG = 1,
  VIDEO,
  AUDIO,
  OTHER,
  LINKS,
}
export const SIZE_FILE_UPLOAD = {
  IMG_AUDIO: 10,
  VIDEO: 100,
  OTHER: 512,
  TEXT: 10000,
};
export interface ContentFileType {
  thumbnail: string;
  file_path: string;
  name: string;
  type: CONTENT_TYPE_FILE;
  width: number;
  height: number;
  size: string;
  is_delete?: boolean
  id?: number
}
export interface MESSAGE_REACTION {
  name: string;
  avatar: string;
  user_id: number;
  reaction: number | string;
  is_current_user: IS_CURRENT_USER;
  isTemp?: boolean;
}

export interface MESSAGE_REACTION_SOCKET extends MESSAGE_REACTION {
  reply_id: number | string | null;
  message_id?: number;
  conversation_id?: number
}
export interface MEMBER_IN_CONVERSATION_SOCKET {
  type: ACTION_USER_IN_CONVERSATION,
  conversation_id?: number,
  user: MEMBER[]
}
export interface MEMBER {
  id: number;
  admin: number;
  name: string;
  avatar: string;
  company_contract: string;
  conversation_id: number |undefined;
  contact_status?: null | number
}
export interface MEMBER_IN_ALL {
  id: number;
  full_name: string;
  avatar: string;
  email: string;
}
export enum REPLY_IS_READ {
  READ,
  NOT_READ
}
export interface MESSAGE {
  id: number | string;
  content_text: string | null;
  quote_text: string | null;
  content_file: string | null;
  reply_id: string | null;
  group_message: string;
  message_type_user: number;
  time_send_message: string;
  list_reaction: MESSAGE_REACTION[];
  list_user_replies: USER_REPLY[];
  user_id: number;
  name: string;
  avatar: string;
  isTo: number;
  message_count:number | null;
  status_pin: IS_MESSAGE_PIN;
  message_reply: MESSAGE_REPLY | null;
  reply_is_read: null | REPLY_IS_READ;
  needMergeMessage?: boolean;
  full_name?: string;
  time_pin_message?: string;
  messages_id?: number;
  event?: string;
  is_sent_group?: IS_SEND_GROUP
  conversation_id?: number
}
export interface DATA_MESSAGE_PREVIEW {
  dateSendMessage: string,
  messageId: string | number,
  messageReplyId: string | number | null,
  messageUserId: string | number
}
export interface TypeEmoji {
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
}
export enum IS_CURRENT_USER {
  NOT_CURRENT_USER,
  CURRENT_USER,
}
export interface ReactionNodeTotalType {
  reaction: number;
  reaction_total: number;
  icon: JSX.Element;
  is_current_user: IS_CURRENT_USER;
  user_reaction: MESSAGE_REACTION[];
}
export enum IS_MESSAGE_PIN {
  PIN,
  NOT_PIN,
}

export enum EVENT_MESSAGE_PIN {
  PIN = "pin",
  UNPIN = "unpin",
}

export enum IS_SEND_GROUP {
  NOT_SEND,
  SEND,
}
export enum REACTION_VALUE {
  ROGER = 1,
  THANKS,
  YAY,
  CONGRAT,
  GREAT,
  LIKE,
  HUNGGING,
}
export enum ACTION_UPDATE {
  EDIT,
  APPEND,
  DELETE 
}
export interface TYPE_MESSAGE_FILE {
  conversation_id: number;
  created_at: Date | string;
  department: string;
  id: number;
  message_id: number;
  name: string;
  path: string;
  size: string;
  thumbnail: string;
  type: number;
  user_avatar: string;
  user_id: number;
  user_name: string;
  reply_id: number | string;
}

export interface MESSAGE_FILE {
  content_file: TYPE_MESSAGE_FILE[];
}

export interface ResponseMessageReply extends ResponseTypeCommon<MESSAGE> {
  message_reply: MESSAGE;
}

export interface ResponseMessageReply extends ResponseTypeCommon<MESSAGE> {
  message_reply: MESSAGE;
}

export interface MEMBER_ADD {
  user_id: number;
  name: string;
  avatar: string;
  company_contract: string;
  conversation_id: number |undefined
}

export enum TYPE_FILE_FILTER {
  IMAGE = 1,
  VIDEO = 2,
  VOICE = 3,
  FILE = 4,
  LINKS = 5,
}

export interface LIST_FILE {
  date: string
  content_file: TYPE_MESSAGE_FILE[];
}

export enum TYPE_DATE_FILTER{
  YESTERDAY = 1,
  LAST_WEEK = 2, 
  LAST_MONTH = 3
}

export enum ACTION_USER_IN_CONVERSATION {
  ADD = 1,
  UPDATE,
  DELETE,
}

export enum TYPE_DATE_FILTER_DAY{
  CUSTOM= 0,
  DAY_7 = 4,
  DAY_30, 
  DAY_90
}
