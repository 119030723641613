import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { ALL_CONTACT, CALLBACK_LOGIN, CHAT, CONTACT, RECEIVED, SENT } from "../constants/pathUrl";
import { ProtectedRoute } from "./routerProvider";
import DefaultLayoutContainer from "layouts/DefaultLayout/DefaultLayoutContainer";
import NotFound from "pages/common/NotFound";
import AllContactContainer from "components/contact/allContact/AllContactContainer";
import ReceivedContainer from "components/contact/received/ReceivedContainer";
import SentContainer from "components/contact/sent/SentContainer";
import CallBackLogin from "pages/login/CallBackLogin";

const ChatContainer = lazy(() => import("../pages/chat/ChatPageContainer"));
const ContactContainer = lazy(() => import("../pages/contact/ContactPageContainer"));

export default createBrowserRouter([
  {
    element: <ProtectedRoute />,
    path:"/",
    children: [
      {
        element: <DefaultLayoutContainer />,
        children: [
          {
            element: <ChatContainer />,
            path: CHAT,
          },
          {
            element: <ContactContainer />,
            path: CONTACT,
            children: [
              {
                element: <AllContactContainer />,
                path: ALL_CONTACT,
              },
              {
                element: <ReceivedContainer />,
                path: RECEIVED,
              },
              {
                element: <SentContainer />,
                path: SENT,
              },
            ],
          },
        ],
        errorElement: <NotFound />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: CALLBACK_LOGIN,
    element: <CallBackLogin />,
  },
]);
