import { TYPE_SEARCH_MAIN } from "constants/constans";
import { Dispatch, SetStateAction } from "react";
import ListConversation from "./ListConversation";

interface SearchResultContactModalProps {
  activeTab: TYPE_SEARCH_MAIN;
  keywordSearch: string;
  isActiveSearch: string;
  setOpenModalSearch: Dispatch<SetStateAction<boolean>>;
  setContactId: Dispatch<SetStateAction<number | undefined>>;
  setDataRecord?: (data: number) => void
  handleSetActiveTab: (value: number) => void;
}
const SearchResultContactModal = ({
  keywordSearch,
  isActiveSearch,
  activeTab,
  setDataRecord,
  setContactId,
  setOpenModalSearch,
  handleSetActiveTab,
}: SearchResultContactModalProps) => {
  return (
    <div className="border-t border-primary-200 h-full px-2">
      <ListConversation
        classNameAvatar="h-12 w-12"
        classNameChildren="text-base gap-2"
        classNameParent="h-full max-h-[500px] w-full"
        activeTab={activeTab}
        keyword={keywordSearch}
        handleSetActiveTab={handleSetActiveTab}
        setContactId={setContactId}
        setIsFocus={setOpenModalSearch}
        setDataRecord={setDataRecord}
        isActiveSearch={isActiveSearch}
      />
    </div>
  );
};

export default SearchResultContactModal;
