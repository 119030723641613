/* eslint-disable max-len */
const ChatSvg = () => {
  return (
    <div>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.53348 4.00001L6.53571 4L21.4643 4V4.78572L21.4665 4.00001C22.4028 4.00267 23.3 4.3758 23.9621 5.03788C24.6242 5.69996 24.9973 6.59716 25 7.53348L25 7.53572L25 16.9643L25 16.9665C24.9973 17.9028 24.6242 18.8 23.9621 19.4621C23.3 20.1242 22.4028 20.4973 21.4665 20.5L21.4643 20.5H13.4964L9.00315 24.2463C8.76897 24.4416 8.44296 24.4837 8.16682 24.3544C7.89069 24.2251 7.71428 23.9478 7.71428 23.6429V20.5H6.53571L6.53348 20.5C5.59716 20.4973 4.69995 20.1242 4.03788 19.4621C3.3758 18.8 3.00267 17.9028 3 16.9665L3 16.9643V7.53348C3.00266 6.59717 3.3758 5.69996 4.03788 5.03788C4.69996 4.3758 5.59716 4.00267 6.53348 4.00001ZM13.6055 20.4091C13.6054 20.4091 13.6055 20.4091 13.6055 20.4091V20.4091ZM21.4631 5.57143H6.53692C6.01619 5.57318 5.51728 5.78082 5.14904 6.14905C4.78081 6.51728 4.57318 7.0162 4.57143 7.53693V16.9631C4.57318 17.4838 4.78081 17.9827 5.14904 18.351C5.51729 18.7192 6.01623 18.9268 6.53698 18.9286H8.5C8.93394 18.9286 9.28571 19.2803 9.28571 19.7143V21.9648L12.5986 19.2026C12.8106 19.0255 13.0781 18.9286 13.3542 18.9286C13.3542 18.9286 13.3543 18.9286 13.3542 18.9286H21.463C21.9838 18.9268 22.4827 18.7192 22.851 18.351C23.2191 17.9828 23.4268 17.484 23.4286 16.9633V7.53669C23.4268 7.01605 23.2191 6.51722 22.851 6.14905C22.4827 5.78082 21.9838 5.57318 21.4631 5.57143Z"
          fill="#B2AAA2"
        />
        <path
          d="M9.28566 13.8216C10.1535 13.8216 10.8571 13.118 10.8571 12.2501C10.8571 11.3823 10.1535 10.6787 9.28566 10.6787C8.41779 10.6787 7.71423 11.3823 7.71423 12.2501C7.71423 13.118 8.41779 13.8216 9.28566 13.8216Z"
          fill="#B2AAA2"
        />
        <path
          d="M14 13.8216C14.8679 13.8216 15.5714 13.118 15.5714 12.2501C15.5714 11.3823 14.8679 10.6787 14 10.6787C13.1321 10.6787 12.4286 11.3823 12.4286 12.2501C12.4286 13.118 13.1321 13.8216 14 13.8216Z"
          fill="#B2AAA2"
        />
        <path
          d="M18.7143 13.8216C19.5821 13.8216 20.2857 13.118 20.2857 12.2501C20.2857 11.3823 19.5821 10.6787 18.7143 10.6787C17.8464 10.6787 17.1428 11.3823 17.1428 12.2501C17.1428 13.118 17.8464 13.8216 18.7143 13.8216Z"
          fill="#B2AAA2"
        />
      </svg>
    </div>
  );
};

export default ChatSvg;
