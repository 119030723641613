import { isString } from "lodash";
import { extractTextToMessage } from "logic/message.logic";
import { useMemo } from "react";
import { highlightTextSearch } from "utils/strUtils";

interface MessageTextSearchProps {
  content: string;
  keywordSearch?: string;
}
const MessageTextSearch = ({ content, keywordSearch = "" }: MessageTextSearchProps) => {
  const [, messageOutput] = useMemo(() => extractTextToMessage(content), [content]);  
  return (
    <div>
      <div
        className={`text-sm leading-5 overflow-wrap-anywhere
            text-neutral-110 font-normal`}
      >
        {messageOutput.map((message) => {
          if (isString(message)) {
            return highlightTextSearch(keywordSearch, message, false);
          } else {
            return message;
          }
        })}
      </div>
    </div>
  );
};

export default MessageTextSearch;
