const YayReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.8754 16.0098C32.8754 24.8577 25.7331 32 16.8852 32C8.03729 32 0.89502 24.8577 0.89502 16.0098C0.89502 
  7.16193 8.03729 0.0196533 16.8852 0.0196533C25.7331 0.0196533 32.8754 7.16193 32.8754 16.0098Z"
        fill="#FFDD67"
      />
      <path
        d="M32.7687 5.98933C32.5021 4.55021 31.7026 3.3776 30.3701 3.0045C28.931 2.63139 27.6518 3.1644 26.4259 
  4.44361C25.733 2.52479 24.667 1.08568 22.9613 0.339467C21.2557 -0.40674 19.5501 0.126265 18.4841 1.45878C17.3648
   2.84459 16.9384 5.02992 18.111 7.85485C19.2303 10.5199 24.1873 15.8499 24.3472 16.0098C24.5604 15.9032 30.1036 
   12.4387 31.4361 10.7331C32.7687 9.08076 33.0352 7.42844 32.7687 5.98933ZM15.2861 1.45878C14.2201 0.126265 12.5144 
   -0.40674 10.8088 0.339467C9.1032 1.08568 8.03719 2.52479 7.34429 4.44361C6.06507 3.2177 4.78586 2.63139 3.34674 
   3.0578C2.06753 3.4309 1.21472 4.60351 0.948218 6.04263C0.681715 7.42844 1.00152 9.13406 2.28073 10.7864C3.66655 
   12.4387 9.2098 15.9032 9.42301 16.0098C9.58291 15.8499 14.5399 10.5199 15.6592 7.85485C16.8318 5.02992 16.4054 
   2.89789 15.2861 1.45878Z"
        fill="#F46767"
      />
      <path
        d="M25.9464 19.2611C25.9464 18.8347 25.6799 18.3017 24.987 18.1418C23.1215 17.7687 20.4032 17.4489 16.8853 
  17.4489C13.3675 17.4489 10.6491 17.822 8.78363 18.1418C8.03742 18.3017 7.82422 18.8347 7.82422 19.2611C7.82422 
  23.152 10.8091 27.043 16.8853 27.043C22.9616 26.9897 25.9464 23.0987 25.9464 19.2611Z"
        fill="#664E27"
      />
      <path
        d="M23.6543 19.3678C22.4817 19.1546 20.0299 18.8348 16.8851 18.8348C13.7404 18.8348 11.2886 19.1546 10.116 
  19.3678C9.42305 19.4744 9.36975 19.7409 9.42305 20.1674C9.47635 20.3806 9.47635 20.7004 9.58295 21.0202C9.63625 
  21.34 9.74285 21.4999 10.2759 21.4466C11.2886 21.34 22.535 21.34 23.5477 21.4466C24.0807 21.4999 24.134 21.34 
  24.2406 21.0202C24.2939 20.7004 24.3472 20.4339 24.4005 20.1674C24.4005 19.7409 24.3472 19.4744 23.6543 19.3678Z"
        fill="white"
      />
    </svg>
  );
};

export default YayReaction;
