import { API_URL } from "constants/pathApi";
import * as axiosClient from "api/axiosClient";
import axios from "axios";
import { replaceDynamicApi } from "utils/common";

export interface UpdateProfile {
  avatar?: File | undefined;
  language?: string;
}
export interface DataAuthorizationResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}
export interface ChangePassword {
  old_password: string;
  new_password: string;
}
export const updateProfile = async <T>(value: UpdateProfile) => {
  try {
    const response = await axiosClient.postAxios<T>(
      API_URL.INFO,
      {
        ...value,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const sendRequestAuthorization = async (dataRequest: { code?: string; grant_type?: string; refresh_token?: string }) => {
  try {
    const data = {
      client_id: process.env.REACT_APP_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_CLIENT_SECRET || "",
      redirect_uri: process.env.REACT_APP_LOGIN_CALLBACK || "",
      scope: "",
      ...dataRequest,
    };
    const response = await axios.post<DataAuthorizationResponse>(process.env.REACT_APP_DOMAIN_BEEID + API_URL.LOGIN_TOKEN, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const changePassword = async<T> (value: ChangePassword) => {
  const response = await axiosClient.putAxios<T>(API_URL.CHANGE_PASSWORD, {
    ...value,
  });
  return response;
};

export const deleteDeviceToken = async<T> (deviceToken:string) => {
  try {
    const response = await axiosClient.deleteAxios<T>(replaceDynamicApi(API_URL.DELETE_TOKEN, deviceToken));
    return response;
  } catch (error) {
    return false;
  }
};
