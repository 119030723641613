import ButtonByTime from "components/button/ButtonByTime";
import ButtonFilterByUser from "components/button/ButtonFilterByUser";
import Calender from "components/files/Calender";
import PopupListMember from "components/files/PopupListMember";
import { LIST_POPUP_SELECT } from "constants/constans";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { SENDER } from "types/contact.type";
import { TYPE_DATE_FILTER_DAY } from "types/message.type";
import PreviewSelectDate from "./PreviewSelectDate";
import PreviewSelectUser from "./PreviewSelectUser";
const typeToFilterArr = [
  {
    name: t("search_main.last_7_days"),
    value: TYPE_DATE_FILTER_DAY.DAY_7,
  },
  {
    name: t("search_main.last_30_days"),
    value: TYPE_DATE_FILTER_DAY.DAY_30,
  },
  {
    name: t("search_main.last_90_days"),
    value: TYPE_DATE_FILTER_DAY.DAY_90,
  },
];

interface FilterUserAndDateProps {
  filterFrom: string | Date | null;
  filterTo: string | Date | null;
  filterByUser: SENDER | undefined;
  typeToFilter: number | undefined;
  typePopup: LIST_POPUP_SELECT;
  setFilterFrom: Dispatch<SetStateAction<string | Date | null>>;
  setFilterTo: Dispatch<SetStateAction<string | Date | null>>;
  setFilterByUser: Dispatch<SetStateAction<SENDER | undefined>>;
  setTypeToFilter: Dispatch<SetStateAction<number | undefined>>;
}
const FilterUserAndDate = ({
  filterFrom,
  filterTo,
  filterByUser,
  typeToFilter,
  setFilterFrom,
  typePopup,
  setFilterTo,
  setTypeToFilter,
  setFilterByUser,
}: FilterUserAndDateProps) => {
  const [openListMember, setOpenListMember] = useState<boolean>(false);
  const [openCalender, setOpenCalender] = useState<boolean>(false);
  const handleOpenListMember = () => {
    setOpenListMember((prev) => !prev);
    setOpenCalender(false);
  };
  const handleOpenCalender = () => {
    setOpenCalender((prev) => !prev);
    setOpenListMember(false);
  };
  const handleResetSearchByDate = () => {
    setFilterFrom("");
    setFilterTo("");
    setTypeToFilter(undefined);
  };
  return (
    <div className="flex flex-col">
      <div className="flex pb-2 gap-4 relative">
        {/* select */}
        <div className="relative flex-1">
          <ButtonByTime
            filterFrom={filterFrom}
            filterTo={filterTo}
            typeToFilter={typeToFilter}
            openCalender={openCalender}
            onclick={handleOpenCalender}
          />
          {(typeToFilter || filterTo || filterFrom) && (
            <PreviewSelectDate
              typeToFilterArr={typeToFilterArr}
              filterFrom={filterFrom}
              filterTo={filterTo}
              handleResetSearchByDate={handleResetSearchByDate}
              typeToFilter={typeToFilter}
              handleOpenCalender={handleOpenCalender}
            />
          )}
        </div>
        <div className="relative flex-1">
          <ButtonFilterByUser filterByUser={filterByUser} openListMember={openListMember} onclick={handleOpenListMember} />
          {filterByUser && (
            <div
              className="absolute h-full w-full top-0 px-3 bg-neutral-20 flex items-center border justify-between cursor-pointer
                          rounded-full border-primary-900"
              onClick={handleOpenListMember}
            >
              <PreviewSelectUser member={filterByUser} onClick={() => setFilterByUser(undefined)} />
            </div>
          )}
        </div>

        {/* list contact and date */}
        {openListMember && (
          <PopupListMember
            typePopup={typePopup}
            setMemberSelected={setFilterByUser}
            setOpenListMember={handleOpenListMember}
            className="max-w-80 right-0"
          />
        )}
        {openCalender && (
          <Calender
            setFilterFrom={setFilterFrom}
            filterFrom={filterFrom}
            setFilterTo={setFilterTo}
            filterTo={filterTo}
            typeToFilter={typeToFilter}
            setOpenCalender={setOpenCalender}
            setTypeToFilter={setTypeToFilter}
            typeToFilterArr={typeToFilterArr}
          />
        )}
      </div>
    </div>
  );
};

export default FilterUserAndDate;
