import { CLoseIcon } from "components/icon";
import CalenderIcon from "components/icon/file/CalenderIcon";
import moment from "moment";
interface PreviewSelectDateProps {
  typeToFilterArr: { name: string; value: number }[];
  typeToFilter: number | undefined;
  filterTo: Date | string | null;
  filterFrom: Date | string | null;
  handleResetSearchByDate: () => void;
  handleOpenCalender: () => void;
}
const PreviewSelectDate = ({
  typeToFilterArr,
  filterFrom,
  filterTo,
  typeToFilter,
  handleResetSearchByDate,
  handleOpenCalender,
}: PreviewSelectDateProps) => {
  const getLabelDateFilter = (value: number) => {
    const type = typeToFilterArr.find((item) => item.value === value);
    return type ? type.name : "";
  };
  return (
    <>
      <div
        onClick={handleOpenCalender}
        className="absolute h-full w-full top-0 px-3 bg-neutral-20 flex items-center border justify-between cursor-pointer
                            rounded-full border-primary-900"
      >
        {(typeToFilter || filterTo || filterFrom) && (
          <div
            className="border-primary-900 text-primary-900 
                              bg-neutral-20 text-sm font-normal
                                w-full h-full text-center cursor-pointer items-center flex justify-between "
          >
            <div className="flex items-center flex-1 gap-2">
              <CalenderIcon color="#592C12" width="12" height="12" />
              {typeToFilter && <span>{getLabelDateFilter(typeToFilter)}</span>}

              {(filterTo || filterFrom) && (
                <span className="text-sm">
                  {filterFrom ? moment(filterFrom).format("DD/MM/YYYY") : ""} - {filterTo ? moment(filterTo).format("DD/MM/YYYY") : ""}
                </span>
              )}
            </div>
            <CLoseIcon className="h-full flex items-center" width={8} height={8} onClick={handleResetSearchByDate} />
          </div>
        )}
      </div>
    </>
  );
};

export default PreviewSelectDate;
