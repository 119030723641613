const RecordIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2055 1.25H9.79297C8.06708 1.25 6.66797 2.64911 6.66797 4.375V9.9375C6.66797 11.6634 8.06708 
        13.0625 9.79297 13.0625H10.2055C11.9314 13.0625 13.3305 11.6634 13.3305 9.9375V4.375C13.3305 2.64911 11.9314 1.25 10.2055 1.25Z"
        fill="#B2AAA2"
      />
      <path
        d="M15.8318 8.62061C15.7497 8.62056 15.6684 8.63669 15.5925 8.66808C15.5167 8.69948 15.4478 8.74551 
        15.3897 8.80356C15.3317 8.8616 15.2857 8.93052 15.2543 9.00637C15.2229 9.08222 15.2067 9.16352 
        15.2068 9.24561C15.2068 10.6266 14.6582 11.951 13.6817 12.9274C12.7052 13.9039 11.3808 14.4525 
        9.99988 14.4525C8.61892 14.4525 7.29452 13.9039 6.31804 12.9274C5.34155 11.951 4.79297 10.6266 
        4.79297 9.24561C4.79297 9.07985 4.72712 8.92087 4.60991 8.80366C4.4927 8.68645 4.33373 8.62061 
        4.16797 8.62061C4.00221 8.62061 3.84324 8.68645 3.72603 8.80366C3.60882 8.92087 3.54297 9.07985 
        3.54297 9.24561C3.54514 10.8491 4.14314 12.3946 5.22085 13.582C6.29856 14.7693 7.77907 15.5138 
        9.37488 15.6708V17.5H7.29175C7.12599 17.5 6.96702 17.5658 6.84981 17.683C6.7326 17.8002 6.66675 
        17.9592 6.66675 18.125C6.66675 18.2907 6.7326 18.4497 6.84981 18.5669C6.96702 18.6841 7.12599 
        18.75 7.29175 18.75H12.708C12.8738 18.75 13.0327 18.6841 13.1499 18.5669C13.2672 18.4497 13.333 
        18.2907 13.333 18.125C13.333 17.9592 13.2672 17.8002 13.1499 17.683C13.0327 17.5658 12.8738 17.5 
        12.708 17.5H10.6249V15.6708C12.2207 15.5138 13.7012 14.7693 14.7789 13.582C15.8566 12.3946 16.4546 
        10.8491 16.4568 9.24561C16.4568 9.16352 16.4407 9.08222 16.4093 9.00637C16.3779 8.93052 16.3319 
        8.8616 16.2738 8.80356C16.2158 8.74551 16.1469 8.69948 16.071 8.66808C15.9952 8.63669 15.9139 8.62056 15.8318 8.62061Z"
        fill="#B2AAA2"
      />
    </svg>
  );
};

export default RecordIcon;
