import ReplyIcon from "components/icon/chat/ReplyIcon";
import Member from "components/user/Member";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { nextSideBar } from "../../../redux/slices/sidebar";
import { ACTION_OPEN_SIDEBAR } from "types/sidebar.type";

interface MessageReplyPropType {
  replyUserId: number;
  replyUserName: string;
  onClick: () => void;
  quoteDataReply?: number | string | null;
}
const MessageReply = ({ replyUserId, onClick, quoteDataReply = "", replyUserName }: MessageReplyPropType) => {
  const { userInfo } = useCustomSelector((store) => store.auth);
  const { conversationSelected } = useCustomSelector((state) => state.conversation);
  const isReplyCurrentUser = userInfo?.id === replyUserId;
  const dispatch = useCustomDispatch();
  return (
    <div className="w-full">
      <div
        className={`inline-flex  my-[2px] w-auto ${
          isReplyCurrentUser ? "bg-primary-900" : "bg-neutral-30"
        } px-1 py-[1px] rounded-md items-center gap-1 cursor-pointer`}
        onClick={() => {
          if (quoteDataReply) {
            dispatch(
              nextSideBar({
                type: ACTION_OPEN_SIDEBAR.SIDEBAR_REPLY,
                data: {
                  messageId: quoteDataReply,
                  conversationId: conversationSelected?.conversation_id,
                },
              }),
            );
          } else {
            onClick();
          }
        }}
      >
        <ReplyIcon className={isReplyCurrentUser ? "text-neutral-10" : "text-primary-900"} />
        <Member
          member_name={replyUserName}
          member_id={replyUserId}
          className={isReplyCurrentUser ? "text-neutral-10 ml-[2px]" : "text-neutral-110 ml-[2px]"}
        />
      </div>
    </div>
  );
};

export default MessageReply;
