/* eslint-disable max-len */
const SettingSvg = () => {
  return (
    <div>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.19104 6.08285L8.37057 5.89351C7.67674 5.7334 6.94937 5.94201 6.44587 6.44551C5.94238 6.94901 5.73376 7.67638 5.89388 8.3702L6.08322 9.19067C6.30146 10.1364 5.91149 11.1184 5.10394 11.6568L4.1063 12.3219C3.54532 12.6959 3.20837 13.3255 3.20837 13.9997C3.20837 14.6739 3.54532 15.3034 4.1063 15.6775L5.10394 16.3426C5.91149 16.8809 6.30146 17.863 6.08322 18.8087L5.89388 19.6292C5.73376 20.323 5.94238 21.0503 6.44587 21.5538C6.94937 22.0574 7.67674 22.266 8.37057 22.1058L9.19104 21.9164C10.1367 21.6983 11.1188 22.0882 11.6572 22.8957L12.3223 23.8935C12.6963 24.4544 13.3258 24.7913 14 24.7913C14.6743 24.7913 15.3038 24.4544 15.6778 23.8935L16.3429 22.8957C16.8812 22.0882 17.8633 21.6983 18.809 21.9164L19.6296 22.1058C20.3234 22.266 21.0507 22.0574 21.5542 21.5538C22.0577 21.0503 22.2663 20.323 22.1062 19.6292L21.9168 18.8087C21.6986 17.863 22.0885 16.8809 22.8961 16.3426L23.8938 15.6775C24.4548 15.3034 24.7917 14.6739 24.7917 13.9997C24.7917 13.3255 24.4548 12.6959 23.8938 12.3219L22.8961 11.6568C22.0885 11.1184 21.6986 10.1364 21.9168 9.19067L22.1062 8.37019C22.2663 7.67638 22.0577 6.94901 21.5542 6.44551C21.0507 5.94201 20.3234 5.7334 19.6296 5.89351L18.809 6.08285C17.8633 6.3011 16.8812 5.91113 16.3429 5.10357L15.6778 4.10593C15.3038 3.54495 14.6743 3.20801 14 3.20801C13.3258 3.20801 12.6963 3.54495 12.3223 4.10593L11.6572 5.10357C11.1188 5.91113 10.1367 6.3011 9.19104 6.08285Z"
          stroke="#B2AAA2"
          strokeWidth="1.7"
          strokeLinejoin="round"
        />
        <path
          d="M17.2083 14.0003C17.2083 15.7723 15.7719 17.2087 14 17.2087C12.228 17.2087 10.7916 15.7723 10.7916 14.0003C10.7916 12.2284 12.228 10.792 14 10.792C15.7719 10.792 17.2083 12.2284 17.2083 14.0003Z"
          stroke="#B2AAA2"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SettingSvg;
