import ImageWithLoader from "components/image/ImageWithLoader";
import { TYPE_SCREEN_CHAT } from "constants/constans";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CONTENT_TYPE_FILE, ContentFileType } from "types/message.type";
import { getFullPathFile, isGIF } from "utils/common";

interface MessageFileImgAndVideoPropType {
  listFile: ContentFileType[];
  typeScreen: TYPE_SCREEN_CHAT;
  type: CONTENT_TYPE_FILE.IMG | CONTENT_TYPE_FILE.VIDEO;
  isPinMessage?: boolean;
  className?: string;
  sidebarPinMessage?: boolean;
  handleOpenPreview: (file: ContentFileType) => void;
}
const MessageFileImgAndVideo = ({
  listFile,
  typeScreen,
  type,
  isPinMessage,
  className,
  sidebarPinMessage,
  handleOpenPreview,
}: MessageFileImgAndVideoPropType) => {
  const [classImg, setClassImg] = useState({
    containerClass: "grid-cols-1",
    imgClass: "h-[105px]",
  });

  useEffect(() => {
    let containerClass = "grid-cols-2";
    let imgClass = "h-[105px]";

    switch (listFile.length) {
      case 1:
        containerClass = "grid-cols-1";
        imgClass = "";
        break;
      case 2:
        containerClass = "grid-cols-2";
        imgClass = "h-[159px]";
        break;
      default:
        containerClass = "grid-cols-3";
        imgClass = "h-[105px]";
        break;
    }

    setClassImg({
      containerClass,
      imgClass,
    });
  }, [listFile]);

  if (isPinMessage) {
    return (
      <div className="flex items-center gap-x-2">
        {type === CONTENT_TYPE_FILE.IMG &&
          listFile.map((file, index: number) => (
            <ImageWithLoader
              isPinMessage={isPinMessage}
              key={index}
              src={getFullPathFile(file.thumbnail)}
              className={"w-6 h-6 object-cover"}
            />
          ))}
        {type === CONTENT_TYPE_FILE.VIDEO &&
          listFile.map((file, index: number) => (
            <ImageWithLoader
              isPinMessage={isPinMessage}
              isVideo={true}
              key={index}
              src={getFullPathFile(file.thumbnail)}
              className={"w-6 h-6 object-cover"}
            />
          ))}
        <span>[{type === CONTENT_TYPE_FILE.IMG ? t("chat.picture") : t("chat.video")}]</span>
      </div>
    );
  }

  return (
    <div
      className={`grid mt-[2px] cursor-pointer ${
        typeScreen === TYPE_SCREEN_CHAT.REPLY ? "max-w-64 w-64" : !sidebarPinMessage ? "max-w-80 w-80" : ""
      } gap-[2px] ${!sidebarPinMessage ? classImg.containerClass : listFile.length === 1 ? "w-full" : "grid-cols-2"}`}
    >
      {type === CONTENT_TYPE_FILE.IMG &&
        listFile.map((file, index: number) => (
          <ImageWithLoader
            originalHeight={listFile.length === 1 ? file.height : 0}
            isDelete={file?.is_delete}
            key={index}
            src={getFullPathFile(isGIF(file.file_path) ? file.file_path : file.thumbnail)}
            className={`${className} w-full ${classImg.imgClass} object-cover ${sidebarPinMessage && listFile.length && "!w-full"}`}
            handleOpenPreview={() => handleOpenPreview(file)}
          />
        ))}
      {type === CONTENT_TYPE_FILE.VIDEO &&
        listFile.map((file, index: number) => (
          <ImageWithLoader
            originalHeight={listFile.length === 1 ? file.height : 0}
            isVideo={true}
            isDelete={file?.is_delete}
            key={index}
            src={getFullPathFile(file.thumbnail)}
            handleOpenPreview={() => handleOpenPreview(file)}
            className={`${className} w-full ${classImg.imgClass} object-cover ${sidebarPinMessage && listFile.length && "!w-full"}`}
          />
        ))}
    </div>
  );
};

export default MessageFileImgAndVideo;
