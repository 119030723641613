import QuoteIcon from "components/icon/chat/QuoteIcon";
import Member from "components/user/Member";
import { TYPE_SCREEN_CHAT } from "constants/constans";
import { isEmpty } from "lodash";
import { extractTextToMessage } from "logic/message.logic";
import { useMemo } from "react";
import { QuotedParseType } from "types/message.type";

interface MessageQuoteType {
  quote: QuotedParseType | null;
  typeScreen: TYPE_SCREEN_CHAT;
}
const MessageQuote = ({ quote, typeScreen }: MessageQuoteType) => {
  const [{ fileNode, replyNode }, messageOutput] = useMemo(
    () =>
      extractTextToMessage(quote?.content ?? "", typeScreen, {
        msgId: quote?.start.msgId || "",
        rpId: quote?.start.rpId || "",
        aid: quote?.start.aid || 0,
        time: quote?.start.time || "",
        user_name: quote?.start.user_name || "",
      }),
    [quote?.content],
  );
  return (
    <>
      {quote && (
        <div className="flex flex-col items-start w-full max-w-full">
          <div className="p-1 bg-[#F9F0E7] inline-flex items-center gap-2 rounded-md mt-1 mb-[2px]">
            <QuoteIcon />
            <Member member_id={quote?.start.aid} className="text-neutral-110" member_name={quote?.start.user_name} />
          </div>
          <div className="pl-3 text-sm border-l-2 border-primary-900">
            {replyNode}
            {!isEmpty(quote?.childrent) && <MessageQuote quote={quote?.childrent} typeScreen={typeScreen} />}
            <div className="overflow-wrap-anywhere">{quote?.content && messageOutput}</div>
            {fileNode}
          </div>
        </div>
      )}
    </>
  );
};

export default MessageQuote;
