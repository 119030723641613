/* eslint-disable max-len */
interface EyeIconProp {
  open: boolean;
  onClick?: () => void;
  className?: string;
}

const EyeIcon = (props: EyeIconProp) => {
  const { open = false, onClick, className } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      {open ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.75 3.125L16.25 16.875M12.1021 12.3123C11.4888 12.8698 10.6792 13.1607 9.85143 13.1213C9.02361 13.0818 8.24536 12.7152 7.68788 12.1019C7.13039 11.4887 6.83933 10.6791 6.87872 9.85131C6.9181 9.02348 7.2847 8.24521 7.89788 7.68767M5.78082 5.35887C2.59572 6.97181 1.25 10 1.25 10C1.25 10 3.75 15.6244 10 15.6244C11.4644 15.6361 12.9105 15.2988 14.2186 14.6404M16.2976 13.2109C18.0009 11.6853 18.75 10 18.75 10C18.75 10 16.25 4.37439 10 4.37439C9.45869 4.3735 8.91824 4.41751 8.3842 4.50596M10.5881 6.9303C11.2523 7.05784 11.8572 7.39729 12.3122 7.89774C12.7671 8.3982 13.0475 9.03266 13.1113 9.70598"
            stroke="#1F1B16"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 4.37451C3.75 4.37451 1.25 10.0001 1.25 10.0001C1.25 10.0001 3.75 15.6245 10 15.6245C16.25 15.6245
             18.75 10.0001 18.75 10.0001C18.75 10.0001 16.25 4.37451 10 4.37451Z"
            stroke="#1F1B16"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.1252C11.7259 13.1252 13.125 11.7261 13.125 10.0002C13.125 8.27428 11.7259 6.87517 10 6.87517C8.27411
             6.87517 6.875 8.27428 6.875 10.0002C6.875 11.7261 8.27411 13.1252 10 13.1252Z"
            stroke="#1F1B16"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

export default EyeIcon;
