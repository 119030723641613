/* eslint-disable max-len */
interface PolygonDownIconProps{
    color?: string
}
export default function PolygonDownIcon({ color }: PolygonDownIconProps) {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.81437 0.222628C9.69076 0.0741683 9.54423 0 9.37492 0H0.625047C0.455668 0 0.30924 0.0741683 0.185523 0.222628C0.0618069 0.371252 0 0.546966 0 0.750097C0 0.953188 0.0618069 1.1289 0.185523 1.2774L4.56048 6.52735C4.68433 6.67581 4.83076 6.75014 5 6.75014C5.16924 6.75014 5.31581 6.67581 5.43942 6.52735L9.81437 1.27736C9.93795 1.1289 10 0.953188 10 0.750056C10 0.546966 9.93795 0.371252 9.81437 0.222628Z"
        fill={`#1F1B16 ${color}`}
      />
    </svg>
  );
}
