const RogerReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.8613 16C32.8613 24.8533 25.7147 32 16.8613 32C8.008 32 0.861328 24.8533 0.861328 16C0.861328 
        7.14667 8.008 0 16.8613 0C25.7147 0 32.8613 7.14667 32.8613 16Z"
        fill="#FFDD67"
      />
      <path
        d="M25.9283 19.2001C25.9283 18.7734 25.6616 18.2401 24.9683 18.0801C23.1016 17.7067 20.3816 17.3867 16.8616 
        17.3867C13.3416 17.3867 10.6216 17.7601 8.75492 18.0801C8.06159 18.2401 7.79492 18.7734 7.79492 19.2001C7.79492 
        23.0934 10.7816 26.9867 16.8616 26.9867C22.9416 26.9867 25.9283 23.0934 25.9283 19.2001Z"
        fill="#664E27"
      />
      <path
        d="M14.9949 13.28C13.9815 10.56 12.4882 9.17334 10.9949 9.17334C9.50153 9.17334 8.0082 10.56 
        6.99487 13.28C6.8882 13.5467 7.42153 14.0267 7.6882 13.76C8.6482 12.7467 9.82153 12.32 10.9949 
        12.32C12.1682 12.32 13.3415 12.7467 14.3015 13.76C14.6215 14.0267 15.1015 13.5467 14.9949 13.28Z"
        fill="#664E27"
      />
      <path
        d="M26.6746 13.28C25.6612 10.56 24.1679 9.17334 22.6746 9.17334C21.1812 9.17334 19.6879 10.56 18.6746 
        13.28C18.5679 13.5467 19.1012 14.0267 19.3679 13.76C20.3279 12.7467 21.5012 12.32 22.6746 12.32C23.9012 
        12.32 25.0212 12.7467 25.9812 13.76C26.2479 14.0267 26.7812 13.5467 26.6746 13.28Z"
        fill="#664E27"
      />
      <path
        d="M23.6347 19.36C22.4614 19.1467 20.0081 18.8267 16.8614 18.8267C13.7147 18.8267 11.2614 19.1467 
        10.0881 19.36C9.39474 19.4667 9.3414 19.7333 9.39474 20.16C9.44807 20.3733 9.44807 20.6933 9.55474 
        21.0133C9.60807 21.3333 9.71474 21.4933 10.2481 21.44C11.2614 21.3333 22.5147 21.3333 23.5281 21.44C24.0614 
        21.4933 24.1147 21.3333 24.2214 21.0133C24.2747 20.6933 24.3281 20.4267 24.3814 20.16C24.3814 19.7333 
        24.3281 19.4667 23.6347 19.36Z"
        fill="white"
      />
      <path
        d="M32.8613 16C32.8613 24.8533 25.7147 32 16.8613 32C8.008 32 0.861328 24.8533 0.861328 16C0.861328 
        7.14667 8.008 0 16.8613 0C25.7147 0 32.8613 7.14667 32.8613 16Z"
        fill="#FFDD67"
      />
      <path
        d="M25.9283 19.2001C25.9283 18.7734 25.6616 18.2401 24.9683 18.0801C23.1016 17.7067 20.3816 17.3867 16.8616 
        17.3867C13.3416 17.3867 10.6216 17.7601 8.75492 18.0801C8.06159 18.2401 7.79492 18.7734 7.79492 19.2001C7.79492 
        23.0934 10.7816 26.9867 16.8616 26.9867C22.9416 26.9867 25.9283 23.0934 25.9283 19.2001Z"
        fill="#664E27"
      />
      <path
        d="M14.9949 13.28C13.9815 10.56 12.4882 9.17334 10.9949 9.17334C9.50153 9.17334 8.0082 10.56 6.99487 
        13.28C6.8882 13.5467 7.42153 14.0267 7.6882 13.76C8.6482 12.7467 9.82153 12.32 10.9949 12.32C12.1682 
        12.32 13.3415 12.7467 14.3015 13.76C14.6215 14.0267 15.1015 13.5467 14.9949 13.28Z"
        fill="#664E27"
      />
      <path
        d="M26.6746 13.28C25.6612 10.56 24.1679 9.17334 22.6746 9.17334C21.1812 9.17334 19.6879 10.56 18.6746 
        13.28C18.5679 13.5467 19.1012 14.0267 19.3679 13.76C20.3279 12.7467 21.5012 12.32 22.6746 12.32C23.9012 
        12.32 25.0212 12.7467 25.9812 13.76C26.2479 14.0267 26.7812 13.5467 26.6746 13.28Z"
        fill="#664E27"
      />
      <path
        d="M23.6347 19.36C22.4614 19.1467 20.0081 18.8267 16.8614 18.8267C13.7147 18.8267 11.2614 19.1467 
        10.0881 19.36C9.39474 19.4667 9.3414 19.7333 9.39474 20.16C9.44807 20.3733 9.44807 20.6933 9.55474 
        21.0133C9.60807 21.3333 9.71474 21.44 10.2481 21.44H23.5281C24.0614 21.44 24.1147 21.3333 24.2214 
        21.0133C24.2747 20.6933 24.3281 20.4267 24.3814 20.16C24.3814 19.7333 24.3281 19.4667 23.6347 19.36Z"
        fill="white"
      />
    </svg>
  );
};

export default RogerReaction;
