import { MouseEvent } from "types/event.type";
interface ProfileIconProps {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const ProfileIcon = ({ onClick = () => {} }: ProfileIconProps) => {
  return (
    <div onClick={onClick}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-[#5f4a3d] hover:text-[#87644e]"
      >
        <path
          d="M11.9997 13.6922C13.5465 13.6922 14.9762 13.2563 16.12 12.5156C17.0059 11.9484 18.159 12.0422 
          18.9372 12.75C21.0981 14.7094 22.3262 17.4891 22.3215 20.4094V21.6609C22.3215 22.9547 21.2715 24 
          19.9778 24H4.02154C2.72779 24 1.67779 22.9547 1.67779 21.6609V20.4094C1.66841 17.4938 2.89654 14.7094 
          5.05748 12.7547C5.8356 12.0469 6.99341 11.9531 7.87466 12.5203C9.0231 13.2563 10.4481 13.6922 11.9997 13.6922Z"
          fill="currentColor"
        />
        <path
          d="M12.0004 11.6063C15.2054 11.6063 17.8035 9.0081 17.8035 5.80313C17.8035 2.59815 15.2054 0 12.0004 
          0C8.79541 0 6.19727 2.59815 6.19727 5.80313C6.19727 9.0081 8.79541 11.6063 12.0004 11.6063Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default ProfileIcon;
