import { MESSAGE } from "types/message.type";
import MessageQuote from "./MessageQuote";
import { coverTimeSendMessage, parseQuotedMessages } from "logic/message.logic";
import { TYPE_SCREEN_CHAT } from "constants/constans";
import MessageFile from "./MessageFile";
import AvatarCatchError from "components/image/AvatarCatchError";
import { isEmpty } from "lodash";
import MessageReply from "./MessageReply";
import MessageTextSearch from "./MessageTextSearch";

interface MessageItemSearchProps {
  message: MESSAGE;
  typeScreen: TYPE_SCREEN_CHAT;
  keywordSearch: string;
}

const MessageItemSearch = ({ message, typeScreen = TYPE_SCREEN_CHAT.MAIN, keywordSearch }: MessageItemSearchProps) => {
  return (
    <>
      <AvatarCatchError src={message?.avatar} className="w-10 h-10 min-w-10" />
      <div className="flex flex-col">
        <div className="flex items-center mb-1 gap-x-4">
          <p className="font-bold text-neutral-110 text-base leading-[18.75px] max-w-[500px] line-clamp-1">{message?.name}</p>
          <p className="text-xs text-neutral-60 whitespace-nowrap leading-[18.75px]">{coverTimeSendMessage(message?.time_send_message)}</p>
        </div>
        <div className="flex flex-col items-start gap-y-1">
          {!isEmpty(message?.message_reply) && (
            <MessageReply
              replyUserId={message?.message_reply.user_id}
              replyUserName={message?.message_reply.full_name}
              onClick={() => {}}
            />
          )}
        </div>
        <div className="flex flex-col items-start gap-1">
          {message?.quote_text && <MessageQuote quote={parseQuotedMessages(message?.quote_text)} typeScreen={typeScreen} />}
          {message?.content_text && <MessageTextSearch keywordSearch={keywordSearch} content={message.content_text} />}
          {message?.content_file && <MessageFile typeScreen={typeScreen} files={message.content_file} />}
        </div>
      </div>
    </>
  );
};

export default MessageItemSearch;
