const PhotoIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 
        17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
        stroke="#B2AAA2"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.0835 8.33325C7.77385 8.33325 8.3335 7.77361 8.3335 7.08325C8.3335 6.3929 7.77385 
        5.83325 7.0835 5.83325C6.39314 5.83325 5.8335 6.3929 5.8335 7.08325C5.8335 7.77361 6.39314 8.33325 7.0835 8.33325Z"
        stroke="#B2AAA2"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4998 12.4999L13.3332 8.33325L4.1665 17.4999"
        stroke="#B2AAA2"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhotoIcon;
