import { createSlice } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

export interface SocketState {
  socket: Socket | null;
  errorSocket: any;
}

const initialState: SocketState = {
  socket: null,
  errorSocket: null,
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setErrorSocket: (state, action) => {
      state.errorSocket = action.payload;
    },
  },
});

export const { setSocket, setErrorSocket } = socketSlice.actions;
export default socketSlice.reducer;
