import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageIndex, mapDataInfiniteQuery } from "utils/common";
import { useDebounce } from "hook/useDebounce";
import { ResponseTypeCommon } from "types/utils.type";
import { ContactReceived } from "types/contact.type";
import { getListContactSent } from "http/contact.http";
import { isEmpty } from "lodash";
import { QUERY_KEY } from "constants/pathApi";
import SentList from "./SentList";
import { useCustomDispatch } from "hook/redux";
import { setContactSent } from "../../../redux/slices/contact";

const SentContainer = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const debouncedSearchContact = useDebounce<string>(searchKeyword, 1000);
  const [sents, setSents] = useState<ContactReceived[]>([]);
  const dispatch = useCustomDispatch();
  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_SENT_CONTACT, debouncedSearchContact],
    queryFn: ({ pageParam = 1 }) =>
      getListContactSent<ResponseTypeCommon<ContactReceived>>({
        pageParam,
        params: {
          keyword: debouncedSearchContact,
        },
      }),
    initialPageParam: 1,
    ...{
      refetchOnWindowFocus: false,
      gcTime: 0,
    },
    getNextPageParam: getNextPageIndex,
  });

  // merge multiple page data
  useEffect(() => {
    if (!isEmpty(data)) {
      const rawData = mapDataInfiniteQuery<ContactReceived, ResponseTypeCommon<ContactReceived>>(data);
      setSents(rawData);
    }
  }, [data]);
  useEffect(() => {
    dispatch(setContactSent(null));
  }, []);

  return (
    <>
      <SentList
        sents={sents}
        total={data?.pages[0].total}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        setSearchKeyword={setSearchKeyword}
      />
    </>
  );
};

export default SentContainer;
