
const NotifiIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0159 0.75415C10.7802 0.75415 9.76532 1.76907 9.7653 3.00476C9.7653 
        3.38766 9.87123 3.74414 10.0426 4.06111C9.13709 4.34186 8.30932 4.80427 7.61006 5.40942C7.53234 5.473 
        7.46816 5.55152 7.42131 5.64033C7.37446 5.72915 7.34588 5.82645 7.33728 5.92649C7.32867 6.02654 7.34021 6.12729 
        7.37121 6.2228C7.40221 6.31831 7.45205 6.40663 7.51777 6.48255C7.58348 6.55847 7.66376 6.62045 7.75384 6.66481C7.84392 
        6.70918 7.94198 6.73504 8.04222 6.74086C8.14246 6.74668 8.24286 6.73235 8.33747 6.69871C8.43208 6.66506 8.51898 6.6128 
        8.59305 6.54499C9.51783 5.74465 10.7092 5.25977 12.0159 5.25977C14.9087 5.25977 17.2756 7.62676 17.2756 10.5195V14.6671C17.2756 
        14.7838 17.3027 14.8988 17.3549 15.0031L18.7032 17.6924C18.9674 18.2194 18.6192 18.7781 18.0268 18.7781H6.01086C5.41843 18.7781 
        5.07157 18.2204 5.33597 17.6924L6.68282 15.0031C6.73495 14.8988 6.76207 14.7838 6.76204 14.6671V10.5195C6.76204 9.90231 6.87694 
        9.30965 7.07895 8.75012C7.11247 8.65726 7.12738 8.55869 7.12281 8.46007C7.11825 8.36144 7.0943 8.26468 7.05234 8.17531C7.01038 
        8.08594 6.95122 8.0057 6.87826 7.93919C6.80529 7.87268 6.71993 7.82119 6.62707 7.78767C6.5342 7.75415 6.43564 7.73924 6.33701 
        7.74381C6.23839 7.74837 6.14163 7.77232 6.05225 7.81428C5.87176 7.89903 5.73232 8.052 5.66462 8.23955C5.4087 8.94839 5.25968 
        9.71621 5.25968 10.5195V14.4896L3.99206 17.0204C3.25522 18.4917 4.36735 20.2805 6.01086 20.2805H8.33923C8.68939 21.9898 10.2101 
        23.2866 12.0188 23.2866C13.8276 23.2866 15.3483 21.9898 15.6985 
        20.2805H18.0268C19.6704 20.2805 20.785 18.4923 20.0471 17.0204L18.778 
        14.4896V10.5195C18.778 7.49415 16.7543 4.91064 13.9966 
        4.05671C14.1672 3.74077 14.2739 3.38592 14.2739 3.00476C14.2738 1.76907 13.2516 0.75415 
        12.0159 0.75415ZM7.5411 1.97775C7.39707 1.97394 7.25498 
        2.01163 7.13177 2.08632C4.22708 3.78562 2.25496 6.93842 2.25496 10.5195C2.25355 
        10.619 2.27194 10.7179 2.30906 10.8102C2.34618 10.9026 2.40128 10.9867 2.47118 11.0576C2.54107 11.1285 2.62436 11.1848 2.71619 
        11.2232C2.80803 11.2616 2.90659 11.2814 3.00614 11.2814C3.10569 11.2814 3.20425 11.2616 3.29608 11.2232C3.38792 11.1848 3.47121 
        11.1285 3.5411 11.0576C3.61099 10.9867 3.6661 10.9026 3.70322 10.8102C3.74034 10.7179 3.75873 10.619 3.75732 10.5195C3.75732 
        7.49066 5.42964 4.82365 7.89175 3.38328C8.03506 3.30253 8.1479 3.17697 8.21295 3.02589C8.278 2.8748 8.29167 2.70654 8.25185 
        2.54694C8.21203 2.38734 8.12093 2.24522 7.99254 2.14239C7.86414 2.03957 7.70555 1.98173 7.5411 1.97775ZM16.4746 1.98069C16.3124
         1.98959 16.1575 2.05081 16.033 2.15518C15.9086 2.25955 15.8213 2.40145 15.7843 2.5596C15.7472 2.71775 15.7625 2.88364 15.8277 
         3.03241C15.8929 3.18117 16.0046 3.3048 16.1459 3.38475C18.6078 4.82512 20.2818 7.49032 20.2818 10.5195C20.2804 10.619 20.2988 
         10.7179 20.3359 10.8102C20.3731 10.9026 20.4282 10.9867 20.4981 11.0576C20.568 11.1285 20.6512 11.1848 20.7431 11.2232C20.8349 
         11.2616 20.9335 11.2814 21.033 11.2814C21.1326 11.2814 21.2311 11.2616 21.323 11.2232C21.4148 11.1848 21.4981 11.1285 21.568 
         11.0576C21.6379 10.9867 21.693 10.9026 21.7301 10.8102C21.7672 10.7179 21.7856 10.619 21.7842 10.5195C21.7842 6.93757 19.8097 
         3.78668 16.9059 2.08779C16.7762 2.0094 16.6259 1.97207 16.4746 1.98069ZM12.0159 2.25064C12.4397 2.25064 12.77 2.58099 12.77 
         3.00476C12.77 3.42852 12.4397 3.75301 12.0159 3.75301C11.5921 3.75301 11.2677 3.42852 11.2677 3.00476C11.2677 2.58099 11.5922 
         2.25064 12.0159 2.25064ZM9.90175 20.2805H14.1359C13.8287 21.1579 
         13.0072 21.7843 12.0188 21.7843C11.0305 21.7843 10.209 21.1579 9.90175 20.2805Z"
        fill="#592C12"
      />
    </svg>
  );
};

export default NotifiIcon;
