/* eslint-disable max-len */
const PictureIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.14 21.75H4.86C4.16779 21.75 3.50392 21.475 3.01445 20.9855C2.52498 20.4961 2.25 19.8322 2.25 19.14V4.86C2.25 4.16779 2.52498 3.50392 3.01445 3.01445C3.50392 2.52498 4.16779 2.25 4.86 2.25H19.14C19.8322 2.25 20.4961 2.52498 20.9855 3.01445C21.475 3.50392 21.75 4.16779 21.75 4.86V19.14C21.75 19.8322 21.475 20.4961 20.9855 20.9855C20.4961 21.475 19.8322 21.75 19.14 21.75ZM4.86 3.75C4.56561 3.75 4.28328 3.86695 4.07511 4.07511C3.86695 4.28328 3.75 4.56561 3.75 4.86V19.14C3.75 19.4344 3.86695 19.7167 4.07511 19.9249C4.28328 20.1331 4.56561 20.25 4.86 20.25H19.14C19.4344 20.25 19.7167 20.1331 19.9249 19.9249C20.1331 19.7167 20.25 19.4344 20.25 19.14V4.86C20.25 4.56561 20.1331 4.28328 19.9249 4.07511C19.7167 3.86695 19.4344 3.75 19.14 3.75H4.86Z"
        fill="#1F1B16"
      />
      <path
        d="M2.99998 18.8099C2.89195 18.8095 2.78523 18.7862 2.68694 18.7413C2.58865 18.6965 2.50105 18.6312 2.42998 18.5499C2.36601 18.4745 2.31758 18.3872 2.28746 18.293C2.25735 18.1988 2.24616 18.0997 2.25452 18.0011C2.26288 17.9026 2.29064 17.8067 2.3362 17.719C2.38176 17.6312 2.44422 17.5534 2.51998 17.4899L7.63998 13.1699C8.02225 12.8472 8.51155 12.6795 9.01136 12.6999C9.51118 12.7203 9.98522 12.9272 10.34 13.2799L12.77 15.7099C12.8605 15.7873 12.9758 15.8299 13.095 15.8299C13.2142 15.8299 13.3294 15.7873 13.42 15.7099L15.78 13.9999C16.1297 13.7306 16.5586 13.5847 17 13.5847C17.4413 13.5847 17.8703 13.7306 18.22 13.9999L21.47 16.5899C21.5475 16.6516 21.612 16.728 21.66 16.8146C21.7079 16.9013 21.7384 16.9966 21.7495 17.095C21.7607 17.1934 21.7523 17.2931 21.7249 17.3883C21.6976 17.4835 21.6517 17.5724 21.59 17.6499C21.5283 17.7273 21.4519 17.7919 21.3652 17.8399C21.2785 17.8878 21.1833 17.9182 21.0848 17.9294C20.9864 17.9405 20.8867 17.9322 20.7915 17.9048C20.6963 17.8774 20.6075 17.8316 20.53 17.7699L17.29 15.1699C17.2025 15.1026 17.0953 15.0661 16.985 15.0661C16.8746 15.0661 16.7674 15.1026 16.68 15.1699L14.32 16.9999C13.9358 17.2883 13.4607 17.4289 12.9815 17.3958C12.5022 17.3627 12.0509 17.1583 11.71 16.8199L9.27998 14.3399C9.1881 14.2569 9.06874 14.211 8.94498 14.211C8.82122 14.211 8.70185 14.2569 8.60998 14.3399L3.47998 18.6399C3.34603 18.7531 3.17534 18.8135 2.99998 18.8099Z"
        fill="#1F1B16"
      />
      <path
        d="M15 11.75C14.4561 11.75 13.9244 11.5887 13.4722 11.2865C13.0199 10.9844 12.6675 10.5549 12.4593 10.0524C12.2512 9.54988 12.1967 8.99695 12.3028 8.4635C12.409 7.93006 12.6709 7.44005 13.0555 7.05546C13.4401 6.67086 13.9301 6.40895 14.4635 6.30284C14.997 6.19673 15.5499 6.25119 16.0524 6.45933C16.5549 6.66747 16.9844 7.01995 17.2865 7.47218C17.5887 7.92442 17.75 8.4561 17.75 9C17.75 9.72935 17.4603 10.4288 16.9445 10.9445C16.4288 11.4603 15.7293 11.75 15 11.75ZM15 7.75C14.7528 7.75 14.5111 7.82331 14.3055 7.96066C14.1 8.09802 13.9398 8.29324 13.8452 8.52165C13.7505 8.75005 13.7258 9.00139 13.774 9.24386C13.8223 9.48634 13.9413 9.70907 14.1161 9.88388C14.2909 10.0587 14.5137 10.1778 14.7561 10.226C14.9986 10.2742 15.2499 10.2495 15.4784 10.1549C15.7068 10.0602 15.902 9.90003 16.0393 9.69446C16.1767 9.4889 16.25 9.24723 16.25 9C16.25 8.66848 16.1183 8.35054 15.8839 8.11612C15.6495 7.8817 15.3315 7.75 15 7.75Z"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default PictureIcon;
