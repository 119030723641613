export enum ACTION_OPEN_SIDEBAR {
    SIDEBAR_REPLY = 1,
    SIDEBAR_CONTACT,
    SIDEBAR_RECEIVED,
    SIDEBAR_SENT,
    SIDEBAR_CONVERSATION_INFO,
    SIDEBAR_MEMBER,
    SIDEBAR_PIN_MESSAGES,
    SIDEBAR_FILES
}

export type SidebarType<T = any> = {
    type: number;
    data: T;
    prevSidebar?: SidebarType;
};
