import PauseIcon from "components/icon/PauseIcon";
import PlayIcon from "components/icon/PlayIcon";
import SoundWave from "components/icon/SoundWaveIcon";
import { TYPE_SCREEN_CHAT } from "constants/constans";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { setPauseAllAudio } from "../../../redux/slices/message";
import { coverSecondsToMin, getFullPathFile } from "utils/common";
import WaveSurfer from "wavesurfer.js";
interface MessageAudioProp {
  audio: string;
  typeScreen: TYPE_SCREEN_CHAT;
  isPinMessage?: boolean;
  sidebarPinMessage?: boolean;
}
const MessageAudio = ({ audio, typeScreen, isPinMessage, sidebarPinMessage }: MessageAudioProp) => {
  const containerRef = useRef(null);
  const waveSurferRef = useRef<WaveSurfer>();
  const [durationTime, setDurationTime] = useState(0);
  const [isPlaying, toggleIsPlaying] = useState(false);
  const { pauseAllAudio } = useCustomSelector((state) => state.message);
  const dispatch = useCustomDispatch();
  useEffect(() => {
    if (containerRef.current) {
      const waveSurfer = WaveSurfer.create({
        container: containerRef.current,
        barWidth: 2,
        barHeight: 2,
        progressColor: "#592C12",
        cursorWidth: 0,
        height: 30,
        width: typeScreen === TYPE_SCREEN_CHAT.MAIN ? 200 : 170,
        barRadius: 5,
        barGap: 2,
      });
      const loadWaveform = async () => {
        try {
          await waveSurfer.load(getFullPathFile(audio));
          waveSurferRef.current = waveSurfer;
          setDurationTime(waveSurfer.getDuration());
          waveSurfer.on("finish", () => {
            toggleIsPlaying((prev) => !prev);
          });
        } catch (error) {
          console.log("error: ", "Failed to execute");
        }
      };
      loadWaveform();
      // cleanup
      return () => {
        waveSurfer.destroy();
      };
    }
  }, [audio]);

  useEffect(() => {
    if (pauseAllAudio && waveSurferRef.current) {
      waveSurferRef.current.pause();
      toggleIsPlaying(false);
      dispatch(setPauseAllAudio(false));
    }
  }, [pauseAllAudio, waveSurferRef]);

  if (isPinMessage) {
    return (
      <div className="flex gap-x-1">
        <SoundWave />
        <span>[{t("chat.voice")}]</span>
      </div>
    );
  }

  return (
    <div
      className={`inline-flex items-center px-2 my-1 bg-white border rounded-lg border-neutral-30 justify-between ${
        typeScreen === TYPE_SCREEN_CHAT.MAIN ? "w-[295px]" : "w-[270px]"
      }`}
    >
      <div
        className="flex items-center justify-center w-8 h-8 p-3 rounded-full bg-primary-900 cursor-pointer"
        onClick={() => {
          if (waveSurferRef.current) {
            if (!waveSurferRef.current.isPlaying()) {
              dispatch(setPauseAllAudio(true));
            }
            setTimeout(() => {
              if(waveSurferRef.current){
                waveSurferRef.current.playPause();
                toggleIsPlaying(waveSurferRef.current.isPlaying());
              }
            }, 50);
          }
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </div>
      <div ref={containerRef} className="flex items-center h-12 p-2 cursor-pointer max-w-72 container-ref" />
      <p className="text-sm text-neutral-60">{coverSecondsToMin(durationTime)}</p>
    </div>
  );
};

export default MessageAudio;
