import { Middleware } from "@reduxjs/toolkit";
import { Socket, io } from "socket.io-client";
import { REDUX_ACTION_TYPE } from "constants/constans";
import { SOCKET_EVENT } from "constants/pathApi";
import { setErrorSocket, setSocket } from "../slices/socket";

const socketMiddleware: Middleware =
  (store) =>
    (next) =>
      (action: any) => {
        let socket: Socket | null = null;
        const { dispatch, getState } = store;
        const state = getState();
        if (action?.type === REDUX_ACTION_TYPE.SET_USER_INFO && action.payload?.id && 
            process.env.REACT_APP_SOCKET_URL && !state?.socket?.socket) {
          // start connect socket
          socket = io(process.env.REACT_APP_SOCKET_URL, { reconnection: true, autoConnect:true });

          // connect success
          socket.on(SOCKET_EVENT.CONNECT, function () {
            // join room
            if(socket){
              socket.emit(SOCKET_EVENT.JOIN_ROOM, { user_id: action.payload?.id });
            }
            dispatch(setSocket(socket));
          });

          // socket error handling
          socket.on(SOCKET_EVENT.CONNECT_FAILED, function (error) {
            dispatch(setErrorSocket(error));
          });
          
          socket.on(SOCKET_EVENT.ERROR, function (error) {
            dispatch(setErrorSocket(error));
          });
        }
        
        // disconnect when logout
        if (action?.type === REDUX_ACTION_TYPE.TOKEN_VALID && socket) {
          socket.disconnect();
        }
        next(action);
      };
export default socketMiddleware;
