import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageIndex, mapDataInfiniteQuery } from "utils/common";
import { useDebounce } from "hook/useDebounce";
import { ResponseTypeCommon } from "types/utils.type";
import { ContactReceived } from "types/contact.type";
import { getListContactReceived } from "http/contact.http";
import { isEmpty } from "lodash";
import ReceivedList from "./ReceivedList";
import { QUERY_KEY } from "constants/pathApi";
import DialogDeleteReceivedContainer from "components/dialog/contact/DialogDeleteReceivedContainer";
import { useCustomDispatch } from "hook/redux";
import { setContactAccepted } from "../../../redux/slices/contact";

const ReceivedContainer = () => {
  // fetch list conversation
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const debouncedSearchContact = useDebounce<string>(searchKeyword, 1000);
  const [receiveds, setReceiveds] = useState<ContactReceived[]>([]);
  const [openDialogDeleteContact, setOpenDialogDeleteContact] = useState(false);
  const dispatch = useCustomDispatch();

  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_RECEIVED_CONTACT, debouncedSearchContact],
    queryFn: ({ pageParam = 1 }) =>
      getListContactReceived<ResponseTypeCommon<ContactReceived>>({
        pageParam,
        params: {
          keyword: debouncedSearchContact,
        },
      }),
    initialPageParam: 1,
    ...{
      refetchOnWindowFocus: false,
      gcTime: 0,
    },
    getNextPageParam: getNextPageIndex,
  });

  // merge multiple page data
  useEffect(() => {
    if (!isEmpty(data)) {
      const rawData = mapDataInfiniteQuery<ContactReceived, ResponseTypeCommon<ContactReceived>>(data);
      setReceiveds(rawData);
    }
  }, [data]);

  useEffect(() => {
    dispatch(setContactAccepted(null));
  }, [data]);

  return (
    <>
      <ReceivedList
        receiveds={receiveds}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        total={data?.pages[0].total}
        fetchNextPage={fetchNextPage}
        setSearchKeyword={setSearchKeyword}
        setOpenDialogDeleteContact={setOpenDialogDeleteContact}
      />
      {openDialogDeleteContact && (
        <DialogDeleteReceivedContainer
          debouncedSearchContact={debouncedSearchContact}
          setOpenDialogDeleteContact={setOpenDialogDeleteContact}
          closeModal={() => setOpenDialogDeleteContact((pre) => !pre)}
        />
      )}
    </>
  );
};

export default ReceivedContainer;
