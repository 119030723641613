import { Button, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { t } from "i18next";
import { CLoseIcon } from "components/icon";
import { useState } from "react";
import { redirectLogout } from "utils/common";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { setTokenInvalid } from "../../../redux/slices/auth";
import CustomModal from "components/Modal/ModalCommon";

interface DialogLogoutProp {
  closeModal: () => void;
  openDialogLogout: boolean;
}
export function DialogLogout({ openDialogLogout, closeModal }: DialogLogoutProp) {
  const [loading, setLoading] = useState(false);
  const { device_token } = useCustomSelector((state) => state.auth);
  const dispatch = useCustomDispatch();
  const logout = async () => {
    setLoading(true);
    dispatch(setTokenInvalid());
    redirectLogout(device_token);
  };

  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[592px] !w-full">
        <DialogHeader placeholder={""} className="font-bold text-neutral-110 text-[22px] flex justify-between">
          {t("logout.title")}
          <CLoseIcon onClick={closeModal} />
        </DialogHeader>
        <DialogBody placeholder={""} className="border-b-2">
          <p className="text-neutral-110">{t("logout.content")}</p>
        </DialogBody>
        <DialogFooter placeholder={""}>
          <Button
            placeholder={""}
            onClick={closeModal}
            className="mr-2 bg-white border-[1px] border-neutral-70 text-neutral-110 py-2"
          >
            <span>{t("logout.cancel")}</span>
          </Button>
          <Button
            loading={loading}
            placeholder={""}
            className="!bg-primary-900 border-[1px] border-primary-900 text-white py-2"
            onClick={logout}
          >
            <span className="font-medium">{!loading ? t("logout.title") : t("logout.loading")}</span>
          </Button>
        </DialogFooter>
      </CustomModal>
    </>
  );
}
