import { CLoseIcon } from "components/icon";
import AvatarCatchError from "components/image/AvatarCatchError";
import { SENDER } from "types/contact.type";
import { MEMBER } from "types/message.type";

interface PreviewSelectUserProps {
  member: MEMBER | SENDER;
  onClick: () => void;
  className?: string;
}

const PreviewSelectUser = ({ member, onClick }: PreviewSelectUserProps) => {
  return (
    <div className="flex bg-neutral-20 justify-between items-center flex-1">
      <div className="flex items-center rounded gap-2 ">
        <AvatarCatchError className="h-5 w-5" src={member.avatar} />
        <p className="text-sm font-normal text-neutral-110 max-w-40 line-clamp-1">{member?.name}</p>
      </div>
      <CLoseIcon onClick={onClick} className="text-primary-900" width={8} height={8} />
    </div>
  );
};

export default PreviewSelectUser;
