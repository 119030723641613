export interface Contact {
  conversation_id: string;
  name: string;
  user_id: number;
  avatar: string;
  company_contract: string;
}

export interface SENDER {
  id: number,
  name: string,
  avatar: string
}

export interface ContactReceived {
  contact_id: string;
  name: string;
  user_id: number;
  avatar: string;
  company_contract: string;
}

export interface SendAddContactType {
  typeRequest?: number;
  value: string;
}

export enum TYPE_ACCEPT_OR_REJECT_CONTACT {
  ACCEPT = 1,
  REJECT = 2,
}

export enum TYPE_LIST_CONTACT {
  IS_SCREEN_CREATE_NEW_CHAT = 1,
}
export enum TYPE_SEARCH_NO_CONTACT {
  EMAIL = 1,
  PHONE_NUMBER = 2,
}
