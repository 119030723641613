import { ErrorResponse, useNavigate } from "react-router-dom";
import { CHAT, CONTACT, SETTING } from "../../constants/pathUrl";
import { ChatSvg, ContactSvg, SettingSvg } from "./svg/index";
import { useLocation } from "react-router-dom";
import { useCustomDispatch, useCustomSelector } from "../../hook/redux";
import AvatarCatchError from "components/image/AvatarCatchError";
import NavigateSetting from "components/setting/DialogSetting";
import { useEffect, useRef, useState } from "react";
import PopupMenu from "components/popupMenu/PopupMenu";
import { InfiniteData, useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY, SOCKET_EVENT } from "constants/pathApi";
import { getListContactReceived } from "http/contact.http";
import { ResponseTypeCommon } from "types/utils.type";
import { ContactReceived } from "types/contact.type";
import { ACTION_CONVERSATION, Conversation, OutConversationType, TYPE_CONVERSATION_FILTER } from "types/conversation.type";
import { getListConversation } from "http/conversation.http";
import useOnClickOutside from "hook/useOnClickOutside";
import { setParamsSocketContact } from "../../redux/slices/paramsSocket";
import LogoSvg from "./svg/Logo";
import NameBeechat from "./svg/NameBeechat";
import ChatSvgActive from "./svg/ChatSvgActive";
import ContactSvgActive from "./svg/ContactSvgActive";
import { LIMIT } from "constants/constans";
import IconOpenTab from "components/icon/IconOpenTab";
import { t } from "i18next";
import { AxiosError } from "axios";
import { getNextPageIndex } from "utils/common";
import { cloneDeep } from "lodash";
import { handleAppendConversation, handleDeleteCacheConversation, handleUpdateCacheConversation } from "logic/conversation.logic";
import SearchMainContainer from "components/search/SearchMainContainer";

const APP_NAVIGATE = [
  {
    component: <ChatSvg />,
    componentActive: <ChatSvgActive />,
    path: CHAT,
  },
  {
    component: <ContactSvg />,
    componentActive: <ContactSvgActive />,
    path: CONTACT,
  },
  // {
  //   component: <ScheduleSvg />,
  //   componentActive: <ScheduleSvgActive />,
  //   path: SCHEDULE,
  // },
  // {
  //   component: <TaskSvg />,
  //   componentActive: <TaskSvgActive />,
  //   path: TASK,
  // },
  {
    component: <SettingSvg />,
    path: SETTING,
  },
];
const Navigate = () => {
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const { userInfo } = useCustomSelector((state) => state.auth);
  const [openSetting, setOpenSetting] = useState(false);
  const [conversationsUnread, setConversationsUnread] = useState<Conversation[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const popupRef = useRef<HTMLUListElement>(null);
  const openPopupRef = useRef<HTMLImageElement>(null);
  useOnClickOutside(popupRef, openPopupRef, () => {
    setOpenPopupMenu(false);
  });
  const queryClient = useQueryClient();
  const { socket } = useCustomSelector((state) => state.socket);
  const dispatch = useCustomDispatch();

  const handleToggleSetting = () => {
    setOpenSetting((prev) => !prev);
  };
  const { data: totalReceived } = useQuery({
    queryKey: [QUERY_KEY.TOTAL_RECEIVED_CONTACT],
    queryFn: ({ pageParam = 1 }) =>
      getListContactReceived<ResponseTypeCommon<ContactReceived>>({
        pageParam,
      }),
    gcTime: 0,
  });

  // get data conversation search
  useInfiniteQuery<ResponseTypeCommon<Conversation>, AxiosError<ErrorResponse>>({
    queryKey: [QUERY_KEY.LIST_ALL_CONTACT_SEARCH],
    queryFn: ({ pageParam = 1 }) =>
      getListConversation<ResponseTypeCommon<Conversation>>({
        per_page: LIMIT.ALL,
        pageParam,
        params: {
          type: TYPE_CONVERSATION_FILTER.GET_HIDDEN,
        },
      }),
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    getNextPageParam: getNextPageIndex,
  });


  const handleAppendCacheConversation = async (conversationAppend: Conversation) => {
    const conversationChange = cloneDeep(conversationAppend);
    // update cache
    queryClient.setQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_ALL_CONTACT_SEARCH], (oldData) => {
      if (!oldData) return oldData;
      const newData = handleAppendConversation(oldData, conversationChange);
      return newData;
    });
  };

  const handleUpdateNewConversation = (type: ACTION_CONVERSATION, selectConversation: Conversation) => {
    // update cache
    if (type in ACTION_CONVERSATION) {
      queryClient.setQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_ALL_CONTACT_SEARCH], (oldData) => {
        if (!oldData) return oldData;
        const [, newData] = handleUpdateCacheConversation(oldData, selectConversation, userInfo, type);
        return newData;
      });
    }
  };

  const handleDeleteConversation = (conversationId: number) => {
    queryClient.setQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_ALL_CONTACT_SEARCH], (oldData) => {
      if (!oldData) return oldData;
      const newData = handleDeleteCacheConversation(oldData, conversationId);
      return newData;
    });
  };

  const { data: totalUnreadConversation } = useQuery({
    queryKey: [QUERY_KEY.CONVERSATION_TOTAL_UNREAD_MESSAGE, TYPE_CONVERSATION_FILTER.UNREAD],
    queryFn: ({ pageParam = 1 }) =>
      getListConversation<ResponseTypeCommon<Conversation>>({
        pageParam,
        params: {
          type: TYPE_CONVERSATION_FILTER.UNREAD,
        },
        per_page: LIMIT.ALL,
      }),
    gcTime: 0,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    setConversationsUnread(totalUnreadConversation?.data || []);
  }, [totalUnreadConversation]);

  useEffect(() => {
    if (location.pathname === CHAT) {
      queryClient.refetchQueries({ queryKey: [QUERY_KEY.CONVERSATION_TOTAL_UNREAD_MESSAGE, TYPE_CONVERSATION_FILTER.UNREAD] });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (socket?.connected) {
      const handleSocketEvent = (params: any) => {
        dispatch(setParamsSocketContact(params));
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.TOTAL_RECEIVED_CONTACT] });
      };

      const listenerUpdateConversation = (params: any) => {
        const conversationUpdate:Conversation = JSON.parse(params);
        // action new message
        if(conversationUpdate?.message_id){
          const indexConversation = conversationsUnread?.findIndex((item) => item.conversation_id === conversationUpdate?.conversation_id);
          // if is action when new message and user send message is not current user
          if(indexConversation < 0 && conversationUpdate.user_id !== userInfo?.id){
            setConversationsUnread((prev) => [...prev, conversationUpdate]);
          }
        }else if(conversationUpdate?.message_count_unread !== undefined){ // action read message
          // if is action when user read message 
          if(conversationUpdate.message_count_unread === 0){
            setConversationsUnread((prev) => prev.filter((item) => +item.conversation_id !== +conversationUpdate.conversation_id));
          }
        }
        handleUpdateNewConversation(ACTION_CONVERSATION.SOCKET, conversationUpdate);
      };

      // delete conversation
      const listenerDeleteConversation = (params: any) => {
        const outConversation: OutConversationType = JSON.parse(params);
        setConversationsUnread((prev) => prev.filter((item) => +item.conversation_id !== +outConversation.conversation_id));
        handleDeleteConversation(+outConversation.conversation_id);
      };

      // new conversation
      const listenerNewConversation = (params: any) => {
        const newConversation: Conversation = JSON.parse(params);
        if(newConversation.user_id !== userInfo?.id ){
          setConversationsUnread((prev) => [...prev, newConversation]);
        }
        handleAppendCacheConversation(newConversation);
      };


      socket.on(SOCKET_EVENT.UPDATE_CONVERSATION, listenerUpdateConversation);
      socket.on(SOCKET_EVENT.NEW_CONVERSATION, listenerNewConversation);
      socket.on(SOCKET_EVENT.DELETE_CONVERSATION, listenerDeleteConversation);
      socket.on(SOCKET_EVENT.OUT_CONVERSATION, listenerDeleteConversation);

      socket.on(SOCKET_EVENT.REJECT_CONTACT, handleSocketEvent);
      socket.on(SOCKET_EVENT.NEW_CONTACT, handleSocketEvent);
      socket.on(SOCKET_EVENT.ACCEPT_CONTACT, (param) => {
        handleSocketEvent(param);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.LIST_ALL_CONTACT_SEARCH] });
      });
      return () => {
        socket.off(SOCKET_EVENT.REJECT_CONTACT);
        socket.off(SOCKET_EVENT.ACCEPT_CONTACT);
        socket.off(SOCKET_EVENT.NEW_CONTACT);

        socket.off(SOCKET_EVENT.UPDATE_CONVERSATION, listenerUpdateConversation);
        socket.off(SOCKET_EVENT.NEW_CONVERSATION, listenerNewConversation);
        socket.off(SOCKET_EVENT.DELETE_CONVERSATION, listenerDeleteConversation);
        socket.off(SOCKET_EVENT.OUT_CONVERSATION, listenerDeleteConversation);
      };
    }
  }, [socket, conversationsUnread, userInfo]);

  return (
    <>
      <div className="flex flex-row-reverse h-full bg-white border-b justify-between">
        <div className="flex gap-20 flex-1">
          <div className="flex items-center justify-between w-full gap-20">
            <SearchMainContainer />
            <div className="flex items-center h-full gap-[88px]">
              <div className="flex items-center h-full">
                {APP_NAVIGATE.map((item, index) =>
                  item.path !== SETTING ? (
                    <div key={index} className="relative w-14 h-full">
                      <div
                        onClick={() => navigate(item.path)}
                        className={`w-full h-full  flex items-center justify-center cursor-pointer
                           ${item.path === location.pathname ? "text-primary-900 bg-neutral-20 " : "text-primary-100"} 
                           `}
                      >
                        {location.pathname.split("/")[1] === item.path.substring(1) ? item.componentActive : item.component}
                      </div>
                      {item.path === CHAT && conversationsUnread && conversationsUnread?.length > 0 && (
                        <div
                          className="w-5 h-5 -right-[-6px] top-[3px] text-center text-xs bg-noti text-white rounded-full absolute 
                           flex justify-center items-center"
                        >
                          {conversationsUnread?.length > 9 ? "9+" : conversationsUnread?.length}
                        </div>
                      )}
                      {item.path === CONTACT && totalReceived && totalReceived.total > 0 && (
                        <div
                          className="w-5 h-5 -right-[-6px] top-[4px] text-center text-xs bg-noti text-white rounded-full absolute
                   flex justify-center items-center"
                        >
                          {totalReceived.total > 9 ? "9+" : totalReceived.total}
                        </div>
                      )}
                    </div>
                  ) : (
                    <NavigateSetting handleToggleSetting={handleToggleSetting} openSetting={openSetting} key={index} />
                  ),
                )}
              </div>
              <div className="relative cursor-pointer flex items-center pr-16">
                <div onClick={() => setOpenPopupMenu((prev) => !prev)}>
                  <AvatarCatchError src={userInfo?.avatar || ""} className="w-9 h-9 select-none" refImg={openPopupRef} />
                </div>
                <PopupMenu
                  popupRef={popupRef}
                  setOpenPopupMenu={setOpenPopupMenu}
                  handleToggleSetting={handleToggleSetting}
                  openPopupMenu={openPopupMenu}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 min-w-[352px] px-4 justify-between">
          <div className="flex items-center gap-x-1">
            <LogoSvg />
            <NameBeechat />
          </div>
          <a
            target="_blank"
            href={process.env.REACT_APP_DOMAIN_BEEID}
            className="w-24 h-8 border-primary-900 border rounded-lg flex items-center justify-center gap-x-2" rel="noreferrer"
          >
            <p className="font-bold text-primary-900 text-xl">{t("beeid")}</p>
            <IconOpenTab />
          </a>
        </div>
      </div>
    </>
  );
};

export default Navigate;
