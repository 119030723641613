import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getUserInfo, saveDeviceToken, setPrevPage } from "../redux/slices/auth";
import { redirectCheckLogin } from "utils/common";
import LoadingPage from "pages/common/LoadingPage";
import { ALL_CONTACT, CHAT, CONTACT, LOGIN_FAIL, RECEIVED } from "constants/pathUrl";
import { EventMessageServiceWorker } from "types/utils.type";
import { NOTIFICATION_TYPE } from "constants/constans";
import { setConversationIdFetch, setDataNotification } from "../redux/slices/conversation";
import { setScrollId } from "../redux/slices/contact";

export function ProtectedRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useCustomDispatch();
  const { oauthToken, userInfo, isLogout, saveTokenPending } = useCustomSelector((state) => state.auth);
  useEffect(() => {
    if(location.pathname === "/"){
      return navigate(CHAT);
    }
    if (!oauthToken && !isLogout) {
      if (!process.env.REACT_APP_DOMAIN_BEEID || !process.env.REACT_APP_CLIENT_ID || !process.env.REACT_APP_LOGIN_CALLBACK) {
        return navigate(LOGIN_FAIL);
      } else {
        dispatch(setPrevPage(location.pathname));
        redirectCheckLogin();
      }
    }else if(oauthToken && !userInfo){
      //get user info
      if(!saveTokenPending){
        dispatch(getUserInfo());
        dispatch(saveDeviceToken());
      }
    }
  }, [oauthToken, location, userInfo]);

  useEffect(() => {
    const handleDataWorker = (event:any) => {
      const data:EventMessageServiceWorker = event?.data;
      if(data){
        const typeNotification = data?.notification_type;
        switch (+typeNotification) {
          case NOTIFICATION_TYPE.NOTIFICATION_TYPE_MESSAGE:
            if(data?.conversation_id ){
              navigate(CHAT);
              dispatch(setDataNotification(data));
            }
            break;
          case NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONVERSATION:
            if(data?.id ){
              navigate(CHAT);
              dispatch(setConversationIdFetch(data?.id));
            }
            break;
          case NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONTACT:
            if(data?.conversation_id) {
              navigate(CONTACT + "/" + ALL_CONTACT);
              dispatch(setScrollId(data?.conversation_id));
            }else{
              navigate(CONTACT + "/" + RECEIVED);
              dispatch(setScrollId(data?.contact_id));
            }
            break;
          default:
            break;
        }
      }
    };
    if(navigator?.serviceWorker){
      navigator.serviceWorker.addEventListener("message", handleDataWorker);
    }
    return () => {
      if(navigator?.serviceWorker){
        navigator.serviceWorker.removeEventListener("message", handleDataWorker);
      }
    };
  }, []);
  return !oauthToken ? <LoadingPage /> : <Outlet />;
}
