import { Spinner } from "@material-tailwind/react";
import { MuteIcon, PinIcon, ProfileIcon } from "components/icon";
import AvatarCatchError from "components/image/AvatarCatchError";
import { LIMIT, MAX_SHOW_ITEM_SEARCH, TYPE_SEARCH_MAIN } from "constants/constans";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { t } from "i18next";
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ACTION_CONVERSATION, Conversation, CONVERSATION_HIDE, TYPE_CONVERSATION } from "types/conversation.type";
import { handleCompareString, highlightTextSearch } from "utils/strUtils";
import { ResponseTypeCommon } from "types/utils.type";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { handleSendReqMuteAndPin, handleUpdateCacheConversation, sortConversationWithTime } from "logic/conversation.logic";
import { QUERY_KEY } from "constants/pathApi";
import { setConversationSelected } from "../../redux/slices/conversation";
import { useNavigate } from "react-router-dom";
import { CHAT } from "constants/pathUrl";
import { mapDataInfiniteQuery } from "utils/common";

interface ListConversationProps {
  classNameParent?: string;
  classNameChildren?: string;
  classNameAvatar?: string;
  keyword: string;
  isActiveSearch?: string;
  activeTab?: TYPE_SEARCH_MAIN;
  setContactId: Dispatch<SetStateAction<number | undefined>>;
  setIsFocus: Dispatch<SetStateAction<boolean>>;
  setDataRecord?: (data: number) => void;
  handleSetActiveTab?: (value: TYPE_SEARCH_MAIN) => void;
}
const ListConversation = ({
  classNameParent,
  classNameChildren,
  classNameAvatar,
  keyword,
  isActiveSearch = "active",
  activeTab,
  setContactId,
  setIsFocus,
  setDataRecord,
  handleSetActiveTab,
}: ListConversationProps) => {
  const [totalPage, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [initSearchValue, setInitSearchValue] = useState<string>(keyword);
  const [listConversation, setListConversation] = useState<Conversation[]>([]);
  const queryClient = useQueryClient();
  const listMember = queryClient.getQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_ALL_CONTACT_SEARCH]);

  const { ref, inView } = useInView();
  const { userInfo } = useCustomSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  const conversations = useMemo(() => {
    if (activeTab === TYPE_SEARCH_MAIN.ALL) {
      return listConversation.slice(0, MAX_SHOW_ITEM_SEARCH);
    }
    return listConversation.slice(0, currentPage * LIMIT.SEARCH_CONTACT);
  }, [listConversation, currentPage]);

  const handlePinOrMute = (type: ACTION_CONVERSATION, selectConversation: Conversation) => {
    if (type === ACTION_CONVERSATION.PIN || type === ACTION_CONVERSATION.MUTE) {
      const conversationChange = handleSendReqMuteAndPin(selectConversation, type);
      queryClient.setQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_ALL_CONTACT_SEARCH], (oldData) => {
        if (!oldData) return oldData;
        const [, newData] = handleUpdateCacheConversation(oldData, conversationChange, userInfo, type);
        return newData;
      });
    }
  };

  const handleOpenConversation = (conversation: Conversation) => {
    navigate(CHAT);
    dispatch(setConversationSelected(conversation));
    // when click open conversation hide => update it not hide
    if (conversation.is_hide === CONVERSATION_HIDE.HIDE) {
      queryClient.setQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_ALL_CONTACT_SEARCH], (oldData) => {
        if (!oldData) return oldData;
        const updateTypeHide = {
          ...conversation,
          is_hide: CONVERSATION_HIDE.NOT_HIDE,
        };
        const [, newData] = handleUpdateCacheConversation(oldData, updateTypeHide, userInfo, ACTION_CONVERSATION.UPDATE_LOCAL);
        return newData;
      });
    }
    setIsFocus(false);
  };

  const handleLoadMore = () => {
    if (currentPage < totalPage) {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        setIsLoading(false);
      }, 500);
    }
  };

  const handleSearch = () => {
    if (!keyword) {
      setListConversation([]);
      setTotalPages(0);
      setCurrentPage(1);
      setDataRecord && setDataRecord(0);
      return;
    }
    const rawData = mapDataInfiniteQuery<Conversation, ResponseTypeCommon<Conversation>>(listMember);
    const filteredData = rawData.filter((item) => {
      return handleCompareString(keyword, item.conversation_name);
    });
    setListConversation(sortConversationWithTime(filteredData));
    setTotalPages(Math.ceil(filteredData.length / LIMIT.SEARCH_CONTACT));
    setCurrentPage(1);
    setDataRecord && setDataRecord(filteredData.length);
  };

  //search when typing
  useEffect(() => {
    if (listMember && activeTab !== TYPE_SEARCH_MAIN.ALL) {
      handleSearch();
    }
  }, [listMember, keyword]);

  // search when click button
  useEffect(() => {
    if (listMember && activeTab) {
      handleSearch();
      if(activeTab === TYPE_SEARCH_MAIN.ALL){
        setInitSearchValue(keyword);
      }
    }
  }, [listMember, isActiveSearch]);

  useEffect(() => {
    if (inView && !isLoading) {
      handleLoadMore();
    }
  }, [inView]);
  return (
    <>
      {conversations && (
        <>
          {listConversation.length > 0 && (
            <div className="w-full font-normal text-neutral-110 px-6 text-sm py-1">
              {t("search_main.contact")} ({listConversation.length >= 100 ? "99+" : listConversation.length})
            </div>
          )}
          <div
            className={`${classNameParent} ${
              activeTab !== TYPE_SEARCH_MAIN.ALL ? "overflow-y-auto" : ""
            } px-2 h-full overflow-hidden overflow-y-auto sidebar-search-style`}
          >
            {conversations.map((conversation, index) => (
              <Fragment key={conversation.conversation_id}>
                <div
                  onClick={() => handleOpenConversation(conversation)}
                  className={`${classNameChildren} flex items-center hover:bg-neutral-20 px-4 py-2 group justify-between cursor-pointer`}
                >
                  <div className="flex items-center gap-2 overflow-hidden overflow-wrap-anywhere">
                    <AvatarCatchError className={`${classNameAvatar}`} src={conversation.conversation_avatar} />
                    <p className="text-sm font-normal text-neutral-110 line-clamp-2">
                      {activeTab === TYPE_SEARCH_MAIN.ALL
                        ? highlightTextSearch(initSearchValue, conversation?.conversation_name)
                        : highlightTextSearch(keyword, conversation?.conversation_name)}
                    </p>
                  </div>
                  <div className="min-h-full">
                    <div className="flex items-center justify-end gap-1 group-hover:hidden">
                      {conversation.conversation_pin ? <PinIcon /> : <></>}
                      {conversation.is_mute ? <MuteIcon /> : <></>}
                    </div>
                    <div className="items-center justify-end hidden gap-1 right-2 group-hover:flex">
                      {conversation.type === TYPE_CONVERSATION.TWO_USER && (
                        <ProfileIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            if (conversation.contact_id) {
                              setContactId(conversation.contact_id);
                            }
                          }}
                        />
                      )}
                      {conversation.is_hide !== CONVERSATION_HIDE.HIDE && (
                        <>
                          <PinIcon
                            isShow={!!conversation.conversation_pin}
                            onClick={(event) => {
                              event.stopPropagation();
                              handlePinOrMute(ACTION_CONVERSATION.PIN, conversation);
                            }}
                          />
                          <MuteIcon
                            isShow={!!conversation.is_mute}
                            onClick={(event) => {
                              event.stopPropagation();
                              handlePinOrMute(ACTION_CONVERSATION.MUTE, conversation);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {conversations.length - 1 === index && activeTab !== TYPE_SEARCH_MAIN.ALL && <div className="p-1" ref={ref}></div>}
              </Fragment>
            ))}
            <div className="flex justify-center">{isLoading && <Spinner className="w-10 h-10" />}</div>
          </div>
        </>
      )}
      {conversations.length === 0 && activeTab !== TYPE_SEARCH_MAIN.ALL && (
        <p className={`text-center ${activeTab === TYPE_SEARCH_MAIN.CONTACT ? "pt-[30px]" : ""}`}>{t("alert.no_data")}</p>
      )}
      {conversations.length > 0 && (
        <>
          {activeTab === TYPE_SEARCH_MAIN.ALL && (
            <div className="px-6">
              <div
                onClick={() => handleSetActiveTab && handleSetActiveTab(TYPE_SEARCH_MAIN.CONTACT)}
                className="cursor-pointer w-full h-8 bg-neutral-20
                    text-primary-900 flex justify-center items-center rounded-md my-2 font-normal text-sm"
              >
                {t("search_main.see_more")}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ListConversation;
