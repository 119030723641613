import ThemeIcon from "components/icon/setting/ThemeIcon";
import { t } from "i18next";
import { Radio } from "@material-tailwind/react";
import IconLanguageSetting from "components/icon/setting/IconLanguageSetting";

const SettingLanguageAndTheme = () => {
  return (
    <div>
      <div className="theme py-2 border-b-[1px]">
        <div className="header flex gap-2 h-12">
          <ThemeIcon />
          <span className="text-xl font-bold text-neutral-110">{t("setting.language.theme.title")}</span>
        </div>
        <div className="">
          <label htmlFor="lightMode" className="cursor-pointer h-12 flex justify-between w-full items-center ">
            <span className="text-neutral-110">{t("setting.language.theme.light_mode")}</span>
            <Radio
              id="lightMode"
              className="!border-[#592C12]"
              iconProps={{
                className: "before:bg-[#592C12]",
              }}
              crossOrigin={false}
              name="mode"
            />
          </label>
          <label htmlFor="darkmode" className="cursor-pointer h-12 flex justify-between w-full items-center">
            <span className="text-neutral-110">{t("setting.language.theme.dark_mode")}</span>
            <Radio id="darkmode" formTarget="darkmode" className="!border-[#592C12]" crossOrigin={false} name="mode" defaultChecked />
          </label>
        </div>
      </div>

      <div className="language pt-8">
        <div className="header flex gap-2 h-12">
          <IconLanguageSetting />
          <span className="text-xl font-bold text-neutral-110">{t("setting.language.language")}</span>
        </div>
        <div className="">
          <label htmlFor="vietnamese" className="cursor-pointer h-12 flex justify-between w-full items-center ">
            <span className="text-neutral-110">{t("setting.language.vietnamese")}</span>
            <Radio
              id="vietnamese"
              className="!border-[#592C12]"
              iconProps={{
                className: "before:bg-[#592C12]",
              }}
              crossOrigin={false}
              name="language"
            />
          </label>
          <label htmlFor="english" className="cursor-pointer h-12 flex justify-between w-full items-center">
            <span className="text-neutral-110">{t("setting.language.english")}</span>
            <Radio id="english" className="!border-[#592C12]" crossOrigin={false} name="language" defaultChecked />
          </label>
        </div>
      </div>
    </div>
  );
};

export default SettingLanguageAndTheme;
