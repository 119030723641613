import axios, { AxiosRequestConfig } from "axios";
import { STATUS_CODE } from "../constants/statusCode";
import { EnhancedStore } from "@reduxjs/toolkit";
import { checkAccessTokenExpires, redirectCheckLogin } from "utils/common";
import { setLoading, setOauthToken, setTokenInvalid } from "../redux/slices/auth";
import { sendRequestAuthorization } from "http/user.http";
import { LOGIN_GRANT_TYPE_CODE } from "types/auth.type";
let store: EnhancedStore;
export const injectStore = (_store: EnhancedStore) => {
  store = _store;
};
let refreshTokenPromise = null;
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Methods": "HEAD, GET, POST, PUT, DELETE",
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "max-age=0",
  },
});

axiosClient.interceptors.request.use(async (config: any) => {
  const state = store.getState();

  const oauthToken = state?.auth?.oauthToken;
  if (oauthToken) {
    // check token expires 
    const tokenExpires = checkAccessTokenExpires(oauthToken?.expires_in);

    // if token don't expires
    if (!tokenExpires && oauthToken?.access_token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${oauthToken.access_token}`,
      };
    } else if (tokenExpires) {
      store.dispatch(setLoading(true));
      // if token expires
      refreshTokenPromise ??= sendRequestAuthorization({
        grant_type: LOGIN_GRANT_TYPE_CODE.REFRESH_TOKEN,
        refresh_token: state.auth.oauthToken?.refresh_token,
      });
      const response = await refreshTokenPromise;
      refreshTokenPromise = null;
      store.dispatch(setLoading(false));
      // if get new access_token success
      if (response?.access_token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${response?.access_token || ""}`,
        };
        store.dispatch(setOauthToken({
          ...response,
          expires_in: Date.now() + (response?.expires_in * 1000),
        }));
      } else if (!response) {
        // if valid get assetToken redirect login
        store.dispatch(setTokenInvalid());
        redirectCheckLogin();
        // cancel request
        throw new axios.Cancel("The operation was canceled due to token expiration");
      }
    }
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === STATUS_CODE.Unauthorized) {
      sessionStorage.clear();
      store.dispatch(setTokenInvalid());
      redirectCheckLogin();
      console.log("Unauthorized");
    }

    if (error.response && error.response.status === STATUS_CODE.Forbidden) {
      console.log("Forbidden");
    }

    if (error.response && error.response.status === STATUS_CODE.BadRequest) {
      console.log("Bad request");
    }

    return Promise.reject(error);
  },
);

export const getAxios = async <T>(path: string, option?: AxiosRequestConfig<any>) => {
  const response = await axiosClient.get<T>(path, option);
  return response.data;
};

export const postAxios = async <T>(path: string, data: any = {}, config?: AxiosRequestConfig<any> | undefined) => {
  const response = await axiosClient.post<T>(path, data, {
    ...config,
  });
  return response.data;
};

export const putAxios = async <T>(path: string, option: any = {}) => {
  const response = await axiosClient.put<T>(path, option);
  return response.data;
};

export const deleteAxios = async <T>(path: string, option: any = {}) => {
  const response = await axiosClient.delete<T>(path, option);
  return response.data;
};

export default axiosClient;
