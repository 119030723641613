/* eslint-disable max-len */
const AttachFileIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C9.929 21 8.25 19.3207 8.25 17.25V5.2485C8.25 4.65225 8.487 4.08 8.9085 3.6585C9.33 3.237 9.902 3 10.4985 3H10.5015C11.7435 3 12.75 4.0065 12.75 5.2485V15.7515C12.75 16.1648 12.415 16.5 12.0015 16.5H12C11.586 16.5 11.25 16.164 11.25 15.75V6.75C11.25 6.336 10.914 6 10.5 6C10.086 6 9.75 6.336 9.75 6.75V15.75C9.75 16.9928 10.7575 18 12 18H12.0015C13.2435 18 14.25 16.9935 14.25 15.7515V5.2485C14.25 3.1785 12.5715 1.5 10.5015 1.5H10.4985C9.5045 1.5 8.551 1.89525 7.848 2.598C7.145 3.30075 6.75 4.254 6.75 5.2485V17.25C6.75 20.1495 9.1005 22.5 12 22.5C13.3925 22.5 14.7275 21.9473 15.7125 20.9625C16.697 19.9778 17.25 18.6427 17.25 17.25C17.25 12.8317 17.25 6.75 17.25 6.75C17.25 6.336 16.914 6 16.5 6C16.086 6 15.75 6.336 15.75 6.75V17.25C15.75 18.2445 15.355 19.1985 14.6515 19.902C13.9485 20.6047 12.9945 21 12 21Z"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default AttachFileIcon;
