import { TYPE_SEARCH_MAIN } from "constants/constans";
import SearchResultContactModal from "./SearchResultContactModal";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import SearchResultMessage from "./SearchResultMessage";
import { Spinner } from "@material-tailwind/react";
import { InfiniteData } from "@tanstack/react-query";
import { ResponseTypeCommon } from "types/utils.type";
import { MESSAGE, TYPE_MESSAGE_FILE } from "types/message.type";
import SearchResultFile from "./SearchResultFile";
interface SearchResultAllProps {
  keywordSearch: string;
  isActiveSearch: string;
  isLoadingMessage: boolean;
  isLoadingFile: boolean;
  messages: InfiniteData<ResponseTypeCommon<MESSAGE>, unknown> | undefined;
  files: InfiniteData<ResponseTypeCommon<TYPE_MESSAGE_FILE>, unknown> | undefined;
  setOpenModalSearch: Dispatch<SetStateAction<boolean>>;
  setContactId: Dispatch<SetStateAction<number | undefined>>;
  handleSetActiveTab: (value: TYPE_SEARCH_MAIN) => void;
  redirectToMessage: (conversationId: number | undefined, messageId: number, reply_id: number | string | null) => void;
}
const SearchResultAll = ({
  handleSetActiveTab,
  isActiveSearch,
  keywordSearch,
  isLoadingMessage,
  isLoadingFile,
  messages,
  files,
  setContactId,
  setOpenModalSearch,
  redirectToMessage,
}: SearchResultAllProps) => {
  const [totalRecordContact, setTotalRecordContact] = useState(0);

  const setDataRecord = (data: number) => {
    setTotalRecordContact(data);
  };

  const isNoData = totalRecordContact === 0 && !messages && !isLoadingMessage && !isLoadingFile && !files;
  return (
    <>
      <SearchResultContactModal
        isActiveSearch={isActiveSearch}
        activeTab={TYPE_SEARCH_MAIN.ALL}
        handleSetActiveTab={handleSetActiveTab}
        keywordSearch={keywordSearch}
        setDataRecord={setDataRecord}
        setOpenModalSearch={setOpenModalSearch}
        setContactId={setContactId}
      />
      {isLoadingMessage ? (
        <Spinner className="w-10 h-10 mx-auto mt-2" />
      ) : (
        <SearchResultMessage
          isActiveSearch={isActiveSearch}
          redirectToMessage={redirectToMessage}
          activeTab={TYPE_SEARCH_MAIN.ALL}
          keywordSearch={keywordSearch}
          messages={messages}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}

      {isLoadingFile && !isLoadingMessage ? (
        <Spinner className="w-10 h-10 mx-auto mt-2" />
      ) : (
        <SearchResultFile
          files={files}
          isActiveSearch={isActiveSearch}
          redirectToMessage={redirectToMessage}
          handleSetActiveTab={handleSetActiveTab}
          activeTab={TYPE_SEARCH_MAIN.ALL}
          keywordSearch={keywordSearch}
        />
      )}
      {isNoData && <p className="text-center mt-[30px]">{t("alert.no_data")}</p>}
    </>
  );
};

export default SearchResultAll;
