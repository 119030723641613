import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Navigate from "../../components/navigate/Navigate";
import LoadingPage from "pages/common/LoadingPage";
import Notification from "components/notification/Notification";

const DefaultLayout = () => {
  return (
    <>
      <Notification/>
      <div className="h-screen max-h-screen">
        <div className="flex flex-col h-full">
          <section className="flex flex-1 bg-primary-200 h-5/6 flex-col">
            <div className="h-12">
              <Navigate />
            </div>
            <Suspense fallback={<LoadingPage />}>
              <Outlet />
            </Suspense>
          </section>
        </div>
      </div>
    </>
  );
};
export default DefaultLayout;
