/* eslint-disable max-len */
interface TrashIconProp {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const TrashBlackIcon = ({ onClick }: TrashIconProp) => {
  return (
    <div onClick={onClick}>
      <svg width="24" className="cursor-pointer" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.9 4.30011H2.1C1.80826 4.30011 1.52847 4.416 1.32218 4.62229C1.11589 4.82858 1 5.10837 1 5.40011C1 5.69185 1.11589 5.97164 1.32218 6.17793C1.52847 6.38422 1.80826 6.50011 2.1 6.50011H4.3V19.7001C4.30026 20.5752 4.64802 21.4145 5.26684 22.0333C5.88565 22.6521 6.72487 22.9998 7.6 23.0001H16.4C17.2751 22.9999 18.1144 22.6521 18.7332 22.0333C19.352 21.4145 19.6998 20.5753 19.7 19.7001V6.50011H21.9C22.1917 6.50011 22.4715 6.38422 22.6778 6.17793C22.8841 5.97164 23 5.69185 23 5.40011C23 5.10837 22.8841 4.82858 22.6778 4.62229C22.4715 4.416 22.1917 4.30011 21.9 4.30011ZM10.9 16.4001C10.9 16.6918 10.7841 16.9716 10.5778 17.1779C10.3715 17.3842 10.0917 17.5001 9.8 17.5001C9.50826 17.5001 9.22847 17.3842 9.02218 17.1779C8.81589 16.9716 8.7 16.6918 8.7 16.4001V10.9001C8.7 10.6084 8.81589 10.3286 9.02218 10.1223C9.22847 9.916 9.50826 9.80011 9.8 9.80011C10.0917 9.80011 10.3715 9.916 10.5778 10.1223C10.7841 10.3286 10.9 10.6084 10.9 10.9001V16.4001ZM15.3 16.4001C15.3 16.6918 15.1841 16.9716 14.9778 17.1779C14.7715 17.3842 14.4917 17.5001 14.2 17.5001C13.9083 17.5001 13.6285 17.3842 13.4222 17.1779C13.2159 16.9716 13.1 16.6918 13.1 16.4001V10.9001C13.1 10.6084 13.2159 10.3286 13.4222 10.1223C13.6285 9.916 13.9083 9.80011 14.2 9.80011C14.4917 9.80011 14.7715 9.916 14.9778 10.1223C15.1841 10.3286 15.3 10.6084 15.3 10.9001V16.4001Z"
          fill="#592C12"
        />
        <path
          d="M9.80117 3.2H14.2012C14.4929 3.2 14.7727 3.08411 14.979 2.87782C15.1853 2.67153 15.3012 2.39174 15.3012 2.1C15.3012 1.80826 15.1853 1.52847 14.979 1.32218C14.7727 1.11589 14.4929 1 14.2012 1H9.80117C9.50943 1 9.22964 1.11589 9.02335 1.32218C8.81706 1.52847 8.70117 1.80826 8.70117 2.1C8.70117 2.39174 8.81706 2.67153 9.02335 2.87782C9.22964 3.08411 9.50943 3.2 9.80117 3.2Z"
          fill="#592C12"
        />
      </svg>
    </div>
  );
};

export default TrashBlackIcon;
