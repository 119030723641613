const CongratReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.813477 32L5.5583 30.3449L1.9721 28.6897L0.813477 32Z" fill="#F7B600" />
      <path d="M14.5513 18.4276L7.87542 11.6965L6.77197 14.7862L14.5513 18.4276Z" fill="#FFDD7D" />
      <path d="M6.77239 14.7863L5.55859 18.2621L20.3448 25.1587L21.062 24.938L14.5517 18.4276L6.77239 14.7863Z" fill="#F7B600" />
      <path d="M4.39941 21.7379L15.4339 26.869L20.3442 25.1586L5.55804 18.2621L4.39941 21.7379Z" fill="#FFDD7D" />
      <path d="M3.18604 25.2138L10.4688 28.6345L15.4343 26.8689L4.39983 21.7379L3.18604 25.2138Z" fill="#F7B600" />
      <path d="M3.18596 25.2137L1.97217 28.6896L5.55838 30.3448L10.4687 28.6344L3.18596 25.2137Z" fill="#FFDD7D" />
      <path
        d="M17.31 15.5586C21.0065 19.2 22.9376 23.2828 21.6134 24.6069C20.2341 25.931 16.1514 24.0552 12.3996 20.3586C8.70308 16.7172
         6.77205 12.6345 8.09619 11.3103C9.4755 9.98621 13.5583 11.9172 17.31 15.5586Z"
        fill="#493816"
      />
      <path
        d="M12.6756 6.34483C11.7929 5.07586 12.7308 4.52414 13.9446 4.74483C12.786 3.36552 13.5032 
        2.37241 15.3239 2.75862C15.8756 2.86896 15.1032 3.80689 14.6066 3.80689C16.0963 4.91034 15.2687 
        6.12414 13.6687 5.84827C15.1032 7.77931 12.6756 7.28276 11.5722 7.3931C11.2963 8.82758 12.9515 
        10.4828 12.3997 10.4828C11.186 10.4828 9.19975 5.90345 12.6756 6.34483Z"
        fill="#42ADE2"
      />
      <path
        d="M24.2617 8.99303C23.4341 9.37924 21.1169 5.73786 23.9858 5.68269C22.3307 4.19303 22.5514 3.47579
         24.7583 3.42062C22.2203 0.882689 26.2479 -7.00355e-05 26.6341 1.32407C26.7445 1.71027 25.4203 0.993033
          24.9789 1.71027C24.4824 2.53786 28.0686 4.68958 24.372 4.52407C25.7514 5.90338 25.8065 6.56545 23.6548 
          6.78614C23.9307 7.22752 24.8134 8.77234 24.2617 8.99303Z"
        fill="#FF8736"
      />
      <path
        d="M25.1997 17.6L26.0273 16.8828C26.0273 16.8828 26.7997 18.0414 27.3514 18.4828C27.7928 16.4966 27.6825
         15.338 29.9445 16.6621C28.6756 13.2414 30.7721 14.5104 32.8135 15.4483C32.7032 14.5655 32.8135 14.6759 
         33.6963 14.4C34.4687 17.3242 32.3721 16.4414 30.7169 15.5035C31.7101 18.1517 30.6618 17.9862 28.5652 
         17.1035C28.5101 18.2069 28.179 19.4759 27.5169 19.5862C26.7445 19.8069 25.1997 17.6 25.1997 17.6Z"
        fill="#ED4C5C"
      />
      <path
        d="M19.0206 9.43448C18.0275 10.7586 16.4275 11.4759 15.2689 12.6345C14.0551 13.8483 13.3379 17.1586 13.3379
         17.1586C13.3379 17.1586 13.7793 13.6828 14.9379 12.3586C15.9862 11.1448 17.531 10.2621 18.3586 8.88276C19.7931
          6.34483 18.4689 3.03448 16.5931 1.10345C16.9793 0.772414 17.531 0.331035 17.8069 0C19.6275 2.26207 21.1724 
          6.62069 19.0206 9.43448Z"
        fill="#C28FEF"
      />
      <path
        d="M20.7305 12.2483C19.296 13.2966 18.2478 14.8414 17.2547 16.2759C16.3719 17.5449 13.5581 19.1449 13.5581 
        19.1449C13.5581 19.1449 16.2064 17.3242 17.034 16.0001C18.0271 14.3449 19.0202 12.6345 20.565 11.4207C23.6547 
        9.04833 28.1236 9.26902 31.5995 10.538C31.3788 11.0345 30.9926 12.0828 30.9926 12.0828C30.9926 12.0828 23.6547
         10.0966 20.7305 12.2483Z"
        fill="#FF8736"
      />
      <path
        d="M26.8548 11.9725C25.9168 13.1863 25.4754 14.6759 24.7582 16.0552C24.0961 17.3242 23.2134 18.538 21.9444 
        19.2001C20.5099 19.9173 17.3651 19.6966 17.3651 19.6966C17.3651 19.6966 20.5099 19.6414 21.8341 18.7587C23.1582
         17.8759 23.8754 16.3311 24.372 14.8966C25.3651 12.138 26.5789 9.15867 29.4479 7.94488C29.6134 8.44143 29.9996
          9.48971 29.9996 9.48971C29.9996 9.48971 28.3996 9.93109 26.8548 11.9725ZM1.48096 6.24833L3.04123 4.6875L4.60206 
          6.24778L3.04178 7.80915L1.48096 6.24833Z"
        fill="#42ADE2"
      />
      <path d="M3.66748 11.1432L5.2272 9.58289L6.78748 11.1432L5.2272 12.7034L3.66748 11.1432Z" fill="#FF8736" />
      <path d="M7.63184 3.76377L9.19211 2.20349L10.7524 3.76377L9.19211 5.32404L7.63184 3.76377Z" fill="#ED4C5C" />
      <path d="M24.6509 22.4529L26.2112 20.8921L27.7714 22.4529L26.2112 24.0132L24.6509 22.4529Z" fill="#C28FEF" />
      <path d="M21.1738 27.8014L22.7341 26.2411L24.2949 27.8019L22.7341 29.3622L21.1738 27.8014Z" fill="#ED4C5C" />
      <path d="M28.002 29.0246L29.5622 27.4637L31.1225 29.0246L29.5622 30.5848L28.002 29.0246Z" fill="#FF8736" />
      <path
        d="M29.5462 21.9204L31.1064 20.3602L32.6667 21.9204L31.1064 23.4813L29.5462 21.9204ZM26.9409 5.41947L28.5012 
        3.85864L30.062 5.41947L28.5017 6.97975L26.9409 5.41947Z"
        fill="#42ADE2"
      />
      <path d="M10.2168 14.7818L11.7776 13.2216L13.3379 14.7818L11.7776 16.3427L10.2168 14.7818Z" fill="#ED4C5C" />
    </svg>
  );
};

export default CongratReaction;
