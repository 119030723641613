const LeaveGroupChatIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4610_36741)">
        <path
          d="M11.9602 21.9668H2.99002C2.43984 21.9668 1.99336 21.5203 1.99336 20.9702V3.02992C1.99336 
          2.47975 2.43989 2.03326 2.99002 2.03326H11.9602C12.5113 2.03326 12.9568 1.58776 12.9568 1.03661C12.9568 
          0.48545 12.5113 0.039856 11.9602 0.039856H2.99002C1.34152 0.039856 0 1.38142 0 3.02992V20.9701C0 22.6186
           1.34152 23.9601 2.99002 23.9601H11.9602C12.5113 23.9601 12.9568 23.5146 12.9568 22.9635C12.9568 22.4123 
           12.5113 21.9668 11.9602 21.9668Z"
          fill="#F21326"
        />
        <path
          d="M23.703 11.2903L17.6431 5.31025C17.2524 4.92353 16.6206 4.92855 16.2338 5.32024C15.8471 5.71192 15.8511 
          6.34281 16.2438 6.72953L20.5744 11.0033H8.97004C8.41889 11.0033 7.97339 11.4488 7.97339 11.9999C7.97339 12.5511
           8.41889 12.9966 8.97004 12.9966H20.5744L16.2438 17.2704C15.8512 17.6571 15.8482 18.288 16.2338 18.6797C16.4292 
           18.877 16.6863 18.9767 16.9435 18.9767C17.1967 18.9767 17.4498 18.881 17.6431 18.6896L23.703 12.7095C23.8923 
           12.5222 24 12.267 24 11.9999C24 11.7329 23.8933 11.4787 23.703 11.2903Z"
          fill="#F21326"
        />
      </g>
    </svg>
  );
};

export default LeaveGroupChatIcon;
