import { useQueryClient } from "@tanstack/react-query";
import AvatarCatchError from "components/image/AvatarCatchError";
import { QUERY_KEY } from "constants/pathApi";
import { useCustomSelector } from "hook/redux";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { MEMBER, MEMBER_IN_ALL } from "types/message.type";
import { ResponseTypeCommon } from "types/utils.type";

interface MemberPropsType {
  className?: string;
  member_id: number;
  member_name: string;
}
const Member = ({ className = "text-neutral-10", member_id, member_name }: MemberPropsType) => {
  const [member, setMember] = useState<MEMBER_IN_ALL | null>(null);

  // get conversation selected
  const { conversationSelected } = useCustomSelector((state) => state.conversation);

  // get list member in cache
  const queryClient = useQueryClient();
  const listMemberInConversation = queryClient.getQueryData<ResponseTypeCommon<MEMBER>>([
    QUERY_KEY.LIST_MEMBER_CONVERSATION,
    conversationSelected?.conversation_id,
  ]);
  // find member in conversation
  useEffect(() => {
    if (conversationSelected?.conversation_id && member_id) {
      const memberInConversation = listMemberInConversation?.data?.find((member) => +member.id === +member_id);
      if (memberInConversation) {
        if (memberInConversation) {
          setMember({
            ...memberInConversation,
            full_name: memberInConversation.name,
            email: "",
          });
        }
      } else {
        setMember({
          id: member_id,
          avatar: "",
          email: "",
          full_name: member_name,
        });
      }
    }
  }, [conversationSelected?.conversation_id, listMemberInConversation]);

  if (isEmpty(member))
    return (
      <div className={`flex items-center gap-1 ${className}`}>
        <AvatarCatchError src={""} className="w-4 h-4" />
      </div>
    );
  return (
    <div className={`flex items-center gap-1 ${className}`}>
      <AvatarCatchError src={member?.avatar} className="w-4 h-4" />
      <p className="text-sm font-bold max-w-[500px] line-clamp-1">{member?.full_name}</p>
    </div>
  );
};

export default Member;
