/* eslint-disable max-len */
interface UploadIconProp {
  onClick?: () => void;
}
const UploadIcon = ({ onClick = () => {} }: UploadIconProp) => {
  return (
    <svg onClick={onClick} className="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0005 15.3332V19.7777C22.0005 20.3671 21.7664 20.9324 21.3496 21.3491C20.9329 21.7659 20.3676 22 19.7783 22H4.22228C3.6329 22 3.06765 21.7659 2.65089 21.3491C2.23413 20.9324 2 20.3671 2 19.7777V15.3332"
        stroke="#592C12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.5567 7.55571L12.001 2L6.44531 7.55571" stroke="#592C12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 2V15.3337" stroke="#592C12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default UploadIcon;
