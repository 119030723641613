import { TYPE_SCREEN_CHAT } from "constants/constans";
import { removeFileExtension, shortenFileName } from "utils/common";
import { getPathFileOther } from "logic/message.logic";
import { ContentFileType } from "types/message.type";
interface MessageFileOtherPropType {
  file: ContentFileType;
  isPinMessage?: boolean;
  sidebarPinMessage?: boolean;
  typeScreen: TYPE_SCREEN_CHAT;
  handleOpenPreview?: (file: ContentFileType, listFile?:ContentFileType[]) => void;
}

const MessageFileOther = ({
  file,
  isPinMessage,
  sidebarPinMessage,
  typeScreen,
  handleOpenPreview = () => {},
}: MessageFileOtherPropType) => {
  if (isPinMessage) {
    return (
      <div className="flex items-center mt-1 gap-x-1">
        <img className="object-cover w-6 h-6" src={getPathFileOther(file?.name)} alt="file img" />
        <p className="max-w-md text-sm text-neutral-110 line-clamp-1" title={file?.name}>
          {file?.name}
        </p>
      </div>
    );
  }
  return (
    <div
      onClick={() => handleOpenPreview(file)}
      className={`flex items-center gap-2 px-3 py-1 bg-white border rounded-lg cursor-pointer border-neutral-30 ${
        typeScreen === TYPE_SCREEN_CHAT.MAIN ? "w-[295px]" : "w-[270px]"
      }`}
    >
      <img className="object-cover w-8 h-8" src={getPathFileOther(file?.name)} alt="file img" />
      <div className={`flex flex-col items-start justify-center ${sidebarPinMessage && "w-52"}`}>
        <div className="flex">
          <span
            className={`overflow-hidden text-sm ${
              typeScreen === TYPE_SCREEN_CHAT.MAIN ? "max-w-48" : "max-w-40"
            } file_name text-neutral-110 text-ellipsis whitespace-nowrap`}
          >
            {removeFileExtension(file.name)}
          </span>
          <span className="h-full text-sm text-neutral-110">{shortenFileName(file.name)}</span>
        </div>
        <p className="text-xs text-neutral-60">{file?.size}</p>
      </div>
    </div>
  );
};

export default MessageFileOther;
