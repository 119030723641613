import { DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CLoseIcon from "components/icon/CLoseIcon";
import { QUERY_KEY } from "constants/pathApi";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { deleteConversation } from "http/conversation.http";
import { Dispatch, SetStateAction, useEffect } from "react";
import { toast } from "react-toastify";
import { prevSideBar } from "../../../redux/slices/sidebar";
import { ErrorResponse, SuccessResponse } from "types/utils.type";
import { t } from "i18next";
import { setConversationIdFetch, setConversationSelected } from "../../../redux/slices/conversation";
import { AxiosError } from "axios";
import { STATUS_API, STATUS_CODE } from "constants/statusCode";
import ButtonCommon from "components/button/ButtonCommon";
import CustomModal from "components/Modal/ModalCommon";

// declare props type
interface DialogDeleteContactProp {
  closeModal: () => void;
  setOpenDialogDeleteContact: Dispatch<SetStateAction<boolean>>;
  debouncedSearchContact?: string;
}

const DialogDeleteContactContainer = ({ closeModal, setOpenDialogDeleteContact, debouncedSearchContact }: DialogDeleteContactProp) => {
  const { userContactSelected } = useCustomSelector((state) => state.contact);
  const queryClient = useQueryClient();
  const dispatch = useCustomDispatch();

  // Handle delete conversation
  const { mutate, isPending, status, error } = useMutation({
    mutationFn: deleteConversation<SuccessResponse<null>>,
    onError: (error: AxiosError<ErrorResponse>) => {},
  });

  useEffect(() => {
    if (status === STATUS_API.Error) {
      if (error?.response?.data.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(t("exception.server_error"));
      }
    }
  }, [status]);

  const handleDeleteConversation = () => {
    if (userContactSelected && userContactSelected?.conversation_id) {
      mutate(userContactSelected.conversation_id, {
        onSuccess: (response) => {
          queryClient.refetchQueries({ queryKey: [QUERY_KEY.LIST_ALL_CONTACT] });
          toast[response?.code === STATUS_CODE.Ok ? "success" : "error"](response?.message);
          queryClient.refetchQueries({ queryKey: [QUERY_KEY.LIST_CONVERSATION] });
          dispatch(setConversationIdFetch(null));
          dispatch(setConversationSelected(null));
          setOpenDialogDeleteContact(false);
          dispatch(prevSideBar());
        },
      });
    }
  };

  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[592px] !w-full">
        <DialogHeader placeholder={"dialog"} className="flex items-center justify-between">
          <p>{t("contact.dialog.delete_contact.title")}</p>
          <CLoseIcon onClick={closeModal} />
        </DialogHeader>
        <DialogBody placeholder={"dialog"} className="pt-0">
          <p className="text-neutral-110">{t("contact.dialog.delete_contact.description")}</p>
          <hr className="h-[1px] bg-primary-200 mt-3" />
        </DialogBody>
        <DialogFooter placeholder={"dialog"} className="!mt-0 gap-x-4">
          <button className="border-neutral-60 py-[2px]  text-lg capitalize border px-4 text-neutral-110 rounded" onClick={closeModal}>
            {t("action.cancel")}
          </button>
          <ButtonCommon
            title={t("action.delete")}
            isPending={isPending}
            className="!bg-primary-900 py-[2px] px-4 capitalize text-lg  border-[1px] rounded border-primary-900 text-white"
            onClick={handleDeleteConversation}
            disabled={isPending}
          />
        </DialogFooter>
      </CustomModal>
    </>
  );
};

export default DialogDeleteContactContainer;
