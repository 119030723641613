import DialogSetting from "./Setting";
import { SettingSvg } from "components/navigate/svg";
interface NavigateSettingProps {
  openSetting: boolean;
  handleToggleSetting: () => void;
}
const NavigateSetting = ({ openSetting, handleToggleSetting }: NavigateSettingProps) => {
  return (
    <div>
      <div
        onClick={handleToggleSetting}
        className={`cursor-pointer w-14 flex items-center justify-center ${openSetting ? "text-primary-900" : "text-primary-100"}`}
      >
        <SettingSvg />
      </div>
      {openSetting && <DialogSetting openSetting={openSetting} closeModal={handleToggleSetting} />}
    </div>
  );
};

export default NavigateSetting;
