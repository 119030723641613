/* eslint-disable max-len */
const NameBeechat = () => {
  return (
    <svg width="78" height="17" viewBox="0 0 78 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M77.8355 5.57279V7.04522H71.7695V5.57279H77.8355ZM73.8226 2.8457H75.7409V14.0133C75.7409 14.3935 75.7997 14.6804 75.9172 14.8739C76.0347 15.0675 76.1868 15.1954 76.3734 15.2576C76.5601 15.3198 76.7606 15.3509 76.9749 15.3509C77.1338 15.3509 77.2998 15.3371 77.4726 15.3094C77.6523 15.2749 77.7871 15.2472 77.877 15.2265L77.8873 16.7922C77.7353 16.8406 77.5348 16.8856 77.2859 16.927C77.044 16.9754 76.7502 16.9996 76.4045 16.9996C75.9345 16.9996 75.5024 16.9063 75.1084 16.7196C74.7144 16.533 74.3998 16.2219 74.1648 15.7864C73.9367 15.344 73.8226 14.7495 73.8226 14.0029V2.8457Z"
        fill="#592C12"
      />
      <path
        d="M68.2025 14.8747V9.09911C68.2025 8.65669 68.1126 8.27303 67.9329 7.94813C67.7601 7.61632 67.4974 7.36055 67.1448 7.18082C66.7923 7.00108 66.3568 6.91122 65.8383 6.91122C65.3544 6.91122 64.9293 6.99417 64.5629 7.16008C64.2034 7.32598 63.92 7.54374 63.7126 7.81333C63.5121 8.08293 63.4119 8.37327 63.4119 8.68434H61.4936C61.4936 8.2834 61.5973 7.88592 61.8047 7.49189C62.0121 7.09786 62.3093 6.74185 62.6964 6.42387C63.0905 6.09897 63.5605 5.84319 64.1066 5.65655C64.6597 5.46299 65.2749 5.36621 65.9524 5.36621C66.7681 5.36621 67.487 5.50447 68.1091 5.78098C68.7382 6.05749 69.229 6.47571 69.5816 7.03565C69.941 7.58867 70.1208 8.2834 70.1208 9.11985V14.3459C70.1208 14.7192 70.1519 15.1167 70.2141 15.5384C70.2832 15.96 70.3835 16.323 70.5148 16.6271V16.793H68.5135C68.4168 16.5718 68.3407 16.278 68.2854 15.9117C68.2301 15.5384 68.2025 15.1927 68.2025 14.8747ZM68.5343 9.99086L68.555 11.3389H66.616C66.0699 11.3389 65.5825 11.3838 65.1539 11.4737C64.7253 11.5566 64.3659 11.6845 64.0755 11.8573C63.7852 12.0301 63.564 12.2479 63.4119 12.5106C63.2598 12.7663 63.1838 13.067 63.1838 13.4127C63.1838 13.7652 63.2633 14.0867 63.4223 14.377C63.5813 14.6674 63.8198 14.8989 64.1378 15.0718C64.4627 15.2377 64.8601 15.3206 65.3302 15.3206C65.9178 15.3206 66.4363 15.1962 66.8856 14.9473C67.3349 14.6985 67.6909 14.3943 67.9536 14.0348C68.2232 13.6754 68.3684 13.3263 68.3891 12.9875L69.2083 13.9104C69.1599 14.2007 69.0285 14.5222 68.8143 14.8747C68.6 15.2273 68.3131 15.566 67.9536 15.8909C67.6011 16.2089 67.1794 16.475 66.6886 16.6893C66.2047 16.8967 65.6586 17.0004 65.0502 17.0004C64.2898 17.0004 63.6228 16.8518 63.049 16.5545C62.4821 16.2573 62.0397 15.8598 61.7217 15.3621C61.4107 14.8575 61.2551 14.2941 61.2551 13.6719C61.2551 13.0705 61.3726 12.5417 61.6077 12.0854C61.8427 11.6223 62.1814 11.2386 62.6239 10.9345C63.0663 10.6234 63.5986 10.3883 64.2207 10.2294C64.8429 10.0704 65.5376 9.99086 66.3049 9.99086H68.5343Z"
        fill="#592C12"
      />
      <path
        d="M51.8089 0.865234V16.7923H49.8906V0.865234H51.8089ZM51.3527 10.7574L50.5543 10.7263C50.5612 9.959 50.6752 9.25044 50.8964 8.60063C51.1176 7.94392 51.4287 7.37362 51.8297 6.88972C52.2306 6.40583 52.7076 6.03254 53.2606 5.76985C53.8205 5.50025 54.4392 5.36545 55.1167 5.36545C55.6697 5.36545 56.1674 5.44149 56.6098 5.59358C57.0523 5.73874 57.429 5.97378 57.7401 6.29868C58.0581 6.62358 58.3 7.04526 58.4659 7.56372C58.6318 8.07526 58.7148 8.70087 58.7148 9.44054V16.7923H56.7861V9.4198C56.7861 8.83221 56.6997 8.36214 56.5269 8.00959C56.3541 7.65013 56.1018 7.3909 55.7699 7.2319C55.4381 7.066 55.0303 6.98304 54.5464 6.98304C54.0694 6.98304 53.6339 7.08328 53.2399 7.28375C52.8528 7.48422 52.5175 7.76073 52.2341 8.11328C51.9575 8.46584 51.7398 8.87023 51.5808 9.32648C51.4287 9.77581 51.3527 10.2528 51.3527 10.7574Z"
        fill="#592C12"
      />
      <path
        d="M45.4629 11.9913H47.4538C47.3501 12.9452 47.0771 13.799 46.6346 14.5525C46.1922 15.306 45.5666 15.9039 44.7578 16.3463C43.949 16.7818 42.9398 16.9996 41.73 16.9996C40.8452 16.9996 40.0398 16.8337 39.314 16.5019C38.5951 16.17 37.9764 15.7 37.4579 15.0917C36.9395 14.4764 36.5385 13.7402 36.2551 12.883C35.9786 12.0189 35.8403 11.058 35.8403 10.0004V8.49686C35.8403 7.43921 35.9786 6.48179 36.2551 5.6246C36.5385 4.76051 36.9429 4.02084 37.4683 3.4056C38.0006 2.79036 38.64 2.31684 39.3866 1.98502C40.1332 1.65321 40.9731 1.4873 41.9063 1.4873C43.0469 1.4873 44.0112 1.7016 44.7993 2.13019C45.5874 2.55879 46.1991 3.15328 46.6346 3.91369C47.0771 4.66718 47.3501 5.54165 47.4538 6.53709H45.4629C45.3661 5.83199 45.1864 5.22712 44.9237 4.72249C44.661 4.21094 44.2877 3.81691 43.8039 3.5404C43.32 3.26389 42.6874 3.12563 41.9063 3.12563C41.2358 3.12563 40.6447 3.25352 40.1332 3.50929C39.6285 3.76507 39.2034 4.12799 38.8578 4.59806C38.519 5.06812 38.2633 5.63152 38.0904 6.28823C37.9176 6.94494 37.8312 7.67424 37.8312 8.47612V10.0004C37.8312 10.7401 37.9073 11.4348 38.0593 12.0846C38.2183 12.7344 38.4568 13.3047 38.7748 13.7955C39.0928 14.2863 39.4972 14.6734 39.988 14.9569C40.4788 15.2334 41.0595 15.3716 41.73 15.3716C42.5803 15.3716 43.2577 15.2368 43.7624 14.9672C44.267 14.6976 44.6472 14.3105 44.903 13.8059C45.1657 13.3012 45.3523 12.6964 45.4629 11.9913Z"
        fill="#592C12"
      />
      <path
        d="M29.4528 17.0004C28.6716 17.0004 27.9631 16.8691 27.3271 16.6064C26.6981 16.3368 26.1554 15.96 25.6992 15.4762C25.2498 14.9923 24.9042 14.4185 24.6622 13.7549C24.4203 13.0912 24.2993 12.3654 24.2993 11.5773V11.1418C24.2993 10.2294 24.4341 9.4171 24.7037 8.70508C24.9733 7.98615 25.3397 7.37783 25.8028 6.88011C26.266 6.38239 26.7914 6.00564 27.379 5.74987C27.9665 5.4941 28.5749 5.36621 29.2039 5.36621C30.0058 5.36621 30.6971 5.50447 31.2778 5.78098C31.8654 6.05749 32.3458 6.4446 32.7191 6.94232C33.0924 7.43313 33.3689 8.01381 33.5486 8.68434C33.7283 9.34797 33.8182 10.0738 33.8182 10.8619V11.7225H25.4399V10.1568H31.8999V10.0116C31.8723 9.51388 31.7686 9.02998 31.5888 8.55991C31.416 8.08985 31.1395 7.70273 30.7593 7.39857C30.3791 7.09441 29.8606 6.94232 29.2039 6.94232C28.7684 6.94232 28.3675 7.03565 28.0011 7.22229C27.6347 7.40202 27.3202 7.67162 27.0575 8.03109C26.7948 8.39055 26.5909 8.82951 26.4457 9.34797C26.3006 9.86643 26.228 10.4644 26.228 11.1418V11.5773C26.228 12.1096 26.3006 12.6108 26.4457 13.0809C26.5978 13.544 26.8156 13.9519 27.099 14.3044C27.3893 14.657 27.7384 14.9335 28.1463 15.134C28.561 15.3344 29.0311 15.4347 29.5565 15.4347C30.2339 15.4347 30.8077 15.2964 31.2778 15.0199C31.7478 14.7434 32.1591 14.3736 32.5117 13.9104L33.673 14.8333C33.4311 15.1996 33.1235 15.5487 32.7502 15.8805C32.3769 16.2124 31.9172 16.482 31.3711 16.6893C30.8319 16.8967 30.1925 17.0004 29.4528 17.0004Z"
        fill="#592C12"
      />
      <path
        d="M18.1921 17.0004C17.4109 17.0004 16.7023 16.8691 16.0664 16.6064C15.4373 16.3368 14.8947 15.96 14.4384 15.4762C13.9891 14.9923 13.6434 14.4185 13.4015 13.7549C13.1595 13.0912 13.0386 12.3654 13.0386 11.5773V11.1418C13.0386 10.2294 13.1734 9.4171 13.443 8.70508C13.7126 7.98615 14.0789 7.37783 14.5421 6.88011C15.0053 6.38239 15.5306 6.00564 16.1182 5.74987C16.7058 5.4941 17.3141 5.36621 17.9432 5.36621C18.7451 5.36621 19.4364 5.50447 20.017 5.78098C20.6046 6.05749 21.085 6.4446 21.4583 6.94232C21.8316 7.43313 22.1081 8.01381 22.2879 8.68434C22.4676 9.34797 22.5575 10.0738 22.5575 10.8619V11.7225H14.1792V10.1568H20.6392V10.0116C20.6115 9.51388 20.5078 9.02998 20.3281 8.55991C20.1553 8.08985 19.8788 7.70273 19.4986 7.39857C19.1184 7.09441 18.5999 6.94232 17.9432 6.94232C17.5077 6.94232 17.1067 7.03565 16.7404 7.22229C16.374 7.40202 16.0595 7.67162 15.7968 8.03109C15.5341 8.39055 15.3302 8.82951 15.185 9.34797C15.0398 9.86643 14.9672 10.4644 14.9672 11.1418V11.5773C14.9672 12.1096 15.0398 12.6108 15.185 13.0809C15.3371 13.544 15.5548 13.9519 15.8382 14.3044C16.1286 14.657 16.4777 14.9335 16.8855 15.134C17.3003 15.3344 17.7704 15.4347 18.2957 15.4347C18.9732 15.4347 19.547 15.2964 20.017 15.0199C20.4871 14.7434 20.8984 14.3736 21.251 13.9104L22.4123 14.8333C22.1704 15.1996 21.8627 15.5487 21.4894 15.8805C21.1162 16.2124 20.6565 16.482 20.1103 16.6893C19.5712 16.8967 18.9317 17.0004 18.1921 17.0004Z"
        fill="#592C12"
      />
      <path
        d="M5.97706 9.73044H2.15083L2.1301 8.12322H5.60377C6.17753 8.12322 6.6787 8.02644 7.1073 7.83288C7.53589 7.63933 7.8677 7.36282 8.10274 7.00335C8.34468 6.63697 8.46566 6.20147 8.46566 5.69684C8.46566 5.14381 8.35851 4.69448 8.14421 4.34884C7.93683 3.99629 7.61539 3.74052 7.17988 3.58152C6.75129 3.41562 6.20518 3.33266 5.54155 3.33266H2.59671V16.7918H0.595459V1.69434H5.54155C6.31578 1.69434 7.00706 1.77383 7.61539 1.93283C8.22371 2.08491 8.73871 2.32686 9.16039 2.65867C9.58898 2.98357 9.91388 3.39834 10.1351 3.90297C10.3563 4.4076 10.4669 5.01247 10.4669 5.71757C10.4669 6.33972 10.3079 6.90312 9.98993 7.40775C9.67194 7.90547 9.22952 8.31332 8.66267 8.63131C8.10274 8.9493 7.44602 9.15322 6.69253 9.24309L5.97706 9.73044ZM5.88373 16.7918H1.36278L2.49302 15.1639H5.88373C6.51971 15.1639 7.05891 15.0533 7.50132 14.8321C7.95066 14.6109 8.29284 14.2998 8.52787 13.8988C8.76291 13.491 8.88042 13.0106 8.88042 12.4575C8.88042 11.8976 8.78019 11.4137 8.57972 11.0058C8.37925 10.598 8.06472 10.2835 7.63612 10.0623C7.20753 9.84105 6.65451 9.73044 5.97706 9.73044H3.12554L3.14627 8.12322H7.04508L7.47022 8.70389C8.19606 8.76611 8.8113 8.97349 9.31593 9.32604C9.82056 9.67168 10.2042 10.1141 10.4669 10.6533C10.7365 11.1925 10.8713 11.787 10.8713 12.4368C10.8713 13.3769 10.6639 14.1719 10.2492 14.8217C9.8413 15.4646 9.26408 15.9554 8.5175 16.2941C7.77092 16.6259 6.893 16.7918 5.88373 16.7918Z"
        fill="#592C12"
      />
    </svg>
  );
};

export default NameBeechat;
