import AvatarCatchError from "components/image/AvatarCatchError";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { Contact } from "types/contact.type";
import ChatIcon from "components/icon/ChatIcon";
import TrashIcon from "components/icon/TrashIcon";
import { nextSideBar } from "../../../redux/slices/sidebar";
import { setContactSelected } from "../../../redux/slices/contact";
import { ACTION_OPEN_SIDEBAR } from "types/sidebar.type";
import { Dispatch, SetStateAction, useState } from "react";
import ChatBlackIcon from "components/icon/ChatBlackIcon";
import TrashBlackIcon from "components/icon/TrashBlackIcon";
import { cloneDeep, isEmpty } from "lodash";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import { getListMessage } from "http/message.http";
import { QUERY_KEY } from "constants/pathApi";
import { useNavigate } from "react-router-dom";
import { CHAT } from "constants/pathUrl";
import { setConversationIdFetch } from "../../../redux/slices/conversation";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Spinner } from "@material-tailwind/react";
import { ResponseTypeCommon } from "types/utils.type";
import { Conversation } from "types/conversation.type";

interface ContactItemProp {
  contact: Contact;
  setOpenDialogDeleteContact: Dispatch<SetStateAction<boolean>>;
}
const ContactItem = ({ contact, setOpenDialogDeleteContact }: ContactItemProp) => {
  const [isHoveredChat, setIsHoveredChat] = useState(false);
  const [isHoveredTrash, setIsHoveredTrash] = useState(false);
  const { sideBarMultiLevel } = useCustomSelector((state) => state.sidebar);
  const { userContactSelected } = useCustomSelector((state) => state.contact);
  const dispatch = useCustomDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // handle open side
  const handleOpenSideBarAllContact = () => {
    if (isEmpty(sideBarMultiLevel)) {
      dispatch(
        nextSideBar({
          type: ACTION_OPEN_SIDEBAR.SIDEBAR_CONTACT,
        }),
      );
    }
    dispatch(setContactSelected({ user_id: contact.user_id, conversation_id: contact.conversation_id }));
  };

  const handleOpenDialogDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setContactSelected({ user_id: contact.user_id, conversation_id: contact.conversation_id }));
    setOpenDialogDeleteContact((prev) => !prev);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: () => getListMessage({ pageParam: null, conversation_id: parseInt(contact.conversation_id) }),
    onSuccess: () => {
      const listConversation = queryClient.getQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_CONVERSATION, 0]);
      if (listConversation) {
        const newData: InfiniteData<ResponseTypeCommon<Conversation>> = cloneDeep(listConversation);
        newData?.pages.forEach((page) => {
          const isTrue = page.data.some((item) => {
            return item.conversation_id === parseInt(contact?.conversation_id);
          });
          if (!isTrue) {
            queryClient.removeQueries({ queryKey: [QUERY_KEY.LIST_CONVERSATION] });
          }
        });
      }
      navigate(CHAT);
    },
    onError: () => {
      toast.error(t("exception.server_error"));
    },
  });

  const handleRedirectConversationChat = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setConversationIdFetch(contact.conversation_id));
    await mutate();
  };

  return (
    <div className="group relative" onClick={handleOpenSideBarAllContact}>
      <div
        className={`${userContactSelected?.user_id === contact.user_id ? " bg-neutral-20" : ""} contact-item-${
          contact.conversation_id
        } flex  border-b mt-5 w-full items-center gap-2 p-3 pr-2 transition-colors cursor-pointer hover:bg-neutral-20`}
      >
        <AvatarCatchError className="w-12 h-12" src={contact?.avatar} />
        <div>
          <h3>{contact.name}</h3>
          <h4 className="text-xs pt-[6px] text-neutral-60">{contact.company_contract}</h4>
        </div>
      </div>
      <div className="hidden right-2 top-3 absolute group-hover:block">
        <div className="flex gap-x-3">
          <div onMouseEnter={() => setIsHoveredChat(true)} onMouseOut={() => setIsHoveredChat(false)}>
            {isHoveredChat && !isPending && (
              <div onClick={handleRedirectConversationChat}>
                <ChatBlackIcon />
              </div>
            )}
            {!isHoveredChat && !isPending && <ChatIcon />}
            {isPending && <Spinner />}
          </div>
          {!isPending && (
            <div onMouseEnter={() => setIsHoveredTrash(true)} onMouseOut={() => setIsHoveredTrash(false)}>
              {isHoveredTrash ? <TrashBlackIcon onClick={handleOpenDialogDelete} /> : <TrashIcon />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
