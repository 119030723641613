/* eslint-disable max-len */
const ContactSvgActive = () => {
  return (
    <div>
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 11C0 10.62 0.307484 10.3125 0.6875 10.3125H2.0625C2.44252 10.3125 2.75 10.62 2.75 11C2.75 11.38 2.44252 11.6875 2.0625 11.6875H0.6875C0.307484 11.6875 0 11.38 0 11ZM0.6875 5.5H2.0625C2.44252 5.5 2.75 5.19252 2.75 4.8125C2.75 4.43248 2.44252 4.125 2.0625 4.125H0.6875C0.307484 4.125 0 4.43248 0 4.8125C0 5.19252 0.307484 5.5 0.6875 5.5ZM19.25 2.0625V19.9375C19.25 21.0748 18.3248 22 17.1875 22H3.4375C2.3002 22 1.375 21.0748 1.375 19.9375V18.5625H2.0625C2.82184 18.5625 3.4375 17.9468 3.4375 17.1875C3.4375 16.4282 2.82184 15.8125 2.0625 15.8125H1.375V12.375H2.0625C2.82184 12.375 3.4375 11.7593 3.4375 11C3.4375 10.2407 2.82184 9.625 2.0625 9.625H1.375V6.1875H2.0625C2.82184 6.1875 3.4375 5.57184 3.4375 4.8125C3.4375 4.05316 2.82184 3.4375 2.0625 3.4375H1.375V2.0625C1.375 0.925203 2.3002 0 3.4375 0H17.1875C18.3248 0 19.25 0.925203 19.25 2.0625ZM8.9375 8.25C8.9375 9.38902 9.86098 10.3125 11 10.3125C12.139 10.3125 13.0625 9.38902 13.0625 8.25C13.0625 7.11098 12.139 6.1875 11 6.1875C9.86098 6.1875 8.9375 7.11098 8.9375 8.25ZM15.125 14.4375C15.125 12.9186 13.8939 11.6875 12.375 11.6875H9.625C8.10614 11.6875 6.875 12.9186 6.875 14.4375V15.125C6.875 15.8843 7.49066 16.5 8.25 16.5H13.75C14.5093 16.5 15.125 15.8843 15.125 15.125V14.4375ZM2.75 17.1875C2.75 16.8075 2.44252 16.5 2.0625 16.5H0.6875C0.307484 16.5 0 16.8075 0 17.1875C0 17.5675 0.307484 17.875 0.6875 17.875H2.0625C2.44252 17.875 2.75 17.5675 2.75 17.1875Z"
          fill="#592C12"
        />
      </svg>
    </div>
  );
};

export default ContactSvgActive;
