/* eslint-disable max-len */
interface MuteV2IconProp {
  onClick?: () => void;
  className?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const MuteV2Icon = ({ onClick = () => {}, className = "" }: MuteV2IconProp) => {
  return (
    <svg width="22" onClick={onClick} height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7534_55176)">
        <path
          d="M17.7347 12.2056C17.352 11.4984 17.1303 10.9321 17.1303 10.1299L17.1304 8.94334C17.1304 7.3154 16.4998 5.77782 15.3548 4.61389C14.3735 3.61634 13.1136 2.99389 11.7514 2.82208V1.66601C11.7514 1.22914 11.3973 0.875 10.9604 0.875C10.5235 0.875 10.1694 1.22914 10.1694 1.66601V2.8237C9.46607 2.91388 8.78927 3.12504 8.14986 3.45454C7.76151 3.65466 7.60892 4.13168 7.80905 4.52003C7.94949 4.7925 8.22615 4.94888 8.51289 4.94888C8.63487 4.94888 8.7587 4.92056 8.87458 4.86084C9.50483 4.53609 10.184 4.36622 10.8935 4.35598H11.0333H11.0429C13.5276 4.39205 15.5483 6.44888 15.5483 8.94334L15.5483 10.1299C15.5483 11.3846 15.9587 12.248 16.3434 12.9587C16.4866 13.2232 16.7587 13.3733 17.0397 13.3733C17.1669 13.3733 17.2959 13.3426 17.4156 13.2778C17.7998 13.0698 17.9427 12.5898 17.7347 12.2056Z"
          fill={`${className}`}
        />
        <path
          d="M20.8933 19.7746L2.22537 1.1067C1.91644 0.797767 1.41557 0.797767 1.10668 1.1067C0.797792 1.41563 0.797753 1.91646 1.10668 2.22535L5.32314 6.44181C4.96972 7.23271 4.79051 8.07407 4.79051 8.94335V10.1299C4.79051 11.1838 4.40794 11.8529 3.92364 12.7001C3.44646 13.5348 2.90565 14.4808 2.63211 15.9403C2.50156 16.637 2.68626 17.3493 3.13888 17.8947C3.59106 18.4395 4.2565 18.7519 4.96446 18.7519H8.60273C8.60273 20.0604 9.66727 21.125 10.9758 21.125C12.2843 21.125 13.3488 20.0604 13.3488 18.7519H16.9718C17.1722 18.7519 17.369 18.7274 17.56 18.6786L19.7747 20.8933C19.9291 21.0478 20.1315 21.125 20.3339 21.125C20.5364 21.125 20.7388 21.0477 20.8933 20.8933C21.2022 20.5844 21.2022 20.0836 20.8933 19.7746ZM10.9758 19.5429C10.5396 19.5429 10.1848 19.1881 10.1848 18.7519H11.7668C11.7668 19.1881 11.412 19.5429 10.9758 19.5429ZM4.96446 17.1699C4.64153 17.1699 4.44476 16.9909 4.35621 16.8843C4.26746 16.7773 4.12741 16.5503 4.18713 16.2317C4.4115 15.0344 4.86171 14.2469 5.29708 13.4853C5.82579 12.5605 6.37254 11.6041 6.37254 10.1299V8.94335C6.37254 8.50814 6.43302 8.08249 6.55281 7.67152L16.0512 17.1699H4.96446Z"
          fill={`${className}`}
        />
      </g>
    </svg>
  );
};

export default MuteV2Icon;
