import { DialogHeader } from "@material-tailwind/react";
import CLoseIcon from "components/icon/CLoseIcon";
import { t } from "i18next";
import DialogMyProfile from "./DialogMyProfile";
import CustomModal from "components/Modal/ModalCommon";

// declare props type
interface DialogMyProfileContainerProp {
  closeModal: () => void;
}

const DialogMyProfileContainer = ({ closeModal }: DialogMyProfileContainerProp) => {
  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[512px] !w-full max-h-[95vh] overflow-hidden flex flex-col">
        <DialogHeader placeholder={"dialog"} className="flex items-center justify-between pb-0">
          <p className="text-black text-[22px]">{t("popup_menu.profile")}</p>
          <CLoseIcon onClick={closeModal} />
        </DialogHeader>
        <DialogMyProfile />
      </CustomModal>
    </>
  );
};

export default DialogMyProfileContainer;
