import { DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import CLoseIcon from "components/icon/CLoseIcon";
import { QUERY_KEY } from "constants/pathApi";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { cloneDeep } from "lodash";
import { Dispatch, SetStateAction, useEffect } from "react";
import { toast } from "react-toastify";
import { prevSideBar } from "../../../redux/slices/sidebar";
import { ContactReceived, TYPE_ACCEPT_OR_REJECT_CONTACT } from "types/contact.type";
import { ErrorResponse, ResponseTypeCommon, SuccessResponse } from "types/utils.type";
import { t } from "i18next";
import { postAcceptOrRejectContact } from "http/contact.http";
import ButtonCommon from "components/button/ButtonCommon";
import { AxiosError } from "axios";
import { STATUS_API, STATUS_CODE } from "constants/statusCode";
import CustomModal from "components/Modal/ModalCommon";

// declare props type
interface DialogDeleteReceivedProp {
  closeModal: () => void;
  setOpenDialogDeleteContact: Dispatch<SetStateAction<boolean>>;
  debouncedSearchContact?: string;
}

const DialogDeleteReceivedContainer = ({ closeModal, setOpenDialogDeleteContact, debouncedSearchContact }: DialogDeleteReceivedProp) => {
  const { userContactSelected } = useCustomSelector((state) => state.contact);
  const queryClient = useQueryClient();
  const dispatch = useCustomDispatch();

  const { mutate, isPending, status, error } = useMutation({
    mutationFn: () =>
      postAcceptOrRejectContact<SuccessResponse<ContactReceived>>(userContactSelected?.contact_id, TYPE_ACCEPT_OR_REJECT_CONTACT.REJECT),
    onSuccess: (response) => {
      if (response) {
        queryClient.setQueryData<InfiniteData<ResponseTypeCommon<ContactReceived>>>(
          [QUERY_KEY.LIST_RECEIVED_CONTACT, debouncedSearchContact ?? ""],
          (oldData) => {
            if (!oldData) return oldData;
            const newData: InfiniteData<ResponseTypeCommon<ContactReceived>> = cloneDeep(oldData);
            newData.pages.forEach((page) => {
              page.data = page.data.filter((contact) => {
                return contact.contact_id !== userContactSelected?.contact_id;
              });
            });
            return newData;
          },
        );
        queryClient.removeQueries({ queryKey: [QUERY_KEY.LIST_RECEIVED_CONTACT] });
        queryClient.refetchQueries({ queryKey: [QUERY_KEY.TOTAL_RECEIVED_CONTACT] });

        toast[response?.code === STATUS_CODE.Ok ? "success" : "error"](response?.message);
        dispatch(prevSideBar());
        setOpenDialogDeleteContact(false);
      }
    },
    onError: (error: AxiosError<ErrorResponse>) => {},
  });

  const handleDeleteReceived = async () => {
    await mutate();
  };

  useEffect(() => {
    if (status === STATUS_API.Error) {
      if (error?.response?.data.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(t("exception.server_error"));
      }
    }
  }, [status]);

  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[592px] !w-full">
        <DialogHeader placeholder={"dialog"} className="flex items-center justify-between">
          <p>{t("contact.dialog.delete_received.title")}</p>
          <CLoseIcon onClick={closeModal} />
        </DialogHeader>
        <DialogBody placeholder={"dialog"} className="pt-0">
          <p className="text-neutral-110">{t("contact.dialog.delete_received.description")}</p>
          <hr className="h-[1px] bg-primary-200 mt-3" />
        </DialogBody>
        <DialogFooter placeholder={"dialog"} className="!mt-0 gap-x-4">
          <button
            className="border-neutral-60 py-[2px]  text-lg capitalize border px-4 text-neutral-110 rounded-[4px]"
            onClick={closeModal}
          >
            {t("action.cancel")}
          </button>
          <ButtonCommon
            onClick={handleDeleteReceived}
            className="border-neutral-60 bg-primary-900 py-[2px]  text-lg capitalize border px-4 text-white rounded-[4px]"
            title={t("action.delete")}
            isPending={isPending}
            disabled={isPending}
          />
        </DialogFooter>
      </CustomModal>
    </>
  );
};

export default DialogDeleteReceivedContainer;
