import { createSlice } from "@reduxjs/toolkit";
import { ContactUserInfo } from "types/user.type";
interface ContactAccepted {
  isAccepted: boolean;
  user_id: number[];
}
interface ContactSent {
  user_id: number;
  isSent: boolean;
}
const initialState: {
  userContactSelected: ContactUserInfo | null;
  contactAccepted: ContactAccepted | null;
  contactSent: ContactSent[] | null;
  isDisableBtn: boolean | undefined;
  scrollId: number | null;
} = {
  userContactSelected: null,
  contactAccepted: null,
  contactSent: null,
  isDisableBtn: undefined,
  scrollId: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContactSelected: (state, action) => {
      state.userContactSelected = action.payload;
    },
    setContactAccepted: (state, action) => {
      state.contactAccepted = action.payload;
    },
    setContactSent: (state, action) => {
      state.contactSent = action.payload;
    },
    setIsDisableBtn: (state, action) => {
      state.isDisableBtn = action.payload;
    },
    setScrollId: (state, action) => {
      state.scrollId = action.payload;
    },
  },
});

export const { setContactSelected, setContactAccepted, setContactSent, setIsDisableBtn, setScrollId } =
  contactSlice.actions;
export default contactSlice.reducer;
