import { NOTIFICATION_TYPE } from "constants/constans";
import { TYPE_STATUS_VALIDATE } from "constants/statusCode";

export interface SuccessResponse<Data> {
  message: string;
  code: number;
  data: Data;
  msg?: string;
  status?: number;
}
export interface ErrorResponse {
  message: string;
  code: number;
}
export interface EventMessageServiceWorker {
  conversation_id?: number;
  id?: number;
  notification_type: NOTIFICATION_TYPE;
  reply_id?: number;
  title?: string;
  avatar?: string;
  content?: string;
  conversation_name?:string;
  contact_id?:string | number;
}
export interface PaginationResponse {
  code: number;
  msg: string;
  current_page: number;
  total_page: number;
  per_page: number;
  total: number;
}

export interface ParamSearchMessage {
  last_record_id?: number;
  keyword: string;
  from: string | Date | null;
  to: string | Date | null;
  typeToFilter: number | undefined;
  senders: number | undefined;
  conversation_id?: number;
}
export enum TYPE_REFETCH{
  PREVIOUS_PAGE = 1,
  NEXT_PAGE,
}
export interface TYPE_PAGE_PARAM{
  page: number
  typeRefetch: TYPE_REFETCH;
}
export interface TypeValidateFile {
  typeError: TYPE_STATUS_VALIDATE;
  file: File;
}
export interface ResponseTypeCommon<K> extends PaginationResponse {
  data: K[];
}

export interface ResponseTypeMessage<K> extends PaginationResponse {
  data: K[];
  unread_message_id: number
}
