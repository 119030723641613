/* eslint-disable max-len */
interface MoreIconProps {
  onClick?: () => void;
  color?: string;
  className?: string;
}
export default function MoreIcon({ color, onClick, className }: MoreIconProps) {
  return (
    <div onClick={onClick} className={`${className} cursor-pointer`}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2969_65814)">
          <path
            d="M12 14.5C13.1046 14.5 14 13.6046 14 12.5C14 11.3954 13.1046 10.5 12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5Z"
            fill={`${color ? color : "#1F1B16"}`}
          />
          <path
            d="M12 7.5C13.1046 7.5 14 6.60457 14 5.5C14 4.39543 13.1046 3.5 12 3.5C10.8954 3.5 10 4.39543 10 5.5C10 6.60457 10.8954 7.5 12 7.5Z"
            fill={`${color ? color : "#1F1B16"}`}
          />
          <path
            d="M12 21.5C13.1046 21.5 14 20.6046 14 19.5C14 18.3954 13.1046 17.5 12 17.5C10.8954 17.5 10 18.3954 10 19.5C10 20.6046 10.8954 21.5 12 21.5Z"
            fill={`${color ? color : "#1F1B16"}`}
          />
        </g>
        <defs>
          <clipPath id="clip0_2969_65814">
            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
