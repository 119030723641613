import { Spinner } from "@material-tailwind/react";
interface LoadingProps{
className?: string
}
const LoadingPage = ({ className }: LoadingProps) => {
  return (
    <div className={`fixed inset-0 z-[999] flex items-center justify-center bg-orange-200 ${className}`}>
      <div className="flex items-center gap-2">
        <Spinner className="w-10 h-10 text-gray-900/50"/> <p>Loading ...</p>
      </div>
    </div>
  );
};

export default LoadingPage;
