const ReplyIcon = () => {
  return (
    <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66667 3.66796V0.833294C6.66667 0.632628 6.546 0.451294 6.36133 0.372628C6.17733
         0.294628 5.962 0.333961 5.81867 0.474628L0.152 5.97463C0.0546667 6.06863 0 6.19796 
         0 6.33329C0 6.46863 0.0546667 6.59796 0.152 6.69196L5.81867 12.192C5.96333 12.332 
         6.178 12.3713 6.36133 12.294C6.546 12.2153 6.66667 12.034 6.66667 11.8333V8.99996H7.612C10.7027 
         8.99996 13.552 10.68 15.0473 13.3813L15.0613 13.4066C15.1507 13.5693 15.32 13.6666 15.5 13.6666C15.5413 
         13.6666 15.5827 13.662 15.624 13.6513C15.8453 13.5946 16 13.3953 16 13.1666C16 7.98396 11.8287 3.75729 6.66667 3.66796Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ReplyIcon;
