import BySenderIcon from "components/icon/file/BySenderIcon";
import PolygonDownIcon from "components/icon/file/PolygonDownIcon";
import { t } from "i18next";
import { SENDER } from "types/contact.type";
import { MEMBER } from "types/message.type";
interface ButtonOpenListMemberProps {
  openListMember: boolean;
  onclick: () => void;
  filterByUser: SENDER | MEMBER | undefined;
}
export default function ButtonFilterByUser({ filterByUser, onclick, openListMember }: ButtonOpenListMemberProps) {
  const isSelected = openListMember || filterByUser;
  return (
    <>
      <div
        onClick={onclick}
        className={`flex-1 by-sender flex  px-4 py-1 border-[1px] 
                   rounded-3xl items-center justify-between cursor-pointer
                  ${isSelected ? "border-primary-900" : "border-neutral-70"}
                  `}
      >
        <div className="flex items-center gap-2 justify-between">
          <BySenderIcon color={isSelected ? "#592C12" : ""} />
          <span className={`${isSelected ? "text-primary-900" : ""}`}>{t("files.by_sender")}</span>
        </div>
        <PolygonDownIcon color={isSelected ? "#592C12" : ""} />
      </div>
    </>
  );
}
