const CaretUp = () => {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.18563 8.77737C1.30924 8.92583 1.45577 9 1.62508 9L10.375 9C10.5443 9 10.6908 
        8.92583 10.8145 8.77737C10.9382 8.62875 11 8.45303 11 8.2499C11 8.04681 10.9382 7.8711
         10.8145 7.7226L6.43952 2.47265C6.31567 2.32419 6.16924 2.24986 6 2.24986C5.83076 2.24986
          5.68419 2.32419 5.56058 2.47265L1.18563 7.72264C1.06205 7.8711 1 8.04681 1 8.24994C1 
          8.45303 1.06205 8.62875 1.18563 8.77737Z"
        fill="#592C12"
      />
    </svg>
  );
};

export default CaretUp;
