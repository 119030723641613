import { createSlice } from "@reduxjs/toolkit";
import { CONTENT_TYPE_FILE, MESSAGE, TYPE_MESSAGE_FILE } from "types/message.type";
const initialState: {
  newMessage: {
    conversation_id: number | null;
    message: MESSAGE | null;
  };
  scrollMessage: {
    messageId: number | null,
    reply: number | null,
  },
  previewFile: {
    typeFileReview: CONTENT_TYPE_FILE.OTHER | CONTENT_TYPE_FILE.IMG | CONTENT_TYPE_FILE.VIDEO | null;
    message_file: TYPE_MESSAGE_FILE | null;
    list_message_files: TYPE_MESSAGE_FILE[] | null;
  };
  pauseAllAudio: boolean;
} = {
  newMessage: {
    conversation_id: null,
    message: null,
  },
  scrollMessage: {
    messageId: null,
    reply: null,
  },
  previewFile: {
    typeFileReview: null,
    message_file: null,
    list_message_files: null,
  },
  pauseAllAudio: false,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    setScrollMessage: (state, action) => {
      state.scrollMessage = {
        ...state.scrollMessage,
        ...action.payload,
      };
    },
    removeScrollMessage: (state) => {
      state.scrollMessage = {
        messageId: null,
        reply: null,
      };
    },
    setPreviewFile: (state, action) => {
      state.previewFile = action.payload;
    },
    setPauseAllAudio: (state, action) => {
      state.pauseAllAudio = action.payload;
    },
  },
});

export const { setNewMessage, setScrollMessage, removeScrollMessage, setPreviewFile, setPauseAllAudio } = messageSlice.actions;
export default messageSlice.reducer;
