/* eslint-disable max-len */
const MailIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5621_127662)">
        <path
          d="M14.0057 14.7041C13.4086 15.1021 12.7151 15.3126 12 15.3126C11.285 15.3126 10.5914 15.1021 9.99431 14.7041L0.159797 8.14752C0.105234 8.11115 0.0520781 8.07323 0 8.03423V18.7778C0 20.0096 0.999609 20.9872 2.20936 20.9872H21.7906C23.0224 20.9872 24 19.9876 24 18.7778V8.03418C23.9478 8.07327 23.8945 8.11129 23.8398 8.14771L14.0057 14.7041Z"
          fill="#1F1B16"
        />
        <path
          d="M0.939844 6.97795L10.7744 13.5345C11.1466 13.7827 11.5733 13.9068 12 13.9068C12.4267 13.9068 12.8534 13.7827 13.2256 13.5345L23.0602 6.97795C23.6487 6.58584 24 5.92959 24 5.2213C24 4.00345 23.0092 3.0127 21.7914 3.0127H2.20861C0.990797 3.01274 0 4.00349 0 5.22248C0 5.92959 0.351375 6.58584 0.939844 6.97795Z"
          fill="#1F1B16"
        />
      </g>
      <defs>
        <clipPath id="clip0_5621_127662">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MailIcon;
