import { DialogBody, DialogHeader } from "@material-tailwind/react";
import { CLoseIcon } from "components/icon";
import { t } from "i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSetingConfig, updateSetting } from "http/setting.http";
import { settingTypeResponse, settingTypeResponseCache } from "types/setting.type";
import { SuccessResponse } from "types/utils.type";
import { useEffect, useState } from "react";
import { QUERY_KEY } from "constants/pathApi";
import PushNotification from "./PushNotification";
import EmailNotification from "./EmailNotification";
import { cloneDeep } from "lodash";
import SettingLanguageAndTheme from "./SettingLanguageAndTheme";
import CustomModal from "components/Modal/ModalCommon";

interface DialogSettingProps {
  closeModal: () => void;
  openSetting: boolean;
}

  interface SettingData {
    push_notification: boolean;
    email_notification: boolean;
  }

const DialogSetting = ({ openSetting, closeModal }: DialogSettingProps) => {
  const [checkedState, setCheckedState] = useState<SettingData>({
    push_notification: false,
    email_notification: false,
  });
  const [openSetLanguageAndTheme, setOpenSetLanguageAndTheme] = useState(false);
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [QUERY_KEY.SETTING_CONFIG],
    queryFn: async () => {
      const response = await getSetingConfig<SuccessResponse<settingTypeResponse>>();
      return response;
    },
    ...{
      refetchOnWindowFocus: false,
    },
    enabled: openSetting,
  });

  const handleSetSetting = async (checkedState: SettingData) => {
    if (!checkedState) return;

    const resultData = {
      value: {
        push_notification: checkedState.push_notification,
        email_notification: checkedState.email_notification,
      },
    };
    // Update CACHE DATA SETTING
    queryClient.setQueryData([QUERY_KEY.SETTING_CONFIG], (oldData: settingTypeResponseCache) => {
      if (!oldData) return oldData;
      const newData: settingTypeResponseCache = cloneDeep(oldData);
      if (newData.data) {
        if (newData.data.value) {
          newData.data.value = JSON.stringify(resultData.value);
        }
      }
      return newData;
    });
    const response = await updateSetting({ value: JSON.stringify(resultData.value) });
    return response;
  };

  useEffect(() => {
    if (data) {
      const value = data.data ? JSON.parse(data.data.value) : {
        push_notification: true,
        email_notification: true,
      };
  
      setCheckedState({
        push_notification: value.push_notification,
        email_notification: value.email_notification,
      });
    }
  }, [data]);

  const handleChangeSwitch = (key: keyof SettingData) => {
    const data = {
      ...checkedState,
      [key]: !checkedState[key],
    };
    setCheckedState(data);
    handleSetSetting(data);
  };

  return (
    <>
      {openSetting && data && (
        <CustomModal closeModal={closeModal} className="max-w-[592px] !w-full">
          <DialogHeader placeholder={"dialog"} className="flex items-center justify-between">
            <div className="flex gap-3 items-center">
              {openSetLanguageAndTheme && (
                <>
                  <div className="cursor-pointer" onClick={() => setOpenSetLanguageAndTheme(false)}>
                    <svg width="8" height="18" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </>
              )}
              <p className="text-2xl">{t("setting.header")}</p>
            </div>
            <CLoseIcon onClick={closeModal} />
          </DialogHeader>
          <DialogBody placeholder={"dialog"} className="px-8 pt-0 ">
            {!openSetLanguageAndTheme ? (
              <>
                <div className="mb-4">
                  <PushNotification
                    checkedPushNotification={checkedState.push_notification}
                    handleChangePushNotification={() => handleChangeSwitch("push_notification")}
                  />
                </div>
                <div className="mb-4">
                  <EmailNotification
                    checkedEmailNotification={checkedState.email_notification}
                    handleChangeEmailNotification={() => handleChangeSwitch("email_notification")}
                  />
                </div>
                {/* <div className="mb-4">
                  <ThemeLanguage setOpenSetLanguage={setOpenSetLanguageAndTheme} />
                </div>
                <div className="mb-4">
                  <ShowMessagePreview />
                </div>
                <div className="mb-4">
                  <Passcode />
                </div> */}
              </>
            ) : (
              <SettingLanguageAndTheme />
            )}
          </DialogBody>
        </CustomModal>
      )}
    </>
  );
};

export default DialogSetting;
