import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../../types/user.type";
import { Thunk } from "../../store";
import * as axiosClient from "../../../api/axiosClient";
import { API_URL } from "../../../constants/pathApi";
import { getClientToken } from "../../../firebase/config.firebase";
import { DataAuthorizationResponse, deleteDeviceToken } from "http/user.http";

export interface AuthState {
  userInfo: User | null;
  accessToken: string | null;
  isLogout: boolean;
  loading: boolean;
  saveTokenPending: boolean;
  device_token: string | null,
  prevPage: string | null;
  oauthToken: DataAuthorizationResponse | null
}

const initialState: AuthState = {
  userInfo: null,
  accessToken: null,
  isLogout: false,
  loading: false,
  device_token: null,
  oauthToken: null,
  prevPage: null,
  saveTokenPending: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<User | null>) => {
      state.userInfo = action.payload;
      if (action.payload?.access_token) {
        state.accessToken = action.payload?.access_token as string;
      }
    },
    setTokenInvalid: (state) => {
      state.userInfo = null;
      state.isLogout = true;
      state.oauthToken = null;
      state.device_token = null;
    },
    setDeviceToken: (state, action) => {
      state.device_token = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOauthToken: (state, action) => {
      state.oauthToken = action.payload;
    },
    setPrevPage: (state, action) => {
      state.prevPage = action.payload;
    },
    setSaveTokenPending: (state, action) => {
      state.saveTokenPending = action.payload;
    },
  },
});

// get info user and check token is valid
export const getUserInfo =
  (): Thunk =>
    async (dispatch): Promise<any> => {
      try {
        const response = await axiosClient.getAxios<User>(API_URL.INFO);
        dispatch(setUserInfo(response));
      } catch (error) {
        dispatch(setTokenInvalid());
      }
    };
export const saveDeviceToken =
  (): Thunk =>
    async (dispatch, getState): Promise<any> => {
      try {
        const state = getState();
        // check user dont allow notification and have old device token => remove old device token
        if(Notification.permission !== "granted" && state.auth.device_token){
          const response = await deleteDeviceToken(state.auth.device_token);
          if(response){
            dispatch(setDeviceToken(null));
          }
          return response;
        }
        
        if(state.auth.device_token) return;

        dispatch(setSaveTokenPending(true));
        const token = await getClientToken();
        if (token) {
          const response = await axiosClient.postAxios(API_URL.SAVE_TOKEN, {
            device_token: token,
          });
          dispatch(setDeviceToken(token));
          return response;
        }
      } catch (error) {
        console.log("Token is valid");
      } finally {
        dispatch(setSaveTokenPending(false));
      }
    };
export const { setUserInfo, setTokenInvalid, setLoading, setOauthToken, setPrevPage, setDeviceToken, setSaveTokenPending } =
  authSlice.actions;
export default authSlice.reducer;
