import { createSlice } from "@reduxjs/toolkit";
import { ACTION_CONVERSATION, Conversation } from "types/conversation.type";
import { EventMessageServiceWorker } from "types/utils.type";
const initialState: {
  conversationIdFetch: number | null;
  conversationSelected: Conversation | null;
  updateConversationNew: {
    conversation: Conversation | null;
    type: ACTION_CONVERSATION | null;
  };
  openDialogLeaveGroupChat: boolean;
  dataNotification: Omit<EventMessageServiceWorker, "data"> | null;
  oldConversationId: number | null;
} = {
  conversationIdFetch: null,
  conversationSelected: null,
  updateConversationNew: {
    conversation: null,
    type: null,
  },
  openDialogLeaveGroupChat: false,
  dataNotification: null,
  oldConversationId: null,
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversationIdFetch: (state, action) => {
      state.conversationIdFetch = action.payload;
    },
    setConversationSelected: (state, action) => {
      if(state.conversationSelected){
        state.oldConversationId = state.conversationSelected.conversation_id;
      }else{
        state.oldConversationId = null;
      }
      state.conversationSelected = action.payload;
    },
    setUpdateConversationNew: (state, action) => {
      state.updateConversationNew = {
        ...state.updateConversationNew,
        ...action.payload,
      };
    },
    setDataNotification: (state, action) => {
      if(action.payload){
        state.conversationIdFetch = action.payload.conversation_id;
      }
      state.dataNotification = action.payload;
    },
    setOpenDialogLeaveGroupChat: (state, action) => {
      state.openDialogLeaveGroupChat = action.payload;
    },
  },
});

export const {
  setConversationIdFetch,
  setConversationSelected,
  setUpdateConversationNew,
  setOpenDialogLeaveGroupChat,
  setDataNotification,
} = conversationSlice.actions;
export default conversationSlice.reducer;
