import { Switch } from "@material-tailwind/react";
import NotifiIcon from "components/icon/setting/NotifiIcon";
import { t } from "i18next";
interface PushNotificationrPops {
  checkedPushNotification: boolean;
  handleChangePushNotification: () => void;
}
export default function PushNotification({ checkedPushNotification, handleChangePushNotification }: PushNotificationrPops) {
  return (
    <div>
      <div className="flex justify-between h-[56px] items-center">
        <div className="flex">
          <NotifiIcon />
          <span className="ml-4 text-neutral-110">{t("setting.push_notification")}</span>
        </div>
        <div>
          <Switch
            className="w-[40px] h-[16px] ouline-none bg-[#F4EDE6] checked:bg-[#BAABA1] focus-visible:outline-none"
            checked={checkedPushNotification}
            crossOrigin={null} 
            onChange={handleChangePushNotification}
            circleProps={{
              className: `w-6 h-6 ${checkedPushNotification ? "bg-[#592C12]" : "bg-[#E5E0DA]"}`,
            }} />
        </div>
      </div>
    </div>
  );
}
