import DialogProfileMemberContainer from "components/dialog/ProfileMember/DialogProfileMemberContainer";
import { CLoseIcon } from "components/icon";
import InputSearch from "components/input/InputSearch";
import useOnClickOutside from "hook/useOnClickOutside";
import { t } from "i18next";
import { useRef, useState } from "react";
import ListConversation from "./ListConversation";
import CustomModal from "components/Modal/ModalCommon";
import SearchModalContainer from "./SearchModalContainer";
import { KeyDownEvent } from "types/event.type";

const SearchMainContainer = () => {
  const [isFocus, setIsFocus] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [contactId, setContactId] = useState<number>();
  const [openModalSearch, setOpenModalSearch] = useState<boolean>(false);

  const searchRef = useRef<HTMLDivElement>(null);
  const openPopupRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchRef, openPopupRef, () => {
    setIsFocus(false);
  });

  const closeModal = () => {
    setOpenModalSearch((prev) => !prev);
  };

  const handleOnKeyDown = (e:KeyDownEvent) => {
    if(e.key === "Enter" && searchKeyword.trim()){
      closeModal();
    }
  };

  return (
    <>
      <div ref={searchRef} className="max-w-[592px] flex-1 relative">
        <InputSearch
          value={searchKeyword}
          placeholder={t("search_main.placeholder_search") as string}
          // comment feature search
          // setIsFocus={setIsFocus}
          onKeyDown={handleOnKeyDown}
          disabled
          className={`flex-1 h-8 px-2  bg-gray-200 w-full ${isFocus ? "border border-primary-900 !rounded-md bg-white" : "rounded-full"} `}
          // onChange={(e) => setSearchKeyword(e.target.value)}
          onChange={() => {}}
        />
        {isFocus && (
          <>
            <div
              className="absolute top-full w-full bg-white  z-[99]
              shadow-xl  min-h-[88px] rounded-lg p-[10px] flex flex-col items-center justify-center"
            >
              {searchKeyword.trim() && (
                <ListConversation
                  classNameAvatar="h-12 w-12"
                  classNameChildren="text-base gap-2"
                  classNameParent="max-h-[440px] w-full"
                  keyword={searchKeyword}
                  setContactId={setContactId}
                  setIsFocus={setIsFocus}
                />
              )}
              <div
                onClick={closeModal}
                className="bg-neutral-20 rounded-lg text-primary-900 h-10 flex justify-center items-center cursor-pointer mt-2 w-full"
              >
                {t("search_main.search_more_option")}
              </div>
            </div>
          </>
        )}
        {searchKeyword && (
          <CLoseIcon
            width={12}
            height={12}
            className="absolute right-4 top-2/4 -translate-y-2/4 text-neutral-70"
            onClick={() => {
              setIsFocus(false);
              setSearchKeyword("");
            }}
          />
        )}
      </div>
      {contactId && (
        <DialogProfileMemberContainer openModal={!!contactId} memberId={contactId} closeModal={() => setContactId(undefined)} />
      )}
      {openModalSearch && (
        <CustomModal
          children={
            <SearchModalContainer
              setContactId={setContactId}
              setOpenModalSearch={setOpenModalSearch}
              initSearch={searchKeyword}
              closeModal={closeModal}
            />
          }
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default SearchMainContainer;
