import { Spinner } from "@material-tailwind/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import AvatarCatchError from "components/image/AvatarCatchError";
import MemberLoadingSkeleton from "components/loading/MemberLoadingSkeleton";
import { QUERY_KEY } from "constants/pathApi";
import { useCustomSelector } from "hook/redux";
import { getListMemberInConversation } from "http/message.http";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { MEMBER } from "types/message.type";
import { ResponseTypeCommon } from "types/utils.type";
import { getNextPageIndex, mapDataInfiniteQuery } from "utils/common";

interface ListMemberInConverSationProps {
  debouncedSearchMember: string;
  classNameParent?: string;
  classNameChildren?: string;
  classNameAvatar?: string;
  onclick?: (value: any) => void;
}
const ListMemberInConverSation = ({
  debouncedSearchMember,
  classNameParent,
  classNameChildren,
  classNameAvatar,
  onclick,
}: ListMemberInConverSationProps) => {
  const { conversationSelected } = useCustomSelector((state) => state.conversation);
  const [listMember, setListMember] = useState<MEMBER[]>([]);
  const { ref, inView } = useInView();
  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_MEMBER_IN_CONVERSATION, conversationSelected?.conversation_id, debouncedSearchMember],
    queryFn: ({ pageParam = 1 }) =>
      getListMemberInConversation<ResponseTypeCommon<MEMBER>>({
        pageParam,
        conversation_id: conversationSelected?.conversation_id,
        params: {
          user_name: debouncedSearchMember,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: getNextPageIndex,
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    const rawData = mapDataInfiniteQuery<MEMBER, ResponseTypeCommon<MEMBER>>(data);
    setListMember(rawData);
  }, [data]);

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <div className={`${classNameParent} h-full overflow-hidden hover:overflow-y-scroll scroll-sidebar`}>
          <MemberLoadingSkeleton />
        </div>
      ) : (
        listMember && (
          <div className={`${classNameParent} h-full overflow-hidden hover:overflow-y-scroll scroll-sidebar`}>
            {listMember.map((member) => (
              <div
                key={member.id}
                onClick={() => onclick && onclick(member)}
                className={`${classNameChildren} flex items-center hover:bg-neutral-20 px-4 py-2`}
              >
                <AvatarCatchError className={`${classNameAvatar}`} src={member.avatar} />
                <p className="text-sm font-normal text-neutral-110">{member?.name}</p>
              </div>
            ))}
            <div ref={ref}></div>
            <div className="flex justify-center">{isFetchingNextPage && <Spinner />}</div>
          </div>
        )
      )}
    </>
  );
};
export default ListMemberInConverSation;
