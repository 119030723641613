export const LOGIN = "login";
export const CHAT = "/chat";
export const CONTACT = "/contact";
export const SCHEDULE = "/schedule";
export const TASK = "/task";
export const SETTING = "/setting";
export const ALL_CONTACT = "all-contact";
export const RECEIVED = "received";
export const SENT = "sent";
export const CALLBACK_LOGIN = "/auth/callback";
export const LOGIN_FAIL = "/fail";
