import { coverContentFileToObjectFile, getAllImgAndVideoInList } from "logic/message.logic";
import { Fragment, useEffect, useMemo, useState } from "react";
import { CONTENT_TYPE_FILE, ContentFileType, DATA_MESSAGE_PREVIEW, MEMBER, TYPE_MESSAGE_FILE } from "types/message.type";
import MessageAudio from "./MessageAudio";
import MessageFileOther from "./MessageFileOther";
import { TYPE_SCREEN_CHAT } from "constants/constans";
import MessageFileImgAndVideo from "./MessageFileImgAndVideo";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { useQueryClient } from "@tanstack/react-query";
import { ResponseTypeCommon } from "types/utils.type";
import { QUERY_KEY } from "constants/pathApi";
import { t } from "i18next";
import { toast } from "react-toastify";
import { setPreviewFile } from "../../../redux/slices/message";

interface MessageFilePropType {
  files: string;
  typeScreen: TYPE_SCREEN_CHAT;
  isPinMessage?: boolean;
  className?: string;
  sidebarPinMessage?: boolean;
  dataPreview?: DATA_MESSAGE_PREVIEW
}
const MessageFile = ({ files, typeScreen, isPinMessage = false, className = "", sidebarPinMessage, dataPreview }: MessageFilePropType) => {
  const [listFile, setListFile] = useState<ContentFileType[]>([]);
  const [listImg, listVideo] = useMemo(() => getAllImgAndVideoInList(listFile), [listFile]);
  const { conversationSelected } = useCustomSelector((state) => state.conversation);
  const dispatch = useCustomDispatch();
  const queryClient = useQueryClient();

  const listMember = queryClient.getQueryData<ResponseTypeCommon<MEMBER>>([
    QUERY_KEY.LIST_MEMBER_CONVERSATION,
    conversationSelected?.conversation_id,
  ]);
  const handleOpenPreview = (file:ContentFileType, list_message_files: ContentFileType[] = []) =>{
    if(listMember && dataPreview){
      const userSendMessage = listMember.data.find((member) => +member.id === +dataPreview?.messageUserId);
      if(userSendMessage){
        const filePreview = coverContentFileToObjectFile(file, dataPreview, userSendMessage, conversationSelected?.conversation_id);
        if(file?.is_delete){
          toast.warning(t("files.file_not_found"));
        }else{
          const listFile:TYPE_MESSAGE_FILE[] = [];
          list_message_files.forEach((fileMessage) => {
            if(!fileMessage?.is_delete){
              const fileCover = coverContentFileToObjectFile(
                fileMessage,
                dataPreview,
                userSendMessage,
                conversationSelected?.conversation_id,
              );
              listFile.push(fileCover);
            }
          });
          dispatch(setPreviewFile({
            typeFileReview: file.type,
            message_file: filePreview,
            list_message_files: listFile,
          }));
        }
      }
    }
  };

  const handlePreviewImgAndVideo = (file: ContentFileType) => {
    handleOpenPreview(file, [...listImg, ...listVideo]);
  };
  useEffect(() => {
    if (files) {
      try {
        const fileParse: ContentFileType[] = JSON.parse(files);
        setListFile(fileParse);
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [files]);
  return (
    <>
      {listImg.length > 0 && (
        <MessageFileImgAndVideo
          className={className}
          isPinMessage={isPinMessage}
          type={CONTENT_TYPE_FILE.IMG}
          listFile={listImg}
          typeScreen={typeScreen}
          sidebarPinMessage={sidebarPinMessage}
          handleOpenPreview={handlePreviewImgAndVideo}
        />
      )}
      {listVideo.length > 0 && (
        <MessageFileImgAndVideo
          className={className}
          isPinMessage={isPinMessage}
          type={CONTENT_TYPE_FILE.VIDEO}
          listFile={listVideo}
          typeScreen={typeScreen}
          sidebarPinMessage={sidebarPinMessage}
          handleOpenPreview={handlePreviewImgAndVideo}
        />
      )}
      {listFile &&
        listFile.map((file, index) => {
          let content = <Fragment key={index} />;
          switch (file?.type) {
            case CONTENT_TYPE_FILE.AUDIO:
              content = (
                <MessageAudio
                  sidebarPinMessage={sidebarPinMessage}
                  isPinMessage={isPinMessage}
                  audio={file?.file_path}
                  key={index}
                  typeScreen={typeScreen}
                />
              );
              break;
            case CONTENT_TYPE_FILE.OTHER:
              content = (
                <MessageFileOther
                  typeScreen={typeScreen}
                  sidebarPinMessage={sidebarPinMessage}
                  isPinMessage={isPinMessage}
                  file={file}
                  handleOpenPreview={handleOpenPreview}
                  key={`file_${index}`}
                />
              );
              break;
            default:
              content = <Fragment key={`default_${index}`} />;
              break;
          }
          return content;
        })}
    </>
  );
};

export default MessageFile;
