import { User, UserContact } from "types/user.type";
import { t } from "i18next";
import AvatarCatchError from "components/image/AvatarCatchError";
import MailIcon from "components/icon/MailIcon";
import { FormProvider, useForm } from "react-hook-form";
import { schema, SchemaPick } from "utils/rules";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadImg from "components/image/UploadImg";
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import { ResponseTypeCommon, SuccessResponse } from "types/utils.type";
import { UpdateProfile, updateProfile } from "http/user.http";
import { STATUS_CODE } from "constants/statusCode";
import { toast } from "react-toastify";
import { ReactNode } from "react";
import { ACTION_CONVERSATION, Conversation, TYPE_CONVERSATION } from "types/conversation.type";
import { QUERY_KEY } from "constants/pathApi";
import { cloneDeep } from "lodash";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { getUserInfo } from "../../../../redux/slices/auth";
import { setUpdateConversationNew } from "../../../../redux/slices/conversation";
interface ProfileInfoProp {
  userDetailContact: UserContact | undefined | User | null;
  isUploadAvatar?: boolean;
  className?: string;
  isMoreInfo?: boolean;
}
// pick yup rule validate
type FormData = Pick<SchemaPick, "group_avatar">;
const createGroupSchema = schema.pick(["group_avatar"]);
const ProfileInfo = ({ userDetailContact, isUploadAvatar, className, isMoreInfo = true }: ProfileInfoProp) => {
  const methods = useForm<FormData>({
    resolver: yupResolver(createGroupSchema),
    mode: "onChange",
  });
  const { handleSubmit, formState } = methods;
  const { errors } = formState;
  const queryClient = useQueryClient();
  const dispatch = useCustomDispatch();
  const { conversationSelected } = useCustomSelector((state) => state.conversation);
  const { mutate, isPending } = useMutation({
    mutationFn: (newData: UpdateProfile) => updateProfile<SuccessResponse<User>>(newData),
    onSuccess: (response) => {
      if (response && response.code === STATUS_CODE.Ok) {
        queryClient.setQueryData<InfiniteData<ResponseTypeCommon<Conversation>>>([QUERY_KEY.LIST_CONVERSATION, 0], (oldData) => {
          if (!oldData) return oldData;
          const newData: InfiniteData<ResponseTypeCommon<Conversation>> = cloneDeep(oldData);
          newData.pages.forEach((page) => {
            page.data = page.data.map((conversation) => {
              if (conversation.type === TYPE_CONVERSATION.MY_CHAT) {
                return {
                  ...conversation,
                  conversation_avatar: response.data.avatar,
                };
              } else {
                return {
                  ...conversation,
                };
              }
            });
          });
          return newData;
        });

        // dispatch user info login to update avatar
        dispatch(getUserInfo());

        // updated avatar in message header
        if (conversationSelected?.type === TYPE_CONVERSATION.MY_CHAT) {
          const newConversation = {
            ...conversationSelected,
            conversation_avatar: response.data.avatar,
          };
          dispatch(
            setUpdateConversationNew({
              conversation: newConversation,
              type: ACTION_CONVERSATION.SOCKET,
            }),
          );
        }

        toast.success(response.message);
      } else {
        toast.error(t("exception.server_error"));
      }
    },
    onError: () => {
      toast.error(t("exception.server_error"));
    },
  });

  const handleUpdate = async (data: FormData) => {
    const newData = {
      ...(data?.group_avatar?.[0] ? { avatar: data?.group_avatar[0] } : {}),
    };
    await mutate(newData);
  };

  const handleUpdateAvatar = () => {
    handleSubmit(handleUpdate)();
  };

  return (
    <div className="text-center p-4 pt-0">
      <div className={`${isMoreInfo ? "mb-4" : "mb-2"}`}>
        {!isUploadAvatar ? (
          <AvatarCatchError className={`w-[72px] h-[72px] ${className} object-cover`} src={userDetailContact?.avatar || ""} />
        ) : (
          <FormProvider {...methods}>
            <form className="space-y-4 md:space-y-6 mt-7">
              <div className="flex justify-center flex-col items-center mb-4 gap-y-5">
                <UploadImg
                  className="h-[100px] w-[100px]"
                  isPending={isPending}
                  avatarGroup={userDetailContact?.avatar}
                  handleUpdateAvatar={handleUpdateAvatar}
                />
                {!!errors["group_avatar"] && (
                  <p className="-mt-3 text-sm text-red-700 whitespace-normal">{errors["group_avatar"]?.message as ReactNode}</p>
                )}
              </div>
            </form>
          </FormProvider>
        )}
        <h2 className="text-xl mt-2 font-bold">{userDetailContact?.name ?? userDetailContact?.full_name}</h2>
        {isMoreInfo && <h3 className="text-sm">{userDetailContact?.position}</h3>}
      </div>
      {isMoreInfo && (
        <div className="flex justify-center items-center gap-x-4">
          <MailIcon />
          <span>
            {t("contact.profile.email")} {userDetailContact?.email}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
