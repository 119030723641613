/* eslint-disable max-len */
interface ChatIconProp {
  color?: string;
}
const ChatIcon = ({ color = "#979088" }: ChatIconProp) => {
  return (
    <svg width="24" className="cursor-pointer" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        fill={color}
      />
      <path
        d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z"
        fill={color}
      />
      <path
        d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z"
        fill={color}
      />
      <path
        d="M12 1.00001C10.0584 0.998039 8.15096 1.51054 6.4718 2.48535C4.79263 3.46015 3.40173 4.86246 2.44065 6.54952C1.47958 8.23658 0.982655 10.1482 1.00046 12.0897C1.01827 14.0312 1.55017 15.9334 2.54203 17.6025L2.00783 21.876C1.99036 22.0167 2.00298 22.1595 2.04485 22.2949C2.08672 22.4303 2.15688 22.5553 2.25069 22.6616C2.34449 22.7678 2.45981 22.853 2.58899 22.9113C2.71817 22.9696 2.85828 22.9999 3.00003 23C3.08187 23.0002 3.1634 22.9901 3.24273 22.97L7.32323 21.95C8.78448 22.644 10.3823 23.0028 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08263 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1.00001 12 1.00001ZM12 21C10.571 21.0018 9.16253 20.6591 7.89403 20.001C7.75244 19.9285 7.5956 19.8908 7.43653 19.891C7.35469 19.8908 7.27316 19.9009 7.19383 19.921L4.17383 20.6759L4.57003 17.501C4.59924 17.2692 4.54615 17.0345 4.42003 16.8379C3.34724 15.1537 2.86547 13.1603 3.05066 11.172C3.23585 9.18375 4.07747 7.31364 5.44284 5.85649C6.80822 4.39934 8.6197 3.43802 10.5918 3.12406C12.5638 2.81011 14.5843 3.16137 16.3346 4.12248C18.085 5.08359 19.4657 6.59989 20.2592 8.43236C21.0527 10.2648 21.2137 12.3093 20.717 14.2434C20.2203 16.1775 19.094 17.8913 17.5157 19.1146C15.9374 20.338 13.9969 21.0013 12 21Z"
        fill={color}
      />
    </svg>
  );
};

export default ChatIcon;
