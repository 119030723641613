import { Button } from "@material-tailwind/react";
import classNames from "utils/classNames";
interface ButtonCommonProp {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title: string;
  isPending?: boolean;
  className: string;
  type?: "button" | "reset" | "submit" | undefined;
  disabled?: boolean | undefined;
}
const ButtonCommon = ({ onClick, type = "button", title, isPending = false, className = "", disabled = false }: ButtonCommonProp) => {
  const defaultClass = "font-normal shadow-none bg-white rounded border px-3 py-1 text-xs text-neutral-110 capitalize";

  return (
    <Button
      type={type}
      loading={isPending}
      onClick={onClick}
      className={classNames(defaultClass, isPending ? "pointer-events-none flex opacity-50 flex-row-reverse" : "", className)}
      placeholder={"received"}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default ButtonCommon;
