import { useEffect, useState } from "react";
import AllContactList from "./AllContactList";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getNextPageIndex, mapDataInfiniteQuery } from "utils/common";
import { useDebounce } from "hook/useDebounce";
import { ResponseTypeCommon } from "types/utils.type";
import { Contact } from "types/contact.type";
import { getListContact } from "http/contact.http";
import { isEmpty } from "lodash";
import DialogDeleteContactContainer from "components/dialog/contact/DialogDeleteContactContainer";
import { QUERY_KEY } from "constants/pathApi";

const AllContactContainer = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const debouncedSearchContact = useDebounce<string>(searchKeyword, 1000);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [openDialogDeleteContact, setOpenDialogDeleteContact] = useState(false);

  const { data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_ALL_CONTACT, debouncedSearchContact],
    queryFn: ({ pageParam = 1 }) =>
      getListContact<ResponseTypeCommon<Contact>>({
        pageParam,
        params: {
          keyword: debouncedSearchContact,
        },
      }),
    initialPageParam: 1,
    ...{
      refetchOnWindowFocus: true,
      gcTime: 0,
    },
    getNextPageParam: getNextPageIndex,
  });

  // merge multiple page data
  useEffect(() => {
    if (!isEmpty(data)) {
      const rawData = mapDataInfiniteQuery<Contact, ResponseTypeCommon<Contact>>(data);
      setContacts(rawData);
    }
  }, [data]);

  return (
    <>
      <AllContactList
        contacts={contacts}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        total={data?.pages[0].total}
        fetchNextPage={fetchNextPage}
        setSearchKeyword={setSearchKeyword}
        setOpenDialogDeleteContact={setOpenDialogDeleteContact}
      />
      {openDialogDeleteContact && (
        <DialogDeleteContactContainer
          debouncedSearchContact={debouncedSearchContact}
          setOpenDialogDeleteContact={setOpenDialogDeleteContact}
          closeModal={() => setOpenDialogDeleteContact((pre) => !pre)}
        />
      )}
    </>
  );
};

export default AllContactContainer;
