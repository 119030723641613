import * as axiosClient from "api/axiosClient";
import { LIMIT } from "constants/constans";
import { API_URL } from "constants/pathApi";
import { replaceDynamicApi } from "utils/common";

interface getListContactType {
  pageParam: number | unknown;
  params?: {
    keyword?: string | undefined;
    is_screen_create_new_chat?: number;
    type_search?: number;
    per_page?: number,
  };
}
export const getListContact = async <T>({ pageParam, params }: getListContactType) => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_CONTACT, {
    params: {
      per_page: LIMIT.CONVERSATION,
      page: pageParam,
      ...params,
    },
  });
  return response;
};
export const getListSender = async <T>({ pageParam, params }: getListContactType) => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_SENDER, {
    params: {
      per_page: LIMIT.SEARCH_CONTACT,
      page: pageParam,
      ...params,
    },
  });
  return response;
};
export const getDetailUserInContact = async <T>({ userId }: { userId: number | null | undefined }) => {
  const response = await axiosClient.getAxios<T>(replaceDynamicApi(API_URL.DETAIL_USER_CONTACT, userId as number));
  return response;
};

export const getListContactReceived = async <T>({ pageParam, params }: getListContactType) => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_RECEIVED_CONTACT, {
    params: {
      per_page: LIMIT.CONVERSATION,
      page: pageParam,
      ...params,
    },
  });
  return response;
};

export const getListContactSent = async <T>({ pageParam, params }: getListContactType) => {
  const response = await axiosClient.getAxios<T>(API_URL.LIST_SENT_CONTACT, {
    params: {
      per_page: LIMIT.CONVERSATION,
      page: pageParam,
      ...params,
    },
  });
  return response;
};

export const postAcceptOrRejectContact = async <T>(contact_id: string | undefined, type_request: number) => {
  const response = await axiosClient.postAxios<T>(API_URL.ACCEPT_OR_REJECT_CONTACT, {
    contact_id,
    type_request,
  });
  return response;
};

export const getListNoContact = async <T>({ pageParam, params }: getListContactType) => {
  const response = await axiosClient.getAxios<T>(API_URL.NOT_CONTACT, {
    params: {
      per_page: LIMIT.CONVERSATION,
      page: pageParam,
      ...params,
    },
  });
  return response;
};

export const getInfoUserLogin = async <T>() => {
  const response = await axiosClient.getAxios<T>(API_URL.INFO);
  return response;
};
