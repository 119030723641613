import AvatarCatchError from "components/image/AvatarCatchError";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { ContactReceived, TYPE_ACCEPT_OR_REJECT_CONTACT } from "types/contact.type";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { nextSideBar, prevSideBar } from "../../../redux/slices/sidebar";
import { postAcceptOrRejectContact } from "http/contact.http";
import { setContactAccepted, setContactSelected, setIsDisableBtn } from "../../../redux/slices/contact";
import { ACTION_OPEN_SIDEBAR } from "types/sidebar.type";
import { Dispatch, SetStateAction, useEffect } from "react";
import { t } from "i18next";
import { toast } from "react-toastify";
import { QUERY_KEY } from "constants/pathApi";
import { isEmpty } from "lodash";
import ButtonCommon from "components/button/ButtonCommon";
import { AxiosError } from "axios";
import { ErrorResponse, SuccessResponse } from "types/utils.type";
import { STATUS_API, STATUS_CODE } from "constants/statusCode";

interface ReceivedItemProp {
  contact: ContactReceived;
  setOpenDialogDeleteContact: Dispatch<SetStateAction<boolean>>;
}
const ReceivedItem = ({ contact, setOpenDialogDeleteContact }: ReceivedItemProp) => {
  const dispatch = useCustomDispatch();
  const queryClient = useQueryClient();
  const { sideBarMultiLevel } = useCustomSelector((state) => state.sidebar);
  const { contactAccepted, userContactSelected, isDisableBtn } = useCustomSelector((state) => state.contact);

  // handle open side
  const handleOpenSideBarReceived = () => {
    dispatch(setContactSelected({ user_id: contact.user_id, contact_id: contact.contact_id }));
    if (isEmpty(sideBarMultiLevel)) {
      dispatch(
        nextSideBar({
          type: ACTION_OPEN_SIDEBAR.SIDEBAR_RECEIVED,
        }),
      );
    }
  };

  const handleOpenDialogDeleteReceived = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setContactSelected({ user_id: contact.user_id, contact_id: contact.contact_id }));
    setOpenDialogDeleteContact((prev) => !prev);
  };

  const { mutate, isPending, status, error } = useMutation({
    mutationFn: () => postAcceptOrRejectContact<SuccessResponse<ContactReceived>>(contact.contact_id, TYPE_ACCEPT_OR_REJECT_CONTACT.ACCEPT),
    onSuccess: (response) => {
      if (response) {
        if (contactAccepted && contactAccepted?.user_id.length > 0) {
          dispatch(
            setContactAccepted({
              isAccepted: true,
              user_id: [...contactAccepted.user_id, contact.user_id],
            }),
          );
        } else {
          dispatch(
            setContactAccepted({
              isAccepted: true,
              user_id: [contact.user_id],
            }),
          );
        }
        queryClient.refetchQueries({ queryKey: [QUERY_KEY.USER_DETAIL_CONTACT, contact.user_id] });
        queryClient.refetchQueries({ queryKey: [QUERY_KEY.TOTAL_RECEIVED_CONTACT] });
        toast[response?.code === STATUS_CODE.Ok ? "success" : "error"](response?.message);
        dispatch(prevSideBar());
      }
    },
    onError: (error: AxiosError<ErrorResponse>) => {},
  });

  useEffect(() => {
    if (status === STATUS_API.Error) {
      if (error?.response?.data.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(t("exception.server_error"));
      }
    }
  }, [status]);

  const handleAcceptReceived = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await mutate();
  };

  useEffect(() => {
    if (isPending) {
      dispatch(setIsDisableBtn(true));
    } else {
      dispatch(setIsDisableBtn(false));
    }
  }, [isPending]);

  return (
    <div className="group relative" onClick={handleOpenSideBarReceived}>
      <div
        className={`flex ${
          userContactSelected?.user_id === contact.user_id && "bg-neutral-20"
        } receive-item-${
          contact.contact_id
        }  border-b mt-5 w-full items-center gap-2 p-3 pr-2 transition-colors cursor-pointer hover:bg-neutral-20`}
      >
        <AvatarCatchError className="w-12 h-12" src={contact?.avatar} />
        <div className="">
          <h3>{contact.name}</h3>
          <h4 className="text-xs pt-[6px] text-neutral-60">{contact.company_contract}</h4>
        </div>
      </div>
      <div className="right-0 top-3 absolute">
        <div className="flex gap-x-3">
          {contactAccepted?.isAccepted && contactAccepted.user_id.includes(contact.user_id) ? (
            <button className="border-neutral-60 bg-neutral-60 text-neutral-20 rounded border px-3 py-1 text-xs">
              {t("toast.accepted")}
            </button>
          ) : (
            <>
              <ButtonCommon
                className="border-neutral-60 font-normal shadow-none bg-white rounded border px-3 py-1 text-xs text-neutral-110"
                onClick={handleOpenDialogDeleteReceived}
                title={t("action.delete")}
                disabled={userContactSelected?.user_id === contact.user_id && isDisableBtn}
              />
              <ButtonCommon
                className="border-neutral-60 rounded border px-3 py-1 text-xs text-neutral-110"
                onClick={handleAcceptReceived}
                title={t("action.accept")}
                isPending={isPending}
                disabled={userContactSelected?.user_id === contact.user_id && isDisableBtn}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReceivedItem;
