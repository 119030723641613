/* eslint-disable max-len */
const MemberIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 20.75H4C3.27082 20.7495 2.57165 20.4596 2.05604 19.944C1.54043 19.4283 1.25053 18.7292 1.25 18C1.25185 16.4756 1.85825 15.0141 2.93618 13.9362C4.01411 12.8582 5.47557 12.2519 7 12.25H9C10.5244 12.2519 11.9859 12.8582 13.0638 13.9362C14.1418 15.0141 14.7481 16.4756 14.75 18C14.7495 18.7292 14.4596 19.4283 13.944 19.944C13.4283 20.4596 12.7292 20.7495 12 20.75ZM7 13.75C5.87324 13.7513 4.793 14.1995 3.99626 14.9963C3.19951 15.793 2.75132 16.8732 2.75 18C2.75053 18.3314 2.88239 18.649 3.1167 18.8833C3.35101 19.1176 3.66864 19.2495 4 19.25H12C12.3314 19.2495 12.649 19.1176 12.8833 18.8833C13.1176 18.649 13.2495 18.3314 13.25 18C13.2487 16.8732 12.8005 15.793 12.0037 14.9963C11.207 14.1995 10.1268 13.7513 9 13.75H7Z"
        fill="#1F1B16"
      />
      <path
        d="M8 11.75C7.06054 11.75 6.14218 11.4714 5.36104 10.9495C4.57991 10.4275 3.97109 9.6857 3.61157 8.81775C3.25206 7.9498 3.15799 6.99473 3.34127 6.07332C3.52455 5.15191 3.97695 4.30554 4.64125 3.64124C5.30554 2.97695 6.15191 2.52455 7.07332 2.34127C7.99473 2.15799 8.9498 2.25206 9.81775 2.61157C10.6857 2.97109 11.4275 3.57991 11.9495 4.36104C12.4714 5.14218 12.75 6.06054 12.75 7C12.7484 8.25929 12.2475 9.46655 11.357 10.357C10.4666 11.2475 9.25929 11.7484 8 11.75ZM8 3.75C7.35721 3.75 6.72886 3.94061 6.1944 4.29773C5.65994 4.65484 5.24338 5.16242 4.99739 5.75628C4.75141 6.35014 4.68705 7.00361 4.81245 7.63405C4.93785 8.26448 5.24738 8.84358 5.70191 9.2981C6.15643 9.75262 6.73552 10.0622 7.36596 10.1876C7.9964 10.313 8.64986 10.2486 9.24372 10.0026C9.83758 9.75663 10.3452 9.34006 10.7023 8.8056C11.0594 8.27115 11.25 7.64279 11.25 7C11.2489 6.13837 10.9062 5.31234 10.2969 4.70307C9.68767 4.09381 8.86163 3.75106 8 3.75Z"
        fill="#1F1B16"
      />
      <path
        d="M20 20.75H14C13.5154 20.7503 13.0394 20.6215 12.621 20.377C12.4539 20.2743 12.3336 20.1102 12.2861 19.9199C12.2385 19.7296 12.2674 19.5282 12.3665 19.3589C12.4657 19.1896 12.6272 19.066 12.8165 19.0144C13.0058 18.9628 13.2077 18.9874 13.379 19.083C13.568 19.1917 13.782 19.2493 14 19.25H20C20.1625 19.2514 20.3235 19.2199 20.4736 19.1575C20.6236 19.0952 20.7594 19.0031 20.873 18.887C20.9928 18.7725 21.088 18.6348 21.1528 18.4823C21.2177 18.3298 21.2507 18.1657 21.25 18C21.249 17.1384 20.9062 16.3123 20.297 15.7031C19.6877 15.0938 18.8617 14.7511 18 14.75H16C15.1599 14.7504 14.3523 15.0753 13.746 15.657C13.6504 15.7811 13.5184 15.8723 13.3684 15.9176C13.2184 15.963 13.058 15.9603 12.9096 15.9099C12.7612 15.8595 12.6323 15.764 12.5409 15.6367C12.4496 15.5094 12.4003 15.3567 12.4 15.2C12.4067 14.9963 12.487 14.802 12.626 14.653C13.0687 14.209 13.5946 13.8566 14.1735 13.6159C14.7524 13.3752 15.3731 13.2508 16 13.25H18C19.2593 13.2516 20.4666 13.7525 21.357 14.643C22.2475 15.5335 22.7484 16.7407 22.75 18C22.7469 18.7284 22.4561 19.426 21.9411 19.9411C21.426 20.4561 20.7284 20.7468 20 20.75Z"
        fill="#1F1B16"
      />
      <path
        d="M17 12.75C16.2583 12.75 15.5333 12.5301 14.9166 12.118C14.2999 11.706 13.8193 11.1203 13.5355 10.4351C13.2516 9.74984 13.1774 8.99584 13.3221 8.26841C13.4668 7.54098 13.8239 6.8728 14.3484 6.34835C14.8728 5.8239 15.541 5.46675 16.2684 5.32206C16.9958 5.17736 17.7498 5.25162 18.4351 5.53545C19.1203 5.81928 19.706 6.29993 20.118 6.91661C20.5301 7.5333 20.75 8.25832 20.75 9C20.7487 9.99416 20.3532 10.9472 19.6502 11.6502C18.9472 12.3532 17.9942 12.7487 17 12.75ZM17 6.75C16.555 6.75 16.12 6.88196 15.75 7.12919C15.38 7.37643 15.0916 7.72783 14.9213 8.13896C14.751 8.5501 14.7064 9.0025 14.7932 9.43895C14.8801 9.87541 15.0943 10.2763 15.409 10.591C15.7237 10.9057 16.1246 11.12 16.561 11.2068C16.9975 11.2936 17.4499 11.249 17.861 11.0787C18.2722 10.9084 18.6236 10.62 18.8708 10.25C19.118 9.88002 19.25 9.44501 19.25 9C19.2492 8.40351 19.0119 7.83167 18.5901 7.40989C18.1683 6.9881 17.5965 6.7508 17 6.75Z"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default MemberIcon;
