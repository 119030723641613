const PlusIcon = () => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 1.75V12.25" stroke="#592C12" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.75 7H12.25" stroke="#592C12" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusIcon;
