/* eslint-disable max-len */

const TrashIcon = () => {
  return (
    <svg className="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9 4.30011H2.1C1.80826 4.30011 1.52847 4.416 1.32218 4.62229C1.11589 4.82858 1 5.10837 1 5.40011C1 5.69185 1.11589 5.97164 1.32218 6.17793C1.52847 6.38422 1.80826 6.50011 2.1 6.50011H4.3V19.7001C4.29987 20.1335 4.38514 20.5627 4.55093 20.9631C4.71673 21.3636 4.9598 21.7274 5.26626 22.0338C5.57272 22.3403 5.93656 22.5834 6.33699 22.7492C6.73742 22.915 7.1666 23.0002 7.6 23.0001H16.4C16.8334 23.0003 17.2626 22.915 17.663 22.7492C18.0635 22.5835 18.4273 22.3404 18.7338 22.0339C19.0403 21.7275 19.2833 21.3636 19.4491 20.9632C19.6149 20.5627 19.7002 20.1335 19.7 19.7001V6.50011H21.9C22.1917 6.50011 22.4715 6.38422 22.6778 6.17793C22.8841 5.97164 23 5.69185 23 5.40011C23 5.10837 22.8841 4.82858 22.6778 4.62229C22.4715 4.416 22.1917 4.30011 21.9 4.30011ZM17.5 19.7001C17.4996 19.9917 17.3836 20.2713 17.1774 20.4775C16.9712 20.6837 16.6916 20.7997 16.4 20.8001H7.6C7.30839 20.7997 7.02883 20.6837 6.82263 20.4775C6.61643 20.2713 6.50041 19.9917 6.5 19.7001V6.50011H17.5V19.7001Z"
        fill="#979088"
      />
      <path
        d="M9.80117 3.2H14.2012C14.4929 3.2 14.7727 3.08411 14.979 2.87782C15.1853 2.67153 15.3012 2.39174 15.3012 2.1C15.3012 1.80826 15.1853 1.52847 14.979 1.32218C14.7727 1.11589 14.4929 1 14.2012 1H9.80117C9.50943 1 9.22964 1.11589 9.02335 1.32218C8.81706 1.52847 8.70117 1.80826 8.70117 2.1C8.70117 2.39174 8.81706 2.67153 9.02335 2.87782C9.22964 3.08411 9.50943 3.2 9.80117 3.2Z"
        fill="#979088"
      />
      <path
        d="M10.9012 16.4001V10.9001C10.9012 10.6084 10.7853 10.3286 10.579 10.1223C10.3727 9.916 10.0929 9.80011 9.80117 9.80011C9.50943 9.80011 9.22964 9.916 9.02335 10.1223C8.81706 10.3286 8.70117 10.6084 8.70117 10.9001V16.4001C8.70117 16.6918 8.81706 16.9716 9.02335 17.1779C9.22964 17.3842 9.50943 17.5001 9.80117 17.5001C10.0929 17.5001 10.3727 17.3842 10.579 17.1779C10.7853 16.9716 10.9012 16.6918 10.9012 16.4001Z"
        fill="#979088"
      />
      <path
        d="M15.2996 16.4001V10.9001C15.2996 10.6084 15.1837 10.3286 14.9774 10.1223C14.7711 9.916 14.4913 9.80011 14.1996 9.80011C13.9079 9.80011 13.6281 9.916 13.4218 10.1223C13.2155 10.3286 13.0996 10.6084 13.0996 10.9001V16.4001C13.0996 16.6918 13.2155 16.9716 13.4218 17.1779C13.6281 17.3842 13.9079 17.5001 14.1996 17.5001C14.4913 17.5001 14.7711 17.3842 14.9774 17.1779C15.1837 16.9716 15.2996 16.6918 15.2996 16.4001Z"
        fill="#979088"
      />
    </svg>
  );
};

export default TrashIcon;
