export const API_URL = {
  //user
  LOGIN: "/login",
  INFO: "/user",
  LOGOUT: "/oauth/logout",
  LOGIN_BEEID: "/oauth/authorize",
  LOGIN_TOKEN: "/oauth/token",
  CHANGE_PASSWORD: "/user/change-password",
  SAVE_TOKEN: "/user/save-device-token",
  DELETE_TOKEN: "/user/device-token/{{device_token}}",

  // conversation
  LIST_CONVERSATION: "/conversation",
  CONVERSATION_PIN: "/conversation/pin",
  CONVERSATION_MUTE: "/conversation/mute",
  CONVERSATION_PARTICIPANT: "/conversation-participant",
  CREATE_CONVERSATION: "/conversation",
  DELETE_CONVERSATION: "/conversation/{{conversation_id}}",
  CONVERSATION_DETAIL: "/conversation/{{conversation_id}}/detail",
  UPDATE_CONVERSATION_INFO: "/conversation/{{conversation_id}}/update",
  LIST_MEMBER_NOTBEEN_ADD: "conversation-participant/{{conversation_id}}/member-not-been-add",
  UPDATE_USER_TO_CONVERSATION: "conversation-participant/{{conversation_id}}/update",

  //message
  MESSAGE_CONVERSATION: "/message/{{conversation_id}}",
  MESSAGE_SEARCH: "/message/search",
  FILE_SEARCH: "/file/search",
  REACTION: "/message/reaction",
  SEND_MESSAGE: "/message/{{conversation_id}}/send-message",
  UPLOAD_FILE: "/upload",
  REACTION_MESSAGE: "/message/reaction",
  LIST_FILES_MESSAGE: "/message/files/list",
  READ_MESSAGE: "/message/{{conversationId}}/read/{{messageId}}",
  DELETE_MESSAGE: "/message/{{messageId}}",
  EDIT_MESSAGE: "/message/{{messageId}}/update",
  PIN_MESSAGE: "/message/pin/{{messageId}}",
  LIST_PIN_MESSAGE: "/message/pin/{{conversationId}}",
  LIST_FILES: "/message/files/list",
  DELETE_FILE_MESSAGES: "message/files/{{{fileId}}}",

  //contact
  NOT_CONTACT: "/contact/no-contact",
  LIST_CONTACT: "/contact",
  DETAIL_USER_CONTACT: "/contact/{{userId}}/detail",
  LIST_RECEIVED_CONTACT: "/contact/received-request",
  LIST_SENT_CONTACT: "/contact/sent-request",
  ACCEPT_OR_REJECT_CONTACT: "/contact/accept-or-reject",
  ADD_CONTACT: "/contact/add-contact",
  LIST_SENDER: "/senders",

  //SETTING
  SETTING: "user-config/list",
  UPDATE_SETTING: "user-config/update",

  //user
  ALL_USER: "/users",
};

export const QUERY_KEY = {
  //conversation
  LIST_CONVERSATION: "conversation",
  CONVERSATION_TOTAL_UNREAD_MESSAGE: "total_unread_message_conversation",
  LIST_MEMBER_CONVERSATION: "list_member",
  DETAIL_CONVERSATION: "detail_conversation",
  LIST_USER_NOT_BEEN_ADD: "list_member_not_been_add",
  LIST_MEMBER_IN_CONVERSATION: "list_member_in_conversation",
  //message
  LIST_MESSAGE: "list_message",
  LIST_MESSAGE_REPLY: "list_message_reply",
  LIST_MEDIA_MESSAGE: "list_media_messages",
  LIST_FILE_MESSAGE: "list_file_messages",
  LIST_LINK_MESSAGE: "list_link_messages",
  LIST_PIN_MESSAGE: "list_pin_messages",
  LIST_FILES_MESSAGE: "list_files_message",
  LIST_MESSAGE_SEARCH: "list_message_search",
  LIST_FILE_SEARCH: "list_message_search",
  //contact
  LIST_ALL_CONTACT: "list_all_contact",
  LIST_RECEIVED_CONTACT: "list_received_contact",
  TOTAL_RECEIVED_CONTACT: "total_received_contact",
  LIST_SENT_CONTACT: "list_sent_contact",
  USER_DETAIL_CONTACT: "user_detail_contact",
  USER_LOGIN_INFO: "user_login_info",
  LIST_NO_ALL_CONTACT: "list_no_all_contact",
  LIST_ALL_CONTACT_SEARCH: "list_all_contact_search",
  LIST_ALL_SENDER_SELECT: "list_all_contact_select",

  //setting:
  SETTING_CONFIG: "setting_config",

  //user
  ALL_USER: "all_user",
};
export const SOCKET_EVENT = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",

  // error handling
  CONNECT_FAILED: "connect_failed",
  ERROR: "error",

  // event message
  JOIN_ROOM: "joinRoom",
  NEW_MESSAGE: "newMessage",
  UPDATE_MESSAGE: "updateMessage",
  DESTROY_MESSAGE: "destroyMessage",
  USER_ONLINE: "userOnline",
  UPDATE_CONVERSATION: "updateConversation",
  NEW_CONVERSATION: "newConversation",
  DELETE_CONVERSATION: "deleteConversation",
  OUT_CONVERSATION: "outConversation",
  GET_USER_ONLINE: "getUserOnline",
  REACTION_MESSAGE: "reaction",
  PIN_MESSAGE: "pin",
  UNPIN_MESSAGE: "unpin",
  USER_IN_CONVERSATION: "updateMembersInConversation",
  REJECT_CONTACT: "rejectContact",
  ACCEPT_CONTACT: "acceptContact",
  NEW_CONTACT: "newContact",
};
export const DEFAULT_AVATAR = "/avatar_default.png";
export const DEFAULT_AVATAR_CONVERSATION = "/avatar_default_conversation.png";
export const BASE_SRC_FILE = process.env.REACT_APP_BASE_SRC_FILE;
