const QuoteIcon = ({ width = 18, height = 14, className = "text-primary-900" }) => {
  return (
    <div className={`${className}`}>
      <svg width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8.28569H3.85714L1.28569 13.4285H5.14283L7.71428 8.28569V0.571411H0V8.28569Z" fill="currentColor" />
        <path d="M10.2856 0.571411V8.28569H14.1428L11.5713 13.4285H15.4285L17.9999 8.28569V0.571411H10.2856Z" fill="currentColor" />
      </svg>
    </div>
  );
};

export default QuoteIcon;
