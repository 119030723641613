import { DialogHeader } from "@material-tailwind/react";
import CLoseIcon from "components/icon/CLoseIcon";
import { t } from "i18next";
import DialogAccountSetting from "./DialogAccountSetting";
import { useState } from "react";
import DialogChangePasswordContainer from "../changePassword/DialogChangePasswordContainer";
import CustomModal from "components/Modal/ModalCommon";

// declare props type
interface DialogAccountSettingContainerProp {
  closeModal: () => void;
}

const DialogAccountSettingContainer = ({ closeModal }: DialogAccountSettingContainerProp) => {
  const [openDialogChangePassword, setOpenDialogChangePassword] = useState(false);

  if (openDialogChangePassword) {
    return <DialogChangePasswordContainer closeModal={() => setOpenDialogChangePassword(false)} />;
  }
  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[512px] !w-full max-h-[95vh] overflow-hidden flex flex-col">
        <DialogHeader placeholder={"dialog"} className="flex items-center justify-between mb-8">
          <p className="text-black text-[22px]">{t("popup_menu.account_setting")}</p>
          <CLoseIcon onClick={closeModal} />
        </DialogHeader>
        <DialogAccountSetting onOpenDialogChangePassword={() => setOpenDialogChangePassword(true)} />
      </CustomModal>
    </>
  );
};

export default DialogAccountSettingContainer;
