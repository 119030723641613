import { Avatar, AvatarProps } from "@material-tailwind/react";
import { DragEventHandler, LegacyRef } from "react";
import { defaultAvatar, getFullPathFileAvatar } from "utils/common";
interface AvatarPropType {
  src: string;
  className?: string;
  refImg?: LegacyRef<HTMLImageElement>;
  props?: AvatarProps;
  isConversation?: boolean;
  onDragStart?: DragEventHandler<HTMLImageElement> | undefined
}
const AvatarCatchError = ({ src, className = "", props, isConversation = false, onDragStart, refImg = null }: AvatarPropType) => {
  return (
    <Avatar
      {...props}
      className={`${className} rounded-full`}
      src={getFullPathFileAvatar(src, isConversation)}
      placeholder="avatar"
      alt="avatar"
      variant="rounded"
      onError={(e) => defaultAvatar(e, isConversation)}
      onDragStart={onDragStart}
      ref={refImg}
    />
  );
};

export default AvatarCatchError;
