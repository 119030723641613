/* eslint-disable max-len */
export default function ThemeIcon() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.34426 4.53304C5.47273 4.72574 5.66544 4.78998 5.85814 4.78998C5.98661 4.78998 6.11508 4.72574 6.24356 4.66151C6.5005 4.4688 6.56473 4.01915 6.37203 3.76221L5.98661 3.24833C5.79391 2.99139 5.4085 2.92715 5.08732 3.11986C4.83038 3.31257 4.76614 3.76221 4.95885 4.01915L5.34426 4.53304Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M2.06825 14.6822L1.4259 14.8749C1.10472 15.0034 0.912015 15.3246 1.04049 15.71C1.10472 15.9669 1.36166 16.1596 1.68284 16.1596H1.87554L2.5179 15.9669C2.83907 15.8385 3.03178 15.5173 2.90331 15.1319C2.77484 14.8107 2.38943 14.618 2.06825 14.6822Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M11.7678 2.92706C12.1533 2.92706 12.4102 2.67012 12.4102 2.28471V1.64235C12.4102 1.25694 12.1533 1 11.7678 1C11.3824 1 11.1255 1.25694 11.1255 1.64235V2.28471C11.1255 2.67012 11.3824 2.92706 11.7678 2.92706Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M1.49006 9.60763C1.49006 9.60763 2.26089 9.86458 2.32512 9.86458C2.58206 9.86458 2.839 9.67187 2.96748 9.41493C3.09595 9.09375 2.90324 8.70834 2.58206 8.57987L1.93971 8.38716C1.61853 8.25869 1.23312 8.4514 1.10465 8.77258C0.911946 9.09375 1.10465 9.47916 1.49006 9.60763Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M5.34426 19.9494L4.95885 20.4633C4.76614 20.7202 4.83038 21.1698 5.08732 21.3625C5.21579 21.4268 5.34426 21.491 5.47273 21.491C5.66544 21.491 5.85814 21.4268 5.98661 21.2341L6.37203 20.7202C6.56473 20.4633 6.5005 20.0136 6.24356 19.8209C5.98661 19.564 5.6012 19.6282 5.34426 19.9494Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M24.7433 11.085C24.5506 10.9565 24.2937 10.8923 24.101 10.9565C23.5229 11.1492 22.8805 11.2777 22.2382 11.1492C20.1826 10.828 18.7052 8.90098 19.0264 6.84545C19.1549 6.07463 19.4761 5.36804 20.0542 4.78992C20.2469 4.59722 20.2469 4.34028 20.1826 4.14757C20.1184 3.89063 19.8615 3.76216 19.5403 3.69792C17.9986 3.44098 16.457 3.8264 15.1723 4.59722C14.1445 4.14757 12.9883 3.89063 11.8321 3.89063C7.20712 3.8264 3.41724 7.61628 3.41724 12.2412C3.41724 16.8662 7.20712 20.656 11.8321 20.656C14.7226 20.656 17.4205 19.1786 18.9622 16.7377C21.7885 16.6092 24.2937 14.6179 25.0003 11.7273C25.0003 11.4704 24.9361 11.2135 24.7433 11.085ZM11.8321 19.3713C7.91371 19.3713 4.70194 16.1596 4.70194 12.2412C4.70194 8.32287 7.91371 5.1111 11.8321 5.1111C12.6029 5.1111 13.3095 5.23957 14.0161 5.43228C12.9883 6.39581 12.3459 7.68051 12.089 9.15792C11.5751 12.6909 13.9518 15.9669 17.4205 16.545C16.0716 18.3436 14.0161 19.3713 11.8321 19.3713ZM17.8059 15.3887C14.9154 14.9391 12.9241 12.3055 13.3095 9.41487C13.6949 6.84545 15.8146 4.98263 18.3198 4.9184C17.9986 5.43228 17.8059 6.07463 17.7417 6.71698C17.3563 9.4791 19.2833 12.0485 22.0455 12.4982C22.4951 12.5624 22.8805 12.5624 23.3302 12.4982C22.3666 14.4895 20.1184 15.7099 17.8059 15.3887Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M18.1274 19.9495C17.9347 19.6926 17.5492 19.6283 17.2281 19.821C16.9711 20.0137 16.9069 20.4634 17.0996 20.7203L17.485 21.2342C17.6135 21.4269 17.8062 21.4912 17.9989 21.4912C18.1274 21.4912 18.2558 21.4269 18.3843 21.3627C18.6412 21.17 18.7055 20.7203 18.5128 20.4634L18.1274 19.9495Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
      <path
        d="M11.7678 21.5552C11.3824 21.5552 11.1255 21.8121 11.1255 22.1975V22.8399C11.1255 23.2253 11.3824 23.4822 11.7678 23.4822C12.1533 23.4822 12.4102 23.2253 12.4102 22.8399V22.1975C12.4102 21.8121 12.089 21.5552 11.7678 21.5552Z"
        fill="#592C12"
        stroke="#592C12"
        strokeWidth="0.2"
      />
    </svg>
  );
}
