/* eslint-disable max-len */
const EditIcon = () => {
  return (
    <div className="w-6 h-6 bg-[#7F57000D] text-primary-900 rounded flex justify-center items-center">
      <svg className="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5484_151001)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.09533 2.42822C1.51208 2.01147 2.07731 1.77734 2.66668 1.77734H7.33335C7.82427 1.77734 8.22224 2.17531 8.22224 2.66623C8.22224 3.15715 7.82427 3.55512 7.33335 3.55512H2.66668C2.54881 3.55512 2.43576 3.60195 2.35241 3.6853C2.26906 3.76865 2.22224 3.88169 2.22224 3.99957V13.3329C2.22224 13.4508 2.26906 13.5638 2.35241 13.6472C2.43576 13.7305 2.54881 13.7773 2.66668 13.7773H12C12.1179 13.7773 12.2309 13.7305 12.3143 13.6472C12.3976 13.5638 12.4445 13.4508 12.4445 13.3329V8.66623C12.4445 8.17531 12.8424 7.77734 13.3333 7.77734C13.8243 7.77734 14.2222 8.17531 14.2222 8.66623V13.3329C14.2222 13.9223 13.9881 14.4875 13.5714 14.9042C13.1546 15.321 12.5894 15.5551 12 15.5551H2.66668C2.07731 15.5551 1.51208 15.321 1.09533 14.9042C0.678584 14.4875 0.444458 13.9223 0.444458 13.3329V3.99957C0.444458 3.4102 0.678584 2.84497 1.09533 2.42822Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3333 2.14106C13.194 2.14106 13.0604 2.19641 12.9618 2.29492L6.80261 8.45416L6.55497 9.44472L7.54553 9.19708L13.7048 3.03784C13.8033 2.93933 13.8586 2.80571 13.8586 2.66638C13.8586 2.52706 13.8033 2.39344 13.7048 2.29492C13.6062 2.19641 13.4726 2.14106 13.3333 2.14106ZM11.7048 1.03784C12.1367 0.605929 12.7225 0.363281 13.3333 0.363281C13.9441 0.363281 14.5299 0.605929 14.9618 1.03784C15.3938 1.46976 15.6364 2.05556 15.6364 2.66638C15.6364 3.2772 15.3938 3.86301 14.9618 4.29492L8.62851 10.6283C8.51459 10.7422 8.37185 10.823 8.21556 10.8621L5.54889 11.5287C5.24598 11.6045 4.92555 11.5157 4.70477 11.2949C4.48398 11.0741 4.39523 10.7537 4.47096 10.4508L5.13762 7.78413C5.1767 7.62783 5.25751 7.4851 5.37143 7.37118L11.7048 1.03784Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </div>
  );
};

export default EditIcon;
