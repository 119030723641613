const CaretDown = () => {
  return (
    <div>
      <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.8144 3.22263C10.6908 3.07417 10.5442 3 10.3749 3H1.62505C1.45567 
          3 1.30924 3.07417 1.18552 3.22263C1.06181 3.37125 1 3.54697 1 3.7501C1 
          3.95319 1.06181 4.1289 1.18552 4.2774L5.56048 9.52735C5.68433 9.67581 
          5.83076 9.75014 6 9.75014C6.16924 9.75014 6.31581 9.67581 6.43942 
          9.52735L10.8144 4.27736C10.938 4.1289 11 3.95319 11 3.75006C11 3.54697 10.938 3.37125 10.8144 3.22263Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default CaretDown;
