import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from "@tanstack/react-query";
import InputSearch from "components/input/InputSearch";
import ConversationLoadingSkeleton from "components/loading/ConversationLoadingSkeleton";
import { Dispatch, SetStateAction, useEffect } from "react";
import { ContactReceived } from "types/contact.type";
import { ChangeEvent } from "types/event.type";
import { PaginationResponse } from "types/utils.type";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";
import { Spinner } from "@material-tailwind/react";
import { t } from "i18next";
import ReceivedItem from "./SentItem";

interface SentListProp {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<PaginationResponse | undefined, unknown>, Error>>;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  sents: ContactReceived[];
  total: number | undefined;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
}
//init
const SentList = ({ sents, fetchNextPage, isFetchingNextPage, isLoading, setSearchKeyword, total }: SentListProp) => {
  const { ref, inView } = useInView();
  //flow scroll
  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);
  return (
    <div className="flex flex-col w-full h-full py-6 pb-3 pl-4 overflow-hidden">
      <div>
        <h2 className="text-xl font-bold mb-2">
          {t("contact.mailbox.sent")} ({total ?? 0})
        </h2>
        <InputSearch
          className="flex-1 h-8 px-2 mb-2 rounded-full bg-neutral-20"
          onChange={(e: ChangeEvent) => setSearchKeyword(e.target.value)}
        />
      </div>

      <div className="flex-1 w-full h-full overflow-y-auto scroll-small pr-2">
        {isLoading ? (
          <ConversationLoadingSkeleton />
        ) : (
          !isEmpty(sents) && (
            <>
              {sents.map((contact) => (
                <ReceivedItem key={contact.user_id} contact={contact} />
              ))}
              <div ref={ref}></div>
            </>
          )
        )}
        <div className="flex justify-center">{isFetchingNextPage && <Spinner className="w-10 h-10" />}</div>
      </div>
    </div>
  );
};
export default SentList;
