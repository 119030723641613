import { API_URL } from "constants/pathApi";
import * as axiosClient from "api/axiosClient";

interface SetingUerInterType {
  value: string;
}

export const getSetingConfig = async <T>(): Promise<T> => {
  const response = await axiosClient.getAxios<T>(API_URL.SETTING);
  return response;
};

export const updateSetting = async <T>(value: SetingUerInterType) => {
  try {
    const response = await axiosClient.postAxios<T>(API_URL.UPDATE_SETTING, {
      ...value,
    });
    return response;
  } catch (error) {
    return null;
  }
};
