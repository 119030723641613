const IconOpenTab = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11844_167224)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9056 0.976264C9.9056 0.437092 10.3427 0 10.8819 0H15.0237C15.5629 0 16 
            0.437092 16 0.976264V5.11819C16 5.65736 15.5629 6.09446 15.0237 6.09446C14.4846 
            6.09446 14.0475 5.65736 14.0475 5.11819V3.33296L8.81073 8.56979C8.42955 8.95106 
            7.81139 8.95106 7.43012 8.56979C7.04885 8.18852 7.04885 7.57035 7.43012 7.18918L12.6666 
            1.95253H10.8819C10.3427 1.95253 9.9056 1.51544 9.9056 0.976264ZM2.92874 0.867934C1.31125 
            0.867934 0 2.1792 0 3.79672V13.0712C0 14.6888 1.31125 16 2.92874 16H12.2031C13.8206 16 
            15.1319 14.6888 15.1319 13.0712V9.16612C15.1319 8.62701 14.6948 8.18993 14.1556 8.18993C13.6165 
            8.18993 13.1794 8.62701 13.1794 9.16612V13.0712C13.1794 13.6104 12.7423 14.0474 12.2031 14.0474H2.92874C2.38958 
            14.0474 1.9525 13.6104 1.9525 13.0712V3.79672C1.9525 3.25754 2.38958 2.82046 2.92874 2.82046H6.83369C7.37289 
            2.82046 7.80998 2.38337
             7.80998 1.8442C7.80998 1.30503 7.37289 0.867934 6.83369 0.867934H2.92874Z"
          fill="#592C12"
        />
      </g>
      <defs>
        <clipPath id="clip0_11844_167224">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOpenTab;
