import AvatarCatchError from "components/image/AvatarCatchError";
import { EventMessageServiceWorker } from "types/utils.type";

interface NotificationViewPropType {
  dataNotification: EventMessageServiceWorker | undefined;
  handleClick: (dataNotification: EventMessageServiceWorker | undefined) => void;
}
const NotificationView = ({ dataNotification, handleClick }: NotificationViewPropType) => {
  return (
    <div className="flex items-center gap-x-2" onClick={() => handleClick(dataNotification)}>
      <AvatarCatchError className="min-w-12" src={dataNotification?.avatar || ""} />
      <div className="flex flex-col">
        <div className="text-black font-semibold">{dataNotification?.title + " :"}</div>
        <div className="text-black line-clamp-4">{dataNotification?.content}</div>
      </div>
    </div>
  );
};

export default NotificationView;
