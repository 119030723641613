import SearchIcon from "components/icon/SearchIcon";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { ChangeEvent, KeyDownEvent } from "types/event.type";

interface InputSearchProp {
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value?: string;
  color?: string;
  onKeyDown?:(e:KeyDownEvent) => void;
  setIsFocus?: Dispatch<SetStateAction<boolean>>;
  onChange: (e: ChangeEvent) => void;
}

const InputSearch = ({
  placeholder = t("chat.search") as string,
  className = "",
  value,
  color,
  onChange,
  onKeyDown = () => {},
  disabled = false,
  setIsFocus,
}: InputSearchProp) => {
  return (
    <div className={`flex items-center gap-2 px-2 rounded-full bg-neutral-20 text-neutral-110 ${className}`}>
      <SearchIcon color={color} />
      <input
        value={value}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className="w-full h-full bg-transparent border-0 outline-none"
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => setIsFocus && setIsFocus(true)}
      />
    </div>
  );
};

export default InputSearch;
