interface ChevronLeftIconPropsType {
  onClick: () => void;
  className?: string;
}
const ChevronLeftIcon = ({ onClick,className }: ChevronLeftIconPropsType) => {
  return (
    <div onClick={onClick} className={`cursor-pointer ${className}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18L9 12L15 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default ChevronLeftIcon;
