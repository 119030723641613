const ThankReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6338 24.5909C23.4244 24.5909 28.9293 19.086 28.9293 12.2954C28.9293 5.50486 23.4244 0 16.6338 0C9.84323 0 4.33838 5.50486 
  4.33838 12.2954C4.33838 19.086 9.84323 24.5909 16.6338 24.5909Z"
        fill="#FFD54F"
      />
      <path
        d="M21.4534 13.7324C22.0066 13.7324 22.4184 14.2355 22.3188 14.7794C21.8266 17.4716 19.4686 19.5121 16.6338 19.5121C13.7989 
  19.5121 11.4409 17.4716 10.9488 14.7794C10.8491 14.2355 11.2609 13.7324 11.8141 13.7324H21.4534Z"
        fill="#F46767"
      />
      <path
        d="M30.4692 32.0002H2.79844C2.22369 32.0002 1.75781 31.5343 1.75781 30.9596V22.6344C1.75781 22.0596 2.22375 21.5938 2.79844 
  21.5938H30.4692C31.0439 21.5938 31.5098 22.0597 31.5098 22.6344V30.9596C31.5099 31.5343 31.0439 32.0002 30.4692 32.0002Z"
        fill="#55A1FF"
      />
      <path
        d="M29.4918 21.6588V22.3097C29.4918 23.8775 28.2163 25.153 26.6485 25.153H22.2258C20.6573 25.153 19.3818 23.8775 19.3818 
  22.3097V21.6588C19.3818 21.6369 19.3818 21.6156 19.3825 21.5938H29.4911C29.4918 21.6156 29.4918 21.6369 29.4918 21.6588Z"
        fill="#55A1FF"
      />
      <path
        d="M22.2255 23.35H26.6483C27.223 23.35 27.6889 22.8841 27.6889 22.3094V21.659C27.6889 20.6532 26.8735 19.8379 25.8678 
  19.8379H23.0059C22.0001 19.8379 21.1848 20.6533 21.1848 21.659V22.3094C21.1849 22.8841 21.6508 23.35 22.2255 23.35Z"
        fill="#FFD54F"
      />
      <path
        d="M25.8679 19.8369H24.3069C25.3126 19.8369 26.128 20.6523 26.128 21.658V22.3084C26.128 22.8832 25.6621 23.349 25.0874 
  23.349H26.6484C27.2231 23.349 27.689 22.8831 27.689 22.3084V21.658C27.689 20.6523 26.8736 19.8369 25.8679 19.8369Z"
        fill="#FFD54F"
      />
      <path
        d="M13.8858 21.6588V22.3097C13.8858 23.8775 12.6103 25.153 11.0419 25.153H6.61919C5.05138 25.153 3.77588 23.8775 3.77588 
  22.3097V21.6588C3.77588 21.6369 3.77588 21.6156 3.7765 21.5938H13.8851C13.8858 21.6156 13.8858 21.6369 13.8858 21.6588Z"
        fill="#55A1FF"
      />
      <path
        d="M11.0422 23.35H6.61948C6.04473 23.35 5.57886 22.8841 5.57886 22.3094V21.659C5.57886 20.6532 6.39423 19.8379 7.39998 
  19.8379H10.2618C11.2676 19.8379 12.0829 20.6533 12.0829 21.659V22.3094C12.0829 22.8841 11.617 23.35 11.0422 23.35Z"
        fill="#FFD54F"
      />
      <path
        d="M10.2617 19.8369H8.70068C9.70643 19.8369 10.5218 20.6523 10.5218 21.658V22.3084C10.5218 22.8832 10.0559 23.349 9.48118 
  23.349H11.0422C11.6169 23.349 12.0828 22.8831 12.0828 22.3084V21.658C12.0828 20.6523 11.2675 19.8369 10.2617 19.8369Z"
        fill="#FFD54F"
      />
      <path
        d="M14.8005 25.8189C14.7989 25.8145 14.7972 25.8103 14.7955 25.806C14.7107 25.5999 14.512 25.4668 14.2891 25.4668C14.2889 
  25.4668 14.2887 25.4668 14.2885 25.4668C14.0654 25.467 13.8667 25.6005 13.7822 25.807C13.7808 25.8105 13.7794 25.8141 13.778 
  25.8176L12.4725 29.2455C12.3803 29.4874 12.5017 29.7582 12.7437 29.8504C12.9857 29.9427 13.2564 29.821 13.3485 29.5792L13.5587
   29.0274H15.0092L15.217 29.5778C15.2878 29.7655 15.4662 29.8812 15.6557 29.8812C15.7106 29.8812 15.7666 29.8714 15.821 
   29.8509C16.0632 29.7594 16.1855 29.489 16.0941 29.2468L14.8005 25.8189ZM13.9158 28.0899L14.2874 27.1144L14.6555 28.0899H13.9158Z"
        fill="#6D4C41"
      />
      <path
        d="M20.4698 25.4668C20.2109 25.4668 20.0011 25.6767 20.0011 25.9355L20.0011 27.9289L18.4171 25.6727C18.2995 25.5054 18.0873 25.4335
   17.8922 25.495C17.6973 25.5567 17.5647 25.7375 17.5647 25.942V29.4123C17.5647 29.6712 17.7745 29.881 18.0334 29.881C18.2924 29.881 
   18.5022 29.6712 18.5022 29.4123V27.4254L20.0863 29.6816C20.1758 29.809 20.3201 29.881 20.47 29.881C20.517 29.881 20.5646 29.8739 
   20.6112 29.8592C20.8062 29.7976 20.9387 29.6167 20.9387 29.4123L20.9386 25.9355C20.9386 25.6767 20.7287 25.4668 20.4698 25.4668Z"
        fill="#6D4C41"
      />
      <path
        d="M23.6508 27.6045L25.0433 26.3245C25.2339 26.1494 25.2464 25.8528 25.0712 25.6622C24.896 25.4715 24.5994 25.4591 24.4088 
  25.6343L23.3245 26.631V25.9355C23.3245 25.6767 23.1147 25.4668 22.8557 25.4668C22.5968 25.4668 22.387 25.6767 22.387 
  25.9355V29.4124C22.387 29.6712 22.5968 29.8811 22.8557 29.8811C23.1147 29.8811 23.3245 29.6712 23.3245 29.4124V28.5924L24.524 
  29.7497C24.6149 29.8374 24.7322 29.8811 24.8493 29.8811C24.9722 29.8811 25.0948 29.8332 25.1867 29.7379C25.3665 29.5515
   25.3612 29.2548 25.1748 29.075L23.6508 27.6045Z"
        fill="#6D4C41"
      />
      <path
        d="M10.5264 25.4668C10.2675 25.4668 10.0577 25.6767 10.0577 25.9355V27.1456H8.58911V25.9355C8.58911 25.6767 8.37924 25.4668 
  8.12036 25.4668C7.86149 25.4668 7.65161 25.6767 7.65161 25.9355V29.4124C7.65161 29.6712 7.86149 29.8811 8.12036 29.8811C8.37924 
  29.8811 8.58911 29.6712 8.58911 29.4124V28.083H10.0577V29.4124C10.0577 29.6712 10.2675 29.8811 10.5264 29.8811C10.7853 29.8811 
  10.9952 29.6712 10.9952 29.4124V25.9355C10.9952 25.6767 10.7853 25.4668 10.5264 25.4668Z"
        fill="#6D4C41"
      />
      <path
        d="M28.8718 27.2616L28.7169 27.2045C28.311 27.0552 28.1072 26.9803 27.9223 26.8795C27.867 26.8494 27.8429 26.7959 27.8507 
  26.7206C27.8612 26.6197 27.9359 26.4928 28.1145 26.4389C28.5855 26.2973 29.0252 26.6423 29.0399 26.6539C29.2382 26.8181 
  29.532 26.7919 29.6978 26.5945C29.8643 26.3964 29.8388 26.1007 29.6406 25.9341C29.6072 25.906 28.8112 25.2498 27.8442 
  25.5413C27.3338 25.695 26.9704 26.1201 26.9182 26.6241C26.8714 27.0773 27.0843 27.4906 27.474 27.7028C27.7192 27.8364 
  27.9587 27.9244 28.3933 28.0843L28.5474 28.1411C28.6601 28.1826 29.0242 28.3368 28.9815 28.5763C28.9492 28.7568 28.7258 
  28.9435 28.4077 28.9435C28.0715 28.9435 27.7485 28.8084 27.5434 28.5821C27.3695 28.3903 27.073 28.3758 26.8813 28.5496C26.6895
   28.7234 26.6748 29.0199 26.8487 29.2117C27.2342 29.6371 27.8025 29.8811 28.4077 29.8811C29.1569 29.8811 29.7864 29.4017 
   29.9043 28.7412C29.9933 28.2428 29.7688 27.5924 28.8718 27.2616Z"
        fill="#6D4C41"
      />
      <path
        d="M5.73409 25.4668H3.81396C3.55509 25.4668 3.34521 25.6767 3.34521 25.9355C3.34521 26.1944 3.55509 26.4043 3.81396 
  26.4043H4.3014V29.4124C4.3014 29.6712 4.51128 29.8811 4.77015 29.8811C5.02903 29.8811 5.2389 29.6712 
  5.2389 29.4124V26.4043H5.73409C5.99296 26.4043 6.20284 26.1944 6.20284 25.9355C6.20284 25.6767 5.99296 25.4668 5.73409 25.4668Z"
        fill="#6D4C41"
      />
      <path
        d="M14.482 9.77565C14.2231 9.77565 14.0132 9.56577 14.0132 9.3069C14.0132 8.63315 13.465 8.08496 12.7913 8.08496C12.1175 
  8.08496 11.5693 8.63315 11.5693 9.3069C11.5693 9.56577 11.3595 9.77565 11.1006 9.77565C10.8417 9.77565 10.6318 9.56577 
  10.6318 9.3069C10.6318 8.11621 11.6006 7.14746 12.7913 7.14746C13.982 7.14746 14.9507 8.11621 14.9507 9.3069C14.9507 9.56577
   14.7408 9.77565 14.482 9.77565Z"
        fill="#6D4C41"
      />
      <path
        d="M22.167 9.77565C21.9081 9.77565 21.6983 9.56577 21.6983 9.3069C21.6983 8.63315 21.1501 8.08496 20.4763 8.08496C19.8026 8.08496 
  19.2544 8.63315 19.2544 9.3069C19.2544 9.56577 19.0446 9.77565 18.7856 9.77565C18.5267 9.77565 18.3169 9.56577 18.3169 
  9.3069C18.3169 8.11621 19.2856 7.14746 20.4763 7.14746C21.6671 7.14746 22.6358 8.11621 22.6358 9.3069C22.6358 9.56577 
  22.426 9.77565 22.167 9.77565Z"
        fill="#6D4C41"
      />
    </svg>
  );
};

export default ThankReaction;
