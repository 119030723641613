import { createSlice } from "@reduxjs/toolkit";
import { replaceSidebarType } from "logic/conversation.logic";
import { ACTION_OPEN_SIDEBAR, SidebarType } from "types/sidebar.type";
const initialState: {
  openSideBar: boolean;
  sideBarMultiLevel: SidebarType | null;
} = {
  openSideBar: false,
  sideBarMultiLevel: null,
};

const sideBarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.openSideBar = !state.openSideBar;
    },
    nextSideBar: (state, actions) => {
      // if now sidebar == old sidebar => off sidebar
      if (state.sideBarMultiLevel?.type === actions.payload.type) {
        if (actions.payload.type === ACTION_OPEN_SIDEBAR.SIDEBAR_REPLY && actions.payload.data) {
          // if open other reply => open
          // if click current reply => close
          if (+state.sideBarMultiLevel?.data?.messageId !== +actions.payload?.data?.messageId) {
            state.sideBarMultiLevel = {
              ...actions.payload,
              prevSidebar: state.sideBarMultiLevel?.prevSidebar,
            };
            return;
          }
          return;
        }
        state.sideBarMultiLevel = state.sideBarMultiLevel?.prevSidebar ?? null;
      } else {

        const newSideBar = {
          ...actions.payload,
          prevSidebar: {
            ...state.sideBarMultiLevel,
          },
        };
        // if now sidebar !== old sidebar => append action sidebar and move now side to prevSidebar
        state.sideBarMultiLevel = replaceSidebarType(newSideBar, actions?.payload?.type);
      }
    },
    prevSideBar: (state) => {
      // if don't have prev sidebar => remove all 
      state.sideBarMultiLevel = state.sideBarMultiLevel?.prevSidebar ?? null;
    },
    setSideBarMultiLevelNull: (state) => {
      state.sideBarMultiLevel = null;
    },
  },
});

export const { toggleSidebar, nextSideBar, prevSideBar, setSideBarMultiLevelNull } = sideBarSlice.actions;
export default sideBarSlice.reducer;
