import { useLocation } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import { useEffect } from "react";
import { useCustomDispatch } from "hook/redux";
import { prevSideBar } from "../../redux/slices/sidebar";

const DefaultLayoutContainer = () => {
  const { pathname } = useLocation();
  const dispatch = useCustomDispatch();
  useEffect(() => {
    dispatch(prevSideBar());
  }, [pathname]);

  return <DefaultLayout />;
};
export default DefaultLayoutContainer;
