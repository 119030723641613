const HuggingReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0402 29.8382C24.2798 29.8382 30.9593 23.1587 30.9593 14.9191C30.9593 6.67951 24.2798 0 16.0402 0C7.80061 
        0 1.12109 6.67951 1.12109 14.9191C1.12109 23.1587 7.80061 29.8382 16.0402 29.8382Z"
        fill="#FFDD67"
      />
      <path
        opacity="0.8"
        d="M29.8986 11.5932C30.3631 9.85927 28.8041 7.93507 26.4165 7.29534C24.0288 6.65562 21.7166 
        7.54262 21.2521 9.27651C20.7875 11.0104 22.3465 12.9346 24.7341 13.5743C27.1218 14.2141 29.434 13.3271 29.8986 11.5932Z"
        fill="#FF717F"
      />
      <path
        opacity="0.8"
        d="M7.40752 16.6119C9.43249 15.1942 10.2395 12.8529 9.21003 11.3824C8.18055 9.91189 5.70444 
        9.86903 3.67947 11.2867C1.6545 12.7043 0.847492 15.0456 1.87697 16.5161C2.90644 17.9866 5.38255 18.0295 7.40752 16.6119Z"
        fill="#FF717F"
      />
      <path
        d="M13.2162 8.63161C11.8842 6.34046 10.3922 5.38137 9.06018 5.64779C7.72812 5.86092 6.60918 
        7.29955 6.18292 9.91039C6.12964 10.1768 6.66247 10.5498 6.8756 10.2301C7.56827 9.16444 8.52735 
        8.63161 9.593 8.47176C10.6587 8.25863 11.7243 8.47176 12.7367 9.21772C13.0031 9.37757 13.3761 
        8.84474 13.2162 8.63161ZM23.6063 6.76672C22.2743 4.52885 20.7823 3.51649 19.4503 3.7829C18.1182 
        3.99603 16.9993 5.48794 16.573 8.0455C16.5197 8.31191 17.0526 8.68489 17.2657 8.3652C17.9584 
        7.29955 18.9175 6.76672 19.9831 6.60687C21.0488 6.39374 22.1144 6.60687 23.1268 7.35283C23.3399 
        7.51268 23.7129 7.03313 23.6063 6.76672ZM21.848 13.0541C18.4912 16.4109 14.2286 17.1568 9.9127
         15.1321C9.37987 14.8657 9.06018 15.5051 9.48644 15.9846C10.9784 17.6896 13.6425 18.9684 16.6263
          18.4356C19.6101 17.9028 21.6881 15.7715 22.4874 13.6402C22.7538 13.1606 22.2743 12.6811 21.848 13.0541Z"
        fill="#664E27"
      />
      <path
        d="M13.4826 23.8702C13.4826 23.8702 14.4417 23.5505 14.0687 22.2717C13.6957 20.9397 10.3922
         22.2717 8.74043 21.6323C8.74043 21.6323 10.0725 21.2061 9.64623 20.0871C9.21997 19.0748 5.59676 
         18.7018 2.02683 21.4725C-1.5431 24.2432 0.748045 30.6371 5.1705 31.4363C8.26088 31.9691 12.9497 
         31.9159 13.5359 30.5838C14.0154 29.5181 12.5235 29.1452 12.5235 29.1452C12.5235 29.1452 14.3884 
         29.1452 14.3884 27.7065C14.3884 26.854 13.6957 26.854 13.6957 26.854C13.6957 26.854 15.2409 26.6409 
         15.2409 25.2023C15.2942 23.9768 13.4826 23.8702 13.4826 23.8702Z"
        fill="white"
      />
      <path
        d="M14.1217 23.9243C15.2939 23.125 14.2815 21.2069 12.8429 21.42C11.6174 21.6331 9.48611 
        21.6331 9.48611 21.6331C9.48611 21.6331 10.1788 21.3667 10.0189 20.4609C9.85908 19.5551 8.5803 
        18.2231 3.46518 20.3011C1.54701 21.1003 0.321507 22.6988 0.108377 24.6702C-0.104753 26.8015 0.854332 
        29.3058 2.39953 30.4248C4.10457 31.7035 8.10076 32.4495 11.9371 31.7035C14.4947 31.224 14.2283 29.5722 
        13.1626 29.3058C14.9209 28.9861 15.0808 27.4942 14.2283 27.068C16.1997 26.4286 15.6669 23.9776 14.1217
         23.9243ZM12.7363 26.6417C11.724 26.7483 9.00656 26.9614 9.00656 26.9614C9.00656 26.9614 10.8715 27.3344
          12.8429 27.0147C14.6012 26.7483 14.4947 28.5066 12.0437 28.8263C10.8715 28.9861 9.00656 29.0394 
          9.00656 29.0394C9.00656 29.0394 10.7116 29.3058 11.6174 29.1993C13.9618 28.8796 13.6954 30.6379 
          11.5108 30.9576C7.62122 31.4904 4.47755 30.9576 2.93235 29.8386C0.747767 28.2402 -0.531014 22.9119 
          3.73159 20.9405C5.64976 20.0347 8.79343 19.2354 9.27297 20.3011C9.91237 21.8463 7.03511 22.0061 
          7.03511 22.0061C7.03511 22.0061 9.37954 22.3258 12.7363 21.9528C13.6421 21.8463 14.3348 22.9652 
          13.2159 23.6046C12.2568 24.1907 9.00656 24.4038 9.00656 24.4038C9.00656 24.4038 10.7649 24.4571 
          13.2159 24.244C15.1873 24.0841 15.3472 26.322 12.7363 26.6417Z"
        fill="#CCCFD4"
      />
      <path
        d="M18.5976 23.8702C18.5976 23.8702 17.6385 23.5505 18.0115 22.2717C18.3845 20.9397 21.688 22.2717 
        23.3398 21.6323C23.3398 21.6323 22.0077 21.2061 22.434 20.0871C22.8602 19.0748 26.4834 18.7018 30.0534
         21.4725C33.6233 24.2432 31.2789 30.6371 26.9097 31.383C23.8193 31.9159 19.1304 31.8626 18.5443 
         30.5305C18.0648 29.4649 19.5567 29.0919 19.5567 29.0919C19.5567 29.0919 17.6918 29.0919 17.6918 
         27.6533C17.6918 26.8007 18.3845 26.8007 18.3845 26.8007C18.3845 26.8007 16.8393 26.5876 16.8393 
         25.149C16.786 23.9768 18.5976 23.8702 18.5976 23.8702Z"
        fill="white"
      />
      <path
        d="M17.8517 27.0146C17.0524 27.4408 17.2123 28.9327 18.9173 29.2524C17.8517 29.5188 17.5852 31.1706 
        20.1428 31.6501C24.0324 32.3961 27.9753 31.7034 29.6804 30.3714C31.1723 29.2524 32.1847 26.7482 
        31.9715 24.6169C31.7584 22.6454 30.5329 21.0469 28.6147 20.2477C23.4463 18.1697 22.2208 19.4484 
        22.061 20.4075C21.9011 21.3133 22.5938 21.5797 22.5938 21.5797C22.5938 21.5797 20.4625 21.5797 
        19.237 21.3666C17.7984 21.1002 16.786 23.0717 17.9582 23.8709C16.413 23.9775 15.8802 26.4285 
        17.8517 27.0146ZM18.864 24.2439C21.315 24.457 23.0734 24.4037 23.0734 24.4037C23.0734 24.4037 
        19.8231 24.1906 18.864 23.6045C17.7451 22.9651 18.4378 21.8462 19.3436 21.9527C22.7537 22.379
         25.0448 22.006 25.0448 22.006C25.0448 22.006 22.1675 21.7929 22.8069 20.301C23.2865 19.2353 
         26.4302 20.0346 28.3483 20.9404C32.6109 22.9651 31.2789 28.2401 29.1476 29.8385C27.6557 30.9575
          24.4587 31.5436 20.5691 30.9575C18.3312 30.6378 18.0648 28.8795 20.4625 29.1992C21.3683 29.3057 
          23.0734 29.0393 23.0734 29.0393C23.0734 29.0393 21.2085 28.986 20.0362 28.8262C17.5852 28.5065
           17.4787 26.7482 19.237 27.0146C21.1552 27.281 23.0734 26.9613 23.0734 26.9613C23.0734 26.9613
            20.3559 26.8014 19.3436 26.6416C16.7327 26.3219 16.8926 24.084 18.864 24.2439Z"
        fill="#CCCFD4"
      />
    </svg>
  );
};

export default HuggingReaction;
