export default function EmailIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_8044_55138"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 1.90735e-06H24V24H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_8044_55138)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5595 13.5553C12.2274 13.8024 11.7726 13.8024 
          11.4405 13.5553L1.875 6.44044V5.06249C1.875 4.54475 
          2.29475 4.12499 2.8125 4.12499H21.1875C21.7052 4.12499 
          22.125 4.54475 22.125 5.06249V6.44044L12.5595 13.5553ZM13.6786 
          15.0598C13.6786 15.0598 13.6786 15.0598 13.6786 15.0598L24 7.38261V5.06249C24 
          3.50921 22.7408 2.24999 21.1875 2.24999H2.8125C1.25922 2.24999 0 3.50921 0 
          5.06249V7.38261L10.3214 15.0598C11.3178 15.8009 12.6822 15.8009 13.6786 15.0598Z"
          fill="#592C12"
        />
        <path
          d="M22.125 11.114V18.9375C22.125 19.4544 21.7044 19.875 21.1875 
          19.875H2.8125C2.29556 19.875 1.875 19.4544 1.875 18.9375V11.114L0 
          9.71939V18.9375C0 20.4883 1.26169 21.75 2.8125 21.75H21.1875C22.7383 21.75 24 20.4883 24 18.9375V9.71939L22.125 11.114Z"
          fill="#592C12"
        />
      </g>
    </svg>
  );
}
