import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { rootReducer } from "./reducer/reducer";
import socketMiddleware from "./middleware/socketMiddleware";


const store = configureStore({
  reducer: rootReducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware({ serializableCheck: false }).concat(socketMiddleware),
});

// types
export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<Promise<unknown>, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);

export default store;
