import CalenderIcon from "components/icon/file/CalenderIcon";
import PolygonDownIcon from "components/icon/file/PolygonDownIcon";
import { t } from "i18next";
interface ButtonByTimeProps {
  openCalender: boolean;
  onclick: () => void;
  filterFrom: string | Date | null;
  filterTo: string | Date | null;
  typeToFilter: number | undefined;
}
export default function ButtonByTime({ filterFrom, filterTo, typeToFilter, onclick, openCalender }: ButtonByTimeProps) {
  const isSelected = openCalender || filterFrom || filterTo || typeToFilter;
  return (
    <div
      onClick={onclick}
      className={`flex-1 by-time flex  px-4 py-1 border-[1px] 
   rounded-3xl items-center justify-between cursor-pointer
  ${isSelected ? "border-primary-900" : "border-neutral-70"}
  `}
    >
      <div className="flex items-center gap-2">
        <CalenderIcon color={isSelected ? "#592C12" : ""} />
        <span className={`${isSelected ? "text-primary-900" : ""}`}>{t("files.by_time")}</span>
      </div>
      <PolygonDownIcon color={isSelected ? "#592C12" : ""} />
    </div>
  );
}
