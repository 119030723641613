/* eslint-disable max-len */
const SoundWave = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4.5C5.586 4.5 5.25 4.836 5.25 5.25V18.75C5.25 19.164 5.586 19.5 6 19.5C6.414 19.5 6.75 19.164 6.75 18.75V5.25C6.75 4.836 6.414 4.5 6 4.5ZM12 4.5C11.586 4.5 11.25 4.836 11.25 5.25V18.75C11.25 19.164 11.586 19.5 12 19.5C12.414 19.5 12.75 19.164 12.75 18.75V5.25C12.75 4.836 12.414 4.5 12 4.5ZM18 4.5C17.586 4.5 17.25 4.836 17.25 5.25V18.75C17.25 19.164 17.586 19.5 18 19.5C18.414 19.5 18.75 19.164 18.75 18.75V5.25C18.75 4.836 18.414 4.5 18 4.5ZM3 7.5C2.586 7.5 2.25 7.836 2.25 8.25V15.75C2.25 16.164 2.586 16.5 3 16.5C3.414 16.5 3.75 16.164 3.75 15.75V8.25C3.75 7.836 3.414 7.5 3 7.5ZM9 7.5C8.586 7.5 8.25 7.836 8.25 8.25V15.75C8.25 16.164 8.586 16.5 9 16.5C9.414 16.5 9.75 16.164 9.75 15.75V8.25C9.75 7.836 9.414 7.5 9 7.5ZM15 7.5C14.586 7.5 14.25 7.836 14.25 8.25V15.75C14.25 16.164 14.586 16.5 15 16.5C15.414 16.5 15.75 16.164 15.75 15.75V8.25C15.75 7.836 15.414 7.5 15 7.5ZM21 7.5C20.586 7.5 20.25 7.836 20.25 8.25V15.75C20.25 16.164 20.586 16.5 21 16.5C21.414 16.5 21.75 16.164 21.75 15.75V8.25C21.75 7.836 21.414 7.5 21 7.5Z"
        fill="#1F1B16"
      />
    </svg>
  );
};

export default SoundWave;
