const TaskIcon = () => {
  return (
    <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8 1.6H9.456C9.12 0.672 8.24 0 7.2 0C6.16 0 5.28 0.672 4.944 1.6H1.6C0.72 1.6 0 2.32 0 3.2V14.4C0 15.28 0.72 
        16 1.6 16H12.8C13.68 16 14.4 15.28 14.4 14.4V3.2C14.4 2.32 13.68 1.6 12.8 1.6ZM7.2 1.6C7.64 1.6 8 1.96 8 2.4C8 
        2.84 7.64 3.2 7.2 3.2C6.76 3.2 6.4 2.84 6.4 2.4C6.4 1.96 6.76 1.6 7.2 1.6ZM5.6 12.8L2.4 9.6L3.528 8.472L5.6 
        10.536L10.872 5.264L12 6.4L5.6 12.8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TaskIcon;
