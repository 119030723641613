import { Spinner } from "@material-tailwind/react";
import { ROLE_MEMBER } from "constants/constans";
import { BASE_SRC_FILE } from "constants/pathApi";
import { useCustomSelector } from "hook/redux";
import { t } from "i18next";
import { copyLinkFile } from "logic/message.logic";
import { TYPE_MESSAGE_FILE } from "types/message.type";
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
import MoreIcon from "components/icon/MoreIcon";
import { placement } from "@material-tailwind/react/types/components/menu";
import { Dispatch, SetStateAction, useState } from "react";
import { downloadFile } from "utils/common";
import { MouseEvent } from "types/event.type";
interface ActionFileProps {
  data: TYPE_MESSAGE_FILE | null;
  handleScrollMessage: () => void;
  deleteFile?: any;
  isPending: boolean;
  placement?: placement | undefined;
  isPreview?: boolean;
  colorIcon?: string;
  hiddenDeleteAndCopy?: boolean;
  openAction: boolean;
  setOpenAction: Dispatch<SetStateAction<boolean>>;
  setDataPreview?: Dispatch<SetStateAction<TYPE_MESSAGE_FILE | null | undefined>>;
  isPreviewImage?: boolean;
  }
export default function ActionFile({
  isPreview,
  data,
  handleScrollMessage,
  deleteFile,
  isPending,
  colorIcon,
  placement,
  openAction,
  hiddenDeleteAndCopy = false,
  setOpenAction,
  setDataPreview,
  isPreviewImage = false,
}: ActionFileProps) {
  const { userInfo } = useCustomSelector((state) => state.auth);
  const { conversationSelected } = useCustomSelector((state) => state.conversation);
  const [checkDelete, setCheckDelete] = useState<boolean>(false);

  const handleAction = (
    action: () => void,
    e: MouseEvent<HTMLLIElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    setOpenAction((prev) => !prev);
    action();
  };

  return (
    <div>
      <Menu
        dismiss={{
          itemPress: false,
        }}
        placement={placement}
        open={openAction}
        handler={() => setOpenAction((prev) => !prev)}
      >
        <MenuHandler>
          <div className="relative">
            <MoreIcon color={colorIcon} />
          </div>
        </MenuHandler>
        <MenuList className="p-0 z-[9999]" placeholder={""}>
          {isPreview && (
            <MenuItem
              className="h-9 py-2 px-4 cursor-pointer text-nowrap bg-white hover:!bg-neutral-20 text-neutral-110 hover:!rounded-none"
              onClick={(e) =>
                handleAction(() => {
                  setDataPreview && setDataPreview(data);
                }, e)
              }
              placeholder={""}
            >
              {t("action.preview")}
            </MenuItem>
          )}
          <MenuItem
            placeholder={""}
            className="h-9 py-2 px-4 cursor-pointer text-nowrap bg-white hover:!bg-neutral-20 text-neutral-110 hover:!rounded-none"
            onClick={(e) => handleAction(handleScrollMessage, e)}
          >
            {t("action.jumbToMessage")}
          </MenuItem>
          {(!isPreviewImage && !hiddenDeleteAndCopy) && (
            <MenuItem
              placeholder={""}
              className="h-9 py-2 px-4 cursor-pointer bg-white hover:!bg-neutral-20 text-neutral-110 hover:!rounded-none"
              onClick={(e) => {
                e.stopPropagation();
                copyLinkFile(data && data?.path);
              }}
            >
              {t("files.file.action.copyLinkFile")}
            </MenuItem>
          )}
          <MenuItem
            placeholder={""}
            className="h-9 py-2 px-4 cursor-pointer bg-white
             hover:!bg-neutral-20 text-neutral-110 hover:!rounded-none flex gap-2 items-center"
            onClick={(e) => handleAction(() => downloadFile(BASE_SRC_FILE && BASE_SRC_FILE + data?.path, data?.name), e)}
          >
            {t("action.download")}
          </MenuItem>
          {(userInfo?.id === data?.user_id || conversationSelected?.is_admin === ROLE_MEMBER.ADMIN) && !hiddenDeleteAndCopy ? (
            <MenuItem
              placeholder={""}
              onClick={() => {
                deleteFile();
                setCheckDelete((prev) => !prev);
              }}
              disabled={isPending || (!isPreviewImage && checkDelete)}
              className={`h-9 text-red-700 hover:!text-red-700 p-2 px-4 text-left  hover:!rounded-none
                   bg-white hover:!bg-neutral-20 disable flex gap-2 items-center bg-inherit ${isPending ? "opacity-50" : ""}`}
            >
              <span>{t("action.delete")}</span>
              {isPending && <Spinner className="w-4" />}
            </MenuItem>
          ) : (
            <></>
          )}
        </MenuList>
      </Menu>
    </div>
  );
}
