import { DialogBody, DialogFooter } from "@material-tailwind/react";
import ButtonCommon from "components/button/ButtonCommon";
import EyeIcon from "components/icon/EyeIcon";
import InputValidate from "components/input/InputValidate";
import useToggleValue from "hook/useToggleValue";
import { t } from "i18next";

interface DialogChangePasswordProp {
  isPending?: boolean;
}
const DialogChangePassword = ({ isPending }: DialogChangePasswordProp) => {
  const { value: showPassword, handleToggleValue: handleShowPassword } = useToggleValue(false);
  const { value: showNewPassword, handleToggleValue: handleShowNewPassword } = useToggleValue(false);
  const { value: showConfirmPassword, handleToggleValue: handleShowConfirmPassword } = useToggleValue(false);

  return (
    <DialogBody placeholder={"dialog"} className="px-4 pt-0">
      <div className="mb-4 relative">
        <label className="text-neutral-60 font-medium text-sm block mb-2" htmlFor="">
          {t("label.current_password")}
        </label>
        <InputValidate
          type={showPassword ? "text" : "password"}
          placeholder={t("input_placeholder.enter_current_password") as string}
          name="password"
        />
        <EyeIcon open={showPassword} onClick={handleShowPassword} className="absolute top-[38px] right-[15px] cursor-pointer" />
      </div>
      <div className="mb-4 relative">
        <label className="text-neutral-60 font-medium text-sm block mb-2" htmlFor="">
          {t("label.new_password")}
        </label>
        <InputValidate
          type={showNewPassword ? "text" : "password"}
          placeholder={t("input_placeholder.enter_new_password") as string}
          name="new_password"
        />
        <EyeIcon open={showNewPassword} onClick={handleShowNewPassword} className="absolute top-[38px] right-[15px] cursor-pointer" />
      </div>
      <div className="mb-4 relative">
        <label className="text-neutral-60 font-medium text-sm block mb-2" htmlFor="">
          {t("label.confirm_password")}
        </label>
        <InputValidate
          type={showConfirmPassword ? "text" : "password"}
          placeholder={t("input_placeholder.confirm_new_password") as string}
          name="confirm_password"
        />
        <EyeIcon
          open={showConfirmPassword}
          onClick={handleShowConfirmPassword}
          className="absolute top-[38px] right-[15px] cursor-pointer"
        />
      </div>
      <DialogFooter placeholder={"dialog"} className="!mt-0 gap-x-4 px-0">
        <ButtonCommon
          type="submit"
          isPending={isPending}
          disabled={isPending}
          className={`text-white bg-primary-900 py-1 rounded text-lg w-full ${isPending && "justify-center"}`}
          title={t("button.change")}
        />
      </DialogFooter>
    </DialogBody>
  );
};

export default DialogChangePassword;
