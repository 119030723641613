import { MouseEvent } from "types/event.type";

interface MuteIconProp {
  onClick?: (event:MouseEvent<HTMLDivElement>) => void;
  isShow?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const MuteIcon = ({ onClick = () => {}, isShow = true }: MuteIconProp) => {
  return (
    <div
      className={`cursor-pointer ${isShow ? "hover:text-[#87644e] text-[#5f4a3d]" : "hover:text-[#5f4a3d] text-[#87644e]"}`}
      onClick={(e) => onClick(e)}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.0004 22.5C13.585 22.5 14.9106 21.3704 15.2151 19.875H8.78564C9.09014 21.3704 10.4158 22.5 12.0004 22.5Z"
          fill="currentColor"
        />
        <path
          d="M5.87497 9.37514V11.8146C5.87497 13.5462 5.11636 15.1807 
          3.78549 16.3059C3.44512 16.5973 3.25 17.0208 3.25 17.4688C3.25 
          18.3132 3.93687 19 4.78123 19H16.2156L5.9161 8.69965C5.8916 8.92189 5.87497 9.14677 5.87497 9.37514Z"
          fill="currentColor"
        />
        <path
          d="M22.2436 21.0064L20.0019 18.7647C20.4446 
          18.496 20.75 18.0235 20.75 17.4688C20.75 17.0208 20.5549 16.5973 
          20.2058 16.2989C18.8837 15.1807 18.125 13.5462 18.125 11.8146V9.37514C18.125 
          6.29604 15.8378 3.7472 12.8751 3.3202V2.37521C12.8751 1.89134 12.4831 1.50022 
          12.0001 1.50022C11.5171 1.50022 11.1251 1.89134 11.1251 2.37521V3.3202C9.44689 
          3.5617 7.99528 4.49269 7.04416 5.80692L2.99383 1.75659C2.65171 1.41447 2.09871 
          1.41447 1.75659 1.75659C1.41447 2.09871 1.41447 2.65171 1.75659 2.99383L21.0064 
          22.2436C21.177 22.4143 21.401 22.5 21.625 22.5C21.849 22.5 22.073 22.4143 22.2436 
          22.2436C22.5857 21.9015 22.5857 21.3485 22.2436 21.0064Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default MuteIcon;
