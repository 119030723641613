import { firebaseMessaging } from "../../firebase/config.firebase";
import { onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { toast } from "react-toastify";
import NotificationView from "./NotificationView";
import { NOTIFICATION_TYPE } from "constants/constans";
import { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { ALL_CONTACT, CHAT, CONTACT, RECEIVED } from "constants/pathUrl";
import { useCustomDispatch } from "hook/redux";
import { setConversationIdFetch, setDataNotification } from "../../redux/slices/conversation";
import { EventMessageServiceWorker } from "types/utils.type";
import { setScrollId } from "../../redux/slices/contact";

const Notification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useCustomDispatch();
  const handleClick = (dataNotification: EventMessageServiceWorker | undefined) => {
    const typeNotification = dataNotification?.["notification_type"];
    if(typeNotification){
      switch (+typeNotification) {
        case NOTIFICATION_TYPE.NOTIFICATION_TYPE_MESSAGE:
          if (dataNotification?.conversation_id) {
            navigate(CHAT);
            dispatch(setDataNotification(dataNotification));
          }
          break;
        case NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONVERSATION:
          if (dataNotification?.id) {
            navigate(CHAT);
            dispatch(setConversationIdFetch(dataNotification?.id));
          }
          break;
        case NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONTACT:
          if (dataNotification?.conversation_id) {
            navigate(CONTACT + "/" + ALL_CONTACT);
            dispatch(setScrollId(dataNotification?.conversation_id));
          } else {
            navigate(CONTACT + "/" + RECEIVED);
            dispatch(setScrollId(dataNotification?.contact_id));
          }
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (firebaseMessaging) {
      onMessage(firebaseMessaging, (payload) => {
        const data = cloneDeep(payload.data);

        // notification new message
        if (data && +data?.["notification_type"] === NOTIFICATION_TYPE.NOTIFICATION_TYPE_MESSAGE && location.pathname !== CHAT) {
          toast(<NotificationView dataNotification={data as any} handleClick={handleClick} />);

          // notification when join room
        } else if (
          data &&
          +data?.["notification_type"] === NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONVERSATION &&
          location.pathname !== CHAT &&
          data?.["id"]
        ) {
          toast(<NotificationView dataNotification={data as any} handleClick={handleClick} />);

          // notification accept contact
        } else if (
          data &&
          +data?.["notification_type"] === NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONTACT &&
          location.pathname !== CONTACT + "/" + ALL_CONTACT &&
          data?.["conversation_id"]
        ) {
          toast(<NotificationView dataNotification={data as any} handleClick={handleClick} />);

          // notification new request contact
        } else if (
          data &&
          +data?.["notification_type"] === NOTIFICATION_TYPE.NOTIFICATION_TYPE_CONTACT &&
          location.pathname !== CONTACT + "/" + RECEIVED &&
          data?.["contact_id"]
        ) {
          toast(<NotificationView dataNotification={data as any} handleClick={handleClick} />);
        }
      });
    }
  }, [firebaseMessaging, location]);
  return <div></div>;
};

export default Notification;
