import { PaginationResponse, SuccessResponse } from "./utils.type";

export interface Conversation {
  content_file: string | null;
  content_text: string;
  conversation_avatar: string;
  conversation_id: number;
  conversation_name: string;
  conversation_pin: PIN_CONVERSATION;
  encrypt_conversation_id: string;
  invite_link: string;
  is_mute: CONVERSATION_MUTE;
  message_count_unread: number;
  message_id: number;
  message_to_unread: number;
  message_type_user: number;
  most_recent_message: string | null;
  time_send_message: string;
  time_send_message_default: string;
  total_members_in_conversation: number;
  type: TYPE_CONVERSATION;
  user_id: number;
  desciption: string;
  is_admin: number;
  contact_id: number | null;
  is_hide: CONVERSATION_HIDE;
}
export interface OutConversationType {
  conversation_id: number, 
  delete_by: number, 
}
export enum CONVERSATION_MUTE {
  NOT_MUTE,
  MUTE,
}
export enum CONVERSATION_HIDE {
  HIDE = 1,
  NOT_HIDE,
}
export interface PinResponse {
  user_id: number;
  conversation_id: number;
  updated_at: string;
  created_at: string;
  id: number;
  event: string;
  receiver: number[];
}

export enum PIN_CONVERSATION {
  NOT_PIN,
  PIN,
}
export enum ACTION_CONVERSATION {
  PIN = 1,
  MUTE,
  SOCKET,
  UPDATE_LOCAL
}
export enum TYPE_CONVERSATION {
  MULTI_USER,
  MY_CHAT,
  TWO_USER,
}
export enum TYPE_CONVERSATION_FILTER {
  ALL,
  UNREAD,
  DIRECT = 3,
  GROUP,
  MUTE,
  GET_HIDDEN
}

export interface ListConversation extends PaginationResponse {
  data: Conversation[];
}

export type DetailConversation = SuccessResponse<Conversation>;

export interface UPDATE_CONVERSATION_INFO {
  type: TYPE_CONVERSATION;
  avatar?: File | undefined;
  name: string;
  desciption?: string | null;
}

export interface CREATE_CONVERSATION {
  name: string;
  type: number;
  avatar: string | null;
  desciption?: string | null;
  updated_at: string | null;
  created_at: string | null;
  id: number;
}

export interface CREATE_CONVERSATION_PARAM {
  type: number;
  avatar?: File | undefined;
  name: string;
  desciption: string | null;
  users: string;
}
