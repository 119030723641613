import AvatarCatchError from "components/image/AvatarCatchError";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { ContactReceived, TYPE_ACCEPT_OR_REJECT_CONTACT } from "types/contact.type";
import { nextSideBar } from "../../../redux/slices/sidebar";
import { postAcceptOrRejectContact } from "http/contact.http";
import { setContactSent, setContactSelected, setIsDisableBtn } from "../../../redux/slices/contact";
import { ACTION_OPEN_SIDEBAR } from "types/sidebar.type";
import { t } from "i18next";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { sendRequestContact } from "http/message.http";
import { useMutation } from "@tanstack/react-query";
import ButtonCommon from "components/button/ButtonCommon";
import { AxiosError } from "axios";
import { ErrorResponse, SuccessResponse } from "types/utils.type";
import { STATUS_API, STATUS_CODE } from "constants/statusCode";
import { useEffect } from "react";

interface SentItemProp {
  contact: ContactReceived;
}
const SentItem = ({ contact }: SentItemProp) => {
  const dispatch = useCustomDispatch();
  const { sideBarMultiLevel } = useCustomSelector((state) => state.sidebar);
  const { contactSent, userContactSelected, isDisableBtn } = useCustomSelector((state) => state.contact);

  const { mutate: deleteContact, isPending: pendingDeleteContact } = useMutation({
    mutationFn: () => postAcceptOrRejectContact<SuccessResponse<ContactReceived>>(contact.contact_id, TYPE_ACCEPT_OR_REJECT_CONTACT.REJECT),
    onSuccess: (response) => {
      toast[response?.code === STATUS_CODE.Ok ? "success" : "error"](response?.message);
      if (contactSent && contactSent.length > 0) {
        dispatch(
          setContactSent([
            ...contactSent,
            {
              user_id: contact.user_id,
              isSent: false,
            },
          ]),
        );
      } else {
        dispatch(
          setContactSent([
            {
              user_id: contact.user_id,
              isSent: false,
            },
          ]),
        );
      }
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (error?.response?.data.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(t("exception.server_error"));
      }
    },
  });

  const {
    mutate: addContact,
    isPending: pendingAddContact,
    status,
  } = useMutation({
    mutationFn: () =>
      sendRequestContact({
        typeRequest: undefined,
        value: contact.user_id.toString(),
      }),
    onSuccess: (response) => {
      toast[response?.code === STATUS_CODE.Ok ? "success" : "error"](response?.message);
      if (contactSent && contactSent.length > 0) {
        const newData = contactSent.map((item) => {
          if (item.user_id === contact.user_id) {
            return { ...item, isSent: true };
          }
          return item;
        });
        dispatch(setContactSent(newData));
      }
    },
    onError: (error: AxiosError<ErrorResponse>) => {
      if (status === STATUS_API.Error) {
        if (error?.response?.data.message) {
          toast.error(error.response?.data.message);
        } else {
          toast.error(t("exception.server_error"));
        }
      }
    },
  });

  useEffect(() => {
    if (pendingDeleteContact || pendingAddContact) {
      dispatch(setIsDisableBtn(true));
    } else {
      dispatch(setIsDisableBtn(false));
    }
  }, [pendingDeleteContact, pendingAddContact]);

  // handle open side
  const handleOpenSideBarSent = () => {
    if (isEmpty(sideBarMultiLevel)) {
      dispatch(
        nextSideBar({
          type: ACTION_OPEN_SIDEBAR.SIDEBAR_SENT,
        }),
      );
    }
    dispatch(setContactSelected({ user_id: contact.user_id, contact_id: contact.contact_id }));
  };

  const handleDeleteSentContact = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await deleteContact();
  };

  const handleSentContact = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await addContact();
  };

  return (
    <div className="group relative" onClick={handleOpenSideBarSent}>
      <div
        className={`flex ${
          userContactSelected?.user_id === contact.user_id && "bg-neutral-20"
        }  border-b mt-5 w-full items-center gap-2 p-3 pr-2 transition-colors cursor-pointer hover:bg-neutral-20`}
      >
        <AvatarCatchError className="w-12 h-12" src={contact?.avatar} />
        <div className="">
          <h3>{contact.name}</h3>
          <h4 className="text-xs pt-[6px] text-neutral-60">{contact.company_contract}</h4>
        </div>
      </div>
      <div className="right-0 top-3 absolute">
        <div className="flex gap-x-3">
          {contactSent && contactSent.some((item) => item.user_id === contact.user_id && item.isSent === false) ? (
            <ButtonCommon
              onClick={handleSentContact}
              className="bg-primary-900 text-white rounded border px-3 py-1 text-xs"
              title={t("action.add")}
              isPending={pendingAddContact}
              disabled={userContactSelected?.user_id === contact.user_id && isDisableBtn}
            />
          ) : (
            <ButtonCommon
              title={t("action.cancel")}
              onClick={handleDeleteSentContact}
              className="border-neutral-60 rounded border px-3 py-1 text-xs text-neutral-110"
              isPending={pendingDeleteContact}
              disabled={userContactSelected?.user_id === contact.user_id && isDisableBtn}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SentItem;
