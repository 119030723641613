import { User, UserContact } from "types/user.type";
import { t } from "i18next";
interface JobInformationProp {
  userDetailContact: UserContact | undefined | User | null;
}
const JobInformation = ({ userDetailContact }: JobInformationProp) => {
  return (
    <>
      <h3 className="text-neutral-110 mb-4">{t("contact.profile.job_information")}</h3>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.email")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.email ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2  mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.work_location")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.work_location ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.department")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.department ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.position")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.position ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.form_of_working")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.form_of_working ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.personnel_status")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.personnel_status ?? "-"}</p>
      </div>
      <div className="grid grid-cols-2 mb-4">
        <h4 className="text-sm text-neutral-60">{t("contact.profile.from_of_salary")}</h4>
        <p className="overflow-wrap-anywhere text-neutral-110">{userDetailContact?.salary_calculation_method ?? "-"}</p>
      </div>
    </>
  );
};

export default JobInformation;
