import { Typography } from "@material-tailwind/react";
import AvatarLoadingSkeleton from "./AvatarLoadingSkeleton";

const ConversationLoadingSkeleton = () => {
  const fakeData = Array.from({ length: 10 }, (_, i) => i + 1);
  return (
    <>
      {fakeData.map((_, index) => (
        <div key={index} className="flex w-full gap-2 p-3 pr-2 cursor-pointer animate-pulse">
          <AvatarLoadingSkeleton />
          <div className="flex flex-col justify-between flex-1">
            <div className="flex items-start justify-between">
              <Typography placeholder="" as="div" variant="h1" className="h-3 mb-4 bg-gray-300 rounded-full w-36">
                &nbsp;
              </Typography>
            </div>
            <p className="w-56 h-3 mb-4 bg-gray-300 rounded-full"></p>
          </div>
        </div>
      ))}
    </>
  );
};

export default ConversationLoadingSkeleton;
