import PlayIcon from "components/icon/PlayIcon";
import AvatarLoadingSkeleton from "components/loading/AvatarLoadingSkeleton";
import { useState } from "react";

interface ImageWithLoaderTypeProp {
  src: string;
  className: string;
  isVideo?: boolean;
  originalHeight?: number;
  isDelete?: boolean;
  handleOpenPreview?: () => void;
  isPinMessage?: boolean;
}
const ImageWithLoader = ({
  src,
  className,
  isVideo = false,
  originalHeight = 0,
  isDelete = false,
  handleOpenPreview = () => {},
  isPinMessage = false,
}: ImageWithLoaderTypeProp) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };
  return (
    <>
      {(!loaded || isDelete) && (
        <div className="place-items-center animate-pulse">
          <AvatarLoadingSkeleton
            isPinMessage={isPinMessage}
            className={`${className} rounded-sm ${!isPinMessage ? "min-h-20" : ""}`}
            style={{
              ...(originalHeight ? { height: originalHeight } : {}),
            }}
          />
        </div>
      )}
      {!isVideo && !isDelete && (
        <img
          onClick={handleOpenPreview}
          src={src}
          alt="load"
          onLoad={handleImageLoaded}
          className={`${className} ${loaded ? "block" : "hidden"}`}
        />
      )}
      {isVideo && !isDelete && (
        <div className={`relative group ${loaded ? "block" : "hidden"}`} onClick={handleOpenPreview}>
          <div className="absolute inset-0 z-10 bg-black opacity-50"></div>
          <div className="absolute inset-0 z-20 flex items-center justify-center text-white transition-all hover:scale-75 ">
            <PlayIcon />
          </div>
          <img src={src} alt="load" onLoad={handleImageLoaded} className={`${className} ${loaded ? "block" : "hidden"}`} />
        </div>
      )}
    </>
  );
};

export default ImageWithLoader;
