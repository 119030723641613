import AvatarCatchError from "components/image/AvatarCatchError";
import Member from "components/user/Member";
import { useCustomSelector } from "hook/redux";
import { memo } from "react";

interface MessageToPropType {
  member_id: number;
  toAll?: boolean;
  member_name:string;
}
const MessageTo = ({ member_id, toAll = false, member_name }: MessageToPropType) => {
  const { userInfo } = useCustomSelector((state) => state.auth);
  const isTo = (userInfo?.id === member_id) || toAll;
  return (
    <div
      className={`inline-flex w-auto ${
        isTo ? "bg-primary-900" : "bg-neutral-30"
      }  px-[3px] py-[1px] rounded-md items-center gap-1  mr-[2px] mb-[2px]`}
    >
      <p className={`mr-1 text-sm font-bold leading-5 ${isTo ? "text-neutral-10" : "text-primary-900"}`}>
        TO
      </p>
      {!toAll && <Member member_name={member_name} member_id={member_id} className={`${isTo ? "text-neutral-10" : "text-neutral-110"}`} />}
      {toAll && (
        <div className="flex items-center gap-1">
          <AvatarCatchError src="" className="w-4 h-4" isConversation={true} />
          <p className="text-sm font-bold text-white">All</p>
        </div>
      )}
    </div>
  );
};

export default memo(MessageTo);
