
export enum LIMIT {
  CONVERSATION = 50,
  MESSAGE = 40,
  ALL = 2000,
  MEMBER_IN_CONVERSATION = 100,
  FILES = 40,
  SEARCH_CONTACT = 40,
  LIMIT_SEARCH_ALL = 5,
}
export enum TYPE_FILE {
  IMAGE = 1,
  VIDEO,
  RADIO,
  OTHER,
  LINK,
}
export enum TYPE_FILE_OTHER {
  WORD = 1,
  EXCEL,
  PDF,
  UNKNOWN,
}
export enum TYPE_MESSAGE {
  TEXT = 1,
  FILE,
  EMOJI,
  CALL,
}
export enum ACTION_CLICK_MESSAGE {
  REPLY = 1,
  REACTION,
  QUOTE,
  TASK,
  DELETE,
  FORWARD,
  EDIT,
  PIN_MESSAGES,
  UNPIN_MESSAGES,
}
export enum TYPE_SCREEN_CHAT {
  MAIN = 1,
  REPLY,
}
export const ACCEPT_INPUT_SEND = {
  IMG_VIDEO_AUDIO: ".jpeg, .png, .jpg, .gif, .jfif, .mp4, .mov, .mp3, weba, webm",
  FILE_OTHER: "*",
};
export const FILE_EXTENSIONS = {
  IMAGE: ["jpeg", "png", "jpg", "gif", "jfif"],
  VIDEO: ["mp4", "mov"],
  AUDIO: ["mp3", "webm", "weba"],
  DOCUMENT: ["xls", "xlsx", "pdf", "doc", "docx", "pptx", "zip"],
};

export const SUPPORTED_IMG_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/jfif"];
export const FILE_IMG_SIZE = 10240 * 1024;

export enum ROLE_MEMBER {
  ADMIN = 1,
  MEMBER = 0,
}

export const REDUX_ACTION_TYPE = {
  TOKEN_VALID: "auth/setTokenInvalid",
  SET_USER_INFO: "auth/setUserInfo",
};

export enum NOTIFICATION_TYPE {
  NOTIFICATION_TYPE_MESSAGE = 2,
  NOTIFICATION_TYPE_CONTACT,
  NOTIFICATION_TYPE_CONVERSATION
}

export const TEXT_AREA_HEIGHT = {
  MAX_HEIGHT: "87px",
  MIN_HEIGHT: "40px",
  MIN_HEIGHT_REPLY: "17px",
};

// The current user's friendship status with the other person
export const CONTACT_STATUS = {
  // Status when an invitation has been sent from another user to the current user
  SENT_INVITE_CONTACT: 1,
  // Status when the current user receives an invitation from the logged in user
  RECEIVED_INVITE_CONTACT: 2,
};

export const MAX_SHOW_ITEM_SEARCH = 5;
export enum TYPE_SEARCH_MAIN {
  ALL = 1,
  CONTACT,
  MESSAGE,
  FILE,
}

export enum LIST_POPUP_SELECT {
  IN_CONVERSATION = 1,
  IN_CONTACT
}
