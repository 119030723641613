interface SearchIconProp {
  color?: string;
}
const SearchIcon = ({ color = "#B2AAA2" }: SearchIconProp) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1073 16.79L13.9864 13.4C14.9933 12.0025 15.5397 10.2767 15.5376 8.5C15.5376 6.91775 
          15.107 5.37103 14.3001 4.05544C13.4932 2.73985 12.3463 1.71447 11.0045 
          1.10897C9.6627 0.503466 8.18622 0.34504 6.76176 0.653721C5.3373 0.962403 4.02885 1.72433 
          3.00188 2.84315C1.9749 3.96197 1.27552 5.38743 0.992177 6.93928C0.708834 8.49113 0.854256 
          10.0997 1.41005 11.5615C1.96585 13.0233 2.90705 14.2727 4.11465 15.1518C5.32225 16.0308 
          6.742 16.5 8.19436 16.5C9.82517 16.5022 11.4093 15.907 12.6921 14.81L15.8038 18.21C15.8892 
          18.3037 15.9907 18.3781 16.1025 18.4289C16.2144 18.4797 16.3344 18.5058 16.4556 18.5058C16.5767 
          18.5058 16.6967 18.4797 16.8086 18.4289C16.9204 18.3781 17.0219 18.3037 17.1073 18.21C17.1933 
          18.117 17.2616 18.0064 17.3082 17.8846C17.3548 17.7627 17.3788 17.632 17.3788 17.5C17.3788 17.368 
          17.3548 17.2373 17.3082 17.1154C17.2616 16.9936 17.1933 16.883 17.1073 16.79ZM2.6869 8.5C2.6869 
          7.31332 3.00991 6.15328 3.61507 5.16658C4.22024 4.17989 5.08039 3.41085 6.08675 2.95673C7.0931 
          2.5026 8.20047 2.38378 9.26881 2.61529C10.3372 2.8468 11.3185 3.41825 12.0887 4.25736C12.859 
          5.09648 13.3835 6.16558 13.596 7.32946C13.8085 8.49335 13.6994 9.69975 13.2826 10.7961C12.8657 
          11.8925 12.1598 12.8295 11.2541 13.4888C10.3484 14.1481 9.28363 14.5 8.19436 14.5C6.73369 14.5 
          5.33285 13.8679 4.3 12.7426C3.26715 11.6174 2.6869 10.0913 2.6869 8.5Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
