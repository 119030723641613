import { User } from "./user.type";
import { SuccessResponse } from "./utils.type";

export type AuthResponse = SuccessResponse<User>;
export interface AuthLogoutResponse {
    message: string;
    code: number;
}

export const LOGIN_GRANT_TYPE_CODE = {
  AUTHORIZATION_CODE: "authorization_code",
  REFRESH_TOKEN: "refresh_token",
};
