import { Dispatch, SetStateAction, useState } from "react";
import Modal from "react-modal";

interface ModalProps {
  closeModal: () => void;
  overlayClassName?: string;
  className?: string;
  children: React.ReactNode;
}

const CustomModal = ({ closeModal, className, children }: ModalProps) => {
  const [openModal, setOpenModal] = useState(true);
  const handleCloseModalCommon = (closeModal: () => void, setOpenModal: Dispatch<SetStateAction<boolean>>) => {
    setOpenModal(false);
    closeModal();
  };
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => handleCloseModalCommon(closeModal, setOpenModal)}
      overlayClassName={"overlay-dialog"}
      className={`dialog-container ${className}`}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
