import { yupResolver } from "@hookform/resolvers/yup";
import { DialogHeader } from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import ChevronLeftIcon from "components/icon/ChevronLeftIcon";
import { t } from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import { schema, SchemaPick } from "utils/rules";
import DialogChangePassword from "./DialogChangePassword";
import { changePassword, ChangePassword } from "http/user.http";
import { toast } from "react-toastify";
import { ErrorResponse, SuccessResponse } from "types/utils.type";
import { STATUS_API, STATUS_CODE } from "constants/statusCode";
import { AxiosError } from "axios";
import { useEffect } from "react";
import CustomModal from "components/Modal/ModalCommon";

// declare props type
interface DialogChangePasswordContainerProp {
  closeModal: () => void;
}

// pick yup rule validate
type FormData = Pick<SchemaPick, "password" | "new_password" | "confirm_password">;
const changePasswordSchema = schema.pick(["password", "new_password", "confirm_password"]);

const DialogChangePasswordContainer = ({ closeModal }: DialogChangePasswordContainerProp) => {

  const methods = useForm<FormData>({
    resolver: yupResolver(changePasswordSchema),
    mode: "onSubmit",
  });
  const { handleSubmit } = methods;

  const { mutate, isPending, status, error } = useMutation({
    mutationFn: (newData: ChangePassword) => changePassword<SuccessResponse<[]>>(newData),
    onSuccess: (response) => {
      if (response.code === STATUS_CODE.Ok) {
        toast.success(response.message);
        closeModal();
      }
    },
    onError: (error: AxiosError<ErrorResponse>) => {},
  });

  useEffect(() => {
    if (status === STATUS_API.Error) {
      if (error?.response?.data.message) {
        toast.error(error.response?.data.message);
      } else {
        toast.error(t("exception.server_error"));
      }
    }
  }, [status]);

  const handleChangePassword = (data: FormData) => {
    const sendData = {
      old_password: data.password,
      new_password: data.new_password,
    };
    mutate(sendData);
  };

  return (
    <>
      <CustomModal closeModal={closeModal} className="!max-w-[512px] !w-full max-h-[95vh] overflow-hidden flex flex-col">
        <DialogHeader placeholder={"dialog"} className="flex items-center justify-between px-3">
          <div className="flex items-center gap-x-2">
            <ChevronLeftIcon className="pb-1" onClick={closeModal} />
            <p className="font-bold text-black text-[22px]">{t("dialog.header.change_password")}</p>
          </div>
        </DialogHeader>
        <FormProvider {...methods}>
          <form
            className="space-y-4 md:space-y-6 hover:overflow-y-scroll scroll-sidebar overflow-hidden h-[818px]"
            onSubmit={handleSubmit(handleChangePassword)}
          >
            <DialogChangePassword isPending={isPending} />
          </form>
        </FormProvider>
      </CustomModal>
    </>
  );
};

export default DialogChangePasswordContainer;
