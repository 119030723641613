const LikeReaction = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_995_85655)">
        <path
          d="M28.6091 23.4287C28.6103 23.6239 28.5754 23.8177 28.5062 24.0002C28.4251 24.242 28.288 24.4613 28.1062 
          24.6402C27.7853 24.9621 27.3494 25.1431 26.8948 25.143H25.7519C26.6987 25.143 27.4662 25.9105 27.4662 
          26.8573C27.4674 27.0525 27.4325 27.2462 27.3633 27.4287C27.2822 27.6706 27.1452 27.8898 26.9633 28.0687C26.6424
           28.3907 26.2065 28.5716 25.7519 28.5716H24.6091C25.5558 28.5716 26.3233 29.3391 26.3233 30.2859C26.3233 
           31.2327 25.5558 32.0002 24.6091 32.0002H14.3233C13.4491 32.0139 12.5802 31.8607 11.7633 31.5487C11.2033 
           31.3259 10.7119 30.9659 10.1691 30.703C9.5927 30.4279 8.96202 30.2853 8.32335 30.2859H6.89478L8.03763 
           18.8573C9.20087 18.856 10.3393 18.5208 11.3176 17.8916C13.3805 16.5773 16.5062 14.1887 15.8319 11.1487C15.6033 
           10.1259 15.5176 9.12589 16.6091 8.5716C17.8205 7.95446 18.9805 8.73732 19.5462 9.72589C21.4491 13.0573 18.1233
            18.2859 18.1233 18.2859H26.3233C27.2701 18.2859 28.0376 19.0534 28.0376 20.0002C28.0388 20.1954 28.004 20.3891
             27.9348 20.5716C27.8536 20.8134 27.7166 21.0327 27.5348 21.2116C27.2138 21.5336 26.7779 21.7145 26.3233 
             21.7145H26.8948C27.8416 21.7145 28.6091 22.482 28.6091 23.4287Z"
          fill="#FDD7AD"
        />
        <path
          d="M27.3635 27.4287C27.2823 27.6705 27.1453 27.8898 26.9635 28.0687C26.6426 28.3907 26.2066 
          28.5716 25.7521 28.5716H22.8949C22.5793 28.5716 22.3235 28.3157 22.3235 28.0001C22.3235 27.6845 
          22.5793 27.4287 22.8949 27.4287H27.3635Z"
          fill="#F9C795"
        />
        <path
          d="M28.5063 24C28.4252 24.2418 28.2882 24.4611 28.1063 24.64C27.7854 24.962 27.3495 25.1429 
          26.8949 25.1429H22.8949C22.5793 25.1429 22.3235 24.887 22.3235 24.5714C22.3235 24.2558 22.5793 24 22.8949 24H28.5063Z"
          fill="#F9C795"
        />
        <path
          d="M27.9349 20.5713C27.8538 20.8131 27.7167 21.0324 27.5349 21.2113C27.214 21.5332 26.7781 21.7142 26.3235 
          21.7141H22.8949C22.5793 21.7141 22.3235 21.4583 22.3235 21.1427C22.3235 20.8271 22.5793 20.5713 22.8949 20.5713H27.9349Z"
          fill="#F9C795"
        />
        <path
          d="M6.32338 17.1426H3.46624C2.51947 17.1426 1.75195 17.9101 1.75195 18.8569V30.2854C1.75195 31.2322 
          2.51947 31.9997 3.46624 31.9997H6.32338C7.27016 31.9997 8.03767 31.2322 8.03767 30.2854V18.8569C8.03767 
          17.9101 7.27016 17.1426 6.32338 17.1426Z"
          fill="#55A1FF"
        />
        <path
          d="M28.0375 12.5718C27.7219 12.5718 27.4661 12.316 27.4661 12.0004V10.8576C27.4661 10.542 27.7219 10.2861 
          28.0375 10.2861C28.3531 10.2861 28.6089 10.542 28.6089 10.8576V12.0004C28.6089 12.316 28.3531 12.5718 28.0375 12.5718Z"
          fill="#EF4F43"
        />
        <path
          d="M28.0375 16.0006C27.7219 16.0006 27.4661 15.7447 27.4661 15.4291V14.2863C27.4661 13.9707 27.7219 13.7148 
          28.0375 13.7148C28.3531 13.7148 28.6089 13.9707 28.6089 14.2863V15.4291C28.6089 15.7447 28.3531 16.0006 28.0375 16.0006Z"
          fill="#EF4F43"
        />
        <path
          d="M30.3232 13.7151H29.1803C28.8647 13.7151 28.6089 13.4593 28.6089 13.1437C28.6089 12.8281 28.8647 12.5723 
          29.1803 12.5723H30.3232C30.6388 12.5723 30.8946 12.8281 30.8946 13.1437C30.8946 13.4593 30.6388 13.7151 30.3232 13.7151Z"
          fill="#EF4F43"
        />
        <path
          d="M26.8947 13.7151H25.7518C25.4363 13.7151 25.1804 13.4593 25.1804 13.1437C25.1804 12.8281 25.4363 
          12.5723 25.7518 12.5723H26.8947C27.2103 12.5723 27.4661 12.8281 27.4661 13.1437C27.4661 13.4593 27.2103 13.7151 26.8947 13.7151Z"
          fill="#EF4F43"
        />
        <path
          d="M14.8948 4.57149C14.7433 4.57146 14.598 4.51123 14.4908 4.40406L13.6337 3.54692C13.4851 3.40345 
          13.4256 3.19099 13.4779 2.9912C13.5302 2.79141 13.6862 2.63539 13.886 2.58309C14.0858 2.5308 14.2982 
          2.59037 14.4417 2.73892L15.2988 3.59606C15.4622 3.75949 15.5111 4.00522 15.4227 4.21872C15.3342 4.43222 15.1259 
          4.57144 14.8948 4.57149Z"
          fill="#BB99FF"
        />
        <path
          d="M12.3234 1.99981C12.1719 1.99978 12.0265 1.93956 11.9194 1.83239L11.0623 0.975243C10.8457 0.751026 10.8488 
          0.394623 11.0692 0.174204C11.2896 -0.0462157 11.646 -0.0493127 11.8703 0.167243L12.7274 1.02439C12.8908 1.18781 
          12.9396 1.43355 12.8512 1.64705C12.7628 1.86054 12.5545 1.99977 12.3234 1.99981Z"
          fill="#BB99FF"
        />
        <path
          d="M11.4663 4.57149C11.2353 4.57144 11.027 4.43222 10.9385 4.21872C10.8501 4.00522 10.899 3.75949 11.0623 
          3.59606L11.9195 2.73892C12.063 2.59037 12.2754 2.5308 12.4752 2.58309C12.675 2.63539 12.831 2.79141 12.8833 
          2.9912C12.9356 3.19099 12.876 3.40345 12.7275 3.54692L11.8703 4.40406C11.7632 4.51123 11.6179 4.57146 11.4663 4.57149Z"
          fill="#BB99FF"
        />
        <path
          d="M14.0376 2.0002C13.8065 2.00015 13.5982 1.86093 13.5098 1.64743C13.4214 1.43394 13.4703 1.1882 13.6336 
          1.02477L14.4908 0.167629C14.6342 0.019082 14.8467 -0.0404928 15.0465 0.0118017C15.2463 0.0640962 15.4023
           0.220122 15.4546 0.41991C15.5069 0.619698 15.4473 0.832158 15.2988 0.975629L14.4416 1.83277C14.3345 1.93994 
           14.1892 2.00017 14.0376 2.0002Z"
          fill="#BB99FF"
        />
        <path
          d="M26.989 4.98594C25.552 5.52998 24.4192 6.66696 23.8805 8.10594C23.8371 8.21813 23.7281 8.29114 23.6079 
          8.28859C23.4876 8.28603 23.3819 8.20844 23.3433 8.09451C22.798 6.66232 21.6669 5.53124 20.2348 4.98594C20.1177 
          4.94886 20.0381 4.84019 20.0381 4.71737C20.0381 4.59454 20.1177 4.48588 20.2348 4.4488C21.6701 3.90831 22.8028 
          2.77553 23.3433 1.34023C23.3804 1.22313 23.4891 1.14355 23.6119 1.14355C23.7347 1.14355 23.8434 1.22313 23.8805
           1.34023C24.421 2.77947 25.5593 3.91352 27.0005 4.4488C27.1127 4.49216 27.1857 4.60113 27.1831 4.72138C27.1806 
           4.84162 27.103 4.94739 26.989 4.98594Z"
          fill="#FFD54F"
        />
        <path
          d="M10.0606 10.0172C9.27224 10.4029 8.63488 11.0403 8.24913 11.8286C8.20567 11.9322 8.10431 11.9996 7.99199 
          11.9996C7.87966 11.9996 7.7783 11.9322 7.73484 11.8286C7.35032 11.0394 6.71261 10.4017 5.92342 10.0172C5.81984
           9.97374 5.75244 9.87238 5.75244 9.76005C5.75244 9.64773 5.81984 9.54637 5.92342 9.50291C6.71261 9.11838 7.35032
            8.48067 7.73484 7.69148C7.7783 7.5879 7.87966 7.52051 7.99199 7.52051C8.10431 7.52051 8.20567 7.5879 8.24913 
            7.69148C8.63488 8.4798 9.27224 9.11716 10.0606 9.50291C10.1641 9.54637 10.2315 9.64773 10.2315 9.76005C10.2315 
            9.87238 10.1641 9.97374 10.0606 10.0172Z"
          fill="#FFD54F"
        />
      </g>
      <defs>
        <clipPath id="clip0_995_85655">
          <rect width={32} height={32} fill="white" transform="translate(0.323486)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LikeReaction;
