import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from "@tanstack/react-query";
import InputSearch from "components/input/InputSearch";
import ConversationLoadingSkeleton from "components/loading/ConversationLoadingSkeleton";
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef } from "react";
import { Contact } from "types/contact.type";
import { ChangeEvent } from "types/event.type";
import { PaginationResponse } from "types/utils.type";
import ContactItem from "./ContactItem";
import { isEmpty } from "lodash";
import { useInView } from "react-intersection-observer";
import { Spinner } from "@material-tailwind/react";
import { useCustomDispatch, useCustomSelector } from "hook/redux";
import { setScrollId } from "../../../redux/slices/contact";
import { scrollToElementDelay } from "utils/common";

interface AllContactListProp {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<PaginationResponse | undefined, unknown>, Error>>;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  contacts: Contact[];
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  setOpenDialogDeleteContact: Dispatch<SetStateAction<boolean>>;
  total: number | undefined;
}
//init
const AllContactList = ({
  contacts,
  fetchNextPage,
  total,
  setOpenDialogDeleteContact,
  isFetchingNextPage,
  isLoading,
  setSearchKeyword,
}: AllContactListProp) => {
  const { ref, inView } = useInView();
  const { scrollId } = useCustomSelector((state) => state.contact);
  const listRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useCustomDispatch();
  //flow scroll
  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useLayoutEffect(() => {
    if (scrollId && listRef.current && !isLoading) {
      scrollToElementDelay(listRef, `.contact-item-${scrollId}`);
      dispatch(setScrollId(null));
    }
  }, [scrollId, isLoading]);
  return (
    <div className="flex flex-col w-full h-full py-6 pb-3 pl-4 overflow-hidden">
      <div>
        <h2 className="mb-2 text-xl font-bold">Contact ({total ?? 0})</h2>
        <InputSearch
          className="flex-1 h-8 px-2 rounded-full mb-2 bg-neutral-20"
          onChange={(e: ChangeEvent) => setSearchKeyword(e.target.value)}
        />
      </div>
      <div className="flex-1 w-full h-full overflow-y-auto scroll-small pr-2" ref={listRef}>
        {isLoading ? (
          <ConversationLoadingSkeleton />
        ) : (
          !isEmpty(contacts) && (
            <>
              {contacts.map((contact) => (
                <ContactItem setOpenDialogDeleteContact={setOpenDialogDeleteContact} key={contact.user_id} contact={contact} />
              ))}
              <div ref={ref}></div>
            </>
          )
        )}
        <div className="flex justify-center">{isFetchingNextPage && <Spinner className="w-10 h-10" />}</div>
      </div>
    </div>
  );
};
export default AllContactList;
