interface CloseIconProp {
  onClick: () => void;
  className?: string;
  width?: number;
  height?: number;
}
const CLoseIcon = ({ onClick, className = "text-black", width = 14, height = 14 }: CloseIconProp) => {
  return (
    <div onClick={onClick} className={`cursor-pointer ${className}`}>
      <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1L1 13" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 1L13 13" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default CLoseIcon;
